import { useStyles } from "./style"
import Topo from "../../Componentes/TopCategorias"
import Switch from "@material-ui/core/Switch"
import { button } from "../../Componentes/Inputs/TextFild"
import { useEffect, useState } from "react"
import ReactQuill from "react-quill"
import complementsRequest from "../../Services/api/privacypolicy"
import "react-quill/dist/quill.snow.css"
import { CircularProgress } from "@material-ui/core"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export default function PoliticaPrivacidade() {
  const [enableInputs, setEnableInputs] = useState(false)
  const [textAreaValue, setTextAreaValue] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const handleRedirect = () => {
    history.replace("/personalizar-lojinha/complementos")
    history.goBack()
  }
  const formats = [
    "background",
    "bold",
    "color",
    "font",
    "code",
    "italic",
    "link",
    "size",
    "strike",
    "script",
    "underline",
    "blockquote",
    "header",
    "indent",
    "list",
    "align",
    "direction",
    "code-block",
    "formula"
  ]
  const [alertType, setAlertType] = useState("")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")

  const classes = useStyles()

  async function getPolitics() {
    setLoading(true)
    try {
    const { data } = await complementsRequest.get()

    setEnableInputs(data.data.complements_privacy_policy_status)
    setTextAreaValue(data.data.complements_privacy_policy.text)
    } catch(error){
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  function sendData() {
    setLoading(true);
    try {
      const textValue = textAreaValue.replace(/<[^>]*>/g, "").trim();
      
      if (!enableInputs || textValue !== "") {
        complementsRequest.put({
          complements_privacy_policy_status: enableInputs,
          complements_privacy_policy: {
            status: enableInputs,
            text: textAreaValue
          }
        });
        setAlertType("success");
        setAlertMessage("Atualizado com sucesso!");
        setShowModalAlert(true);
        setTimeout(() => {
          handleRedirect();
        }, 1000);
      } else {
        setAlertType("error");
        setAlertMessage("Preencha o campo antes de salvar.");
        setShowModalAlert(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  
  

  useEffect(() => {
    getPolitics()
  }, [])

  return (
    <div className="page-container">
      <div className="page-align">
        <div>
          <Topo name="Políticas de privacidade" />
          {loading ? (
              <div
                style={{
                  backgroundColor: "#f9f9f9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%"
                }}
              >
                <CircularProgress color="secondary" size="40px" />
              </div>
            ) : (
              <>
          <div className={classes.ContainerForms}>
            <div className={classes.InputBox}>
              <div className={classes.InputLabel}>
                <label>Ativar</label>
                <Switch
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onClick={() => {
                    setEnableInputs(!enableInputs)
                  }}
                  checked={enableInputs}
                />
              </div>
              {enableInputs ? (
                <h3 className={classes.Subtitle}>
                  Descritivo sobre a política de privacidade da sua loja:
                </h3>
              ) : null}
              {enableInputs ? (
                <ReactQuill
                  theme="snow"
                  value={textAreaValue}
                  readOnly={!enableInputs}
                  onChange={setTextAreaValue}
                  formats={formats}
                />
              ) : null}
            </div>
          </div>
          </>
            )}
        </div>
        <div className="footer-column">
          <hr style={{ width: "100%" }} />
          {button({
            name:"Salvar alterações",
            funcao: sendData,
            disabled: loading
          })}
        </div>
      </div>
      <CustomAlert
        type={alertType}
        alertText={alertMessage}
        show={showModalAlert}
        close={() => setShowModalAlert(false)}
      />
    </div>
  )
}
