import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  textField: {
    '& p': {
      margin: '20px 0 5px 0'
    }
  },

  bestShipping: {
    '& .MuiAutocomplete-root *': {
      borderColor: '#D0D0D0 !important',
      outline: 'none !important',
      color: '#747474'
    },

    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'none'
    },
  },

  formControl: {
    margin: '25px 0 45px 0',

    '& .MuiFormLabel-root': {
      fontSize: '15px',
      fontWeight: '500',
      color: '#353535',
      marginBottom: '4px'
    },

    '& .MuiFormControlLabel-root': {
      marginBottom: '-8px'
    },

    '& .MuiTypography-body1': {
      fontSize: '15px',
      color: '#555555',
    }
  },

  btnSave: {
    color: "#fff",
    minWidth: "48%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "10px",
    textTransform: "none",
    fontWeight: "bold",
    marginBottom: "10px",
    backgroundColor: "#EA3458",

    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#ff0053c9"
    }
  },
}))


