import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  Banner: {
    background: "#ECECEC",
    width: "100%",
    minHeight: "100px",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px"
  },

  Box: {
    marginTop: "10px",
    cursor: "pointer"
  },

  boxContainer: {
    borderBottom: "1px solid #F3F3F3",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  rootConfig: {
    width: "100%",
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    justifyContent: "flex-start"
  },

  boxConteudo: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px"
  },

  btnalinhamento: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  fonteTitulo: {
    display: "flex",
    alignItems: "left",
    color: "#353535",
    fontSize: "14px",
    marginTop: "10px"
  },

  fonteSubtitulo: {
    display: "flex",
    color: "#6C6C6C",
    fontSize: "12px",
    marginTop: "5px",
    marginBottom: "5px"
  },
  btnNews: {
    backgroundColor: "#EA3458",
    borderRadius: "10px",
    color: "#fff",
    border: "none",
    textAlign: "center",
    margin: "0px 10px"
  }
}))
