import React from "react"

function moneyMount() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="62"
      fill="none"
      viewBox="0 0 62 62"
    >
      <path
        fill="#6C6C6C"
        d="M31 0C13.879 0 0 13.88 0 31c0 17.12 13.879 31 31 31 17.12-.001 31-13.88 31-31C62 13.88 48.12 0 31 0zm.002 59.933C25.297 59.93 20.67 55.39 20.67 46.34V42.21c0-.55.116-.878.85-.878h.752v3.638c0 9.175 3.43 13.282 8.73 13.285 5.302 0 8.723-4.11 8.726-13.287v-3.636h.757c.731 0 .85.328.85.878v4.128c.001 9.05-4.627 13.59-10.332 13.594zm-7.899-14.962v-3.638h15.792v3.636c0 8.626-3.095 12.376-7.893 12.376-4.796-.003-7.899-3.753-7.899-12.374zm16.674 13.031c1.693-1.96 2.953-4.722 3.417-8.452 3.517-3.005 5.375-7.22 5.375-11.317 0-.51-.405-1.033-1.163-1.033h-32.81c-.757 0-1.163.524-1.163 1.033 0 4.098 1.858 8.313 5.377 11.317.464 3.747 1.736 6.519 3.439 8.479C10.85 54.33 2.583 43.616 2.583 31 2.583 15.33 15.331 2.583 31 2.583c15.668 0 28.417 12.748 28.417 28.417 0 12.604-8.259 23.292-19.64 27.002z"
      ></path>
      <path
        fill="#6C6C6C"
        d="M17.532 19.384c0-2.5 5.43-1.297 7.26.4 1.078-1.771.566-3.264.566-3.264s-1.572-1.11-3.885-1.536l-.006-2.323c-1.899-.585-3.94 0-3.94 0v2.159s-3.747.689-3.747 4.565c0 5.02 7.69 4.05 7.69 7.284 0 2.358-5.163 1.375-7.69-.506-.784 1.9 0 3.538 0 3.538 1.198.511 2.457.865 3.745 1.053l.007 2.038s2.04.622 3.94 0v-2.174s4.361-.24 4.361-3.948c0-4.82-8.3-4.404-8.3-7.286zM40.266 19.384c0-2.5 5.429-1.297 7.26.4 1.078-1.771.565-3.264.565-3.264s-1.571-1.11-3.885-1.536l-.005-2.323c-1.9-.585-3.94 0-3.94 0v2.159s-3.747.689-3.747 4.565c0 5.02 7.69 4.05 7.69 7.284 0 2.358-5.164 1.375-7.69-.506-.784 1.9 0 3.538 0 3.538 1.198.51 2.456.865 3.745 1.053l.006 2.038s2.04.622 3.94 0v-2.174s4.362-.24 4.362-3.948c0-4.82-8.301-4.404-8.301-7.286zM29.686 54.37l.003 1.325s1.274.404 2.462 0v-1.413s2.727-.157 2.727-2.567c0-3.132-5.188-2.862-5.188-4.735 0-1.624 3.394-.842 4.537.26.674-1.15.356-2.12.356-2.12s-.983-.723-2.43-1l-.003-1.51c-1.186-.38-2.462 0-2.462 0v1.403s-2.342.448-2.342 2.966c0 3.264 4.805 2.633 4.805 4.735 0 1.533-3.227.894-4.805-.328a3.14 3.14 0 000 2.3 9.12 9.12 0 002.34.684z"
      ></path>
    </svg>
  )
}

export default moneyMount
