import React, { useEffect, useState } from "react"
import DeleteIcon from "@material-ui/icons/Delete"
import Modal from "@material-ui/core/Modal"
import { TextField } from "@material-ui/core"
import conexao from "../../../../Services/api"
import { useStyles } from "./style"
import { StateGlobal } from "../../../../ContextAPI/index"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../../../Componentes/Alerts/CustomAlert"
import { Alert } from "@material-ui/lab"

export default function SimpleModal(props) {
  const classes = useStyles()
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [excluir, setExcluir] = useState()
  const [btnDisable, setBtnDisable] = useState(true)
  const { idByOrder, setOrderPagesIndex, setOpenDeleteModal } = StateGlobal()
  const history = useHistory()

  function validateBtn() {
    if (String(excluir).toLocaleUpperCase() === "EXCLUIR") {
      setBtnDisable(false)
    } else {
      setBtnDisable(true)
    }
  }

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  useEffect(() => {
    validateBtn()
  }, [excluir])

  async function deleteOrder() {
    if (String(excluir).toLocaleUpperCase() === "EXCLUIR") {
      const resDel = await conexao.delete(`/order/delete/${idByOrder}`)
      if (resDel) {
        setAlertType("success")
        setShowAlertOk(true)

        setTimeout(() => {
          setOrderPagesIndex(0)
          setOpenDeleteModal(false)
        }, [2000])
        setBtnDisable(false)
      }
    } else {
      setAlertType("error")
      setShowAlertError(true)
      setBtnDisable(false)
    }
  }

  const body = (
    <div className={classes.paper}>
      <div className={classes.titleText}>
        <div className={classes.title}>Excluir Pedido</div>

        <div className={classes.text}>
          Ao excluir um pedido, todos os dados referentes a ele serão apagados
          definitivamente do banco de dados e da sua conta. <br />
          Você não terá mais acesso ao pedido e nem às informações inseridas nele
          (como cliente, contatos, itens, etc.).
          <br /> <br />
          Caso você queira prosseguir com a exclusão, informe sua senha abaixo e
          clique em “Excluir”.
        </div>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          marginLeft: "30px",
          marginBottom: "5px"
        }}
      >
        <spam style={{ color: "#555555", fontWeight: "400", fontSize: "11px" }}>
          Para prosseguir digite <b>“EXCLUIR”</b>
        </spam>
      </div>

      <div className={classes.textExcluir}>
        <TextField
          className={classes.textInput}
          id="outlined-size-small"
          value={excluir}
          onChange={e =>
            setExcluir(String(e.target.value).replace(/\s/g, "").toLocaleUpperCase())
          }
          variant="outlined"
          size="small"
          color="secondary"
        />
      </div>

      <div className={classes.groupBtn}>
        <div className={classes.btnModal}>
          <button
            className={classes.btnCancelarStyle}
            onClick={() => {
              props.fechar()
            }}
          >
            Cancelar
          </button>
        </div>
        <div className={classes.btnModal}>
          {btnDisable ? (
            <button
              className={classes.btnDisable}
              onClick={() => {
                deleteOrder()
              }}
            >
              Excluir
            </button>
          ) : (
            <button
              className={classes.btnExcluirStyle}
              onClick={() => {
                deleteOrder()
              }}
            >
              Excluir
            </button>
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px"
        }}
      >
        {/* alertas */}
        <CustomAlert
          type={alertType}
          alertText={
            alertType === "success"
              ? "Pedido excluido com sucesso!"
              : "Algo deu errado!"
          }
          show={showAlertOk || showAlertError}
          close={() => closeAlerts()}
        />
      </div>
    </div>
  )

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          aria-hidden="true"
          id="modal"
          onClick={() => {
            // props.fechar()
          }}
          className={classes.positionModal}
        >
          {body}
        </div>
      </Modal>
    </div>
  )
}
