import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  SelectWrapper: {
    width: "100%",
    gridTemplateRows: "1fr 1fr",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    display: "grid",
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "center",
    alignContent: "center"
  },
  ColorBtn: {
    width: "45px",
    height: "45px",
    borderRadius: "10px",
    border: "none",
    margin: "5px"
  }
}))
