import React, { useEffect, useState } from "react"

import Click from "./Click"
import "./styles.css"

export default function CardInfo(props) {
  return (
    <>
      <div className="cardBox">
        <div>
          <Click />
        </div>
        <div>
          <div className="cardText2">{props.data}</div>
          <div className="cardText1">Visitas em sua loja</div>
        </div>
      </div>
    </>
  )
}
