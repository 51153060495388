import ApiRequest from "../index"

const lojinhaConfigsRequest = {
  create: async data => ApiRequest.post(`configuration/create`, { ...data }),
  put: async (id, data) =>
    ApiRequest.put(`configuration/update/${localStorage.getItem("config_id")}`, {
      ...data
    }),
  getByLojinhaId: async () =>
    ApiRequest.get(`configuration/show/${localStorage.getItem("id")}`),

  bestShippingAuthentication: async me_token =>
    ApiRequest.post(
      `${
        process.env.REACT_APP_PAYHOW_API || "https://api.dev.payhow.com.br/api/v1"
      }/integrations/shipping/melhor_envio/token`,
      { token: me_token },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("payhow_token")}`
        }
      }
    ),

  bestShippingCreateIntegration: async body =>
    ApiRequest.post(
      `${
        process.env.REACT_APP_PAYHOW_API || "https://api.dev.payhow.com.br/api/v1"
      }/integrations/shipping`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("payhow_token")}`
        }
      }
    ),

  bestShippingGetIntegrationInfos: async payhowID =>
    ApiRequest.get(
      `${
        process.env.REACT_APP_PAYHOW_API || "https://api.dev.payhow.com.br/api/v1"
      }/integrations/shipping/${payhowID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("payhow_token")}`
        }
      }
    ),

  postOfficeCreateIntegration: async body =>
    ApiRequest.post(
      `${
        process.env.REACT_APP_PAYHOW_API || "https://api.dev.payhow.com.br/api/v1"
      }/integrations/shipping`,
      body,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("payhow_token")}`
        }
      }
    ),

  postOfficeGetIntegration: async payhowID =>
    ApiRequest.get(
      `${
        process.env.REACT_APP_PAYHOW_API || "https://api.dev.payhow.com.br/api/v1"
      }/integrations/shipping/${payhowID}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("payhow_token")}`
        }
      }
    )
}

export default lojinhaConfigsRequest
