import { red } from "@material-ui/core/colors"
import React from "react"
import MoneyMount from "./moneyMount"
import "./styles.css"

export default function CardInfo(props) {
  return (
    <>
      <div className="cardBox">
        <div>
          <MoneyMount />
        </div>
        <div>
          <div className="cardText2">
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL"
            }).format(props.data && props.data[0].total_price)}
          </div>
          <div className="cardText1">Total de vendas em Reais</div>
        </div>
      </div>
    </>
  )
}
