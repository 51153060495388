import React, { useState } from 'react'
import CustomAlert from '../../../Componentes/Alerts/CustomAlert'
import ColorSelectContainer from '../../../Componentes/ColorPallet/ColorSelectContainer'
import { globalStyles } from '../../../Componentes/CssGlobal'
import { button } from '../../../Componentes/Inputs/TextFild'
import { useSettings } from '../../../ContextAPI/settingsContext'

export default function EtapaCores({ handleStep }) {
  const classesGlobal = globalStyles()
  const { primaryColor, secondaryColor, updateLojinhaConfigs } = useSettings()
  const [showAlertOk, setShowAlertOk] = useState(false)

  const handleSaveColors = async () => {
    updateLojinhaConfigs({
      primary_color: primaryColor,
      secondary_color: secondaryColor
    })
    setShowAlertOk(true)
    handleStep('proximo')
  }
  const closeAlert = () => {
    setShowAlertOk(false)
  }

  return (
    <>
      <div className="infinite-scroll">
        <div className="EtapaHeader">
          <h2>Escolha a cor</h2>
          <h3>da sua loja:</h3>
        </div>
        <ColorSelectContainer />
        {showAlertOk ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {/* alertas */}
            <CustomAlert
              alertText="Cores salvas com sucesso!"
              show={showAlertOk}
              close={() => closeAlert()}
            />
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="footer-column">
        {button({
          funcao: () => handleSaveColors(),
          name: `Próximo`
        })}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <button
            onClick={() => handleStep('backStep')}
            className="voltarLink"
            style={{ margin: 0 }}
          >
            Voltar etapa
          </button>
          <button
            onClick={() => handleStep('nextStep')}
            className="voltarLink"
            style={{ margin: 0 }}
          >
            Pular etapa
          </button>
        </div>
      </div>
    </>
  )
}
