import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import React, { useState } from "react"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { globalStyles } from "../CssGlobal/index"
import Topo from "../TopModalCrop"
import getCroppedImg from "./getCroppedImg"
import { useStyles } from "./style"
import { useDevice } from "../../ContextAPI/deviceContext"
// import getCroppedImgMobile from "./getCroppedImgBACKUP"

export default function Cropper({ src, callback, open, close }) {
  const classes = useStyles()
  const classesGlobal = globalStyles()
  const { checkDevice, isMobileDevice } = useDevice()

  const [crop, setCrop] = useState({
    unit: "px",
    aspect: 1 / 1,
    width: 150,
    height: 150
  })
  const [image, setImage] = useState(null)
  const [completedCrop, setCompletedCrop] = useState(null)
  /* RETURN CROPPED IMAGE */
  const showCroppedImage = async () => {
    callback(getCroppedImg(image, completedCrop))
  }
  return (
    <div
      style={{
        zIndex: "9999",
        display: "flex"
      }}
    >
      <Dialog
        className={classes.newModal}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: "9999", height: "100%" }}
      >
        <DialogActions className="page-align" style={{ padding: "0 20px" }}>
          <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <div>
              <Topo name="Recortar imagem" type="button" fechar={close} />
            </div>
            <div className="infinite-scroll">
              <div className={classes.imgCrop}>
                <div className={classes.modalCrop}>
                  <>
                    {src && (
                      <ReactCrop
                        minWidth={150}
                        minHeight={150}
                        src={src}
                        keepSelection
                        onImageLoaded={setImage}
                        crop={crop}
                        onChange={c => setCrop(c)}
                        onComplete={c => setCompletedCrop(c)}
                      />
                    )}
                  </>
                </div>
              </div>
            </div>
            <div className="footer-column">
              <hr style={{ width: "100%", marginTop: "15px" }} />
              <button
                type="button"
                className={
                  crop.width !== 0 && crop.height
                    ? classes.btnDanger
                    : classes.btnDanger2
                }
                onClick={() => {
                  crop.width !== 0 && crop.height ? showCroppedImage() : ""
                }}
              >
                Recortar
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}
