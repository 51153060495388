import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import EditIcon from "@material-ui/icons/Edit"
import React, { useEffect, useState } from "react"
import { BsFillCheckCircleFill } from "react-icons/bs"
import { useHistory } from "react-router-dom"
import flatDesign from "../../../../Assets/svg/flat-variation.svg"
import { useProduct } from "../../../../ContextAPI/productContext"
import { useSettings } from "../../../../ContextAPI/settingsContext"
import api from "../../../../Services/api"
import variationsRequest from "../../../../Services/api/variacoes"
import ModalOops from "../../../Planos/Alertas/AlertaModal"
import CardVariation from "./CardVariacao"
import { useStyles } from "./style"

export default function ProdutoVariações({ onScroll }) {
  const classes = useStyles()
  const [loadPage, setLoadPage] = useState(true)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  const { lojinhaInfo } = useSettings()
  const [modalOops, setModalOops] = useState(false)

  const [variationsList, setVariationsList] = useState([])
  const {
    currentVariation,
    setCurrentVariation,
    currentProduct,
    setIsEditVariation,
    isEdit,
    setAllVariationList
  } = useProduct()

  const history = useHistory()

  const loadNextPage = async () => {
    setLoadPage(true)

    const { data } = await api.get(
      `/variation/product/getall/${currentProduct.id}?page=${page}`
    )
    setVariationsList(oldState => [...oldState, ...data.data?.data])
    setAllVariationList(oldState => [...oldState, ...data.data?.data])
    if (data.data.next_page_url !== null) {
      setHasMore(true)
      setPage(oldState => oldState + 1)
    } else {
      setHasMore(false)
    }
    setLoadPage(false)
    return true
  }

  useEffect(() => {
    if (onScroll === true && hasMore === true) {
      loadNextPage()
    }
  }, [onScroll])

  useEffect(() => {
    if (currentVariation === null || currentVariation.id) {
      setCurrentVariation({
        types: [],
        price: "",
        promotional_price: "",
        images: []
      })
    }
  }, [currentVariation])

  function handleVariationEdit(id) {
    setIsEditVariation(true)
    history.push({ pathname: "/produto-variacoes", id })
  }

  const handleEditVariationTypes = async () => {
    const resDeleteAll = await variationsRequest.deleteAll(currentProduct.id)
    if (resDeleteAll) history.push("/selecionar-variacoes")
  }

  function openModalPro() {
    setModalOops(true)
  }
  function closeModalPro() {
    setModalOops(false)
  }
  function planPro() {
    history.push("/planos")
  }

  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  return (
    <>
      <div className={classes.variationContainer}>
        <h2>Variações do produto</h2>
        <p>
          Aqui você poderá inserir os tipos de variações do seu produto como tamanho,
          cores, sabor, etc. e as opções dentro dessas variações.
        </p>

        {currentProduct.variation_type_selected.length === 0 ? (
          <>
            <div className={classes.variationFlatDesign}>
              <img width="90%" src={flatDesign} alt="" />
            </div>
            <div className={classes.variationDescription}>
              <p>
                Selecione até 3 tipos de variações para esse produto.
                <br />
                <span style={{ color: "#EA3458" }}>
                  Variações estão disponíveis apenas para produtos já cadastrados.
                </span>
              </p>
            </div>
            <Button
              style={{
                width: "80%",
                margin: "auto",
                textTransform: "none",
                fontSize: "12px",
                height: "55px"
              }}
              variant="outlined"
              color="secondary"
              disabled={!isEdit}
              onClick={() => {
                if (hasPro === false) {
                  openModalPro()
                } else {
                  history.push("selecionar-variacoes")
                }
              }}
            >
              selecionar tipos de variação
            </Button>
          </>
        ) : (
          <>
            <div>
              <div>
                <div className={classes.variationOptions}>
                  <h3>Tipos selecionados: </h3>
                </div>
                <div className={classes.variationTagsList}>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-start"
                    }}
                  >
                    {currentProduct.variation_type_selected.map(variation => (
                      <div className={classes.variationTags}>
                        <BsFillCheckCircleFill style={{ color: "#fff" }} />
                        <p className={classes.variationTagsTitle}>
                          {variation.name}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div>
                    <div className={classes.variationBtnEdit}>
                      <button
                        style={{ backgroundColor: "transparent", border: "none" }}
                        onClick={() => setOpenModalDelete(true)}
                      >
                        <EditIcon style={{ color: "#fff" }} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  className={classes.btnAddVariations}
                  onClick={() => {
                    if (hasPro === false) {
                      openModalPro()
                    } else {
                      history.push("produto-variacoes")
                    }
                  }}
                >
                  adicionar variações
                </Button>
              </div>
            </div>
            <CardVariation
              variations={variationsList}
              handleEdit={handleVariationEdit}
              loading={loadPage}
            />
          </>
        )}
        {openModalDelete && (
          <Dialog
            open={openModalDelete}
            onClose={() => setOpenModalDelete(false)}
            aria-labelledby="responsive-dialog-title"
            className={classes.PlanosAlertaModal}
          >
            <DialogTitle id="responsive-dialog-title">
              <div className={classes.PlanosAlertaModalHeader}>
                <h2>Atenção</h2>
                <button type="button" onClick={() => setOpenModalDelete(false)}>
                  <CloseIcon style={{ fontSize: "30" }} />
                </button>
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.PlanosAlertaModalBody}>
                <p>
                  Ao editar os tipos de variações, todas as variações já criadas
                  serão apagadas da sua loja. <br />
                  Você não terá mais acesso as variações e nem às informações
                  inseridas nelas.
                  <br /> <br />
                </p>
                <p>Você gostaria realmente de editar os tipos de variações?</p>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                    marginTop: "15px"
                  }}
                >
                  <button
                    className={classes.btnExcluirStyle}
                    onClick={() => setOpenModalDelete(false)}
                  >
                    Voltar
                  </button>

                  <button
                    className={classes.btnCancelarStyle}
                    onClick={() => {
                      handleEditVariationTypes()
                    }}
                  >
                    Editar
                  </button>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        )}
        {modalOops && (
          <ModalOops
            isOpen={modalOops}
            handleClose={closeModalPro}
            isPush={planPro}
          />
        )}
      </div>
    </>
  )
}
