import { CircularProgress } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import elo from "../../../../../Assets/svg/elo.svg"
import mastercard from "../../../../../Assets/svg/mastercard.svg"
import visa from "../../../../../Assets/svg/visa.svg"
import { CpfInput } from "../../../../../Componentes/CustomInputs/CPF"
import { RequiredInput } from "../../../../../Componentes/CustomInputs/RequiredInput"
import { button } from "../../../../../Componentes/Inputs/TextFild"
import { validateCpfCnpj } from "../../../../../Componentes/validates/Validate"
import api from "../../../../../Services/api"
import { useStyles } from "./style"

export default function CreditCard(props) {
  const couponCheckout = sessionStorage.getItem("cupom_checkout")
  const {priceWithCoupon} = props
  const classes = useStyles()
  const [currentValue, setCurrentValue] = useState(priceWithCoupon || 334.80 )
  const [focus, setFocus] = useState(null)
  const [submit, setSubmit] = useState(false)
  const history = useHistory()
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [loadPage, setLoadPage] = useState(true)
  const [cardType, setCardType] = useState(null)


  const [formData, setFormData] = useState({
    document_number: "",
    card_number: "",
    card_holder_name: "",
    card_expiration_date: "",
    card_cvv: "",
    installments: 1
  })

  const validadeInputs = () => {
    return (
      formData.document_number.replace(/\D+/g, "") &&
      formData.card_number &&
      formData.card_holder_name &&
      formData.card_expiration_date.replace(/\D+/g, "") &&
      formData.card_cvv &&
      validateCpfCnpj(formData.document_number)
    )
  }

  const handleInputFocus = e => {
    if (e.target.name === "card_number") setFocus("number")
    else if (e.target.name === "document_number") setFocus("name")
    else if (e.target.name === "card_expiration_date") setFocus("expiry")
    else if (e.target.name === "card_cvv") setFocus("cvc")
  }

  const handleFormDataChange = e => {
    const { name, value } = e.target
    let newValue = value
    if (name === "card_expiration_date") {
      newValue = newValue
        .replace(
          /[^0-9]/g,
          "" // To allow only numbers
        )
        .replace(
          /^([2-9])$/g,
          "0$1" // To handle 3 > 03
        )
        .replace(
          /^(1{1})([3-9]{1})$/g,
          "0$1/$2" // 13 > 01/3
        )
        .replace(
          /^0{1,}/g,
          "0" // To handle 00 > 0
        )
        .replace(
          /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
          "$1/$2" // To handle 113 > 11/3
        )
    } else if (name === "card_cvv") {
      newValue = newValue.replace(/[^0-9]/g, "").substring(0, 3)
    } else if (name === "card_number") {
      newValue = newValue.replace(/[^0-9]/g, "").substring(0, 16)

      const visaRegex = /^4[0-9]{0,15}$/
      const mastercardRegex = /^5[1-5][0-9]{0,14}$/

      const eloRegex =
        /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/

      if (visaRegex.test(newValue)) {
        setCardType("visa")
      } else if (mastercardRegex.test(newValue)) {
        setCardType("mastercard")
      } else if (eloRegex.test(newValue)) {
        setCardType("elo")
      } else {
        setCardType(null)
      }
    }
    setFormData(oldData => {
      return { ...oldData, [name]: newValue }
    })
  }

  const handleSubmitCard = () => {
    setDisabledBtn(true)
    if (validadeInputs() === true) {
      handleCheckout()
    } else {
      setSubmit(true)
      setTimeout(() => {
        setSubmit(false)
        setDisabledBtn(false)
      }, 1000)
    }
  }

  async function handleCheckout() {
    setLoadPage(true)
    const body = {
      ...formData,
      card_expiration_date: formData.card_expiration_date.replace("/", ""),
      document_number: formData.document_number.replace(/\D/g, ""),
      card_number: formData.card_number.replace(/\D/g, ""),
      document: "cpf",
      email: localStorage.getItem("email"),
      lojinha_id: localStorage.getItem("id"),
      cupom: couponCheckout
    }


    try {
      const res = await api.post(`/lojinha/asaas/planos/cartao`, body)

      if (res.data.status === "paid") {
        history.push({
          pathname: "/planos/v2/checkout/success"
        })
        return
      }
      history.push({
        pathname: "/planos/v2/checkout/error",
        message: res.data.data.message
      })
    } catch (error) {
      const { message } = error.response.data
      history.push({
        pathname: "/planos/v2/checkout/error",
        message
      })
    } finally {
      setLoadPage(false)
      setDisabledBtn(false)
    }
  }



  return (
    <>
      <div className={classes.header}>
        <h3>
          Parcela em <span>até 12x</span> nos cartões:
        </h3>

        <div className={classes.logoContainer}>
          <img className={classes.logo} src={visa} alt="visa logo" />
          <img className={classes.logo} src={elo} alt="elo logo" />
          <img className={classes.logo} src={mastercard} alt="mastercard logo" />
        </div>
      </div>
      <div className={classes.containerContent}>
        <div className="infinite-scroll">
          <div className={classes.EtapaPagamentoContainer}>
            <div className={classes.FormCartao}>
              <form action="">
                <div className={classes.Card}>
                  <label htmlFor="card_number">Número do cartão</label>
                  <RequiredInput
                    type="tel"
                    name="card_number"
                    value={formData.card_number.replace(/(\d{4})(?=\d)/g, "$1 ")}
                    onFocus={e => handleInputFocus(e)}
                    callback={e => handleFormDataChange(e)}
                    submit={submit}
                  />
                  {cardType !== null ? (
                    <img
                      src={
                        cardType === "visa"
                          ? visa
                          : cardType === "mastercard"
                          ? mastercard
                          : elo
                      }
                      alt="logo cartao"
                      className={classes.logoInput}
                    />
                  ) : (
                    ""
                  )}
                </div>

                <div className={classes.FormCartaoInputGroup}>
                  <div className="validadeInput">
                    <label htmlFor="validade">Data de validade</label>
                    <RequiredInput
                      type="tel"
                      name="card_expiration_date"
                      value={formData.card_expiration_date}
                      onFocus={e => handleInputFocus(e)}
                      callback={e => handleFormDataChange(e)}
                      submit={submit}
                    />
                  </div>
                  <div className="cvvInput">
                    <label htmlFor="card_cvv">CVV</label>
                    <RequiredInput
                      type="tel"
                      name="card_cvv"
                      value={formData.card_cvv}
                      onFocus={e => handleInputFocus(e)}
                      callback={e => handleFormDataChange(e)}
                      submit={submit}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="card_holder_name">
                    Nome do titular do cartão
                  </label>
                  <RequiredInput
                    type="text"
                    name="card_holder_name"
                    value={formData.card_holder_name}
                    onFocus={e => handleInputFocus(e)}
                    callback={e => handleFormDataChange(e)}
                    submit={submit}
                  />
                </div>
                <div>
                  <label htmlFor="document_number">
                    CPF do titular do cartão
                  </label>
                  <CpfInput
                    type="tel"
                    value={formData.document_number}
                    name="document_number"
                    onFocus={e => handleInputFocus(e)}
                    callback={e => handleFormDataChange(e)}
                    submit={submit}
                  />
                </div>

                <div>
                  <label htmlFor="installments">Número de parcelas</label>
                  <select
                    type="text"
                    id="installments"
                    name="installments"
                    value={formData.installments}
                    onChange={e => handleFormDataChange(e)}
                    className={classes.CyclesInput}
                  >
                    <option value={1}>
                      1 x R$
                      {String(Math.fround(currentValue).toFixed(2)).replace(
                        ".",
                        ","
                      )}
                    </option>
                    <option value={2}>
                      2 x R$
                      {String(Math.fround(currentValue / 2).toFixed(2)).replace(
                        ".",
                        ","
                      )}
                    </option>
                    <option value={3}>
                      3x R$
                      {String(Math.fround(currentValue / 3).toFixed(2)).replace(
                        ".",
                        ","
                      )}
                    </option>
                    <option value={4}>
                      4 x R$
                      {String(Math.fround(currentValue / 4).toFixed(2)).replace(
                        ".",
                        ","
                      )}
                    </option>
                    <option value={5}>
                      5 x R$
                      {String(Math.fround(currentValue / 5).toFixed(2)).replace(
                        ".",
                        ","
                      )}
                    </option>
                    <option value={6}>
                      6 x R$
                      {String(Math.fround(currentValue / 6).toFixed(2)).replace(
                        ".",
                        ","
                      )}
                    </option>
                    <option value={7}>
                      7 x R$
                      {String(Math.fround(currentValue / 7).toFixed(2)).replace(
                        ".",
                        ","
                      )}
                    </option>
                    <option value={8}>
                      8 x R$
                      {String(Math.fround(currentValue / 8).toFixed(2)).replace(
                        ".",
                        ","
                      )}
                    </option>
                    <option value={9}>
                      9 x R$
                      {String(Math.fround(currentValue / 9).toFixed(2)).replace(
                        ".",
                        ","
                      )}
                    </option>
                    <option value={10}>
                      10 x R$
                      {String(Math.fround(currentValue / 10).toFixed(2)).replace(
                        ".",
                        ","
                      )}
                    </option>
                    <option value={11}>
                      11 x R$
                      {String(Math.fround(currentValue / 11).toFixed(2)).replace(
                        ".",
                        ","
                      )}
                    </option>
                    <option value={12}>
                      12 x R$
                      {String(Math.fround(currentValue / 12).toFixed(2)).replace(
                        ".",
                        ","
                      )}
                    </option>
                  </select>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className={classes.Button}>
          {button({
            name: `Ativar plano PRO`,
            color: "#52EB58",
            disabled: disabledBtn,
            funcao: () => handleSubmitCard()
          })}
        </div>
      </div>
    </>
  )
}
