import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  ordersPayment: {
    "&__paymentMethod": {
      "& p:nth-child(1)": {
        color: "#F21C62",
        display: "flex",
        alignItems: "center",
        margin: "12.8px 0 0 0",

        "& span": {
          color: "#555555",
          fontWeight: "500",
          display: "flex",
          alignItems: "center",
          marginLeft: "8px",

          "& svg": {
            marginRight: "5px"
          }
        }
      },

      "& p:nth-child(2)": {
        display: "flex",
        alignItems: "center",
        margin: "7px 0 0 0",

        "& img": {
          marginLeft: "5px"
        }
      },

      "& p:nth-child(3)": {
        margin: "7px 0 0 0"
      },

      "& p:nth-child(4)": {
        display: "flex",
        alignItems: "center",
        margin: "7px 0 20px 0",

        "& div": {
          marginLeft: "5px"
        }
      }
    },

    "&__orderTotalValue": {
      "& p:nth-child(1)": {
        color: "#F21C62",
        margin: "30px 0 0 0"
      },

      "& p:nth-child(2)": {
        margin: "6px 0 0 0"
      }
    },

    "&__valueInstallments": {
      "& p:nth-child(1)": {
        color: "#F21C62",
        margin: "20px 0 0 0"
      },

      "& p:nth-child(2)": {
        margin: "6px 0 23px 0",

        "& span": {
          marginLeft: "5px",
          fontSize: "10px"
        }
      }
    },

    "& button": {
      margin: "25px 0 0 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 25px 10px 25px",
      border: "none",
      borderRadius: "7px",
      backgroundColor: "#F21C62",
      color: "#f9f9f9",
      fontWeight: "500",
      wordBreak: "break-word",

      "& img": {
        margin: "0px 13px 0 30px",
        height: "35px"
      }
    }
  },

  statusInfo: {
    margin: "10px 0 21px 0",

    "& p": {
      fontSize: "12px",
      fontWeight: "700",
      color: "#353535 !important"
    },

    "& .MuiFormControl-root": {
      margin: "10px 0 0 0",
      width: "100%"
    }
  },

  icon: {
    width: "25px",
    height: "25px",
    margin: "0px 5px"
  },

  statusLabel: {
    margin: "0 0 0 5px",
    border: "1px solid",
    borderRadius: "5px",
    padding: "1px 4.5px",
    fontSize: "11.5px",
    fontWeight: "400"
  },

  pending: {
    borderColor: "#FF8A00",
    color: "#FF8A00"
  },

  failed: {
    borderColor: "#FF0000",
    color: "#FF0000"
  },

  canceled: {
    borderColor: "#FF0000",
    color: "#FF0000"
  },

  paid: {
    borderColor: "#2ECC71",
    color: "#2ECC71"
  }
}))
