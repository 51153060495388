import React, { useEffect, useState } from "react"
import InputMask from "react-input-mask"
import { validateCpfCnpj } from "../../validates/Validate"

export const CpfInput = ({ style, value, callback, submit, validate }) => {
  const [isEmpty, setIsEmpty] = useState(false)
  const [invalidCpf, setInvalidCpf] = useState(false)

  const checkInput = e => {
    if (submit === true) {
      if (value) {
        validateCpfCnpj(value) === true ? setInvalidCpf(false) : setInvalidCpf(true)
        setIsEmpty(false)
      } else setIsEmpty(true)
    } else {
      if (e.target.value !== "") setIsEmpty(false)
      else setIsEmpty(true)
      callback(e)
    }
  }

  useEffect(() => {
    if (submit === true) checkInput()
  }, [submit])

  return (
    <>
      <InputMask
        name="document_number"
        id="document_number"
        type="text"
        mask="999.999.999-99"
        value={value}
        style={style}
        onChange={e => checkInput(e)}
      >
        {inputProps => <input {...inputProps} />}
      </InputMask>
      <span
        hidden={isEmpty === false || invalidCpf === true}
        style={{ color: "red", fontSize: "0.7rem", textAlign: "center" }}
      >
        Campo Obrigatório
      </span>
      <span
        hidden={invalidCpf === false || isEmpty === true}
        style={{ color: "red", fontSize: "0.7rem", textAlign: "center" }}
      >
        CPF Inválido
      </span>
    </>
  )
}
