import React, { useState } from "react"
import { useStyles } from "./style"
import FilterListIcon from "@material-ui/icons/FilterList"
import SearchIcon from "@material-ui/icons/Search"
import InputBase from "@material-ui/core/InputBase"
import { useProduct } from "../../../../ContextAPI/productContext"
import ClearIcon from "@material-ui/icons/Clear"
import { StateGlobal } from "../../../../ContextAPI/index"

export default function NavPesquisa() {
  const classes = useStyles()
  const { setOrderPagesIndex } = StateGlobal()

  const { queryOrder, setQueryOrder } = useProduct()
  const [searchText, setSearchText] = useState("")

  function handleSearch() {
    searchText !== "" && setQueryOrder({ search: searchText, last: 1 })
  }

  function handleClearSearch() {
    setSearchText("")
    setQueryOrder({})
  }

  return (
    <>
      <div className={classes.search}>
        <InputBase
          placeholder="Pesquise pelo código..."
          classes={{ root: classes.inputRoot }}
          inputProps={{ "aria-label": "search" }}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <button
          style={{
            height: "40px",
            background: "transparent",
            border: "none",
            cursor: "pointer"
          }}
          onClick={() =>
            Object.keys(queryOrder).length ? handleClearSearch() : handleSearch()
          }
        >
          {Object.keys(queryOrder).length ? (
            <ClearIcon className={classes.searchIcon} />
          ) : (
            <SearchIcon className={classes.searchIcon} />
          )}
        </button>
      </div>
      <div className={classes.index}>
        <div className={classes.ExibindoPedido}>
          <p className={classes.tipoFiltro}>
            Exibindo Pedidos:{" "}
            <spam style={{ fontWeight: "bold", fontSize: "14px" }}>Todos</spam>
          </p>
        </div>
        <div
          aria-hidden="true"
          onClick={() => {
            setOrderPagesIndex(1)
          }}
          className={classes.Filtro}
        >
          <span className={classes.text}>
            Filtro{" "}
            <FilterListIcon style={{ paddingLeft: "10px", cursor: "pointer" }} />
          </span>
        </div>
      </div>
    </>
  )
}
