import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: "20px",
    paddingRight: "20px",

    backgroundColor: "#EA3458",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    // height: "100%",
    height: "100%"
  },

  Secondary: {
    backgroundColor: "#fff",
    paddingLeft: "20px",
    paddingRight: "20px",
    // alignItems: 'center',
    // justifyContent: 'center',
    flexDirection: "column",
    // height: "100%",
    height: "100%"
  },

  rootCadastro: {
    width: "100%",
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    justifyContent: "space-between"
  },

  Banner: {
    background: "#ECECEC",
    width: "100%",
    minHeight: "100px",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px"
  },

  rootConfig: {
    width: "100%",
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    justifyContent: "flex-start"
  },

  boxConteudo: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px"
  },

  btnalinhamento: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  fonteTitulo: {
    display: "flex",
    alignItems: "left",
    color: "#353535",
    fontSize: "1.1rem",

    marginTop: "10px"
  },

  fonteSubtitulo: {
    display: "flex",
    color: "#6C6C6C",
    fontSize: "0.8rem",
    marginTop: "5px",
    marginBottom: "5px"
  }
}))
