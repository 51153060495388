import { CircularProgress, Switch } from "@material-ui/core"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import React, { useLayoutEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { globalStyles } from "../../../Componentes/CssGlobal"
import Topo from "../../../Componentes/TopCategorias"
import { useSettings } from "../../../ContextAPI/settingsContext"
import PlanosAlertaModal from "../../Planos/Alertas/AlertaModal"
import { useStyles } from "./style"
import payhow_title from "../../../Assets/svg/payhow_title.svg"
import all_payments from "../../../Assets/img/all_payments.png"
import api from "../../../Services/api"

export default function CheckoutPayhow() {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const history = useHistory()
  const { lojinhaInfo } = useSettings()
  const [openModal, setOpenModal] = useState(false)
  const [switchValue, setSwitchValue] = useState(0)
  const [currentPayment, setCurrentPayment] = useState({})
  const [isActivePayhow, setIsActivePayhow] = useState(false)
  const [newData, setNewData] = useState()
  const [loading, setLoading] = useState(false)
  function handleCloseModal() {
    setOpenModal(false)
  }

  function planPro() {
    history.push("/planos")
  }

  useLayoutEffect(() => {
    getPayhowStatus()
  }, [])

  async function getPayhowStatus() {
    setLoading(true)
    const {
      data: { data }
    } = await api.get(`/payhow/gateway/status/${localStorage.getItem("id")}`)

    setNewData(data)

    const paymentActiveRes = await api.get(
      `lojinha/payment/get/${localStorage.getItem("id")}`
    )
    const payhow_active = paymentActiveRes?.data?.data
    const isActive = payhow_active.payment_data?.payhow.gateway === 1

    setCurrentPayment(payhow_active)
    setIsActivePayhow(isActive)
    setSwitchValue(isActive)
    setLoading(false)
  }

  async function updatePayment() {
    setLoading(true)
    const body = {
      lojinha_id: localStorage.getItem("id"),
      payment_active: "payhow",
      payment_data: {
        ...currentPayment.payment_data,
        payhow: {
          pagseguro: 0,
          gateway: !!switchValue === true ? 0 : 1
        }
      }
    }

    const res = await api.put(`/lojinha/payment/update/${currentPayment.id}`, body)
    if (res) {
      getPayhowStatus()
      setLoading(false)
    }
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <Topo name="Ativar Super Checkout" />
          </div>
          <div className="infinite-scroll">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  minHeight: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <>
                <div className={classes.boxConteudo}>
                  <div
                    className={classes.boxConteudo}
                    aria-hidden="true"
                    style={{ cursor: "pointer" }}
                  >
                    <div className={classes.alignSwitch}>
                      <div
                        className={
                          newData?.registration_status === "aprovado"
                            ? classesGlobal.fonteTitulo
                            : classes.fonteTituloDisabled
                        }
                      >
                        Habilitar esta opção de pagamento
                      </div>
                      <div className={classes.switchChange}>
                        <Switch
                          checked={switchValue}
                          onClick={() =>
                            loading === false ? updatePayment() : null
                          }
                          disabled={newData?.registration_status !== "aprovado"}
                        />
                      </div>
                    </div>
                    <div className={classes.btnalinhamento}>
                      <div className={classes.alignIcon}>
                        <div
                          className={
                            newData?.registration_status === "aprovado"
                              ? classesGlobal.fonteSubtitulo
                              : classes.fonteSubtituloDisabled
                          }
                        >
                          Em parceria com
                        </div>
                        <img
                          src={payhow_title}
                          alt="payhow-icon"
                          style={{ marginLeft: "5px" }}
                        />
                      </div>
                    </div>
                    <img
                      src={all_payments}
                      alt="icones de pagamentos"
                      style={{ marginTop: "5px", width: "50%" }}
                    />

                    <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                  </div>

                  <div
                    className={classes.boxConteudo}
                    onClick={() =>
                      newData?.registration_status === "aprovado"
                        ? history.push("/payhow-config")
                        : null
                    }
                    aria-hidden="true"
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      className={
                        newData?.registration_status === "aprovado"
                          ? classesGlobal.fonteTitulo
                          : classes.fonteTituloDisabled
                      }
                    >
                      Configuração de pagamento
                    </div>
                    <div className={classes.btnalinhamento}>
                      <div
                        className={
                          newData?.registration_status === "aprovado"
                            ? classesGlobal.fonteSubtitulo
                            : classes.fonteSubtituloDisabled
                        }
                      >
                        Ofereça ao seus clientes um parcelamento de até 12x sem juros
                        e receba em até 14 dias
                      </div>
                      <div>
                        <ArrowForwardIosIcon fontSize="small" color="disabled" />
                      </div>
                    </div>
                    <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                  </div>

                  <div
                    onClick={() =>
                      newData?.registration_status === "aprovado"
                        ? history.push("/taxas-tarifas")
                        : null
                    }
                    aria-hidden="true"
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      className={
                        newData?.registration_status === "aprovado"
                          ? classesGlobal.fonteTitulo
                          : classes.fonteTituloDisabled
                      }
                    >
                      Taxas e tarifas
                    </div>
                    <div className={classes.btnalinhamento}>
                      <div
                        className={
                          newData?.registration_status === "aprovado"
                            ? classesGlobal.fonteSubtitulo
                            : classes.fonteSubtituloDisabled
                        }
                      >
                        Receba seus pagamentos automaticamente via cartão de crédito,
                        débito, boleto bancário e PIX utilizando o nosso super
                        checkout
                      </div>
                      <div>
                        <ArrowForwardIosIcon fontSize="small" color="disabled" />
                      </div>
                    </div>

                    <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                  </div>

                  <div
                    onClick={() => history.push("/service-registration-status")}
                    aria-hidden="true"
                    style={{ cursor: "pointer" }}
                  >
                    <div className={classesGlobal.fonteTitulo}>
                      Status do serviço e situação cadastral
                    </div>
                    <div className={classes.btnalinhamento}>
                      <div className={classesGlobal.fonteSubtitulo}>
                        Receba seus pagamentos automaticamente via cartão de crédito,
                        débito, boleto bancário e PIX utilizando o nosso super
                        checkout{" "}
                      </div>
                      <div>
                        <ArrowForwardIosIcon fontSize="small" color="disabled" />
                      </div>
                    </div>
                    <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {openModal === true ? (
        <PlanosAlertaModal
          isOpen={openModal}
          handleClose={handleCloseModal}
          isPush={planPro}
          bannerId="modal-oops-marketing"
        />
      ) : (
        ""
      )}
    </>
  )
}
