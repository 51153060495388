import React, { useCallback, useRef, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useStyles } from "./style"
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined"
import { MoreVert } from "@material-ui/icons"
import { Menu, MenuItem } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { useProduct } from "../../../ContextAPI/productContext"

const CategoryComponent = ({
  index,
  id,
  name,
  moveCard,
  threepoints,
  deleteicon,
  handleDeleteCategory,
  showTopo,
  from,
}) => {
  const ref = useRef(null)
  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const {setTabSession} = useProduct()


  return (
      <div
        ref={ref}
        className={classes.CardWrapper}
        style={{
          opacity: 1,
          transform: "rotate(0deg)",
          margin: "5px 0px",
          boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)"
        }}
      >
        <h2 style={{ flex: 1, textAlign: "center" }}>{name}</h2>
        {deleteicon && (
          <DeleteOutlineOutlinedIcon onClick={() => handleDeleteCategory(id)} />
        )}
        {threepoints && (
          <MoreVert
            style={{
              fontSize: "2.3em",
              color: "#F21C62",
              cursor: "pointer",
              background: "transparent"
            }}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          />
        )}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
        >
          <MenuItem
            onClick={() => {
              history.push("/subcategorias", {
                state: { title: name, id: id, from: from ?? ""}
              })
              handleClose()
            }}
          >
            Subcategorias
          </MenuItem>
          <MenuItem onClick={() => handleDeleteCategory(id)}>Deletar </MenuItem>
        </Menu>
      </div>
  )
}

export default CategoryComponent
