import React from "react"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { useHistory } from "react-router-dom"
import { useStyles } from "./style"
import Topo from "../../../Componentes/TopCategorias"
import { globalStyles } from "../../../Componentes/CssGlobal"

export default function FaceInsta() {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <Topo name="Facebook e Instagram" />
          </div>
          <div className="infinite-scroll">
            <div className={classes.boxConteudo}>
              <div className={classes.boxConteudo}>
                <div
                  onClick={() => {
                    history.push("/marketing/facebook-instagram/verificacao-dominio")
                  }}
                  aria-hidden="true"
                >
                  <div className={classesGlobal.fonteTitulo}>
                    Verificação de domínio
                  </div>
                  <div className={classes.btnalinhamento}>
                    <div>
                      <div className={classesGlobal.fonteSubtitulo}>
                        Faça a ativação da sua loja no Facebook e Instagram
                      </div>
                    </div>
                    <div>
                      <ArrowForwardIosIcon fontSize="small" color="disabled" />
                    </div>
                  </div>
                  <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                </div>
              </div>

              <div
                onClick={() => {
                  history.push("/marketing/facebook-instagram/instagram-shopping")
                }}
                aria-hidden="true"
              >
                <div className={classesGlobal.fonteTitulo}>Instagram Shopping</div>
                <div className={classes.btnalinhamento}>
                  <div className={classesGlobal.fonteSubtitulo}>
                    Ative a “sacolinha” do Instagram e transforme seus posts e
                    stories em vendas
                  </div>
                  <div>
                    <ArrowForwardIosIcon fontSize="small" color="disabled" />
                  </div>
                </div>
                <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
              </div>

              <div
                onClick={() => {
                  history.push("/marketing/facebook-instagram/facebook-pixel")
                }}
                aria-hidden="true"
              >
                <div className={classesGlobal.fonteTitulo}>Facebook Pixel</div>
                <div className={classes.btnalinhamento}>
                  <div className={classesGlobal.fonteSubtitulo}>
                    Integração da sua loja com o Facebook Ads
                  </div>
                  <div>
                    <ArrowForwardIosIcon fontSize="small" color="disabled" />
                  </div>
                </div>
                <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
