import { makeStyles } from "@material-ui/core/styles"

export default makeStyles(() => ({
  rootInfo: {
    width: "100%",
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    justifyContent: "flex-start"
  },

  fonteTitulo: {
    fontSize: "0.8em",
    color: "#555555",
    display: "flex",
    marginTop: "1.1em",
    width: "100%"
  },

  boxLink: {
    display: "flex",
    // justifyContent: 'space-between',
    alignItems: "flex-end"
  },

  inputLink: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  },

  fonteLojinha: {
    fontSize: "16px",
    color: "#353535",
    marginBottom: "0.8em",
    marginLeft: "0.4em"
  },

  boxWhats: {},

  boxDescricao: {},

  btnSalvar: {
    color: "#fff",
    minWidth: "100%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "5px",
    marginBottom: "15px",
    textTransform: "none",
    backgroundColor: "#EA3458",

    "& .MuiButton-label": {
      textTransform: "initial",
      fontSize: "14px",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#ff5089c9"
    }
  }
}))
