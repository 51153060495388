import { CircularProgress } from "@material-ui/core"
import DescriptionIcon from "@material-ui/icons/Description"
import { useEffect, useState } from "react"
import storiesRequest from "../../../Services/api/stories"
import LabelStories from "../../Stories/components/LabelStories"
import { useStyles } from "./style"
export default function EtapaStoriesCategoria({ handleStep, onSetCategoryId, onSetStep }) {
  const classes = useStyles()
  const [categoryList, setCategoryList] = useState([{}])
  const [isLoading, setIsLoading] = useState(false)
  const [ isCategoryEmpty, setIsCategoryEmpty ] = useState(false)
  const getData = async () => {
    try {
      setIsLoading(true)
      const { data } = await storiesRequest.getCategoryList()
      setCategoryList(data.data)
      setIsCategoryEmpty(data.data.length === 0)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }
  const onSelectCategory = index => {
    const currentCategory = categoryList[index]
    const currentCategoryId = currentCategory.category_id
    onSetCategoryId(currentCategoryId)
    handleStep("nextStep")
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <div className="infinite-scroll">
        <div className="EtapaHeader">
          <h2>Conheça nossa funcionalidade de Stories</h2>
          <h3>Comece selecionando um categoria:</h3>
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              height: "100%",
              minHeight: "100%",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "40px"
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            {isCategoryEmpty ? (
              <>
                <div className={classes.EmptyCategories}>
                  <p>Não há categorias cadastradas<a className="voltarLink" onClick={() => onSetStep(4)}>Cadastre aqui!</a></p>
                  <DescriptionIcon />
                </div>
              </>
            ) : (
              <div className={classes.StoryContainer}>
                {categoryList.map((item, index) => (
                  <LabelStories
                    key={index}
                    categoryName={item.category_name}
                    index={index}
                    onSelectCategory={onSelectCategory}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
      <div className="footer-column">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <button
            onClick={() => handleStep("backStep")}
            className="voltarLink"
            style={{ margin: 0 }}
          >
            Voltar etapa
          </button>
          <button
            onClick={() => onSetStep(9)}
            className="voltarLink"
            style={{ margin: 0 }}
          >
            Pular etapa
          </button>
        </div>
      </div>
    </>
  )
}
