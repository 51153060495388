import React, { useState, useCallback, useContext } from "react"
import TopCategorias from "../../Componentes/TopCategorias"
import InfiniteScroll from "./Componentes/InfiniteScroll"
import NavPesquisa from "./Componentes/NavPesquisa"
import Filtro from "./FiltroPedidos"
import Pedido from "../Pedido"
import { StateGlobal } from "../../ContextAPI/index"

export default function MeusPedidos() {
  const { orderPagesIndex } = StateGlobal()

  // eslint-disable-next-line no-shadow
  const MeusPedidos = () => {
    return (
      <div className="page-container">
        <div className="page-align">
          <TopCategorias name="Meus Pedidos" />
          <NavPesquisa />
          <InfiniteScroll />
        </div>
      </div>
    )
  }

  const Pages = [<MeusPedidos />, <Filtro />, <Pedido />]

  return <>{Pages[orderPagesIndex]}</>
}
