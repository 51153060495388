import { useState } from "react"

import sendAirplane from "../../../../Assets/svg/sendAirplane.svg"
import visaFlag from "../../../../Assets/svg/visaFlag.svg"
import FormControl from "@material-ui/core/FormControl"
import NativeSelect from "@material-ui/core/NativeSelect"
import InputBase from "@material-ui/core/InputBase"
import CustomAlert from "../../../../Componentes/Alerts/CustomAlert"
import clsx from "clsx"
import { withStyles } from "@material-ui/core/styles"

import api from "../../../../Services/api"
import { StateGlobal } from "../../../../ContextAPI"
import pix from "../../../../Assets/img/pix.png"
import boleto from "../../../../Assets/img/boleto.png"



import { BsCreditCard2Back } from "react-icons/bs"

import { useStyles } from "./style"

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(4)
    }
  },
  input: {
    width: "100%",
    borderRadius: 5,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid  #EA3458",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    color: "#EA3458",
    fontWeight: "500",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#EA3458",
      boxShadow: "0 0 0 0.1rem #EA3458cc"
    }
  }
}))(InputBase)

function PedidosPagamento({ currentPaymentStatus, checkout, totalPrice }) {
  const classes = useStyles()

  const { idByOrder, paymentMethodOrderInfo } = StateGlobal()

  const [paymentStatus, setPaymentStatus] = useState(currentPaymentStatus)
  const [showAlert, setShowAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")

  const statusLabel = [
    { value: "pending", name: "Pendente" },
    { value: "processing", name: "Processando" },
    { value: "failed", name: "Erro no pagamento" },
    { value: "canceled", name: "Cancelado" },
    { value: "refunded", name: "Estornado" },
    { value: "paid", name: "Pago" }
  ]

  async function handlePaymentStatus(status) {
    await api
      .put(`/order/update/${idByOrder}`, {
        payment_status: status
      })
      .then(res => {
        setPaymentStatus(res.data.data.payment_status)
      })
      .finally(() => {
        setAlertType("success")
        setAlertMessage("Pedido atualizado com sucesso!")
        setShowAlert(true)
      })
  }

  async function sendEmail() {
    await api.post("order/mail", { order_id: idByOrder })
    .then(res => {
      setAlertMessage
      setAlertType("success")
      setAlertMessage(res.data.data)
      setShowAlert(true)
    })
  }

  return (
    <div className={classes.ordersPayment}>
      <div className={`${classes.ordersPayment}__paymentMethod`}>
        <p>
          Método de pagamento: {' '}
          <span>
            {paymentMethodOrderInfo === 'Cartão de crédito' &&
              <BsCreditCard2Back fontSize="18px" />
            }
            {paymentMethodOrderInfo === 'PIX' &&
              <img src={pix} alt='icone pix' className={classes.icon} />
            }
            {paymentMethodOrderInfo === 'Boleto Bancário' &&
              <img src={boleto} alt='icone boleto' className={classes.icon} />
            }
            {paymentMethodOrderInfo}
          </span>
        </p>
        {checkout.checkoutFrom === "payhow" ? (
          <>
            {checkout.installments? (
            <p style={{ marginTop: "15px" }}>
              Parcelamento: {checkout.installments}x sem juros
            </p>): ''}
            <p>
              Status do pagamento:
              <span
                className={clsx({
                  [classes.statusLabel]: true,
                  [classes.pending]: paymentStatus === "pending",
                  [classes.failed]: paymentStatus === "failed",
                  [classes.canceled]: paymentStatus === "canceled",
                  [classes.paid]: paymentStatus === "paid"
                })}
              >
                {statusLabel.find(status => status.value === paymentStatus).name}
              </span>
            </p>
          </>
        ) : (
          <div className={classes.statusInfo}>
            <p>Status do pagamento:</p>
            <FormControl>
              <NativeSelect
                id="demo-customized-select-native"
                value={paymentStatus}
                fullWidth
                onChange={e => handlePaymentStatus(e.target.value)}
                input={<BootstrapInput />}
              >
                <option value="pending">Aguardando Pagamento</option>
                <option value="processing">Processando</option>
                <option value="canceled">Cancelado</option>
                <option value="paid">Pago</option>
                <option value="failed">Falha no pagamento</option>
                <option value="refunded">Estornado</option>
              </NativeSelect>
            </FormControl>
          </div>
        )}
      </div>

      <div className={`${classes.ordersPayment}__orderTotalValue`}>
        <p>Valor total do pedido:</p>
        <p>
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2
          }).format(totalPrice)}
        </p>
      </div>

      {(checkout.checkoutFrom === "payhow" && checkout.installments) && (
        <div className={`${classes.ordersPayment}__valueInstallments`}>
          <p>Valor por parcela:</p>
          <p>
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
              minimumFractionDigits: 2
            }).format(checkout.installmentsPrice)}{" "}
            <span>({checkout.installments}x sem juros)</span>
          </p>
        </div>
      )}

      <hr
        style={{ backgroundColor: "#ffffff21", opacity: "0.3", marginTop: "21px" }}
      />

      <button onClick={e => sendEmail()}>
        Enviar uma cópia desse pedido para o meu e-mail de cadastro
        <img src={sendAirplane} alt="send-button" />
      </button>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <CustomAlert
          type={alertType}
          show={showAlert}
          close={() => setShowAlert(false)}
          alertText={alertMessage}
        />
      </div>
    </div>
  )
}

export default PedidosPagamento
