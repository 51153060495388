import { useState, useEffect } from "react"
import { Tab, Tabs } from "@material-ui/core"
import moment from "moment"
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined"
import ListOutlinedIcon from "@material-ui/icons/ListOutlined"
import TopPedidos from "../../Componentes/TopPedidos"
import PedidoEntrega from "./Abas/PedidoEntrega"
import PedidoPagamento from "./Abas/PedidoPagamento"
import PedidosInfo from "../MeusPedidos/PedidosInfo"
import ModalDeletePedido from "../MeusPedidos/Componentes/ModalDeletePedido"
import { StateGlobal } from "../../ContextAPI"
import { useStyles } from "./style"
import { BsTruck } from "react-icons/bs"

export default function Pedido() {
  const classes = useStyles()
  const { openDeleteModal, setOpenDeleteModal } = StateGlobal()

  const [pos, setPos] = useState(0)
  const [orderInfos, setOrderInfos] = useState({})

  const tabsComponents = [
    <PedidosInfo getOrderInfos={getOrderInfos} />,
    <PedidoPagamento
      currentPaymentStatus={orderInfos.payment_status}
      checkout={{
        checkoutFrom: orderInfos.order_data?.active_checkout,
        installments: orderInfos.order_data?.installments,
        installmentsPrice: orderInfos.order_data?.installments_value
      }}
      totalPrice={orderInfos.total_price}
    />,
    <PedidoEntrega
      melhorEnvio={
        orderInfos.order_data?.melhor_envio
          ? orderInfos.order_data.melhor_envio
          : false
      }
      orderID={orderInfos.id}
      deliveryPrice={orderInfos.frete}
      deliveryInfos={orderInfos.delivery}
      deliveryMethod={orderInfos.order_data?.delivery_method}
    />
  ]

  function getOrderInfos(infosObj) {
    setOrderInfos(infosObj)
  }

  return (
    <div className="page-container">
      <div className="page-align">
        <div className={classes.header}>
          <TopPedidos name="Pedido" orderNumber={orderInfos.code} />

          <Tabs
            value={pos}
            onChange={(e, value) => setPos(value)}
            indicatorColor="secondary"
            textColor="#000"
            variant="scrollable"
            scrollButtons="auto"
            className={classes.deliveryTabPanel}
          >
            <Tab
              label="Informações"
              value={0}
              icon={
                <ListOutlinedIcon style={{ fontSize: "1.2rem", margin: "0 5px" }} />
              }
            />
            <Tab
              label="Dados de Pagamento"
              value={1}
              icon={
                <MonetizationOnOutlinedIcon
                  style={{ fontSize: "1.2rem", margin: "0 5px" }}
                />
              }
            />
            <Tab
              label="Dados de Entrega"
              value={2}
              icon={<BsTruck style={{ fontSize: "1.2rem", margin: "0 5px" }} />}
            />
          </Tabs>

          <div className={classes.headerOrder}>
            <p>Pedido {orderInfos.code}</p>
            <p>{moment(orderInfos.created_at).format("DD/MM/YYYY HH:mm")}</p>
          </div>
        </div>

        <div className="infinite-scroll">
          {tabsComponents[pos]}

          {openDeleteModal && (
            <ModalDeletePedido
              setPosition={e => setPos(e)}
              open={openDeleteModal}
              fechar={() => setOpenDeleteModal(false)}
            />
          )}
        </div>
      </div>
    </div>
  )
}
