import ArrowRight from "../../../Assets/svg/arrowRight.svg"
import { useHistory } from "react-router-dom"
import { useEffect, useState } from "react"
import requestBanners from "../../../Services/api/banners"
import { useStyles } from "./style"
import { button } from "../../../Componentes/Inputs/TextFild"
import { CircularProgress } from "@material-ui/core"

const initialBanners = [
    { banner_position: "1" },
]

export default function EtapaBanner({ handleStep }) {
    const history = useHistory()
    const [banners, setBanners] = useState(initialBanners)
    const [isLoading, setIsLoading] = useState(true)
    const classes = useStyles()

    const getBanners = async () => {
        try {
            const { data } = await requestBanners.getPrimaryBanners()
            const receivedBanners = data.data
            setBanners(currentBanners =>
                currentBanners.map(banner =>
                    receivedBanners.find(receivedBanner => receivedBanner.banner_position == banner.banner_position) || banner
                ))
            setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getBanners()
    }, [])

    return (
        <>
            {
                isLoading ? (
                    <div
                        style={{
                            display: "flex",
                            height: "100%",
                            minHeight: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "40px"
                        }}
                    >
                        <CircularProgress color="secondary" />
                    </div>
                )
                    :
                    (
                        <>
                            <div className="infinite-scroll">
                                <div className={classes.mainContainer}>
                                    {banners.map((banner, index) => (
                                        <div key={index} className={classes.bannerContainer}>
                                            <label style={{ fontSize: "1.25rem", color: "#555" }}>
                                                Banner {banner.banner_position}
                                            </label>
                                            <button
                                                className={classes.bannerButton}
                                                onClick={() => history.push({
                                                    pathname: "/adicionar-banners",
                                                    state: { banner, type: "primary", sessao_guiada: true }
                                                })}
                                            >
                                                {banner.banner_name || "Adicionar banner"}
                                                <img src={ArrowRight} alt="arrow" />
                                            </button>
                                        </div>
                                    ))}
                                    <p className={classes.text}>Esse banner será exibido na página inicial da sua loja. Se desejar adicionar mais banners, você poderá fazê-lo acessando o menu principal &gt; configurações da loja &gt; personalize sua loja &gt; banners principais.</p>
                                </div>
                            </div>
                            <div className="footer-column">
                                {button({
                                    funcao: () => handleStep("proximo"),
                                    name: `Próximo`
                                })}
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <button
                                        onClick={() => handleStep("backStep")}
                                        className="voltarLink"
                                        style={{ margin: 0 }}
                                    >
                                        Voltar etapa
                                    </button>
                                    <button
                                        onClick={() => handleStep("nextStep")}
                                        className="voltarLink"
                                        style={{ margin: 0 }}
                                    >
                                        Pular etapa
                                    </button>
                                </div>
                            </div>
                        </>
                    )
            }
        </>
    )
}