import React, { useRef, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useStyles } from "./style"
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined"
import { SimpleImg } from "react-simple-img"
import ModalFoto from "../../ModalFoto"

const CardFotos = ({ id, index, image, handleDelete, priority, moveCard }) => {
  const ref = useRef(null)
  const classes = useStyles()

  // OPEN FULL IMAGE
  const [openModalPhoto, setOpenModalPhoto] = useState(false)
  const [fullImage, setFullImage] = useState({ url: "", index: "" })

  /* OPEN FULL SIZE PHOTO */
  const openPhoto = (url, position) => {
    setFullImage({ url, index })
    setOpenModalPhoto(true)
  }

  /* CLOSE FULL SIZE PHOTO */
  function closePhoto() {
    setOpenModalPhoto(false)
  }

  const [{ handlerId }, drop] = useDrop({
    accept: "Card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })
  const [{ isDragging }, drag] = useDrag({
    type: "Card",
    item: () => {
      return { id, index }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const opacity = isDragging ? 0.5 : 1

  drag(drop(ref))

  return (
    <>
      <div
        ref={ref}
        className={classes.CardWrapper}
        style={{
          opacity,
          transform: isDragging ? "rotate(2deg)" : "rotate(0deg)",
          margin: isDragging ? "15px 0px" : "5px 10px",
          boxShadow: isDragging
            ? "1px 1px 10px rgba(0, 0, 0, 0.5)"
            : "0px 3px 10px rgba(0, 0, 0, 0.1)"
        }}
        data-handler-id={handlerId}
      >
        {index === 0 ? (
          <div
            style={{
              width: "75px",
              display: "block",
              zIndex: 2,
              marginTop: "-11px",
              marginRight: "-76px"
            }}
          >
            <svg
              width="32"
              height="31"
              viewBox="0 0 32 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.9657 10.916L21.3727 9.50166L17.0844 0.682262C16.9673 0.440792 16.7746 0.245317 16.5366 0.126498C15.9396 -0.172464 15.2142 0.0766713 14.9157 0.682262L10.6274 9.50166L1.03443 10.916C0.769949 10.9543 0.52814 11.0808 0.343006 11.2724C0.119189 11.5058 -0.00414439 11.8198 0.000106342 12.1453C0.00435708 12.4709 0.135844 12.7814 0.365676 13.0087L7.30632 19.8734L5.66656 29.5667C5.62811 29.7921 5.6527 30.024 5.73756 30.2361C5.82242 30.4481 5.96414 30.6318 6.14666 30.7662C6.32917 30.9007 6.54518 30.9806 6.77019 30.9969C6.99519 31.0132 7.22019 30.9652 7.41967 30.8583L16.0001 26.2819L24.5805 30.8583C24.8147 30.9848 25.0868 31.027 25.3475 30.981C26.0049 30.866 26.4469 30.2336 26.3336 29.5667L24.6938 19.8734L31.6345 13.0087C31.8234 12.8209 31.9481 12.5756 31.9859 12.3073C32.0879 11.6366 31.6269 11.0156 30.9657 10.916Z"
                fill="#F8DF00"
              />
            </svg>
          </div>
        ) : (
          ""
        )}

        <img
          width="auto"
          height="auto"
          alt="img do produto"
          aria-hidden="true"
          className={classes.imgProduto}
          src={image}
          onClick={() => {
            openPhoto(image, index)
          }}
        />
      </div>
      <ModalFoto
        open={openModalPhoto}
        close={closePhoto}
        index={fullImage.index}
        src={fullImage.url}
        excluir={handleDelete}
      />
    </>
  )
}

export default CardFotos
