/* eslint-disable no-empty */
import { Checkbox, CircularProgress } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import { globalStyles } from "../../../../Componentes/CssGlobal"
import { useStyles } from "../../style"
import Boleto from "../Assets/Boleto"
import CartoesdeCredito from "../Assets/CartaoCredito"
import CartaoDebito from "../Assets/CartaoDebito"
import Dinheiro from "../Assets/Dinheiro"
import PicPay from "../Assets/picpay"
import Pix from "../Assets/pix"
import ValeAlimentacao from "../Assets/ValeAlimentacao"
import ValeRefeicao from "../Assets/ValeRefeicao"
import Combinar from "../Assets/Combinar"
import conexao from "../../../../Services/api"

export default function ListaMeiosPagamentos({ getBody }) {
  const classesGlobal = globalStyles()
  const classes = useStyles()

  const lojinha_id = localStorage.getItem("id")

  const [loadPage, setLoadPage] = useState(true)
  const [data, setData] = useState()
  const [DataChecked, setDataChecked] = useState([])
  const [idsPaymentsApi, setIdsPaymentsApi] = useState([])
  const [ids, setIds] = useState([])

  const [valorCheckd, setValorCheckd] = useState()
  const [dataInfo, setDataInfo] = useState([])

  const icones = [
    { name: "Cartões de Crédito", icon: <CartoesdeCredito /> },
    { name: "Cartões de Débito", icon: <CartaoDebito /> },
    { name: "Boleto Bancário", icon: <Boleto /> },
    { name: "PicPay", icon: <PicPay /> },
    { name: "Vale Refeição", icon: <ValeRefeicao /> },
    { name: "Vale Alimentação", icon: <ValeAlimentacao /> },
    { name: "PIX", icon: <Pix /> },
    { name: "Dinheiro", icon: <Dinheiro /> },
    { name: "A Combinar", icon: <Combinar /> }
  ]

  const iconesColorido = [
    { name: "Cartões de Crédito", icon: <CartoesdeCredito fill="#EA3458" /> },
    { name: "Cartões de Débito", icon: <CartaoDebito fill="#EA3458" /> },
    { name: "Boleto Bancário", icon: <Boleto fill="#EA3458" /> },
    { name: "PicPay", icon: <PicPay fill="#EA3458" /> },
    { name: "Vale Refeição", icon: <ValeRefeicao fill="#EA3458" /> },
    { name: "Vale Alimentação", icon: <ValeAlimentacao fill="#EA3458" /> },
    { name: "PIX", icon: <Pix fill="#EA3458" /> },
    { name: "Dinheiro", icon: <Dinheiro fill="#EA3458" /> },
    { name: "A Combinar", icon: <Combinar fill="#EA3458" /> }
  ]

  async function getPaymentes() {
    const dataPayment = await conexao.get("/lojinha/payment/getall")
    const dataPaymentSave = await conexao.get(`/lojinha/payment/get/${lojinha_id}`)

    dataPaymentSave.data.data.map((item, index) => {
      dataPayment.data.data.map((i, p) => {
        if (item.id === i.id) {
          dataPayment.data.data[p].checked = true
        } else if (dataPayment.data.data[p].checked) {
        } else {
          dataPayment.data.data[p].checked = false
        }
      })
      idsPaymentsApi.push(item.id)
    })
    setData(dataPayment.data.data)
    setLoadPage(false)
  }
  useEffect(() => {
    getPaymentes()
  }, [])

  function getCheck(e, id, i) {
    data.map((item, index) => {
      if (item.id === id) {
        item.checked = !item.checked
      }
    })
    const arr = data.slice()
    setData(arr)
  }

  // corpo para requisição

  useEffect(() => {
    getBody(data)
  }, [data])

  return (
    <>
      {loadPage ? (
        <div
          style={{
            backgroundColor: " #F9F9F9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <>
          {data.map((item, index) => {
            return (
              <div className={classes.rowPagamento}>
                <div className={classes.textInfo}>
                  <div className={classes.paymentIcon}>
                    {item.checked ? icones[index].icon : iconesColorido[index].icon}
                  </div>
                  <div className={classes.paymentText}>{item.name}</div>
                </div>
                <div className={classes.paymentCheckbox}>
                  {/* idsPaymentsApi.includes(item.id) */}

                  <Checkbox
                    id={item.id}
                    checked={item.checked}
                    name={item.name}
                    onChange={e => getCheck(e, item.id, index)}
                  />
                </div>
              </div>
            )
          })}
        </>
      )}
    </>
  )
}
