import { CircularProgress, Dialog, Button, TextField } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import InputBase from "@material-ui/core/InputBase"
import NativeSelect from "@material-ui/core/NativeSelect"
import { withStyles } from "@material-ui/core/styles"
import { WhatsApp } from "@material-ui/icons"
import React, { useEffect, useState } from "react"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { StateGlobal } from "../../../ContextAPI/index"
import conexao from "../../../Services/api"
import { paymentMethods, variationsArr } from "../utils"
import { useStyles } from "./style"

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(4)
    }
  },
  input: {
    width: "100%",
    borderRadius: 5,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid  #EA3458",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    color: "#EA3458",
    fontWeight: "500",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#EA3458",
      boxShadow: "0 0 0 0.1rem #EA3458cc"
    }
  }
}))(InputBase)

export default function PedidosInfo({ getOrderInfos }) {
  const classes = useStyles()

  const { idByOrder, setOpenDeleteModal, setPaymentMethodOrderInfo } = StateGlobal()

  const [dataOrder, setDataOrder] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [subTotal, setSubTotal] = useState(0)
  const [status, setStatus] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState({})
  const [statusPayload, setStatusPayload] = useState({
    status: "",
    note: null
  })

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    const { data } = await conexao.get(`order/get/${idByOrder}`)

    if (data) {
      setDataOrder(data.data)
      getPaymentMethod(data.data.order_data.payment_method)
      setStatus(data.data.status)
      setStatusPayload({
        status: data.data.status,
        note: data.data.note
      })
      getOrderInfos(data.data)

      let total = 0

      data.data.items.map(product => {
        total += Math.fround(product.order_item_product.price_pay * product.quantity)
      })

      setSubTotal(total)
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  function getPaymentMethod(payment_method) {
    let currentPaymentMethod = paymentMethods.find(
      payment => payment.value === payment_method
    ).name

    setPaymentMethod(currentPaymentMethod)
    setPaymentMethodOrderInfo(currentPaymentMethod)
  }

  function dataPut(payload) {
    conexao
      .put(`/order/update/${dataOrder.id}`, payload)
      .then(() => {
        setAlertType("success")
        setShowAlertOk(true)
        setIsLoading(false)
      })
      .catch(() => {
        setAlertType("error")
        setShowAlertError(true)
        setIsLoading(false)
      })
      .finally(() => {
        setIsOpen(false)
        setStatus(payload.status)
        setIsDisabled(false)
      })
  }

  return (
    <>
      {isLoading ? (
        <div
          style={{
            backgroundColor: " #fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <div className="page-container">
          <div className="infinite-scroll">
            <div className={classes.statusInfo}>
              <p>Atualizar status de Entrega:</p>
              <FormControl>
                <NativeSelect
                  id="demo-customized-select-native"
                  value={status}
                  fullWidth
                  onChange={e => {
                    setStatusPayload(prevState => ({
                      ...prevState,
                      status: e.target.value
                    }))
                    setIsOpen(true)
                  }}
                  input={<BootstrapInput />}
                >
                  <option value="PENDENTE">Pendente</option>
                  <option value="ENTREGUE">Entregue</option>
                  <option value="CANCELADO">Cancelado</option>
                  <option value="EM TRÂNSITO">Em Trânsito</option>
                  <option value="AGUARDANDO PAGAMENTO">Aguardando Pagamento</option>
                </NativeSelect>
              </FormControl>
            </div>

            <div className={classes.genericoGrupo}>
              <p>
                Nome:
                <span>{dataOrder.client.name}</span>
              </p>
              <p>
                E-mail:
                <span>{dataOrder.client.email}</span>
              </p>
              <p>
                Telefone:
                <span>{dataOrder.client.cellphone}</span>
              </p>
            </div>

            <div className={classes.positionZap}>
              <button
                className={classes.btnZap}
                onClick={() => {
                  window.open(
                    `https://api.whatsapp.com/send?phone=55${dataOrder.client.cellphone.replace(
                      /\D/g,
                      ""
                    )}`,
                    "_blank"
                  )
                }}
              >
                Conversar no Whatsapp <WhatsApp style={{ paddingLeft: "5px" }} />
              </button>
            </div>

            <div className={classes.orderItens}>
              <div className={classes.titleItem}>
                <span className={classes.genericoText}>Itens do Pedido</span>
              </div>

              {dataOrder.items.length > 0 &&
                dataOrder.items.map(item => {
                  return (
                    <article className={classes.itemGrid}>
                      <div className={classes.topico1}>
                        <span className={classes.genericoText}>
                          {`${item.quantity}x `}
                        </span>
                      </div>

                      <div className={classes.topico2}>
                        <span className={classes.genericoText}>
                          {item.order_item_product.title}
                        </span>

                        {item.order_item_variable &&
                        Object.entries(item.order_item_variable)?.length > 0 ? (
                          <p>
                            {Object.entries(item.order_item_variable).map(
                              variation => {
                                return `${variation[0]}: ${variation[1]} `
                              }
                            )}
                          </p>
                        ) : null}
                      </div>

                      {item.order_item_product.promotional_price ? (
                        <div
                          className={classes.topico3}
                          style={{ paddingRight: "10px" }}
                        >
                          <span
                            className={classes.genericoInfo}
                            style={{
                              textDecoration: "line-through",
                              color: "#EA3458"
                            }}
                          >
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL"
                            }).format(item.order_item_product.price)}
                          </span>
                          <span className={classes.genericoInfo}>
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL"
                            }).format(item.order_item_product.price_pay)}
                          </span>
                        </div>
                      ) : (
                        <div className={classes.topico3}>
                          <span className={classes.genericoInfo}>
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL"
                            }).format(item.order_item_product.price)}
                          </span>
                        </div>
                      )}
                    </article>
                  )
                })}
            </div>

            <div>
              <div className={classes.headerInfo}>
                <p className={classes.pedidoInfo}>Subtotal:</p>
                <span className={classes.pedidoInfo}>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  }).format(subTotal)}
                </span>
              </div>

              <hr style={{ width: "100%", marginBottom: "20px" }}></hr>
            </div>

            <div className={classes.headerInfo}>
              <p className={classes.metodoPagamente}>Método de Pagamento:</p>
              <span className={classes.genericoInfo}>{paymentMethod}</span>
            </div>
            <div className={classes.headerInfo}>
              <p className={classes.metodoEntrega}>Método de Entrega:</p>
              <span className={classes.genericoInfo}>
                {dataOrder?.order_data?.delivery_method || "Entrega"}
              </span>
            </div>

            <div className={classes.pedidoTotal}>
              <p className={classes.total}>TOTAL:</p>
              <span className={classes.valor}>
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(dataOrder.total_price)}
              </span>
            </div>

            <div>
              <hr style={{ width: "100%", marginBottom: "25px" }}></hr>
            </div>

            <div>
              <span className={classes.pedidoInfo}>Observações sobre o pedido:</span>
            </div>
            <div style={{ marginTop: "10px" }}>
              <TextField
                size="small"
                variant="outlined"
                color="secondary"
                rows={4}
                multiline
                fullWidth
                value={statusPayload.note}
                disabled
                style={{
                  backgroundColor: "#b5b5b512"
                }}
              />
            </div>
            <div className={classes.positionBtnExcluir}>
              <span
                onClick={() => {
                  setOpenDeleteModal(true)
                }}
                className={classes.btnExcluir}
                aria-hidden
              >
                Excluir Pedido
              </span>
            </div>

            <Dialog className={classes.dialog} open={isOpen}>
              <div>
                <h2>Tem certeza que deseja atualizar o status desse pedido?</h2>
                <p>
                  Essa ação fará com que seu cliente fique sabendo dessa atualização.
                </p>
              </div>

              <Button
                className={classes.btnFooterPink}
                disabled={isDisabled}
                onClick={() => {
                  setIsDisabled(true)
                  dataPut(statusPayload)
                }}
                fullWidth
                variant="contained"
                color="secondary"
              >
                sim, quero atualizar
              </Button>

              <Button
                className={classes.btnFooterWhite}
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={() => setIsOpen(false)}
              >
                não, obrigado.
              </Button>
            </Dialog>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              {/* alertas */}
              <CustomAlert
                type={alertType}
                alertText={
                  alertType === "success"
                    ? "Pedido atualizado com sucesso!"
                    : "Algo deu errado!"
                }
                show={showAlertOk || showAlertError}
                close={() => closeAlerts()}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
