import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => ({
  productContainer: {
    display: "flex",
    marginBottom: "21px",

    "& h2": {
      fontWeight: "500",
      fontSize: "24px",
      lineHeight: "28px",
      marginRight: "9px"
    }
  },

  productContent: {
    display: "flex",
    alignItems: "center"
  },

  productInfos: {
    alignSelf: "start",
    marginLeft: "10px",

    "& p": {
      fontWeight: "500",
      fontSize: "14px",
      margin: "0"
    },

    "& small": {
      fontWeight: "400",
      fontSize: "12px"
    }
  },

  banner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-210px",
    position: "relative"
  }
}))
