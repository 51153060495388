import './styles.css'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import { useEffect, useState } from 'react'

const COLORS = ['#808080', '#D62B2B', '#FADF5D', '#43D893', '#D8D8D8']

export default function CardPedidos(props) {
  const [dataorder, setDataorder] = useState(props.orders)

  return (
    <>
      <div className="bodyPie">
        <div className="boxPie">
          <div className="titleCardPie"> Pedidos x Status</div>
          <div className="boxCardPie">
            <div className="CardPie">
              <ResponsiveContainer width={180} height={200}>
                <PieChart>
                  <Pie
                    data={dataorder}
                    innerRadius={45}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="total"
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                  >
                    {dataorder.map((item, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="legendCardPie">
            <div>
              <div
                style={{ backgroundColor: '#43D893' }}
                className="legendCard2Pie"
              ></div>
              Entregue
            </div>
            <div>
              <div
                style={{ backgroundColor: '#D62B2B' }}
                className="legendCard2Pie"
              ></div>
              Cancelado
            </div>
            <div>
              <div
                style={{ backgroundColor: '#FADF5D' }}
                className="legendCard2Pie"
              ></div>
              Em Trânsito
            </div>
          </div>
          <div className="legendCardPie">
            <div>
              <div
                style={{ backgroundColor: '#808080' }}
                className="legendCard2Pie"
              ></div>
              Aguardando pgto.
            </div>
            <div>
              <div
                style={{ backgroundColor: '#D8D8D8' }}
                className="legendCard2Pie"
              ></div>
              Pendente
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
