import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  mainContainer: {
    position: "relative"
  },

  BoxSwitch: {
    display: "flex",
    alignItems: "center", 
    width: "100%",
    gap: "10px"
  },

  Input: {
    width: "100%",
    border: "1px solid #D0D0D0",
    padding: "0.5rem 0.3rem",
    maxWidth: "500px",
    borderRadius: "5px"
  },

  InputTextArea: {
    width: "100%",
    height: "100px",
    border: "1px solid #D0D0D0",
    padding: "0.5rem 0.3rem",
    maxWidth: "500px",
    borderRadius: "5px"
  },

  boxAccordion: {
    marginTop: "25px"
  },

  customAccordion: {
    "& .MuiAccordion-region": {
      backgroundColor: "#EEEDED",
      border: "1px solid #F3F3F3",
      boxShadow: "none"
    },

    "& .MuiAccordionSummary-root": {
      backgroundColor: "#EEEDED"
    },

    "& .MuiTypography-root": {
      fontSize: "14px",
      fontWeight: "500"
    },

    "& p": {
      fontWeight: "500",
      fontSize: "14px"
    }
  },

  ButtonAdd: {
    backgroundColor: "transparent",
    border: "none",
    width: "30px",
    margin: "10px"
  },

  DeleteIcon: {
    display: "flex",
    justifyContent: "end",
    color: "#7C7C7C",
   
  },

  ContainerMap: {
    margin: "10px 0px"
  },

  InputAddCircle: {
    width: "100%",
    border: "2px solid #D0D0D0",
    padding: "0.5rem 1.5rem ",
    maxWidth: "500px",
    borderRadius: "5px",
    height: "30px",
    
  },

}))
