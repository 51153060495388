import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import React, { useEffect, useState } from "react"
import { globalStyles } from "../../../Componentes/CssGlobal"
import useStyles from "./style"

export default function ModalChamado({ isOpen, handleClose }) {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const Lojinha_id = localStorage.getItem("id")
  const [cancel, setCancel] = useState()
  const [btnDisable, setBtnDisable] = useState(true)
  const [alertType, setAlertType] = useState("")
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const shopId = localStorage.getItem("id")

  function validateBtn() {
    if (cancel === "EXCLUIR") {
      setBtnDisable(false)
    } else {
      setBtnDisable(true)
    }

    let body = {
      plan_id: 1
    }
  }

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  useEffect(() => {
    validateBtn()
  }, [cancel])

  return (
    <div className="page-container">
      <div className="page-align">
        <div className="infinite-scroll">
          <div>
            <Dialog
              open={isOpen}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              className={classes.PlanosAlertaModal}
            >
              <DialogTitle id="responsive-dialog-title">
                <div className={classes.PlanosAlertaModalHeader}>
                  <h2>Atenção</h2>
                  <button type="button" onClick={handleClose}>
                    <CloseIcon style={{ fontSize: "30" }} />
                  </button>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText className={classes.PlanosAlertaModalBody}>
                  <p>
                    Para alterar o LINK da sua loja será necessário solicitar
                    diretamente ao nosso suporte através de um chamado. Gostaria de
                    solicitar suporte técnico para esta alteração?
                    <br /> <br />
                  </p>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-around",
                      marginTop: "15px"
                    }}
                  >
                    <button
                      className={classes.btnCancelarStyle}
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      NÃO
                    </button>
                    <button
                      className={classes.btnCancelarStyle}
                      onClick={() => {
                        window.open(
                          "https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/9",
                          "_blank"
                        )
                      }}
                    >
                      SIM
                    </button>
                  </div>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  )
}
