import CircularProgress from "@material-ui/core/CircularProgress"
import Switch from "@material-ui/core/Switch"
import Alert from "@material-ui/lab/Alert"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { button } from "../../Componentes/Inputs/TextFild"
import Topo from "../../Componentes/TopCategorias"
import footerRequest from "../../Services/api/rodape"
import { formatCnpj, formatPhone } from "../../utils"
import { useStyles } from "./style"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
export default function FooterStoreInfo() {
  const [hasErrors, setHasErrors] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [errors, setErrors] = useState({
    footer_name: {
      errors: false,
      message: ""
    },
    footer_document: {
      errors: false,
      message: ""
    },
    footer_address: {
      errors: false,
      message: ""
    },
    footer_phone: {
      errors: false,
      message: ""
    }
  })
  const history = useHistory()
  const alertsFooter = {
    success: {
      type: "success",
      text: "Informações atualizadas com sucesso!"
    },
    error: {
      type: "error",
      text: "Ocorreu um erro na sua solicitação, tente novamente."
    }
  }
  const classes = useStyles()
  const [alertMessage, setAlertMessage] = useState({
    type: "",
    text: ""
  })
  const [showAlert, setShowAlert] = useState(false)
  const [loadingData, setLoadingData] = useState(true)
  const [redirect, setRedirect] = useState(false)
  const [bodyFooter, setBodyFooter] = useState({
    footer_document: "",
    footer_document_status: false,
    footer_name: "",
    footer_name_status: false,
    footer_address: "",
    footer_address_status: false,
    footer_phone: "",
    footer_phone_status: false
  })

  const handleChangeFormData = e => {
    const { name, value } = e.target
    if (name == "footer_document") {
      const formattedValue = formatCnpj(value)
      setBodyFooter(prevState => ({
        ...prevState,
        [name]: formattedValue
      }))
      return
    }
    if (name == "footer_phone") {
      const formattedValue = formatPhone(value)
      setBodyFooter(prevState => ({
        ...prevState,
        [name]: formattedValue
      }))
      return
    }
    setBodyFooter(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const hasError = () => {
    const updatedErrors = { ...errors }

    for (const key in bodyFooter) {
      if (!key.endsWith("_status")) {
        const isFieldActive = bodyFooter[`${key}_status`]
        const fieldValue = bodyFooter[key]

        if (isFieldActive && !fieldValue) {
          updatedErrors[key] = {
            errors: true,
            message: "Este campo é obrigatório"
          }
        } else if (
          isFieldActive &&
          ((key === "footer_document" && fieldValue.length < 18) ||
            (key === "footer_phone" && fieldValue.length < 15))
        ) {
          updatedErrors[key] = {
            errors: true,
            message:
              key === "footer_document"
                ? "Este não é um CNPJ válido!"
                : "Este não é um telefone válido!"
          }
        } else {
          updatedErrors[key] = {
            errors: false,
            message: ""
          }
        }
      }
    }

    setErrors(updatedErrors)
    return Object.values(updatedErrors).some(error => error.errors)
  }

  function handleNewFooterStoreInfo() {
    if (hasError()) return
    try {
      footerRequest.put({
        footer_document: bodyFooter.footer_document,
        footer_document_status: bodyFooter.footer_document_status,
        footer_name: bodyFooter.footer_name,
        footer_name_status: bodyFooter.footer_name_status,
        footer_address: bodyFooter.footer_address,
        footer_address_status: bodyFooter.footer_address_status,
        footer_phone: bodyFooter.footer_phone,
        footer_phone_status: bodyFooter.footer_phone_status
      })
      setAlertType("success")
      setAlertMessage("Informações atualizado com sucesso!")
      setShowModalAlert(true)
      setTimeout(() => {
        setRedirect(true)
      }, 2000)
    } catch (error) {
      console.error("Error fetching data:", error)
      setAlertType("error")
      setShowModalAlert(true)
    } finally {
      setShowAlert(true)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await footerRequest.get()
        setBodyFooter({
          footer_name_status: data.data.footer_name_status,
          footer_document_status: data.data.footer_document_status,
          footer_address_status: data.data.footer_address_status,
          footer_phone_status: data.data.footer_phone_status,
          footer_name: data.data.footer_name,
          footer_document: data.data.footer_document,
          footer_address: data.data.footer_address,
          footer_phone: data.data.footer_phone
        })
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        setLoadingData(false)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (redirect) {
      history.push("/personalizar-lojinha")
    }
  }, [redirect])
  return (
    <>
      <div className="page-container">
        <div className="page-align">
          {loadingData ? (
            <div
              style={{
                display: "flex",
                height: "100%",
                minHeight: "100%",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <>
              <main>
                <header>
                  <Topo name="Informações da Loja" noPadding={false} />
                </header>
                <div className={classes.FormStoreContainer}>
                  {/* CNPJ INPUT */}
                  <div className={classes.InputBox}>
                    <div className={classes.InputLabel}>
                      <p>CNPJ</p>
                      <Switch
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onClick={() => {
                          setBodyFooter(prev => ({
                            ...prev,
                            footer_document_status: !prev.footer_document_status
                          }))
                        }}
                        checked={
                          bodyFooter?.footer_document_status !== undefined
                            ? bodyFooter?.footer_document_status
                            : false
                        }
                      />
                    </div>
                    <input
                      value={bodyFooter.footer_document}
                      disabled={!bodyFooter.footer_document_status}
                      name="footer_document"
                      onChange={handleChangeFormData}
                      maxLength={18}
                      minLength={18}
                    />
                    {errors.footer_document.errors && (
                      <p className={classes.ErrorMessage}>
                        {errors.footer_document.message}
                      </p>
                    )}
                  </div>
                  {/* REASON INPUT */}
                  <div className={classes.InputBox}>
                    <div className={classes.InputLabel}>
                      <p>Razão Social</p>
                    </div>
                    <input
                      name="footer_name"
                      value={bodyFooter.footer_name}
                      onChange={handleChangeFormData}
                      disabled={!bodyFooter.footer_document_status}
                      required
                    />
                    {errors.footer_name.errors && (
                      <p className={classes.ErrorMessage}>
                        {errors.footer_name.message}
                      </p>
                    )}
                  </div>

                  {/* ADDRESS INPUT */}
                  <div className={classes.InputBox}>
                    <div className={classes.InputLabel}>
                      <p>Endereço</p>
                      <Switch
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onClick={() =>
                          setBodyFooter(prev => ({
                            ...prev,
                            footer_address_status: !bodyFooter.footer_address_status
                          }))
                        }
                        checked={
                          bodyFooter?.footer_address_status !== undefined
                            ? bodyFooter?.footer_address_status
                            : false
                        }
                      />
                    </div>
                    <input
                      value={bodyFooter.footer_address}
                      disabled={!bodyFooter.footer_address_status}
                      name="footer_address"
                      onChange={handleChangeFormData}
                      required
                    />
                    {errors.footer_address.errors && (
                      <p className={classes.ErrorMessage}>
                        {errors.footer_address.message}
                      </p>
                    )}
                  </div>
                  {/* PHONE INPUT */}
                  <div className={classes.InputBox}>
                    <div className={classes.InputLabel}>
                      <p>Telefone de Contato</p>
                      <Switch
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onClick={() =>
                          setBodyFooter(prev => ({
                            ...prev,
                            footer_phone_status: !bodyFooter.footer_phone_status
                          }))
                        }
                        checked={
                          bodyFooter.footer_phone_status !== undefined
                            ? bodyFooter?.footer_phone_status
                            : false
                        }
                      />
                    </div>
                    <input
                      value={bodyFooter.footer_phone}
                      disabled={!bodyFooter.footer_phone_status}
                      name="footer_phone"
                      onChange={handleChangeFormData}
                      maxLength={15}
                      required
                    />
                    {errors.footer_phone.errors && (
                      <p className={classes.ErrorMessage}>
                        {errors.footer_phone.message}
                      </p>
                    )}
                  </div>
                </div>
              </main>
              {/* SUBMIT */}

              <div className="footer-column">
                <div className={classes.BoxLoading}>
                <CustomAlert
                type={alertType}
                alertText={alertMessage}
                show={showModalAlert}
                close={() => setShowModalAlert(false)}
              />
                </div>
                <hr style={{ width: "100%" }} />
                {button({
                  name: "Salvar alterações",
                  type: "submit",
                  funcao: handleNewFooterStoreInfo
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
