import axios from "axios"

export const LeadLoversApi = {
   create: async data => apiLeadLovers.post(`Lead?token=${process.env.REACT_APP_TOKEN_LEADLOVERS}`, {...data}),
   get: async () => apiLeadLovers.get(`Leads?token=${process.env.REACT_APP_TOKEN_LEADLOVERS}&page=1`)
}
const apiLeadLovers = axios.create({
  baseURL: process.env.REACT_APP_LEADLOVERS_API,
  headers: {
    "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
    "Content-Type": "application/json",
    "Accept": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
})
