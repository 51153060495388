import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  PlanosAlertaContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0px"
  },
  PlanosAlertaHeaderMsg: {
    textAlign: "center",
    "& h2, & p": {
      fontSize: "1.8rem",
      margin: "5px 0"
    },
    "& h2": {
      fontWeight: "700"
    }
  },
  PlanosAlertaImgWrapper: {
    maxHeight: "270px",
    margin: "1rem 0 2rem",
    "& img": {
      width: "100%",
      maxHeight: "270px"
    }
  },
  PlanosAlertaFooterMsg: {
    marginBottom: "0.5rem",
    "& h5": {
      fontSize: "1rem",
      color: "#555555",
      fontWeight: "200",
      textAlign: "center"
    }
  },
  PlanosAlertaVoltarLink: {
    color: "#EA3458",
    borderBottom: "1px solid #EA3458",
    marginBottom: "15px"
  },
  EtapaVisualizarCompartilhar: {
    display: "flex",
    margin: "auto",
    alignItems: "center",
    justifyContent: "center",
    "& div": {
      display: "flex",
      margin: "10px"
    }
  }
})
export default useStyles
