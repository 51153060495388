import { makeStyles } from "@material-ui/core/styles"

export const globalStyles = makeStyles(() => ({
  root: {
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "#EA3458",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
    minHeight: "100%",
    //  height: '100%',
    overflowX: "hidden"
  },

  Secondary: {
    display: "flex",
    backgroundColor: "#f9f9f9",
    paddingLeft: "20px",
    paddingRight: "20px",
    flexDirection: "column",
    height: "100%",
    minHeight: "100%",
    justifyContent: "space-between"
    //  height: '100%',

    // alignItems: 'center',
    // justifyContent: 'center'
  },

  // Secondary2 SERVE PARA CASOS ESPECIFICOS ONDE A MARGEN TEM QUE SER MENOR QUE O NORMAL

  Secondary2: {
    display: "flex",
    backgroundColor: "#f9f9f9",
    paddingLeft: "5px",
    paddingRight: "5px",
    flexDirection: "column",
    height: "100%",
    minHeight: "100%",
    justifyContent: "space-between"
    //  height: '100%',
    // alignItems: 'center',
    // justifyContent: 'center'
  },

  Secondary3: {
    display: "flex",
    backgroundColor: "#f9f9f9",
    paddingLeft: "10px",
    paddingRight: "10px",
    flexDirection: "column",
    height: "100%",
    minHeight: "100%",
    justifyContent: "space-between"
    //  height: '100%',
    // alignItems: 'center',
    // justifyContent: 'center'
  },

  Secondary4: {
    display: "flex",
    backgroundColor: "#f9f9f9",
    paddingLeft: "15px",
    paddingRight: "15px",
    flexDirection: "column",
    height: "100%",
    minHeight: "100%",
    justifyContent: "space-between"
    //  height: '100%',
    // alignItems: 'center',
    // justifyContent: 'center'
  },

  rootCadastro: {
    width: "100%",
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    justifyContent: "space-between"
  },

  Infinity: {
    height: "100%",
    minHeight: "100%",
    overflow: "auto",
    overflowX: "hidden"
  },

  FooterRow: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center"
  },

  FooterColumn: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "Column",
    alignItems: "center",
    paddingBottom: "0.5rem"
  },
  fonteTitulo: {
    display: "flex",
    alignItems: "left",
    color: "#353535",
    fontSize: "1.1em",
    marginTop: "10px"
  },

  fonteSubtitulo: {
    display: "flex",
    color: "#6C6C6C",
    fontSize: "12px",
    marginTop: "5px",
    marginBottom: "5px"
  },

  rootConfig: {
    width: "100%",
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    justifyContent: "flex-start"
  },

  footerTeste: {
    position: "fixed",
    bottom: "0",
    isplay: "contents"
  },

  headerTeste: {
    position: "fixed",
    top: "0",
    display: "contents"
  }
}))
