import React, { useState } from "react"
import { texto, button } from "../../Componentes/Inputs/TextFild"
import { IoIosArrowBack } from "react-icons/io"
import { validateEmail } from "../../Componentes/validates/Validate"
import { globalStyles } from "../../Componentes/CssGlobal"
import { useHistory } from "react-router-dom"
import api from "../../Services/api"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"

export default function ResetSenha() {
  const classesGlobal = globalStyles()
  const history = useHistory()

  const state = {
    user: { value: "", error: false },
    password: { value: "", error: false }
  }

  const [form, setForm] = useState(state)
  const [next, setnext] = useState(true)
  const [type, settype] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  function pegadados(e) {
    const { name, value } = e

    setForm({ ...form, [name]: { value, error: validateEmail(value) } }) // vou adicionar funcoes dinamicas para validar os campos
  }

  async function Enviar() {
    let res = await api.post("/change/password", { email: form.user.value })
    if (res.data.error === false) {
      setAlertType("success")
      setShowAlertOk(true)
      setTimeout(() => {
        window.location.href = "/"
      }, 2200)
    } else {
      setAlertType("error")
      setShowAlertError(true)
    }
  }

  return (
    <div className="page-container">
      <div className="page-align">
        <div>
          <div
            style={{
              display: "flex",
              minWidth: "100%",
              justifyContent: "space-between",
              height: "50px",
              alignItems: "center",
              paddingTop: "20px"
            }}
          >
            <div>
              <IoIosArrowBack
                onClick={() => {
                  history.push("/")
                }}
                style={{
                  fontSize: "35px",
                  color: "#EA3458",
                  cursor: "pointer",
                  minWidth: "40px",
                  marginRight: "10px"
                }}
              />{" "}
            </div>{" "}
            <h2>Recuperar Senha</h2> <div style={{ width: "35px" }}></div>
          </div>
          <div style={{ width: "100%", textAlign: "certer" }}>
            <p>
              Esqueceu sua senha? Sem problemas!
              <br />
              Basta digitar abaixo o e-mail vinculado à sua conta uppy que enviaremos
              um link para a redefinição de senha.
            </p>
          </div>
          {texto({
            name: "user",
            title: "E-mail",
            css: "",
            pegadados,
            maxlength: "50",
            error: form.user.error
          })}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <CustomAlert
            type={alertType}
            alertText={
              alertType === "success"
                ? "Email enviado! Por favor, aguarde a chegada da sua nova senha."
                : "Email não encontrado"
            }
            show={showAlertOk || showAlertError}
            close={() => closeAlerts()}
          />
        </div>
        <div>
          <hr style={{ width: "100%", borderColor: "#d4d4d445" }}></hr>
          {button({
            name: "Enviar",
            funcao: () => {
              Enviar()
            }
          })}
        </div>
      </div>
    </div>
  )
}
