import update from "immutability-helper"
import { useCallback, useEffect, useState } from "react"
import { useProduct } from "../../../../../../ContextAPI/productContext"
import conexao from "../../../../../../Services/api"
import CardFotos from "../CardFotos"
import CustomAlert from "../../../../../../Componentes/Alerts/CustomAlert"
import { readFile } from "../../../../../../utils"
import { useSettings } from "../../../../../../ContextAPI/settingsContext"
import addPictureIcon from "../../../../../../Assets/svg/addPictureIcon.svg"
import { useStyles } from "./style"
import Cropper from "../../../../../../Componentes/Cropper"
import PlanosAlertaModal from "../../../../../Planos/Alertas/AlertaModal"
import CircularProgressWithLabel from "../../../../../../Componentes/CircularProgressWithLabel"
import { useHistory } from "react-router-dom"
import ModalOops from "../../../../../Planos/Alertas/AlertaModal"


function FotosContainer() {
  const classes = useStyles()
  const history = useHistory()

  const { currentProduct, setCurrentProduct } = useProduct()
  const { lojinhaInfo } = useSettings()

  const [imageSrc, setImageSrc] = useState(null)
  const [openCrop, setOpenCrop] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [modalOops, setModalOops] = useState(false)


  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  function openModalPro() {
    setModalOops(true)
  }
  function closeModalPro() {
    setModalOops(false)
  }
  function planPro() {
    history.push("/planos")
  }

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = currentProduct.images[dragIndex]
      setCurrentProduct(oldState => {
        return {
          ...oldState,
          images: update(oldState.images, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard]
            ]
          })
        }
      })
    },
    [currentProduct.images !== undefined && currentProduct.images]
  )

  /* ON UPLOAD */
  const onFileChange = async e => {
    if (!e.target.files?.length) return

    let file = e.target.files[0]
    let imageDataUrl = await readFile(file)

    setImageSrc(imageDataUrl)
    e.target.value = null
    setOpenCrop(true)
  }

  /* HANDLE CROP IMAGE  */
  const handleCroppedImage = image => {
    imageUpload(imageSrc, image)
    setOpenCrop(false)
    setImageSrc(null)
  }
  /* REMOVE PHOTO */
  const removePhotoFromArray = i => {
    const filteredPhotos = currentProduct.images.filter((item, index) => index !== i)
    setCurrentProduct(oldState => {
      return { ...oldState, images: filteredPhotos }
    })
  }

  /* SAVE IMAGES ON SERVER & RETURN URL */
  const imageUpload = async (src, min) => {
    setLoading(true)

    const er = /[^a-zA-Zs]/g
    let shop_name = localStorage.getItem("name").replace(er, "").toLocaleLowerCase()

    const payload = {
      path: shop_name,
      photo: src,
      crop: min
    }

    try {
      const {
        data: { data }
      } = await conexao.post("/image/upload", payload, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total))
          setUploadProgress(progress)
        }
      })

      setCurrentProduct(oldState => ({
        ...oldState,
        images: [
          ...oldState.images,
          {
            dndID: oldState.images.length + 1,
            url: data.url,
            xlg_url: data.xlg_url,
            lg_url: data.lg_url,
            md_url: data.md_url,
            sm_url: data.sm_url,
            url_min: data.sm_url,
            order: null
          }
        ]
      }))
    } catch ({ response }) {
      if (response.status === 413) {
        setShowAlert(true)
      }
    } finally {
      setLoading(false)
      setUploadProgress(0)
    }
  }

  return (
    <>
      {loading ? (
        <div className={classes.progress}>
          <CircularProgressWithLabel
            value={uploadProgress}
            size={50}
            color="secondary"
          />
        </div>
      ) : (
        <div className={classes.style}>
          {currentProduct.images &&
            currentProduct.images.map((item, i) => (
              <CardFotos
                key={item.dndID}
                id={item.dndID}
                index={i}
                image={item.md_url}
                handleDelete={removePhotoFromArray}
                moveCard={moveCard}
              />
            ))}

          {currentProduct.images !== undefined && currentProduct.images.length < 5 && (
            <>
              <label
                onClick={() => {
                  hasPro === false && currentProduct.images.length >= 1
                    ? openModalPro()
                    : ""
                }}
                aria-hidden="true"
                htmlFor="new-photo"
                className={classes.areaBtn}
              >
                <img
                  src={addPictureIcon}
                  width={64}
                  height="auto"
                  alt="addPictureIcon-icon"
                />
              </label>

              {hasPro === false && currentProduct.images.length >= 1 ? (
                ""
              ) : (
                <input
                  hidden="true"
                  id="new-photo"
                  type="file"
                  accept="image/*"
                  onChange={onFileChange}
                />
              )}
            </>
          )}

          <Cropper
            src={imageSrc}
            open={openCrop}
            close={() => setOpenCrop(false)}
            callback={handleCroppedImage}
          />

          {openModal && (
            <PlanosAlertaModal
              isOpen={openModal}
              handleClose={setOpenModal(false)}
              isPush={history.push("/planos")}
            />
          )}

          <CustomAlert
            type="error"
            alertText="Imagem muito grande."
            show={showAlert}
            close={() => setShowAlert(false)}
          />
        </div>
      )}
      {modalOops && (
        <ModalOops isOpen={modalOops} handleClose={closeModalPro} isPush={planPro} />
      )}
    </>
  )
}

export default FotosContainer
