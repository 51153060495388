import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  top: {
    display: "flex",
    minWidth: "100%",
    justifyContent: "space-between",
    height: "50px",
    alignItems: "center",
    padding: "35px 20px 35px 10px",
    boxSizing: "border-box"
  },
  arrowBack: {
    fontSize: "35px",
    color: "#EA3458",
    cursor: "pointer",
    minWidth: "30px"
  },
  orderNumber: {
    color: "#EA3458",
    marginLeft: "4px",
    fontWeight: 500
  },
  name: {
    color: "#555555",
    fontWeight: "600",
    fontSize: "14px"
  },
  titleTop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}))
