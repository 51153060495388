import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  btnDanger: {
    marginTop: "10px",
    minWidth: "100%",
    background: "#EA3458",
    color: "#FFFFFF",
    textTransform: "none",
    marginBottom: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer"
  },

  btnDanger2: {
    marginTop: "10px",
    minWidth: "100%",
    backgroundColor: "#cccccc",
    color: "#a4a4a4",
    textTransform: "none",
    marginBottom: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer"
  },

  modalCrop: {
    height: "100%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
    /* position: 'absolute' */
  },

  imgCrop: {
    display: "flex",
    justifyContent: "center",
    height: "100%"
    /* position: 'absolute' */
  },

  newModal: {
    "& .MuiDialog-paper": {
      maxHeight: "none",
      margin: "0",
      width: "100%",
      borderRadius: "0",
      boxShadow: "none",
      height: "100%!important"
    },

    "& .MuiDialogActions-root": {
      display: "block"
    },

    "& .MuiBackdrop-root": {
      backgroundColor: "#00000000"
    }
  }
}))
