import { makeStyles } from "@material-ui/core/styles"
// import { button } from "../../Componentes/Inputs/TextFild"

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "#0D273D",
    height: "100vh",
    textAlign: "center",

    "& img": {
      height: "40vh",
      marginLeft: "1.4rem"
      // marginTop: "2rem"
    },

    "& h1": {
      color: "#FFFFFF",
      margin: 0
    },

    "& h2": {
      color: "#FFFFFF",
      marginBottom: "4rem"
    }
  }

  // Button: {
  //   color: "#FFFFFF"
  // }
}))
