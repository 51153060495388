import { CircularProgress } from "@material-ui/core"
import moment from "moment"
import React, { useEffect, useState } from "react"
import noVisitors from "../../../../../Assets/Home/noProducts.svg"
import { globalStyles } from "../../../../../Componentes/CssGlobal"
import Topo from "../../../../../Componentes/TopCategorias"
import api from "../../../../../Services/api"
import CardInfo from "./components/CardInfo"
import CardPedidos from "./components/CardPedidos"
import CardProduto from "./components/CardProduto"
import { useStyles } from "./style"

export default function CardHoje(props) {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const [loadPage, setLoadPage] = useState(true)
  const [sales, setSales] = useState(0)
  const [products, setProducts] = useState([])
  const [orders, setOrder] = useState([])
  const [charts, setCharts] = useState()
  const shopId = localStorage.getItem("id")

  const dataTotalPrice = async () => {
    const hasByTotal = await api.get(`/product/gettotalpricebyday/${shopId}/1`)
    const newData = hasByTotal.data.data

    const newTotalFilterDay = filterByDay(newData)

    setSales(newTotalFilterDay === undefined ? [] : newTotalFilterDay)
  }

  const dataProduct = async () => {
    const hasTopByday = await api.get(`/product/gettopbyday/${shopId}/30`)

    const newDataProduct = hasTopByday.data.data
    const newDataProductFilter = filterByDay(newDataProduct)

    newDataProductFilter.sort((x, y) => {
      return y.total_sales - x.total_sales
    })

    setProducts(newDataProductFilter === undefined ? [] : newDataProductFilter)
  }

  let arrStatus = [
    { status: "AGUARDANDO PAGAMENTO", total: 0 },
    { status: "CANCELADO", total: 0 },
    { status: "EM TRÂNSITO", total: 0 },
    { status: "ENTREGUE", total: 0 },
    { status: "PENDENTE", total: 0 }
  ]

  const dataOrders = async () => {
    const hasByOrders = await api.get(`/order/status/${shopId}`)
    if (hasByOrders.data.data.length) {
      hasByOrders.data.data.map(status => {
        const findStatus = arrStatus.find(item => item.status === status.status)
        if (findStatus !== undefined) {
          findStatus.total = status.total
        }
      })
      setOrder(arrStatus)
    }
    setLoadPage(false)
  }

  function filterByDay(arrProduct) {
    return arrProduct.filter(
      product =>
        product.day === moment().date() && product.month !== moment().month()
    )
  }

  useEffect(() => {
    dataProduct()
    dataOrders()
    dataTotalPrice()
  }, [])

  return (
    <>
      {loadPage ? (
        <div
          style={{
            backgroundColor: " #fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <>
          <div className="page-container">
            <div className="page-align">
              <Topo name="Acompanhamento das vendas" />

              <div className={classes.topOption}>
                <div className={classes.topSelector2}>
                  <div
                    className={classes.topSelector}
                    onClick={() => {
                      props.setPosition(0)
                    }}
                    aria-hidden="true"
                  >
                    Ano
                  </div>
                  <div
                    className={classes.topSelector}
                    onClick={() => {
                      props.setPosition(1)
                    }}
                    aria-hidden="true"
                  >
                    <div>Mensal</div>
                    {/* <div style={{ color: '#EA3458', fontSize: '1em' }}>PRO</div> */}
                  </div>
                  <div
                    className={classes.topSelector}
                    onClick={() => {
                      props.setPosition(2)
                    }}
                    aria-hidden="true"
                  >
                    <div>7 dias</div>
                  </div>
                  <div
                    className={classes.topSelected}
                    onClick={() => {
                      props.setPosition(3)
                    }}
                    aria-hidden="true"
                  >
                    <div>Hoje</div>
                  </div>
                </div>
              </div>
              <div style={{ maxHeight: "100%" }} className="infinite-scroll">
                <div className={classes.cardInfo}></div>
                {sales !== undefined && sales.length === 0 ? (
                  ""
                ) : (
                  <CardInfo data={sales} />
                )}

                {products !== undefined && products.length === 0 ? (
                  <div className={classes.noVisitors}>
                    <img
                      src={noVisitors}
                      alt="sem produtos"
                      style={{
                        marginTop: "10px",
                        marginBottom: "12px",
                        maxWidth: "165px",
                        maxHeight: "115px"
                      }}
                    />
                    <span className={classes.infoText}>
                      Você ainda não teve nenhuma venda hoje.
                    </span>
                  </div>
                ) : (
                  <div style={{ display: "flex" }}>
                    <div style={{ color: "#EA3458", fontWeight: "bold" }}>
                      TOP 5{" "}
                    </div>
                    <div style={{ color: "#353535", fontWeight: "500" }}>
                      - Produtos mais vendidos
                    </div>
                  </div>
                )}
                <div className={classes.cardProduto}>
                  <CardProduto products={products} />
                </div>
                <div>
                  {/* {charts.length <= 0 ? (
                    ''
                  ) : (
                    <div className={classes.cardBar}>
                      <CardVendas charts={charts} />
                    </div>
                  )} */}
                  {orders !== undefined && orders.length <= 0 ? (
                    ""
                  ) : (
                    <div>
                      <CardPedidos orders={orders} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
