import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  toggledTitle: {
    display: "flex",
    justifyContent: "space-between"
  },
  deliveryTime: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "10px"
  },
  dropdown: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "15px",
    alignItems: "center"
  }
}))
