import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  toggledTitle: {
    display: "flex",
    justifyContent: "space-between"
  },
  divbox: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px"
  },
  switch: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }
}))
