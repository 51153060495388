import React, { useState, useEffect } from 'react';
import { Button, Checkbox } from "@material-ui/core";
import Topo from "../../Componentes/TopCategorias";
import { useStyles } from "./style";
import { useLocation, useHistory } from 'react-router-dom';
import api from '../../Services/api';
import CustomAlert from '../../Componentes/Alerts/CustomAlert';
import requestsFrases from '../../Services/api/userFrases';

export default function OpcoesFrases() {
    const classes = useStyles();
    const location = useLocation()
    const history = useHistory()
    const [checked, setChecked] = useState(null);
    const [frases, setFrases] = useState([])
    const [alertType, setAlertType] = useState("")
    const [showModalAlert, setShowModalAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const receivedPhraseId = location.state?.data.phrase_id
    const receivedPhraseItem = location.state?.data.item

    useEffect(() => {
        const getAllFrases = async () => {
            try {
                const res = await requestsFrases.getAllFrases()
                setFrases(res.data.data)
            } catch (e) {
                console.log(e)
            }
            setChecked(receivedPhraseId)
        }

        getAllFrases()

    }, [])

    const handleCheckboxChange = (phrase_id) => {
        setChecked(phrase_id === checked ? null : phrase_id);
    };

    const updateFrase = async () => {
        try {
            await requestsFrases.updateUserFrase(receivedPhraseItem, checked)
            setAlertType("success")
            setAlertMessage("Frase atualizada com sucesso!")
            setShowModalAlert(true)
            setTimeout(() => {
                history.push("/frases-destaques")
            }, 1000)
        } catch (e) {
            setAlertType("error")
            setAlertMessage(e.response.data.data)
            setShowModalAlert(true)
        }
    }

    return (
        <div className="page-container">
            <div className="page-align" style={{ justifyContent: "flex-start" }}>
                <div>
                    <Topo name="Opções de frases" rota={"/frases-destaques"} />
                </div>
                <div className="infinite-scroll">
                    <div className={classes.mainContainer}>
                        {frases.map((f, i) => (
                            <div
                                key={i}
                                className={`${classes.optionContainer}`}
                                style={checked == f.phrase_id ? { border: "1px solid #000" } : null}
                                onClick={() => handleCheckboxChange(f.phrase_id)}
                            >
                                <Checkbox
                                    checked={checked == f.phrase_id}
                                    className={classes.checkbox}
                                />
                                <div className={classes.optionTextContainer}>
                                    <div style={{ display: "flex", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: f.phrase_icon }} />
                                    <p>{f.phrase_text}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="footer-column">
                    <hr style={{ width: "100%" }} />
                    <Button
                        onClick={updateFrase}
                        style={{
                            color: "#fff",
                            minWidth: "100%",
                            height: "50px",
                            boxShadow:
                                "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                            marginTop: "5px",
                            marginBottom: "15px",
                            textTransform: "none",
                            backgroundColor: "#EA3458"
                        }}
                        type="submit"
                        variant="contained"
                    >
                        Salvar alterações
                    </Button>
                </div>
                <CustomAlert
                    type={alertType}
                    alertText={alertMessage}
                    show={showModalAlert}
                    close={() => setShowModalAlert(false)}
                />
            </div>
        </div >
    );
}
