import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import { globalStyles } from "../../Componentes/CssGlobal"
import Topo from "../../Componentes/TopCategorias"
import { telefone, celular } from "../../Componentes/validates/Validate"
import { campovazio } from "../../Componentes/validates/ValidateForm"
import { useSettings } from "../../ContextAPI/settingsContext"
import api from "../../Services/api"
import ModalChamado from "./ModalChamado"
import makeStyles from "./style"

export default function InformacoesLojinha() {
  const classesGlobal = globalStyles()
  const classes = makeStyles()
  const { getLojinhaInfo } = useSettings()
  const [loading, setLoading] = useState(true)
  const [alertType, setAlertType] = useState("")
  const [openModal, setOpenModal] = useState(false)
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const lojinha_id = localStorage.getItem("id")
  const [saving, setSaving] = useState(false)
  const history = useHistory()

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  const current = {
    link: { value: "", error: false }
  }

  const [form, setForm] = useState({
    name: { value: "", error: false },
    description: { value: "", error: false },
    whatsapp: { value: "", error: false },
    link: { value: "", error: false }
  })
  const [urlCurrent, setUrlCurrent] = useState(current)
  const [getError, setGetError] = useState("url existente")

  function urlExistente(e) {
    if (e === "") {
      return "Esse campo precisa ser preenchido"
    }
    if (getError === "url existente") {
      return "Ops, esse link já existe. Tente um diferente."
    }
    return false
  }

  function handleOpenModal() {
    setOpenModal(true)
  }

  function handleCloseModal() {
    setOpenModal(false)
  }

  function pegadados(e) {
    const { name, value } = e
    if (name === "name") {
      setForm({
        ...form,
        // eslint-disable-next-line object-shorthand
        [name]: { value: value, error: campovazio(value) }
      })
    }
    if (name === "link") {
      setForm({
        ...form,
        // eslint-disable-next-line object-shorthand
        [name]: { value: value.toLowerCase(), error: campovazio(value) }
      })
    }
    if (name === "whatsapp") {
      if (value.length <= 14) {
        const formatado = telefone(value)
        setForm({
          ...form,
          // eslint-disable-next-line object-shorthand
          [name]: { value: formatado, error: campovazio(value) }
        })
      } else if (value.length <= 15) {
        const formatado = celular(value)
        setForm({
          ...form,
          // eslint-disable-next-line object-shorthand
          [name]: { value: formatado, error: campovazio(value) }
        })
      }
    }
    if (name === "description") {
      setForm({
        ...form,
        // eslint-disable-next-line object-shorthand
        [name]: { value: value, error: campovazio(value) }
      })
    }
  }

  const body = {
    name: form.name.value,
    description: form.description ? form.description.value : null,
    url: form.link.value,
    whatsapp: form.whatsapp.value
  }

  const bodyNoUrl = {
    name: form.name.value,
    description: form.description ? form.description.value : null,
    whatsapp: form.whatsapp.value
  }

  function getData() {
    api.get(`/lojinha/show/${lojinha_id}`).then(res => {
      const dataGet = res.data.data
      const numeroCelular = dataGet.whatsapp ? dataGet.whatsapp : ""
      setForm({
        name: { value: dataGet.name, error: false },
        link: { value: dataGet.url, error: false },
        whatsapp: { value: numeroCelular, error: false },
        description: {
          value: dataGet.description ? dataGet.description : "",
          error: false
        }
      })
      setUrlCurrent({
        link: { value: dataGet.url, error: false }
      })
      setLoading(false)
    })
  }

  // essa é uma função de salva/ atualizar valores
  async function dataSave() {
    setSaving(true)

    if (
      form.name.value !== "" &&
      form.link.value !== "" &&
      form.whatsapp.value.length >= 14
    ) {
      if (form.link.value === urlCurrent.link.value) {
        api
          .put(`/lojinha/update/${lojinha_id}`, bodyNoUrl)
          .then(res => {
            getLojinhaInfo()
            setAlertType("success")
            setShowAlertOk(true)
            setTimeout(() => {
              history.goBack()
            }, 1500)
          })
          .catch(error => {
            setSaving(false)
            setAlertType("error")
            setShowAlertError(true)
          })
      } else {
        api
          .put(`/lojinha/update/${lojinha_id}`, body)
          .then(res => {
            if (res.data.data === "url existente") {
              setSaving(false)
              setAlertType("error")
              setShowAlertError(true)

              setForm({
                ...form,
                link: { error: urlExistente(form.link.value) }
              })
            } else {
              getLojinhaInfo()
              setAlertType("success")
              setShowAlertOk(true)
              setTimeout(() => {
                history.goBack()
              }, 1500)
            }

            setUrlCurrent({
              link: { value: body.url, error: false }
            })
          })
          .catch(error => {
            setSaving(false)
            setAlertType("error")
            setShowAlertError(true)
            setGetError(error)
          })
      }
    } else {
      setSaving(false)
      setAlertType("error")
      setShowAlertError(true)
      setForm({
        // eslint-disable-next-line object-shorthand
        name: { value: form.name.value, error: campovazio(form.name.value) },
        whatsapp: {
          value: form.whatsapp.value,
          error: campovazio(form.whatsapp.value)
        },
        description: {
          value: form.description.value,
          error: campovazio(form.description.value)
        },
        link: { value: form.link.value, error: campovazio(form.link.value) }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {loading ? (
        <div
          style={{
            backgroundColor: " #fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <>
          <div className="page-container">
            <div className="page-align">
              <div>
                <Topo name="Informações da loja" />
              </div>

              <div className="infinite-scroll">
                <div>
                  <div className={classes.fonteTitulo}>Nome da loja</div>
                  <div>
                    <TextField
                      style={{ backgroundColor: "#fff" }}
                      name="name"
                      value={form.name.value}
                      size="small"
                      variant="outlined"
                      color="secondary"
                      onChange={e => pegadados(e.target)}
                      error={form.name.error}
                      helperText={form.name.error}
                      fullWidth
                      inputProps={{ maxLength: 30 }}
                      placeholder="Limite de 30 caracteres"
                    />
                  </div>
                </div>

                <div
                  className={classes.boxLink}
                  onClick={() => setOpenModal(true)}
                  aria-hidden="true"
                >
                  <div className={classes.inputLink}>
                    <div className={classes.fonteTitulo}>Link da loja</div>
                    <TextField
                      disabled
                      style={{
                        backgroundColor: "#fff"
                      }}
                      size="small"
                      variant="outlined"
                      color="secondary"
                      value={form.link.value}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <p className={classes.fonteLojinha}>.uppy.app</p>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </div>

                <div>
                  <div className={classes.fonteTitulo}>Whatsapp de contato</div>
                  <TextField
                    style={{ backgroundColor: "#fff" }}
                    name="whatsapp"
                    onChange={e => pegadados(e.target)}
                    error={form.whatsapp.error}
                    helperText={form.whatsapp.error}
                    size="small"
                    type="tel"
                    value={form.whatsapp ? form.whatsapp.value : ""}
                    variant="outlined"
                    color="secondary"
                    placeholder="(99) 9 9999 9999"
                    fullWidth
                  />
                </div>

                <div className={classes.boxDescricao}>
                  <div className={classes.fonteTitulo}>Descrição da loja</div>
                  <div>
                    <TextField
                      style={{ backgroundColor: "#fff" }}
                      name="description"
                      value={form.description ? form.description.value : ""}
                      onChange={e => pegadados(e.target)}
                      size="small"
                      variant="outlined"
                      color="secondary"
                      rows={4}
                      multiline
                      fullWidth
                      inputProps={{ maxLength: 140 }}
                      placeholder="Limite de 140 caracteres"
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <CustomAlert
                    type={alertType}
                    alertText={
                      alertType === "success"
                        ? "Dados salvos com sucesso!"
                        : "Algo deu errado! Preencha todos os campos."
                    }
                    show={showAlertOk || showAlertError}
                    close={() => closeAlerts()}
                  />
                </div>
              </div>

              <div className="footer-column">
                <hr style={{ width: "100%" }} />
                <Button
                  className={classes.btnSalvar}
                  variant="contained"
                  disabled={saving}
                  onClick={() => {
                    dataSave()
                  }}
                >
                  Salvar alterações
                </Button>
              </div>
            </div>
          </div>
          {openModal ? (
            <ModalChamado isOpen={handleOpenModal} handleClose={handleCloseModal} />
          ) : (
            ""
          )}
        </>
      )}
    </>
  )
}
