import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  ColorSelectContainer: {
    "& h3": {
      color: "#6C6C6C!important",
      fontSize: "0.8rem",
      fontWeight: "400"
    },
    "& div": {
      marginBottom: "1.2rem"
    }
  },

  InputBtn: {
    marginLeft: "10px",
    width: "129px",
    padding: "5px",
    height: "40px",
    border: "1px solid #D0D0D0",
    borderRadius: "5px",
    color: "#D0D0D0"
  },

  TitleCustom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  ContainerPrimary: {
    width: "45px",
    height: "45px",
    borderRadius: "10px",
    margin: "20px"
  },

  SubtitleCustom: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#6C6C6C",
  },

  

}))
