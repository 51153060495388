import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  btnFooterPink: {
    height: "50px"
  },
  containerLoading: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
  }
}))
