import { React, useEffect, useState } from "react"
import { Button, CircularProgress, Switch, TextField } from "@material-ui/core"
import { globalStyles } from "../../../Componentes/CssGlobal"
import { useStyles } from "./style"
import Topo from "../../../Componentes/TopCategorias"
import { IoIosAdd, IoMdTrash } from "react-icons/io"
import api from "../../../Services/api"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { useHistory } from "react-router-dom"

export default function ConfigSEO() {
  const classesGlobal = globalStyles()
  const classes = useStyles()

  const [switchValue, setSwitchValue] = useState(1)
  const [title, setTitle] = useState()
  const [description, setDescription] = useState()
  const [loading, setLoading] = useState(true)
  const [alertType, setAlertType] = useState("")
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [saving, setSaving] = useState(false)
  const history = useHistory()

  const [arrayTag, setArray] = useState([]) // ARRAY DE TAGS
  const [valorInput, setValorInput] = useState("") // VALOR DO INPUT

  // ADICIONAR
  const handleAdicionar = () => {
    const test2 = valorInput.toString()
    const test = test2.replace(/( )+/g, " ")

    if (test !== " " || test !== "") {
      setArray(arrayAntigo => {
        return [...arrayAntigo, valorInput.replace(/,/g, ".")]
      })
    }

    setValorInput("")
  }

  // DELETAR
  const handleDelete = index => {
    setArray(arrayAntigo => {
      return arrayAntigo.filter(item => arrayAntigo.indexOf(item) !== index)
    })
  }

  function getData() {
    const lojinha_id = localStorage.getItem("id")
    api.get(`/configuration/show/${lojinha_id}`).then(res => {
      if (res.data.data[0].seo_tags === null) {
        setArray([])
        setLoading(false)
        setTitle(res.data.data[0].seo_text)
        setDescription(res.data.data[0].seo_description)
        setSwitchValue(res.data.data[0].seo_check)
      } else {
        const seo_tags = String(res.data.data[0].seo_tags).split("|")
        setTitle(res.data.data[0].seo_text)
        setDescription(res.data.data[0].seo_description)
        setSwitchValue(res.data.data[0].seo_check)
        setArray(seo_tags)
        setLoading(false)
      }
    })
  }

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  function activeSwitch() {
    if (switchValue === 0) {
      setSwitchValue(1)
    } else {
      setSwitchValue(0)
    }
  }

  const tranformArr = arrayTag.toString()
  const NewTagSEO = tranformArr.replace(/,/g, "|")

  function dataSave() {
    setSaving(true)

    const body = {
      seo_check: switchValue,
      seo_text: switchValue === 0 ? "" : title,
      seo_description: switchValue === 0 ? "" : description,
      seo_tags: switchValue === 0 ? "" : NewTagSEO
    }
    const id = localStorage.getItem("config_id")
    api
      .put(`/configuration/update/${id}`, body)
      .then(res => {
        setAlertType("success")
        setShowAlertOk(true)
        setTimeout(() => {
          history.goBack()
        }, 1500)
      })
      .catch(error => {
        setSaving(false)
        setAlertType("error")
        setShowAlertError(true)
      })
    setValorInput("")
  }

  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      {loading ? (
        <div
          style={{
            backgroundColor: " #fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <>
          <div className="page-container">
            <div className="page-align">
              <div>
                <Topo name="Configurações SEO" />
              </div>

              <div className="infinite-scroll">
                <div className={classes.Box}>
                  <div className={classes.titleText}>
                    Habilitar Configurações SEO
                  </div>
                  <div className={classes.switchChange}>
                    <Switch
                      checked={switchValue}
                      onClick={() => {
                        activeSwitch()
                      }}
                    />
                  </div>
                </div>

                {switchValue === 1 ? (
                  <div>
                    <div className={classes.fonteTitulo}>Título da loja</div>
                    <div>
                      <TextField
                        style={{ backgroundColor: "#fff" }}
                        name="title"
                        error=""
                        helperText=""
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        inputProps={{ maxLength: 30 }}
                        placeholder="Limite de 30 caracteres"
                      />
                    </div>
                    <div className={classes.fonteTitulo}>Descrição da loja</div>
                    <div>
                      <TextField
                        style={{ backgroundColor: "#fff" }}
                        name="description"
                        multiline
                        rows={4}
                        error=""
                        helperText=""
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        inputProps={{ maxLength: 140 }}
                        placeholder="Limite de 140 caracteres"
                      />
                    </div>

                    <div className={classes.BoxKeyWords}>
                      <div className={classes.inputKeywords}>
                        <label htmlFor="title" className={classes.fonteTitulo}>
                          Palavras-chave
                        </label>

                        <TextField
                          style={{ backgroundColor: "#fff" }}
                          name="title"
                          value={valorInput}
                          onChange={evento => setValorInput(evento.target.value)}
                          size="small"
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          inputProps={{ maxLength: 50 }}
                          placeholder="Limite de 50 caracteres"
                        />
                      </div>

                      <div>
                        <button className={classes.btnAdd} onClick={handleAdicionar}>
                          {" "}
                          <IoIosAdd
                            style={{
                              fontSize: "3.1em",
                              color: "#EA3458",
                              cursor: "pointer",
                              minWidth: "30px",
                              border: "none",
                              background: "transparent"
                            }}
                          />
                        </button>
                      </div>
                    </div>

                    <div>
                      {arrayTag.map((elemento, key) => {
                        return (
                          <div>
                            {elemento === "" ? (
                              ""
                            ) : (
                              <>
                                <div className={classes.boxWords}>
                                  <p>{elemento}</p>
                                  <button
                                    className={classes.btnDelete}
                                    onClick={() => handleDelete(key)}
                                  >
                                    <IoMdTrash
                                      style={{
                                        fontSize: "1.8em",
                                        color: "#EA3458",
                                        cursor: "pointer",
                                        minWidth: "30px",
                                        border: "none",
                                        background: "transparent"
                                      }}
                                    />
                                  </button>
                                </div>
                                <hr style={{ width: "100%", marginTop: "-2px" }} />
                              </>
                            )}
                          </div>
                        )
                      })}
                    </div>
                    <p style={{ textAlign: "center", fontWeight: "700" }}>
                      Para a conclusão da configuração o processo pode levar até
                      72hrs, por favor aguarde.
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <CustomAlert
                    type={alertType}
                    alertText={
                      alertType === "success"
                        ? "Dados salvos com sucesso!"
                        : "Algo deu errado!"
                    }
                    show={showAlertOk || showAlertError}
                    close={() => closeAlerts()}
                  />
                </div>
              </div>

              <div className="footer-column">
                <hr style={{ width: "100%" }} />
                <Button
                  className={classes.btnSalvar}
                  disabled={saving}
                  variant="contained"
                  onClick={() => {
                    dataSave()
                  }}
                >
                  Salvar alterações
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
