import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  CardWrapper: {
    width: "140px",
    height: "120px",
    cursor: "move",
    display: "flex",
    flexDirection: "row",
    margin: "5px 0px",
    borderRadius: "5px",
    boxSizing: "border-box"
  },

  imgProduto: {
    width: "140px",
    height: "120px",
    objectFit: "cover",
    borderRadius: "5px",
    /* margin: "2px 5px 2px 5px", */
    cursor: "move"
  },

  star: {
    position: 'absolute',
    marginTop:'-13px',
    marginLeft: '-11px'
  }
}))
