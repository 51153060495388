import { Button, CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import LogoCinza from "../../../Assets/img/logoCinza.png"
import { globalStyles } from "../../../Componentes/CssGlobal"
import { button } from "../../../Componentes/Inputs/TextFild"
import Cropper from "../../../Componentes/Cropper"
import { readFile } from "../../../utils"
import { useStyles } from "./style"
import conexao from "../../../Services/api"
import lojinhaRequest from "../../../Services/api/lojinha"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import CircularProgressWithLabel from "../../../Componentes/CircularProgressWithLabel"
import { SimpleImg } from "react-simple-img"

export default function EtapaLogo({ handleStep, logo }) {
  const classesGlobal = globalStyles()
  const classes = useStyles()

  const [ImageSrc, setImageSrc] = useState(null)
  const [preview, setPreview] = useState(null)
  const [openCrop, setOpenCrop] = useState(false)
  const [url, setUrl] = useState(null)
  const [showAlert, setShowAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertText, setAlertText] = useState("")
  const [loadPage, setLoadPage] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  useEffect(() => {
    if (logo !== null) setPreview(logo)
    // getData()
  }, [])

  const handleCroppedImg = img => {
    imageUpload(ImageSrc, img)
    setOpenCrop(false)
    setImageSrc(null)
  }

  async function imageUpload(src, min) {
    setLoadPage(true)

    const er = /[^a-zA-Zs]/g
    let shop_name = localStorage.getItem("name").replace(er, "").toLocaleLowerCase()

    const payload = {
      path: shop_name,
      photo: src,
      crop: min
    }

    try {
      const {
        data: { data }
      } = await conexao.post("/image/upload", payload, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total))
          setUploadProgress(progress)
        }
      })

      setUrl({
        logo: data.url,
        xlg_url: data.xlg_url,
        lg_url: data.lg_url,
        md_url: data.md_url,
        sm_url: data.sm_url,
        url_min: data.sm_url,
        order: null
      })
      setPreview(data.md_url)
    } catch ({ response }) {
      if (response.status === 413) {
        setShowAlert(true)
        setAlertType("error")
        setAlertText("Imagem muito grande!")
      }
    } finally {
      setLoadPage(false)
      setUploadProgress(0)
    }
  }

  async function getData() {
    const id = localStorage.getItem("id")

    try {
      const { data } = await conexao.get(`/lojinha/show/${id}`, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total))
          setUploadProgress(progress)
        }
      })

      setPreview(data.data.md_url)
    } catch (err) {
      setAlertType("error")
      setAlertText("Algo deu errado!")
      setShowAlert(true)
    } finally {
      setLoadPage(false)
    }
  }

  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let imageDataUrl = await readFile(file)
      setImageSrc(imageDataUrl)
      file = null
      imageDataUrl = null
      setOpenCrop(true)
    }
  }

  const saveLogo = async () => {
    if (url !== null) {
      const saveLogoRes = await lojinhaRequest.put(url)
      if (saveLogoRes.data.error === false) {
        setAlertType("success")
        setAlertText("Logo salva com sucesso!")
        setShowAlert(true)
        setImageSrc(null)
        handleStep("proximo")
      }
    } else {
      setAlertType("error")
      setAlertText("Erro ao salvar logo!")
      setShowAlert(true)
    }
  }

  const closeAlert = () => {
    setShowAlert(false)
  }

  return (
    <>
      <div className="infinite-scroll">
        <div className="EtapaHeader">
          <h2>Adicione a logo</h2>
          <h3>da sua loja abaixo:</h3>
        </div>
        {openCrop === false ? (
          <>
            <div className={classes.positionImg}>
              {loadPage ? (
                <div className={classes.progress}>
                  <CircularProgressWithLabel
                    value={uploadProgress}
                    size={50}
                    color="secondary"
                  />
                </div>
              ) : (
                <>
                  {preview ? (
                    <SimpleImg
                      width="auto"
                      height="auto"
                      placeholder={false}
                      alt="logoDefault"
                      className={classes.previewImg}
                      src={preview}
                    />
                  ) : (
                    <div className={classes.positionImg}>
                      {preview ? (
                        <SimpleImg
                          width="auto"
                          height="auto"
                          placeholder={false}
                          alt="logo da sua loja"
                          className={classes.previewImg}
                          src={preview}
                        />
                      ) : (
                        <div className={classes.createImg}>
                          <div className={classes.positionLogo}>
                            <SimpleImg
                              width="auto"
                              height="auto"
                              placeholder={false}
                              alt="logoDefault"
                              className={classes.defaultLogo}
                              src={LogoCinza}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={classes.positionBtnAddLogo}>
              <label htmlFor="addFoto">
                <input
                  accept="image/*"
                  className={classes.input}
                  id="addFoto"
                  type="file"
                  onChange={onFileChange}
                />

                <Button
                  className={classes.btnAddLogo}
                  variant="contained"
                  component="span"
                  onClick={() => {}}
                >
                  Escolher imagem
                </Button>
              </label>
            </div>

            <div className={classes.positionTextDescription}>
              <span className={classes.textDescription}>
                Resolução recomendável 500px por 500px
                <br />
                Tamanho máx.: 3 mb
              </span>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <CustomAlert
                alertText={alertText}
                show={showAlert}
                type={alertType}
                close={() => closeAlert()}
              />
            </div>
          </>
        ) : (
          <Cropper
            src={ImageSrc}
            open={openCrop}
            close={() => setOpenCrop(false)}
            callback={handleCroppedImg}
          />
        )}
      </div>
      <div className="footer-column">
        {button({
          funcao: () => saveLogo(),
          name: `Próximo`
        })}
        <button
          onClick={() => handleStep("nextStep")}
          className="voltarLink"
          style={{ margin: 0, marginLeft: "auto" }}
        >
          Pular etapa
        </button>
      </div>
    </>
  )
}
