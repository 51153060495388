import axios from "axios"

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  headers: {
    Authorization: localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "",
    "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
    "Content-Type": "application/json",
    "Accept": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
})

export default api
