export default function CartaoDebito(props) {
  return (
    <svg
      width="30"
      height="25"
      viewBox="0 0 25 19"
      fill={props.fill ? "#a4a4a4" : " #EA3458"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3.25C0 2.4212 0.32924 1.62634 0.915291 1.04029C1.50134 0.45424 2.2962 0.125 3.125 0.125H21.875C22.7038 0.125 23.4987 0.45424 24.0847 1.04029C24.6708 1.62634 25 2.4212 25 3.25V15.75C25 16.5788 24.6708 17.3737 24.0847 17.9597C23.4987 18.5458 22.7038 18.875 21.875 18.875H3.125C2.2962 18.875 1.50134 18.5458 0.915291 17.9597C0.32924 17.3737 0 16.5788 0 15.75V3.25ZM3.125 1.6875C2.7106 1.6875 2.31317 1.85212 2.02015 2.14515C1.72712 2.43817 1.5625 2.8356 1.5625 3.25V4.8125H23.4375V3.25C23.4375 2.8356 23.2729 2.43817 22.9799 2.14515C22.6868 1.85212 22.2894 1.6875 21.875 1.6875H3.125ZM23.4375 7.9375H1.5625V15.75C1.5625 16.1644 1.72712 16.5618 2.02015 16.8549C2.31317 17.1479 2.7106 17.3125 3.125 17.3125H21.875C22.2894 17.3125 22.6868 17.1479 22.9799 16.8549C23.2729 16.5618 23.4375 16.1644 23.4375 15.75V7.9375Z"
        fill={props.Color}
      />
      <path
        d="M3.125 12.625C3.125 12.2106 3.28962 11.8132 3.58265 11.5201C3.87567 11.2271 4.2731 11.0625 4.6875 11.0625H6.25C6.6644 11.0625 7.06183 11.2271 7.35485 11.5201C7.64788 11.8132 7.8125 12.2106 7.8125 12.625V14.1875C7.8125 14.6019 7.64788 14.9993 7.35485 15.2924C7.06183 15.5854 6.6644 15.75 6.25 15.75H4.6875C4.2731 15.75 3.87567 15.5854 3.58265 15.2924C3.28962 14.9993 3.125 14.6019 3.125 14.1875V12.625Z"
        fill={props.Color}
      />
    </svg>
  )
}
