import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  signatureContainer: {},

  signatureHeader: {
    height: "8.9rem",
    padding: "12px 14px",
    background: "#FFFFFF",
    boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
    marginTop: "30px",
    borderRadius: "5px"
  },

  signatureTexts: {
    display: "flex",
    justifyContent: "space-between",

    "& div": {
      "& p": {
        margin: "0",
        marginBottom: "10px",
        fontSize: "12px",
        fontWeight: "600"
      },

      "& span": {
        fontWeight: "400",
        marginBottom: "10px",
        fontSize: "12px",
        color: "#555555"
      }
    }
  },

  groupData: {
    width: "140px"
  },

  cancelText: {
    fontWeight: "500",
    fontSize: "12px",
    color: "#EA3458",
    textDecorationLine: "underline",
    margin: "0",
    marginTop: "15px",
    textAlign: "end",
    paddingTop: "8px"
  },

  changePassword: {
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
    marginBottom: "60px",
    justifyContent: "space-between",

    "& div": {
      display: "flex",

      "& p": {
        marginLeft: "13px",
        fontWeight: "500",
        fontSize: "14px"
      }
    }
  },

  bannerPro: {
    background: "#C4C4C4",
    borderRadius: "10px",
    width: "280px",
    height: "157px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "24px",
    fontWeight: "400",
    marginTop: "40px",
    marginBottom: "27px"
  },

  deleteText: {
    fontWeight: "500",
    fontSize: "11.5px",
    color: "#353535",
    margin: "0",
    marginTop: "15px",
    marginBottom: "10px",
    textAlign: "center",
    paddingTop: "8px"
  }
}))
