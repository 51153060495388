import { AiFillCheckCircle } from "react-icons/ai"
import { useStyles } from "./style"
import { IconPorcentagem } from "../../Assets/IconPorcentagem"
import { button } from "../../../../Componentes/Inputs/TextFild"
import { useHistory } from "react-router-dom"

export default function BoxCupom(props) {
  const history = useHistory()

  const classes = useStyles()
  const pricePerMonth = props.newPrice / 12
  const priceFull = props.newPrice.toFixed(2).replace(".", ",")

  return (
    <>
      <div
        className={classes.PlanComponent}
        onClick={() => {
          props.handleSwitchComponents()
        }}
      >
        <div className={classes.IconDisable}></div>

        <div>
          <h3>
            12x <span>R$27,90</span> no plano anual
          </h3>
          <h4>ou R$ 334,80 à vista</h4>
        </div>
      </div>

      <div className={classes.PlanComponentCupom}>
        <div className={classes.BoxInfo}>
          <AiFillCheckCircle
            style={{ color: "#3498DB", margin: "10px" }}
            size={34}
          />
          <div>
            <h3>
              12x <span>R${pricePerMonth.toFixed(2).replace(".", ",")}</span> no
              plano anual
            </h3>
            <h4>ou R$ {priceFull} à vista</h4>
          </div>
        </div>
        <IconPorcentagem width="75" height="75" color1="#FFF" color2="#3498DB" />
      </div>

      <div>
        <div>
          <div className={classes.Button}>
            {button({
              name: `utilizar cupom de desconto`,
              color: "#3498DB",
              funcao: () => history.push("/planos/v2/checkout", props.newPrice)
            })}
          </div>
        </div>

        <div className={classes.Footer}>
          <h3>
            Sua satisfação garantida: compre com tranquilidade, cancelamento e
            reembolso em até 7 dias após a compra.
          </h3>
        </div>
      </div>
    </>
  )
}
