import { CircularProgress } from "@material-ui/core"
import moment from "moment"
import React, { useEffect, useState } from "react"
import noVisitors from "../../../../../Assets/Home/noProducts.svg"
import { globalStyles } from "../../../../../Componentes/CssGlobal"
import Topo from "../../../../../Componentes/TopCategorias"
import api from "../../../../../Services/api"
import CardInfo from "./components/CardInfo"
import CardProduto from "./components/CardProduto"
import { useStyles } from "./style"

export default function CardAno(props) {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const [visits, setVisits] = useState()
  const [products, setProducts] = useState()
  const [loadPage, setLoadPage] = useState(true)
  const [charts, setCharts] = useState([])
  const shopId = localStorage.getItem("id")

  const getByDay = async () => {
    const hasTrackingTotal = await api.get(
      `/tracking/getbyday/${shopId}`
    )
    const newData = hasTrackingTotal.data.data

    const newDataFilterDay = filterByDay(newData)
    setVisits(newDataFilterDay === undefined ? [] : newDataFilterDay)
  }

  const dataProduct = async () => {
    const hasTrackingByDay = await api.get(
      `/tracking/getproductsday/${shopId}/10`
    )
    const newDataProduct = hasTrackingByDay.data.data
    const newDataProductFilter = filterByDay(newDataProduct)

    newDataProduct.sort((x, y) => {
      return y.tracking_total - x.tracking_total
    })
    setProducts(newDataProductFilter)

    setLoadPage(false)
  }

  function filterByDay(arrProduct) {
    return arrProduct.filter(
      product =>
        product.day === moment().date() && product.month !== moment().month()
    )
  }

  useEffect(() => {
    getByDay()
    dataProduct()
  }, [])
  return (
    <>
      {loadPage ? (
        <div
          style={{
            backgroundColor: " #fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <>
          <div className="page-container">
            <div style={{ justifyContent: "flex-start" }} className="page-align">
              <Topo name="Relatório de visitas" />
              <div className={classes.topOption}>
                <div className={classes.topSelector2}>
                  <div
                    className={classes.topSelector}
                    onClick={() => {
                      props.setPosition(0)
                    }}
                    aria-hidden="true"
                  >
                    Ano
                  </div>
                  <div
                    className={classes.topSelector}
                    onClick={() => {
                      props.setPosition(1)
                    }}
                    aria-hidden="true"
                  >
                    <div>Mensal</div>
                    {/* <div style={{ color: '#EA3458', fontSize: '1em' }}>PRO</div> */}
                  </div>
                  <div
                    className={classes.topSelector}
                    onClick={() => {
                      props.setPosition(2)
                    }}
                    aria-hidden="true"
                  >
                    <div>7 dias</div>
                  </div>
                  <div
                    className={classes.topSelected}
                    onClick={() => {
                      props.setPosition(3)
                    }}
                    aria-hidden="true"
                  >
                    <div>Hoje</div>
                  </div>
                </div>
              </div>
              <div style={{ maxHeight: "100%" }} className="infinite-scroll">
                <div className={classes.cardInfo}>
                  {visits !== undefined && visits.length === 0 ? (
                    <div className={classes.noVisitors}>
                      <img
                        src={noVisitors}
                        alt="sem produtos"
                        style={{
                          marginTop: "10px",
                          marginBottom: "12px",
                          maxWidth: "165px",
                          maxHeight: "115px"
                        }}
                      />
                      <span className={classes.infoText}>
                        Você ainda não teve visitas hoje.
                      </span>
                    </div>
                  ) : (
                    <CardInfo data={visits}></CardInfo>
                  )}
                </div>
                {products !== undefined && products.length <= 0 ? (
                  ""
                ) : (
                  <div style={{ display: "flex" }}>
                    <div style={{ color: "#EA3458", fontWeight: "bold" }}>
                      TOP 10
                    </div>
                    <div style={{ color: "#353535", fontWeight: "500" }}>
                      - Produtos mais procurados na sua loja
                    </div>
                  </div>
                )}
                <div className={classes.cardProduto}>
                  <CardProduto products={products} />
                </div>
                {/* {charts.length <= 0 ? (
                  ''
                ) : (
                  <div>
                    <CardVisitas charts={charts} />
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
