import { FormControl, NativeSelect, TextField } from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import Switch from "@material-ui/core/Switch"
import { withStyles } from "@material-ui/core/styles"
import { useEffect, useState } from "react"
import { current } from "../../../../Componentes/validates/Validate"
import { useStyles } from "./style"
import InputBase from "@material-ui/core/InputBase"

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(4)
    }
  },
  input: {
    width: "100%",
    borderRadius: 5,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid  #EA3458",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    color: "#EA3458",
    fontWeight: "500",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#EA3458",
      boxShadow: "0 0 0 0.1rem #EA3458cc"
    }
  }
}))(InputBase)

export function HabilitarEntrega({
  handleDeliveryFormData,
  deliveryInformations,
  entrega
}) {
  const classes = useStyles()

  const [hasEnableDelivery, setHasEnableDelivery] = useState(entrega)
  const [hasDeliveryTime, setHasDeliveryTime] = useState(false)
  const [hasFreeShipping, setHasFreeShipping] = useState(false)
  const [enableDeliveryFormData, setEnableDeliveryFormData] = useState({
    value: "",
    min_time: "",
    max_time: "",
    time_type: "HORAS",
    free_shipping_above: ""
  })

  useEffect(() => {
    if (deliveryInformations !== undefined) {
      setEnableDeliveryFormData(deliveryInformations)
      if (
        deliveryInformations.max_time !== null &&
        deliveryInformations.max_time !== "" &&
        deliveryInformations.min_time !== null &&
        deliveryInformations.min_time !== ""
      ) {
        setHasDeliveryTime(true)
        setEnableDeliveryFormData({
          ...deliveryInformations,
          enableDeliveryTime: true
        })
      }

      if (
        deliveryInformations.free_shipping_above !== null &&
        deliveryInformations.free_shipping_above !== ""
      ) {
        setHasFreeShipping(true)
      }
    }
  }, [deliveryInformations])

  return (
    <section style={{ marginBottom: "20px" }}>
      <div className={classes.toggledTitle}>
        <h2>Habilitar Entrega</h2>
        <Switch
          inputProps={{ "aria-label": "secondary checkbox" }}
          onClick={() => {
            setHasEnableDelivery(!hasEnableDelivery)
            handleDeliveryFormData(
              {
                ...enableDeliveryFormData
              },
              !hasEnableDelivery
            )
          }}
          checked={hasEnableDelivery}
        />
      </div>

      {hasEnableDelivery ? (
        <form>
          <div style={{ marginBottom: "20px" }}>
            <h3>Valor da entrega</h3>
            <TextField
              name="title"
              onChange={e => {
                setEnableDeliveryFormData({
                  ...enableDeliveryFormData,
                  value: current(e.target.value)
                })
                handleDeliveryFormData(
                  {
                    ...enableDeliveryFormData,
                    value: current(e.target.value)
                  },
                  hasEnableDelivery
                )
              }}
              size="small"
              variant="outlined"
              type="tel"
              color="secondary"
              value={
                new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(
                  enableDeliveryFormData.value
                ) || ""
              }
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>
              }}
              style={{ backgroundColor: "#fff" }}
            />
          </div>

          <section style={{ marginBottom: "20px" }}>
            <div className={classes.toggledTitle}>
              <h2>Prazo de entrega</h2>
              <Switch
                inputProps={{ "aria-label": "secondary checkbox" }}
                onClick={() => {
                  setHasDeliveryTime(!hasDeliveryTime)
                  setEnableDeliveryFormData({
                    ...enableDeliveryFormData,
                    max_time: null,
                    min_time: null,
                    enableDeliveryTime: !hasDeliveryTime
                  })
                  handleDeliveryFormData(
                    {
                      ...enableDeliveryFormData,
                      max_time: null,
                      min_time: null,
                      enableDeliveryTime: !hasDeliveryTime
                    },
                    hasEnableDelivery
                  )
                }}
                checked={hasDeliveryTime}
              />
            </div>

            {hasDeliveryTime ? (
              <div>
                <div className={classes.dropdown}>
                  <p style={{ marginRight: "20px" }}>Prazo de entrega em: </p>
                  <FormControl>
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={enableDeliveryFormData.time_type || ""}
                      onChange={e => {
                        setEnableDeliveryFormData({
                          ...enableDeliveryFormData,
                          time_type: e.target.value
                        })
                        handleDeliveryFormData(
                          {
                            ...enableDeliveryFormData,
                            time_type: e.target.value
                          },
                          hasEnableDelivery
                        )
                      }}
                      input={<BootstrapInput />}
                    >
                      <option>DIAS</option>
                      <option>HORAS</option>
                      <option>MINUTOS</option>
                    </NativeSelect>
                  </FormControl>
                </div>

                <div className={classes.deliveryTime}>
                  <p>De</p>
                  <TextField
                    name="title"
                    onChange={e => {
                      setEnableDeliveryFormData({
                        ...enableDeliveryFormData,
                        min_time: e.target.value
                      })
                      handleDeliveryFormData(
                        {
                          ...enableDeliveryFormData,
                          min_time: e.target.value
                        },
                        hasEnableDelivery
                      )
                    }}
                    size="small"
                    variant="outlined"
                    type="tel"
                    color="secondary"
                    value={enableDeliveryFormData.min_time || ""}
                    fullWidth
                    style={{
                      backgroundColor: "#fff",
                      width: "6rem",
                      margin: "0 10px"
                    }}
                    inputProps={{ maxLength: 3 }}
                  />

                  <p>Até</p>
                  <TextField
                    name="title"
                    onChange={e => {
                      setEnableDeliveryFormData({
                        ...enableDeliveryFormData,
                        max_time: e.target.value
                      })
                      handleDeliveryFormData(
                        {
                          ...enableDeliveryFormData,
                          max_time: e.target.value
                        },
                        hasEnableDelivery
                      )
                    }}
                    size="small"
                    variant="outlined"
                    type="tel"
                    color="secondary"
                    value={enableDeliveryFormData.max_time || ""}
                    fullWidth
                    style={{
                      backgroundColor: "#fff",
                      width: "6rem",
                      margin: "0 10px"
                    }}
                    inputProps={{ maxLength: 3 }}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </section>

          <section style={{ marginBottom: "30px" }}>
            <div className={classes.toggledTitle}>
              <h2>Frete Grátis</h2>
              <Switch
                inputProps={{ "aria-label": "secondary checkbox" }}
                onClick={() => {
                  setHasFreeShipping(!hasFreeShipping)
                  if (!hasFreeShipping === false) {
                    setEnableDeliveryFormData({
                      ...enableDeliveryFormData,
                      free_shipping_above: null
                    })
                    handleDeliveryFormData(
                      {
                        ...enableDeliveryFormData,
                        free_shipping_above: null
                      },
                      hasEnableDelivery
                    )
                  }
                }}
                checked={hasFreeShipping}
              />
            </div>

            {hasFreeShipping ? (
              <>
                <h3>Frete grátis para pedidos com valor acima de:</h3>
                <TextField
                  name="title"
                  onChange={e => {
                    setEnableDeliveryFormData({
                      ...enableDeliveryFormData,
                      free_shipping_above: current(e.target.value)
                    })
                    handleDeliveryFormData(
                      {
                        ...enableDeliveryFormData,
                        free_shipping_above: current(e.target.value)
                      },
                      hasEnableDelivery
                    )
                  }}
                  size="small"
                  variant="outlined"
                  color="secondary"
                  value={
                    new Intl.NumberFormat("pt-BR", {
                      minimumFractionDigits: 2
                    }).format(enableDeliveryFormData.free_shipping_above) || ""
                  }
                  type="tel"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    )
                  }}
                  fullWidth
                  style={{ backgroundColor: "#fff" }}
                />
              </>
            ) : (
              <></>
            )}
          </section>
        </form>
      ) : (
        <></>
      )}
    </section>
  )
}
