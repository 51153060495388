import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  Box: {
    display: "flex",
    justifyContent: "space-between"
  },
  titleText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "500",
    fontSize: "0.9em",
    color: "#353535"
  },
  switchChange: {
    display: "flex",
    alignItems: "center"
  },
  descriptionText: {
    display: "flex",
    fontSize: "0.8em",
    color: "#555555"
  },
  bannerSejaPro: {
    height: "200px",
    borderRadius: "5px",
    fontSize: "24px",
    fontWeight: "400",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#C4C4C4",
    marginTop: "20px"
  },
  /// //////////////////////////////////////////////
  btnAddLogo: {
    color: "#fff",
    minWidth: "100%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "5px",
    marginBottom: "15px",
    textTransform: "none",
    backgroundColor: "#EA3458",
    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px",
      fontWeight: "500"
    },
    "&:hover": {
      backgroundColor: "#ff5089c9"
    }
  }
}))
