import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  rootConfig: {
    width: "100%",
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    justifyContent: "flex-start"
  },

  Box: {
    marginTop: "10px"
  },

  boxContainer: {
    borderBottom: "1px solid #F3F3F3",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  boxConteudo: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px"
  },

  btnalinhamento: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  fonteTitulo: {
    display: "flex",
    alignItems: "left",
    color: "#353535",
    fontSize: "1.0em",
    marginTop: "10px"
  },

  fonteSubtitulo: {
    display: "flex",
    color: "#6C6C6C",
    fontSize: "0.8em",
    marginTop: "5px",
    marginBottom: "5px"
  }
}))
