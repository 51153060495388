import { Box, CircularProgress } from "@material-ui/core"
import Switch from "@material-ui/core/Switch"
import React, { useEffect, useState } from "react"
import { globalStyles } from "../../../Componentes/CssGlobal"
import CategoriasContainer from "../../../Componentes/DragAndDrop/CategoriasContainer"
import { button } from "../../../Componentes/Inputs/TextFild"
import Topo from "../../../Componentes/TopCategorias"
import ColorSelectContainer from "../../../Componentes/ColorPallet/ColorSelectContainer"
import { useSettings } from "../../../ContextAPI/settingsContext"
import categoriasRequest from "../../../Services/api/categorias"
import { useProduct } from "../../../ContextAPI/productContext"
import api from "../../../Services/api"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { useHistory } from "react-router-dom"
import log from "eslint-plugin-react/lib/util/log"

export default function PersonalizarDesign() {
  const classesGlobal = globalStyles()
  const { primaryColor, secondaryColor, updateLojinhaConfigs } = useSettings()
  const { getCategoriesList } = useProduct()
  const [enabledStories, setEnabledStories] = useState(1)
  const [storiesList, setStoriesList] = useState([])
  const [loading, setLoading] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [saving, setSaving] = useState(false)

  const history = useHistory()
  const saveConfigs = () => {
    setSaving(true)
    handleSaveColors()
  }

  const handleSaveColors = async () => {
    const newPrimaryColor = primaryColor.substring(0, 7)
    const newSecondaryColor = secondaryColor.substring(0, 7)
    try {
      await updateLojinhaConfigs({
        primary_color: newPrimaryColor,
        secondary_color: newSecondaryColor
      })
      setAlertType("success")
      setShowAlertOk(true)
      setTimeout(() => {
        history.goBack()
      }, 1500)
    } catch (error) {

    }
    finally{setSaving(false)}
  }

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            backgroundColor: " #fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <div className="page-container">
          <div className="page-align">
            <div>
              <Topo name="Personalizar Design" />
            </div>

            <div className="infinite-scroll">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
              >
                <h4 style={{ margin: "1rem 0 0.5rem 0" }}>Cores da lojinha</h4>
                <ColorSelectContainer />
              </Box>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <CustomAlert
                  type={alertType}
                  alertText={
                    alertType === "success"
                      ? "Dados salvos com sucesso!"
                      : "Algo deu errado!"
                  }
                  show={showAlertOk || showAlertError}
                  close={() => closeAlerts()}
                />
              </div>
            </div>

            <div className="footer-column">
              <hr style={{ width: "96%" }} />
              {button({
                name: "Salvar alterações",
                funcao: () => saveConfigs(),
                disabled: saving
              })}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
