import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import BannerSejaPro from "../../Componentes/BannerSejaPro"
import { globalStyles } from "../../Componentes/CssGlobal"
import Topo from "../../Componentes/TopCategorias"
import { useSettings } from "../../ContextAPI/settingsContext"
import PlanosAlertaModal from "../Planos/Alertas/AlertaModal"
import { useStyles } from "./style"

export default function PersonalizarLojinha() {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const { lojinhaInfo } = useSettings()
  const history = useHistory()
  const [openModal, setOpenModal] = useState(false)

  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  function handleCloseModal() {
    setOpenModal(false)
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <Topo rota="/configuracoes" name="Personalizar Loja" />
          </div>
          <div className="infinite-scroll">
            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("modo-demonstração")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Modo demonstração</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Atualize sua loja pré montada
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>
            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/habilitar-whatsapp")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>
                Botão flutuante Whatsapp
              </div>
              <div className={classes.btnalinhamento}>
                <div>
                  <div className={classesGlobal.fonteSubtitulo}>
                    Ativação do botão de chat para falar com seus clientes via
                    Whatsapp.
                  </div>
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>
            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/informacoes-da-lojinha")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Informações da loja</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Atualizar as informações de contato e descrição da sua loja
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/meu-logo")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Logo</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Alterar ou atualizar a logo da sua loja.
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/design")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Cores</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Personalizar cores para o design da sua loja
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            {hasPro ? "" : <BannerSejaPro bannerId="banner-personalizar-loja" />}

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/stories")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Stories</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Defina os produtos que irão aparecer como stories na sua loja
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/novidades")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Novidades</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Defina quais produtos irão aparecer como novidades na sua loja.
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/frases-destaques")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Frases de destaque</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Frases que irão aparecer na sua página como itens importantes.
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/banners-principais")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Banners principais</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Defina os banners que irão aparecer na sua página inicial.
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/banners-secundarios")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Banners secundários</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Defina os banners que irão aparecer no decorrer da página inicial,
                  entre os produtos.
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/institucional")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Institucional</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Cadastre as informações da sua loja para que seus clientes possam
                  te conhecer melhor.
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/midias-sociais")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Mídias sociais</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Cadastre as informações das suas redes sociais para que elas
                  apareçam na sua loja.
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/informacoes-rodape")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Rodapé</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Defina as informações que irão aparecer no rodapé da página da sua
                  loja.
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>

            <div
              className={classes.Box}
              aria-hidden="true"
              onClick={() => {
                history.push("/personalizar-lojinha/complementos")
              }}
            >
              <div className={classesGlobal.fonteTitulo}>Complementos</div>
              <div className={classes.btnalinhamento}>
                <div className={classesGlobal.fonteSubtitulo}>
                  Defina algumas informações referentes a sua loja para que o seu
                  cliente possa ter o contato com você.
                </div>
                <div>
                  <ArrowForwardIosIcon fontSize="small" color="disabled" />
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
            </div>
          </div>
        </div>
      </div>

      {openModal ? (
        <PlanosAlertaModal
          isOpen={openModal}
          handleClose={handleCloseModal}
          bannerId="modal-oops-personalizar-loja"
        />
      ) : (
        ""
      )}
    </>
  )
}
