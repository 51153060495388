import { Button, CircularProgress, Switch } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import BannerSejaPro from "../../Componentes/BannerSejaPro"
import Topo from "../../Componentes/TopCategorias"
import { useSettings } from "../../ContextAPI/settingsContext"
import conexao from "../../Services/api"
import { useStyles } from "./style"

export default function AtivarWhatsapp() {
  const classes = useStyles()
  const { lojinhaInfo } = useSettings()
  const [Whatsapp, setWhatapp] = useState(0)
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [loadPage, setLoadPage] = useState(true)
  const [alertType, setAlertType] = useState("")
  const [saving, setSaving] = useState(false)
  const [Cpf, setCpf] = useState(0)

  const history = useHistory()

  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  function activeWhatapp() {
    if (Whatsapp === 0) {
      setWhatapp(1)
    } else {
      setWhatapp(0)
    }
  }

  function activeCpf() {
    setCpf(oldState => (oldState === 1 ? 0 : 1))
  }

  function getData() {
    conexao
      .get(`/configuration/show/${localStorage.getItem("id")}`)
      .then(res => {
        const dataWhatsapp = res.data.data[0].floating_button
        const dataCpf = res.data.data[0].ask_document

        setWhatapp(dataWhatsapp)
        setCpf(dataCpf)

        setLoadPage(false)
      })
      .catch(error => {
        console.log(error)
        setLoadPage(false)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  function SaveData() {
    setSaving(true)

    const id = localStorage.getItem("config_id")
    conexao
      .put(`/configuration/update/${id}`, {
        ask_document: Cpf,
        floating_button: Whatsapp
      })
      .then(res => {
        setAlertType("success")
        setShowAlertOk(true)
        setTimeout(() => {
          history.goBack()
        }, 1500)
      })
      .catch(error => {
        setSaving(false)
        setAlertType("error")
        setShowAlertError(true)
      })
  }

  return (
    <>
      {loadPage ? (
        <div
          style={{
            backgroundColor: " #fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <div className="page-container">
          <div className="page-align">
            <div>
              <Topo name="Habilitar Whatsapp" />
            </div>
            <div className="infinite-scroll">
              <div className={classes.Box}>
                <div className={classes.titleText}>
                  Habilitar botão flutuante Whatsapp
                </div>
                <div className={classes.switchChange}>
                  <Switch
                    onClick={() => {
                      activeWhatapp()
                    }}
                    checked={Whatsapp}
                  />
                </div>
              </div>
              <div className={classes.descriptionText}>
                Este botão será apresentado em todas as telas da loja virtual e fará
                com que o seu cliente faça contato diretamente através do seu
                Whatsapp a qualquer momento.
              </div>
              {hasPro ? (
                ""
              ) : (
                <BannerSejaPro bannerId="banner-menu-configurações-gerais" />
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <CustomAlert
                  type={alertType}
                  alertText={
                    alertType === "success"
                      ? "Configurações foram salvas!"
                      : "Algo deu errado!"
                  }
                  show={showAlertOk || showAlertError}
                  close={() => closeAlerts()}
                />
              </div>
            </div>

            <div className="footer-column">
              <Button
                className={classes.btnAddLogo}
                variant="contained"
                disabled={saving}
                onClick={() => {
                  SaveData()
                }}
              >
                Salvar alterações
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
