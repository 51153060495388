import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1.5rem",
        paddingTop: "2.5rem"
    },

    optionContainer: {
        display: "flex",
        alignItems: "center",
        borderRadius: "0.3rem",
        border: "1px solid #D0D0D0",
        backgroundColor: "#FFFCFC",
        width: "90%",
        gap: "2rem",
        padding: "0.25rem 1rem",
        cursor: "pointer",
    },

    optionTextContainer: {
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        flex: 1,
    },

    checkbox: {
        width: "1.1rem",
        height: "1.1rem",
        "&.Mui-checked" : {
            color: "#000",
        }
    },

}))
    