import { useEffect, useState } from "react"

import Topo from "../../../../Componentes/TopCategorias"
import { TextField, Button } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { FiSearch } from "react-icons/fi"
import FormLabel from "@material-ui/core/FormLabel"
import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"

import { StateGlobal } from "../../../../ContextAPI"

import { useStyles } from "./style"

function ConfiguracoesMelhorEnvio() {
  const classes = useStyles()
  const history = useHistory()

  const {
    bestShippingIntegrationInfos,
    bestShippingSetupFormData,
    setBestShippingSetupFormData,
    setSyncFormData,
    setDisableSaveButton
  } = StateGlobal()

  useEffect(() => {
    if (bestShippingSetupFormData.enable === 1) {
      if (typeof bestShippingSetupFormData.me_jadlog_id === "object") return

      setBestShippingSetupFormData(prevState => ({
        ...prevState,
        me_jadlog_id: bestShippingIntegrationInfos.jadlog_agencies.find(
          item => item.id === bestShippingSetupFormData.me_jadlog_id
        ),
        me_correios_id: bestShippingIntegrationInfos.correios_agencies.find(
          item => item.id === bestShippingSetupFormData.me_correios_id
        ),
        me_azul_cargo_id: bestShippingIntegrationInfos.azul_cargo_agencies?.find(
          item => item.id === bestShippingSetupFormData?.me_azul_cargo_id
        ),
        me_latam_cargo_id: bestShippingIntegrationInfos.latam_cargo_agencies?.find(
          item => item.id === bestShippingSetupFormData?.me_latam_cargo_id
        )
      }))
    }
  }, [])

  function handleChange(e) {
    const { value, name } = e.target

    setBestShippingSetupFormData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  function handleAutoComplete(value, objKey) {
    if (!value) return

    setBestShippingSetupFormData(prevState => ({
      ...prevState,
      [objKey]: value
    }))
  }

  function handleCheckbox(e) {
    const { name } = e.target
    let newArr = []

    const nameAlredyExists = bestShippingSetupFormData.me_checkbox_price.some(
      item => item === name
    )

    if (nameAlredyExists) {
      newArr = bestShippingSetupFormData.me_checkbox_price.filter(
        item => item !== name
      )
    }

    setBestShippingSetupFormData(prevState => ({
      ...prevState,
      me_checkbox_price: nameAlredyExists
        ? [...newArr]
        : [...prevState.me_checkbox_price, name]
    }))
  }

  function handleSaveButton() {
    let data = {
      company_id: localStorage.getItem("payhow_id"),
      integration_slug: "melhor-envio"
    }

    setBestShippingSetupFormData(prevState => ({
      ...prevState,
      ...data
    }))

    setSyncFormData(false)
    setDisableSaveButton(false)
    history.goBack()
  }

  return (
    <div className="page-container">
      <div className="page-align">
        <div>
          <Topo name="Configurações do Melhor Envio" noPadding />
        </div>

        <div className={`${classes.bestShipping}  infinite-scroll`}>
          <article className={classes.textField}>
            <p>Escolha o endereço para cálculo do frete</p>
            <TextField
              name="me_address_id"
              fullWidth
              select
              variant="outlined"
              value={bestShippingSetupFormData.me_address_id}
              SelectProps={{ native: true }}
              onChange={e => handleChange(e)}
            >
              <option value="" selected disabled>
                Selecione um endereço
              </option>

              {bestShippingIntegrationInfos.addresses.map(address => (
                <option key={address.id} value={address.id}>
                  {address.address}
                </option>
              ))}
            </TextField>
          </article>

          <article className={classes.textField}>
            <p>Escolha uma agência Jadlog de sua preferência</p>
            <Autocomplete
              options={bestShippingIntegrationInfos.jadlog_agencies || []}
              getOptionLabel={option => option.name}
              value={bestShippingSetupFormData.me_jadlog_id || ""}
              onChange={(e, value) => handleAutoComplete(value, "me_jadlog_id")}
              popupIcon={<FiSearch />}
              noOptionsText="Agência não encontrada..."
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Pesquisar agência Jadlog"
                />
              )}
            />
          </article>

          <article className={classes.textField}>
            <p>Escolha uma agência Correios de sua prêferencia</p>
            <Autocomplete
              options={bestShippingIntegrationInfos.correios_agencies || []}
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              noOptionsText="Agência não encontrada..."
              popupIcon={<FiSearch />}
              value={bestShippingSetupFormData.me_correios_id || ""}
              onChange={(e, value) => handleAutoComplete(value, "me_correios_id")}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Pesquisar agência Correios"
                />
              )}
            />
          </article>

          <article className={classes.textField}>
            <p>Escolha uma agência Azul Cargo Express</p>
            <Autocomplete
              options={bestShippingIntegrationInfos.azul_cargo_agencies || []}
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              noOptionsText="Agência não encontrada..."
              popupIcon={<FiSearch />}
              value={bestShippingSetupFormData.me_azul_cargo_id || ""}
              onChange={(e, value) => handleAutoComplete(value, "me_azul_cargo_id")}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Pesquisar agência Azul"
                />
              )}
            />
          </article>

          <article className={classes.textField}>
            <p>Escolha uma agência Latam Cargo de sua preferência</p>
            <Autocomplete
              options={bestShippingIntegrationInfos.latam_cargo_agencies || []}
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              noOptionsText="Agência não encontrada..."
              popupIcon={<FiSearch />}
              value={bestShippingSetupFormData.me_latam_cargo_id || ""}
              onChange={(e, value) => handleAutoComplete(value, "me_latam_cargo_id")}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Pesquisar agência Latam"
                />
              )}
            />
          </article>

          <article className={classes.textField}>
            <p>Escolha uma loja cadastrada em sua conta Melhor Envio</p>
            <TextField
              name="me_company_id"
              fullWidth
              select
              variant="outlined"
              SelectProps={{ native: true }}
              value={bestShippingSetupFormData.me_company_id}
              onChange={e => handleChange(e)}
            >
              <option value="" selected disabled>
                Selecione uma loja
              </option>

              {bestShippingIntegrationInfos.companies.map(company => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </TextField>
          </article>

          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Opções para cotação:</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="aviso_recebimento"
                    checked={bestShippingSetupFormData.me_checkbox_price.some(
                      item => item === "aviso_recebimento"
                    )}
                    onChange={e => handleCheckbox(e)}
                  />
                }
                label="Aviso de recebimento"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="mao_propria"
                    checked={bestShippingSetupFormData.me_checkbox_price.some(
                      item => item === "mao_propria"
                    )}
                    onChange={e => handleCheckbox(e)}
                  />
                }
                label="Mão própria"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="assegurar_sempre"
                    checked={bestShippingSetupFormData.me_checkbox_price.some(
                      item => item === "assegurar_sempre"
                    )}
                    onChange={e => handleCheckbox(e)}
                  />
                }
                label="Assegurar sempre"
              />
            </FormGroup>
          </FormControl>
        </div>

        <div className="footer-column">
          <hr style={{ width: "100%", backgroundColor: "#ffffff0f" }} />

          <Button
            className={classes.btnSave}
            fullWidth
            onClick={() => handleSaveButton()}
          >
            Salvar informações
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ConfiguracoesMelhorEnvio
