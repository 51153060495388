import CircularProgress from "@material-ui/core/CircularProgress"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import { globalStyles } from "../../Componentes/CssGlobal"
import { button } from "../../Componentes/Inputs/TextFild"
import Topo from "../../Componentes/TopCategorias"
import { useSettings } from "../../ContextAPI/settingsContext"
import api from "../../Services/api"
import { HabilitarEntrega } from "./componentes/HabilitarEntrega"
import { RetiradaLocal } from "./componentes/RetiradaLocal"

export default function OpcoesDeEntrega() {
  const classesGlobal = globalStyles()
  const { getShipping } = useSettings()
  const [entrega, setEntrega] = useState(false)
  const [retirada, setRetirada] = useState(false)
  const [alertMsg, setAlertMsg] = useState("Salvo com sucesso")
  const [alertType, setAlertType] = useState("success")
  const [showAlert, setShowAlert] = useState(false)
  const [deliveryInformations, setDeliveryInformations] = useState({})
  const [withdrawalInformations, setWithdrawalInformations] = useState({})
  const [saving, setSaving] = useState(false)
  const [enableDeliveryTime, setEnableDeliveryTime] = useState(false)

  const [hasEmptyZipCode, setHasEmptyZipCode] = useState(false)
  const [loading, setloading] = useState(true)
  const history = useHistory()

  useEffect(() => {
    getInformations()
  }, [])

  const getInformations = async () => {
    const shopId = localStorage.getItem("id")

    const {
      data: { data }
    } = await api.get(`/lojinha/shipping/list/${shopId}`)
    const currentInformations = data[0]

    if (currentInformations) {
      const {
        value,
        min_time,
        max_time,
        time_type,
        free_shipping_above,
        address,
        pickup
      } = currentInformations
      const { city, complement, neighborhood, number, state, street, zip_code } =
        address || []
      setDeliveryInformations({
        value,
        min_time,
        max_time,
        time_type,
        free_shipping_above
      })
      setWithdrawalInformations({
        city,
        complement,
        neighborhood,
        number,
        state,
        street,
        zip_code
      })
      const enableTime = !!(max_time !== null && min_time !== null)
      setEnableDeliveryTime(enableTime)

      if (pickup === 1) {
        setRetirada(true)
        setEntrega(false)
      } else if (pickup === 2) {
        setRetirada(false)
        setEntrega(true)
      } else if (pickup === 3) {
        setRetirada(true)
        setEntrega(true)
      }
    }
    setloading(false)
  }

  const handleSubmitButton = async () => {
    setSaving(true)

    let formData = {}
    let pickup = 0

    if (retirada === true) {
      let zipCodeFilter = withdrawalInformations.zip_code.replace(/\D/g, "")
      pickup++
      formData = {
        pickup,
        address: {
          ...withdrawalInformations,
          zip_code: zipCodeFilter
        }
      }
    }
    if (entrega === true) {
      pickup += 2
      formData = {
        pickup,
        ...deliveryInformations,
        address: {}
      }
    }

    if (entrega === true && retirada === true) {
      let zipCodeFilter = withdrawalInformations.zip_code.replace(/\D/g, "")
      formData = {
        pickup,
        ...deliveryInformations,
        address: {
          ...withdrawalInformations,
          zip_code: zipCodeFilter
        }
      }
    }

    if (entrega === false && retirada === false) {
      setSaving(false)
      setShowAlert(true)
      setAlertType("error")
      setAlertMsg("Selecione uma opção.")
      return null
    }
    if (entrega === true && enableDeliveryTime === true) {
      if (
        deliveryInformations.max_time !== null &&
        deliveryInformations.min_time !== null &&
        deliveryInformations.max_time !== "" &&
        deliveryInformations.min_time !== ""
      ) {
        if (
          Number(deliveryInformations.max_time) <
          Number(deliveryInformations.min_time)
        ) {
          setSaving(false)
          setShowAlert(true)
          setAlertType("error")
          setAlertMsg("Tempo máximo deve ser maior")
          return null
        }
      } else if (
        (deliveryInformations.min_time === null ||
          deliveryInformations.min_time === "") &&
        (deliveryInformations.max_time === "" ||
          deliveryInformations.max_time === null)
      ) {
        setSaving(false)
        setShowAlert(true)
        setAlertType("error")
        setAlertMsg("Preencha os campos de tempo de entrega")
        return null
      } else if (
        deliveryInformations.max_time === null ||
        deliveryInformations.max_time === ""
      ) {
        setSaving(false)
        setShowAlert(true)
        setAlertType("error")
        setAlertMsg("Preencha o tempo máximo de entrega")
        return null
      } else if (
        deliveryInformations.min_time === null ||
        deliveryInformations.min_time === ""
      ) {
        setSaving(false)
        setShowAlert(true)
        setAlertType("error")
        setAlertMsg("Preencha o tempo mínimo de entrega")
        return null
      }
    }

    setHasEmptyZipCode(false)

    let entregaValidate = false
    if (entrega === true) {
      entregaValidate = Number(deliveryInformations.value) === 0
    }

    let retiradaValidate = false
    if (retirada === true) {
      let zipCodeFilter = withdrawalInformations.zip_code.replace(/\D/g, "")
      retiradaValidate =
        !withdrawalInformations.city ||
        !withdrawalInformations.number ||
        !withdrawalInformations.neighborhood ||
        !withdrawalInformations.state ||
        !withdrawalInformations.street ||
        zipCodeFilter === null
      if (zipCodeFilter.length !== 8) {
        setHasEmptyZipCode(true)
      }
    }

    if (retiradaValidate) {
      setSaving(false)
      setShowAlert(true)
      setAlertType("error")
      setAlertMsg("Preencha os campos retirada corretamente.")
    } else if (entregaValidate) {
      setSaving(false)
      setShowAlert(true)
      setAlertType("error")
      setAlertMsg("Preencha os campos de entrega corretamente.")
    } else {
      let res = await api.put(
        `/lojinha/shipping/update/${localStorage.getItem("id")}`,
        {
          ...formData
        }
      )
      if (res.data.error === false) {
        getShipping()
        setShowAlert(true)
        setAlertType("success")
        setAlertMsg("Salvo com sucesso!")

      } else {
        setSaving(false)
        setShowAlert(true)
        setAlertType("error")
        setAlertMsg("Erro ao salvar.")
      }
    }
    return null
  }

  const handleDeliveryFormData = (formData, pickup) => {
    setDeliveryInformations({ ...formData })
    setEnableDeliveryTime(formData.enableDeliveryTime)

    if (pickup === true) {
      setEntrega(true)
    } else if (pickup === false) {
      setEntrega(false)
    }
  }

  const handleWithdrawalFormData = (formData, pickup) => {
    const { city, complement, neighborhood, number, state, street, zip_code } =
      formData

    setWithdrawalInformations({
      city,
      complement,
      neighborhood,
      number,
      state,
      street,
      zip_code
    })
    if (pickup === true) {
      setRetirada(true)
    } else if (pickup === false) {
      setRetirada(false)
    }
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <div>
            <Topo name="Opções de entrega" />
            <CustomAlert
              type={alertType}
              alertText={alertMsg}
              show={showAlert}
              close={() => setShowAlert(false)}
            />
          </div>
          <div className="infinite-scroll">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  minHeight: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <>
                <HabilitarEntrega
                  deliveryInformations={deliveryInformations}
                  handleDeliveryFormData={handleDeliveryFormData}
                  entrega={entrega}
                />
                <RetiradaLocal
                  withdrawalInformations={withdrawalInformations}
                  hasEmptyZipCode={hasEmptyZipCode}
                  handleWithdrawalFormData={handleWithdrawalFormData}
                  retirada={retirada}
                />
              </>
            )}
          </div>

          <div className="footer-column">
            <hr style={{ width: "100%", borderColor: "#E4E4E4", opacity: 0.3 }} />

            {button({
              name: "Salvar Alterações",
              funcao: handleSubmitButton,
              disabled: saving
            })}
          </div>
        </div>
      </div>
    </>
  )
}
