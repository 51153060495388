import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },

  large: {
    width: theme.spacing(8.35),
    height: theme.spacing(8.35)
  },

  container: {
    display: "flex",
    backgroundColor: "#f9f9f9",
    paddingLeft: "20px",
    paddingRight: "20px",
    flexDirection: "column",
    height: "100%",
    minHeight: "100%",
    justifyContent: "space-between"
  },

  bodySection: {
    width: "100%",
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    justifyContent: "space-between"
  },

  headerContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "16px"
  },

  headerContainer2: {
    display: "flex",
    alignItems: "center",
    marginTop: "16px"
  },

  textContent: {
    marginLeft: "20px"
  },

  coroinha: {
    width: "75px",
    display: "block",
    zIndex: "2",
    marginRight: "-73px",
    marginTop: "-18px"
  },

  avatarContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "& span": {
      fontSize: "10px",
      fontWeight: "700",
      padding: "0.1px .8px 2px 1.8px",
      borderRadius: "11px",
      width: "40px",
      color: "#FFFFFF",
      background: "#EA3458",
      marginTop: "-13px",
      marginLeft: "-2px",
      textAlign: "center",
      zIndex: "2",
      lineHeight: "13px"
    }
  }
}))
