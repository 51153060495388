import React, { createContext, useState, useContext } from "react"

const CountContext = createContext()

export default function CountProvider({ children }) {
  const [idByOrder, setIdByOrder] = useState()
  const [orderPagesIndex, setOrderPagesIndex] = useState(0)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [paymentMethodOrderInfo, setPaymentMethodOrderInfo] = useState('')

  // Frete Fixo, Frete Gratis
  const [freeShippingCitiesData, setFreeShippingCitiesData] = useState([])
  const [freeShippingStatesData, setFreeShippingStatesData] = useState([])
  const [freeShippingData, setFreeShippingData] = useState(null)
  const [firstTimeFree, setFirstTimeFree] = useState(false)
  const [enableFreeDelivery, setEnableFreeDelivery] = useState(false)
  const [enableFreeByPrice, setEnableFreeByPrice] = useState(true)




  const [fixedShippingCitiesData, setFixedShippingCitiesData] = useState([])
  const [fixedShippingStatesData, setFixedShippingStatesData] = useState([])
  const [fixedShippingData, setFixedShippingData] = useState(null)
  const [firstTimeFixed, setFirstTimeFixed] = useState(false)
  const [enableFixedDelivery, setEnableFixedDelivery] = useState(false)


  const [pickupShippingCitiesData, setPickupShippingCitiesData] = useState([])
  const [pickupShippingStatesData, setPickupShippingStatesData] = useState([])
  const [pickupShippingData, setPickupShippingData] = useState(null)
  const [enableWithdrawDelivery, setEnableWithdrawDelivery] = useState(false)

  const [hasContext, setHasContext] = useState(false)

  // Melhor Envio
  const [bestShippingIntegrationInfos, setBestShippingIntegrationInfos] = useState(
    {}
  )
  const [bestShippingSetupFormData, setBestShippingSetupFormData] = useState({
    me_checkbox_price: []
  })
  const [syncFormData, setSyncFormData] = useState(true)
  const [disableSaveButton, setDisableSaveButton] = useState(true)

  // Retirada no Local
  const [address, setAddress] = useState({})

  return (
    <CountContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        idByOrder,
        setIdByOrder,
        orderPagesIndex,
        setOrderPagesIndex,
        openDeleteModal,
        setOpenDeleteModal,
        freeShippingCitiesData,
        setFreeShippingCitiesData,
        freeShippingStatesData,
        setFreeShippingStatesData,
        freeShippingData,
        setFreeShippingData,
        firstTimeFree,
        setFirstTimeFree,
        enableFreeDelivery,
        setEnableFreeDelivery,
        enableFreeByPrice,
        setEnableFreeByPrice,
        fixedShippingCitiesData,
        setFixedShippingCitiesData,
        fixedShippingStatesData,
        setFixedShippingStatesData,
        fixedShippingData,
        setFixedShippingData,
        firstTimeFixed,
        setFirstTimeFixed,
        enableFixedDelivery,
        setEnableFixedDelivery,
        pickupShippingCitiesData,
        setPickupShippingCitiesData,
        pickupShippingStatesData,
        setPickupShippingStatesData,
        pickupShippingData,
        setPickupShippingData,
        bestShippingIntegrationInfos,
        setBestShippingIntegrationInfos,
        bestShippingSetupFormData,
        setBestShippingSetupFormData,
        syncFormData,
        setSyncFormData,
        address,
        setAddress,
        disableSaveButton,
        setDisableSaveButton,
        enableWithdrawDelivery,
        setEnableWithdrawDelivery,
        paymentMethodOrderInfo,
        setPaymentMethodOrderInfo,
        setHasContext,
        hasContext
      }}
    >
      {children}
    </CountContext.Provider>
  )
}

export function StateGlobal() {
  const context = useContext(CountContext)

  const {
    idByOrder,
    setIdByOrder,
    orderPagesIndex,
    setOrderPagesIndex,
    openDeleteModal,
    setOpenDeleteModal,
    freeShippingCitiesData,
    setFreeShippingCitiesData,
    freeShippingStatesData,
    setFreeShippingStatesData,
    freeShippingData,
    setFreeShippingData,
    firstTimeFree,
    setFirstTimeFree,
    enableFreeDelivery,
    setEnableFreeDelivery,
    enableFreeByPrice,
    setEnableFreeByPrice,
    fixedShippingCitiesData,
    setFixedShippingCitiesData,
    fixedShippingStatesData,
    setFixedShippingStatesData,
    fixedShippingData,
    setFixedShippingData,
    firstTimeFixed,
    setFirstTimeFixed,
    enableFixedDelivery,
    setEnableFixedDelivery,
    pickupShippingCitiesData,
    setPickupShippingCitiesData,
    pickupShippingStatesData,
    setPickupShippingStatesData,
    pickupShippingData,
    setPickupShippingData,
    bestShippingIntegrationInfos,
    setBestShippingIntegrationInfos,
    bestShippingSetupFormData,
    setBestShippingSetupFormData,
    syncFormData,
    setSyncFormData,
    address,
    setAddress,
    disableSaveButton,
    setDisableSaveButton,
    enableWithdrawDelivery,
    setEnableWithdrawDelivery,
    paymentMethodOrderInfo,
    setPaymentMethodOrderInfo,
    setHasContext,
    hasContext
  } = context

  return {
    idByOrder,
    setIdByOrder,
    orderPagesIndex,
    setOrderPagesIndex,
    openDeleteModal,
    setOpenDeleteModal,
    freeShippingCitiesData,
    setFreeShippingCitiesData,
    freeShippingStatesData,
    setFreeShippingStatesData,
    freeShippingData,
    setFreeShippingData,
    firstTimeFree,
    setFirstTimeFree,
    enableFreeDelivery,
    setEnableFreeDelivery,
    enableFreeByPrice,
    setEnableFreeByPrice,
    fixedShippingCitiesData,
    setFixedShippingCitiesData,
    fixedShippingStatesData,
    setFixedShippingStatesData,
    fixedShippingData,
    setFixedShippingData,
    firstTimeFixed,
    setFirstTimeFixed,
    enableFixedDelivery,
    setEnableFixedDelivery,
    pickupShippingCitiesData,
    setPickupShippingCitiesData,
    pickupShippingStatesData,
    setPickupShippingStatesData,
    pickupShippingData,
    setPickupShippingData,
    bestShippingIntegrationInfos,
    setBestShippingIntegrationInfos,
    bestShippingSetupFormData,
    setBestShippingSetupFormData,
    syncFormData,
    setSyncFormData,
    address,
    setAddress,
    disableSaveButton,
    setDisableSaveButton,
    enableWithdrawDelivery,
    setEnableWithdrawDelivery,
    paymentMethodOrderInfo,
    setPaymentMethodOrderInfo,
    setHasContext,
    hasContext
  }
}
