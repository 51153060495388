import React, { useState } from "react"
import { TextField } from "@material-ui/core"
import { useStyles } from "./style"
import { useProduct } from "../../../../ContextAPI/productContext"

export default function ProdutoEstoque() {
  const classes = useStyles()

  const { currentProduct, setCurrentProduct } = useProduct()

  const handleChange = e => {
    const { name, value } = e.target
    const newValue = value.replace(/[^0-9]/g, "").substring(0, 9)

    setCurrentProduct(oldState => {
      return { ...oldState, [name]: newValue }
    })
  }

  return (
    <>
      <div className={classes.estoqueTitle}>
        <h2>Controle de estoque</h2>
        <p>Aqui você pode definir uma quantidade para seu produto.</p>
        <h3>Quantidade disponível</h3>

        <TextField
          name="quantity"
          style={{ backgroundColor: "#fff", width: "60%" }}
          color="secondary"
          size="small"
          variant="outlined"
          type="tel"
          onChange={handleChange}
          value={
            currentProduct !== null && currentProduct.quantity !== null
              ? currentProduct.quantity
              : ""
          }
          inputProps={{ maxLength: 9 }}
        />
      </div>
    </>
  )
}
