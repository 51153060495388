import React, { useState, useEffect } from "react"
import { useStyles } from "../TopCategorias/style"
import CloseIcon from "@material-ui/icons/Close"

export default function Categoria(props) {
  const classes = useStyles()

  return (
    <div className={classes.top}>
      <CloseIcon
        style={{
          fontSize: "35px",
          color: "#EA3458",
          cursor: "pointer",
          minWidth: "30px"
        }}
        onClick={() => {
          props.fechar()
        }}
      />
      <div className={classes.dots}>
        <h2>{props.name}</h2>
      </div>
      <div className={classes.space} />
    </div>
  )
}
