import { makeStyles } from "@material-ui/core/styles"
// import { button } from "../../Componentes/Inputs/TextFild"

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "space-around",
    backgroundColor: "#FFFFFF",
    height: "100vh",
    textAlign: "center",
    padding: "2rem 1rem",

    "& img": {
      height: "40vh",
      marginTop: "1rem"
    },

    "& h1": {
      color: "#F21C62",
      margin: 0
    },

    "& h3": {
      color: "#333333",
      fontWeight: "500",
      margin: "1rem 0 3rem"
    },

    "& h4": {
      color: "red",
      fontWeight: "500",
      margin: "0 0 1rem",
      fontSize: "0.8rem"
    }
  },

  Button: {
    width: "100%"
  },

  shareButton: {
    display: "flex",
    alignItems: "center",
    color: "#6DC89E",
    fontSize: "1rem",
    marginTop: "1rem"
  }
}))
