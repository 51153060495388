import { Autocomplete } from "@material-ui/lab"
import TextField from '@material-ui/core/TextField';
import { useEffect, useState } from "react"
import api from "../../../../Services/api"
import ProductList from "./components/ProductList"
import { useProduct } from "../../../../ContextAPI/productContext"
import { CircularProgress } from "@material-ui/core";

export default function ProdutosRelacionados() {
    const { allProductList, setAllProductList, currentProduct, setCurrentProduct } = useProduct()
    const [errorProductExist, setErrorProductExist] = useState(false)
    const [relatedProducts, setRelatedProducts] = useState(currentProduct.product_related || [])
    const [isLoading, setIsLoading] = useState(true)

    const handleGetAllProducts = async () => {
        try {
            const res = await api.get("v2/lojinha/products/filter/all")
            const allProducts = res.data.data
            if (currentProduct.id) {
                const allProductsFilter = allProducts.filter(
                    produto => produto.product_id !== currentProduct.id
                )
                setAllProductList(allProductsFilter)
            } else {
                setAllProductList(allProducts)
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setCurrentProduct(prevState => ({
            ...prevState,
            product_related: relatedProducts
        }))
    }, [relatedProducts]);

    useEffect(() => {
        handleGetAllProducts()
    }, [])

    return (
        <div className="page-container">
            {
                isLoading ?
                    (
                        <div
                            style={{
                                backgroundColor: " #f9f9f9",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%"
                            }}
                        >
                            <CircularProgress color="secondary" size="40px" />
                        </div>
                    ) :
                    (

                        <div className="page-align">
                            <Autocomplete
                                id="tags-standard"
                                onChange={(event, newValue) => {
                                    const isProductExist = relatedProducts.find(item => item.product_id === newValue.product_id)
                                    if (isProductExist) {
                                        setErrorProductExist(true)
                                        return
                                    }
                                    if (newValue === null || newValue === undefined) return
                                    setRelatedProducts(prevList => [...prevList, newValue])
                                    setErrorProductExist(false)
                                }}
                                options={allProductList}
                                renderInput={(params) => <TextField variant="standard" {...params} label="Procurar produtos" />}
                                getOptionLabel={(option) => option.product_title}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <img
                                                src={props.product_image}
                                                alt={props.product_title}
                                                style={{ width: 60, margin: 10 }}
                                            />
                                            {props.product_title}
                                        </div>
                                    </li>
                                )}
                            />
                            {errorProductExist && (
                                <p style={{ color: "red", fontSize: "0.9rem" }}>Esse produto já está selecionado.</p>
                            )}
                            <div className="infinite-scroll" style={{ marginTop: "20px" }}>
                                <h3>Organize abaixo a ordem de exibição:</h3>
                                <ProductList
                                    relatedProducts={relatedProducts}
                                    setRelatedProducts={setRelatedProducts}
                                />
                            </div>
                        </div>
                    )
            }
        </div >
    )
}
