import React from "react"

const styles = {
  ProgressBar: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },

  Step: {
    height: "4px",
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    margin: "0 10px",
    width: "25px"
  }
}

export default function StepProgressBar({ steps, current, color }) {
  const { ProgressBar, Step } = styles
  return (
    <div style={{ ...ProgressBar }}>
      {steps.map(step => (
        <div
          style={{
            ...Step,
            backgroundColor: step === current ? "#EA3458" : "#D8D8D8"
          }}
        ></div>
      ))}
    </div>
  )
}
