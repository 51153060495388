import ApiRequest from "../index"

const ativarDesativarRequest = {
  put: async (id, body) => {
   const data = ApiRequest.put(`/v2/lojinha/products/update/${id}`, body)
   return data
  }
}

export default ativarDesativarRequest
