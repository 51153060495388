import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  top: {
    display: "flex",
    minWidth: "100%",
    justifyContent: "space-between",
    height: "50px",
    alignItems: "center",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
  btnClose: {
    fontSize: "35px",
    color: "#EA3458",
    cursor: "pointer",
    minWidth: "30px"
  },
  space: {
    width: "10px",
    hidden: "true"
  },
  arrowBack: {
    fontSize: "35px",
    color: "#EA3458",
    cursor: "pointer",
    minWidth: "30px"
  }
}))
