import { InputAdornment, TextField } from "@material-ui/core"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import React, { useEffect, useState } from "react"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { globalStyles } from "../../../Componentes/CssGlobal"
import { button } from "../../../Componentes/Inputs/TextFild"
import lojinhaRequest from "../../../Services/api/lojinha"
import { useStyles } from "./styles"
import { telefone, celular } from "../../../Componentes/validates/Validate"

export default function EtapaDescricao({ handleStep, description, whats }) {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const [showAlert, setShowAlert] = useState(false)
  const [alertType, setAlertType] = useState("")

  /* carrega descricao e whatsapp onLoad */
  useEffect(() => {
    if (description !== null)
      setFormData({
        whatsapp: whats,
        description
      })
    else if (whats !== null) {
      setFormData({
        whatsapp: whats,
        description: ""
      })
    }
  }, [])

  const [formData, setFormData] = useState({
    whatsapp: "",
    description: ""
  })

  const handleSaveInfo = async () => {
    // eslint-disable-next-line no-lonely-if
    if (formData.whatsapp !== "" && formData.whatsapp.length >= 14) {
      lojinhaRequest.put({
        whatsapp: formData.whatsapp,
        description: formData.description
      })
      setAlertType("success")
      setShowAlert(true)
      handleStep("proximo")
    } else {
      setAlertType("error")
      setShowAlert(true)
    }
  }

  const closeAlert = () => {
    setShowAlert(false)
  }

  const handleFormDataChange = e => {
    const { name, value } = e.target
    let newValue = value
    if (name === "whatsapp") {
      if (newValue.length <= 14) {
        newValue = telefone(newValue)
      } else if (newValue.length <= 15) {
        newValue = celular(newValue)
      }
    }
    setFormData(oldData => {
      return { ...oldData, [name]: newValue }
    })
  }

  return (
    <>
      <div className="infinite-scroll">
        <div className="EtapaHeader">
          <h2>Conte-nos mais</h2>
          <h3>sobre a sua loja:</h3>
        </div>
        <div className={classes.inputDescricaoContainer}>
          <h3 className={classes.inputLabel}>Descrição</h3>
          <textarea
            className={classes.inputDescricao}
            name="description"
            onChange={e => handleFormDataChange(e)}
            value={formData.description}
            maxLength="140"
            placeholder="Limite de 140 caracteres"
          />
        </div>
        <div style={{ fontSize: "1.4em" }}>
          <h3>Whatsapp de contato</h3>
          <TextField
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WhatsAppIcon style={{ color: "#A4A4A4" }} />
                </InputAdornment>
              )
            }}
            style={{
              backgroundColor: "#fff",
              marginBottom: "10px",
              fontSize: "1.2em"
            }}
            name="whatsapp"
            onChange={e => handleFormDataChange(e)}
            helperText="Preencha um número para contato."
            size="small"
            type="tel"
            error=""
            value={formData.whatsapp}
            variant="outlined"
            color="secondary"
            fullWidth
            placeholder="(99) 9 9999 9999"
          />
        </div>
        {showAlert ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            {/* alertas */}
            <CustomAlert
              alertText={
                alertType === "success"
                  ? "Informações salvas com sucesso!"
                  : "Prencha um número para contato!"
              }
              show={showAlert}
              type={alertType}
              close={() => closeAlert()}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="footer-column">
        {button({
          funcao: () => handleSaveInfo(),
          name: `Próximo`
        })}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <button
            onClick={() => handleStep("backStep")}
            className="voltarLink"
            style={{ margin: 0 }}
          >
            Voltar etapa
          </button>
          {/* <button
            onClick={() => handleStep('nextStep')}
            className="voltarLink"
            style={{ margin: 0 }}
          >
            Pular etapa
          </button> */}
        </div>
      </div>
    </>
  )
}
