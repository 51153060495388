import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  top: {
    display: "flex",
    minWidth: "100%",
    justifyContent: "space-between",
    height: "50px",
    alignItems: "center",
    paddingTop: "10px",
    paddingBottom: "5px"
  },
  arrowBack: {
    fontSize: "35px",
    color: "#EA3458",
    cursor: "pointer",
    minWidth: "30px",
    marginLeft: "-10px"
  },
  dots: {
    display: "flex",
    justifyContent: "center",
    height: "50px",
    alignItems: "center",
    flexDirection: "column",
    marginLeft: "-10px"
  },

  space: {
    width: "10px",
    hidden: "true"
  }
}))
