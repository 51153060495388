import React, { useEffect, useState } from "react"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { useHistory } from "react-router-dom"
import { useStyles } from "./style"
import { globalStyles } from "../../Componentes/CssGlobal"
import BannerSejaPro from "../../Componentes/BannerSejaPro"
import { useSettings } from "../../ContextAPI/settingsContext"
import CorreioIcon from "../../Assets/img/correios.png"
import EnvioIcon from "../../Assets/img/melhor-envio.png"
import api from "../../Services/api"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import TopCategorias from "../../Componentes/TopCategorias"
import IntegrationAlertModal from "../../Componentes/IntegrationAlertModal"
import { CircularProgress } from "@material-ui/core"
import WarningRoundedIcon from "@material-ui/icons/WarningRounded"

export default function MenuOpcoesDeEntrega() {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const { lojinhaInfo } = useSettings()
  const history = useHistory()
  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2
  const shopId = localStorage.getItem("id")
  const [deliveryActivePickup, setDeliveryActivePickup] = useState(null)
  const [deliveryActiveBestShipping, setDeliveryActiveBestShipping] = useState(null)
  const [deliveryActivePostOffice, setDeliveryActivePostOffice] = useState(null)
  const [deliveryActiveFreeShipping, setDeliveryActiveFreeShipping] = useState(null)
  const [deliveryActiveFixedShipping, setDeliveryActiveFixedShipping] =
    useState(null)
  const [deliveryActiveCustomShipping, setDeliveryActiveCustomShipping] =
    useState(null)
  const [shippingActive, setShippingActive] = useState()
  const [payhowActive, setPayhowActive] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [dimensionInfo, setDimensionInfo] = useState()
  const [showAlert, setShowAlert] = useState(false)

  async function getInformationsData() {
    const paymentActive = await api.get(`lojinha/payment/get/${shopId}`)
    const shipping = await api.get(`lojinha/shipping/list/active/${shopId}`)
    const dimensionData = await api.get(`product/check/dimesion/${shopId}`)

    setDeliveryActiveFreeShipping(
      shipping.data.data.find(e => e.name === "free_shipping")
    )
    setDeliveryActivePickup(shipping.data.data.find(e => e.name === "pickup"))
    setDeliveryActiveFixedShipping(
      shipping.data.data.find(e => e.name === "fixed_shipping")
    )
    setDeliveryActiveCustomShipping(
      shipping.data.data.find(e => e.name === "custom_shipping")
    )
    setDeliveryActivePostOffice(shipping.data.data.find(e => e.name === "Correios"))
    setDeliveryActiveBestShipping(
      shipping.data.data.find(e => e.name === "Melhor Envio")
    )

    setShippingActive(shipping.data.data)
    setPayhowActive(paymentActive.data.data)
    setDimensionInfo(dimensionData.data.data)

    setLoading(false)
  }

  function openModalPayhow() {
    setOpenModal(true)
  }

  function handleCloseModal() {
    setOpenModal(false)
  }

  function handleClick(param) {
    // if (dimensionInfo === false) {
    //   setShowAlert(true)
    //   return
    // }
    if (param === "correios") {
      payhowActive.payment_active === "manual"
        ? openModalPayhow()
        : history.push("/integracao-correios")
    } else {
      payhowActive.payment_active === "manual"
        ? openModalPayhow()
        : history.push("/melhor-envio")
    }
  }

  useEffect(() => {
    getInformationsData()
  }, [])

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <TopCategorias name="Opções de entrega" />
          </div>
          <div className="infinite-scroll">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  minHeight: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <>
                <>
                  <div
                    className={classes.Box}
                    aria-hidden="true"
                    onClick={() => {
                      history.push("/frete-gratis")
                    }}
                  >
                    <div className={classesGlobal.fonteTitulo}>Frete grátis</div>
                    <div className={classes.btnalinhamento}>
                      <div className={classes.fonteSubtitulo}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          Configure em sua loja as regras para que o seu cliente
                          obtenha frete grátis em suas compras
                          {deliveryActiveFreeShipping?.name === "free_shipping" ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <CheckCircleIcon
                                style={{ color: "#27AE60", fontSize: "1rem" }}
                              />
                              <p
                                style={{
                                  color: "#27AE60",
                                  margin: "5px 0px",
                                  fontSize: "1rem"
                                }}
                              >
                                ativo
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div>
                        <ArrowForwardIosIcon fontSize="small" color="disabled" />
                      </div>
                    </div>
                    <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                  </div>

                  <div
                    className={classes.Box}
                    aria-hidden="true"
                    onClick={() => {
                      history.push("/frete-fixo")
                    }}
                  >
                    <div className={classesGlobal.fonteTitulo}>
                      Frete com valor fixo
                    </div>
                    <div className={classes.btnalinhamento}>
                      <div>
                        <div className={classes.fonteSubtitulo}>
                          Ative essa opção caso você queira atribuir um valor fixo ao
                          frete da sua loja
                          {deliveryActiveFixedShipping?.name === "fixed_shipping" ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <CheckCircleIcon
                                style={{ color: "#27AE60", fontSize: "1rem" }}
                              />
                              <p
                                style={{
                                  color: "#27AE60",
                                  margin: "5px 0px",
                                  fontSize: "1rem"
                                }}
                              >
                                ativo
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div>
                        <ArrowForwardIosIcon fontSize="small" color="disabled" />
                      </div>
                    </div>
                    <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                  </div>

                  <div
                    className={classes.Box}
                    aria-hidden="true"
                    onClick={() => {
                      history.push("/retirar-loja")
                    }}
                  >
                    <div className={classesGlobal.fonteTitulo}>
                      Possibilidade de retirar na loja
                    </div>
                    <div className={classes.btnalinhamento}>
                      <div>
                        <div className={classes.fonteSubtitulo}>
                          Essa opção é mais utilizada para os lojistas que tem um
                          espaço físico disponível para retirada
                          {deliveryActivePickup?.name === "pickup" ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <CheckCircleIcon
                                style={{ color: "#27AE60", fontSize: "1rem" }}
                              />
                              <p
                                style={{
                                  color: "#27AE60",
                                  margin: "5px 0px",
                                  fontSize: "1rem"
                                }}
                              >
                                ativo
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div>
                        <ArrowForwardIosIcon fontSize="small" color="disabled" />
                      </div>
                    </div>
                    <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                  </div>

                  <div
                    className={classes.Box}
                    aria-hidden="true"
                    onClick={() => {
                      history.push("/frete-personalizado")
                    }}
                  >
                    <div className={classesGlobal.fonteTitulo}>
                      Frete personalizado
                      <button className={classes.btnNews}>novidade</button>
                    </div>
                    <div className={classes.btnalinhamento}>
                      <div>
                        <div className={classes.fonteSubtitulo}>
                          Opção mais utilizada para venda de produtos digitais ou que
                          não necessitam a opção de frete
                          {deliveryActiveCustomShipping?.name ===
                          "custom_shipping" ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <CheckCircleIcon
                                style={{ color: "#27AE60", fontSize: "1rem" }}
                              />
                              <p
                                style={{
                                  color: "#27AE60",
                                  margin: "5px 0px",
                                  fontSize: "1rem"
                                }}
                              >
                                ativo
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div>
                        <ArrowForwardIosIcon fontSize="small" color="disabled" />
                      </div>
                    </div>
                    <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                  </div>

                  <div
                    className={classes.Box}
                    aria-hidden="true"
                    onClick={() => handleClick("correios")}
                  >
                    <div className={classesGlobal.fonteTitulo}>
                      Integração com os Correios
                      <button className={classes.btnNews}>novidade</button>
                    </div>
                    <div className={classes.btnalinhamento}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                          }}
                        >
                          <img
                            style={{ padding: "10px" }}
                            src={CorreioIcon}
                            alt="correios"
                          ></img>
                          {deliveryActivePostOffice?.name === "Correios" ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <CheckCircleIcon
                                style={{ color: "#27AE60", fontSize: "1rem" }}
                              />
                              <p
                                style={{
                                  color: "#27AE60",
                                  margin: "5px 0px",
                                  fontSize: "1rem"
                                }}
                              >
                                ativo
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className={classes.fonteSubtitulo}>
                          Simulação e cálculo automático do frete diretamente
                          integrado com os Correios do Brasil
                        </div>
                      </div>
                      <div>
                        <ArrowForwardIosIcon fontSize="small" color="disabled" />
                      </div>
                    </div>
                    {dimensionInfo === false ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <WarningRoundedIcon
                          style={{ color: "#ff9800", fontSize: "1rem" }}
                        />
                        <p
                          style={{
                            color: "#ff9800",
                            margin: "5px",
                            fontSize: "0.9rem"
                          }}
                        >
                          Existem produtos sem dimensões definidas
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                  </div>

                  <div
                    className={classes.Box}
                    aria-hidden="true"
                    onClick={() => handleClick("melhorEnvio")}
                  >
                    <div className={classesGlobal.fonteTitulo}>
                      Integração com Melhor Envio
                      <button className={classes.btnNews}>novidade</button>
                    </div>
                    <div className={classes.btnalinhamento}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                          }}
                        >
                          <img
                            style={{ padding: "10px" }}
                            src={EnvioIcon}
                            width={50}
                            alt="melhor-envio"
                          ></img>
                          {deliveryActiveBestShipping?.name === "Melhor Envio" ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <CheckCircleIcon
                                style={{ color: "#27AE60", fontSize: "1rem" }}
                              />
                              <p
                                style={{
                                  color: "#27AE60",
                                  margin: "5px 0px",
                                  fontSize: "1rem"
                                }}
                              >
                                ativo
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className={classes.fonteSubtitulo}>
                          Simulação e cálculo automático do frete diretamente
                          integrado com o Melhor Envio
                        </div>
                      </div>
                      <div>
                        <ArrowForwardIosIcon fontSize="small" color="disabled" />
                      </div>
                    </div>
                    {dimensionInfo === false ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <WarningRoundedIcon
                          style={{ color: "#ff9800", fontSize: "1rem" }}
                        />
                        <p
                          style={{
                            color: "#ff9800",
                            margin: "5px",
                            fontSize: "0.9rem"
                          }}
                        >
                          Existem produtos sem dimensões definidas
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                  </div>

                  {hasPro ? (
                    ""
                  ) : (
                    <BannerSejaPro lite bannerId="banner-menu-ajustes" />
                  )}
                </>
              </>
            )}
          </div>
        </div>
      </div>

      <CustomAlert
        type="warning"
        alertText="Para ativar a integração com os correios ou melhor envio, seus produtos precisam ter as dimensões configuradas."
        show={showAlert}
        close={() => setShowAlert(false)}
      />

      {openModal === true ? (
        <IntegrationAlertModal isOpen={openModal} handleClose={handleCloseModal} />
      ) : (
        ""
      )}
    </>
  )
}
