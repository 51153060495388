import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useStyles } from "./style"
import noVisitors from "../../../../../Assets/Home/noProducts.svg"
import { globalStyles } from "../../../../../Componentes/CssGlobal"
import CardInfo from "./components/CardInfo"
import Topo from "../../../../../Componentes/TopCategorias"
import { useSettings } from "../../../../../ContextAPI/settingsContext"
import api from "../../../../../Services/api"
import CardProduto from "./components/CardProduto"
import CardVendas from "./components/CardVendas"
import CardPedidos from "./components/CardPedidos"
import { CircularProgress } from "@material-ui/core"

export default function CardAno(props) {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const { lojinhaInfo } = useSettings()
  const [loadPage, setLoadPage] = useState(true)
  const [sales, setSales] = useState(0)
  const [products, setProducts] = useState([])
  const [orders, setOrder] = useState([])
  const [charts, setCharts] = useState([])
  const history = useHistory()
  const shopId = localStorage.getItem("id")
  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  async function getData() {
    const data = await api.get(`/product/gettotalpricebyyear/${shopId}/10`)
    const newData = data.data.data
    const newDataCharts = data.data.data
    setCharts(newDataCharts)

    let priceYearTotal = 0

    for (let index = 0; index < newData.length; index++) {
      priceYearTotal = newData[index].total_price + priceYearTotal
    }

    setSales(priceYearTotal)

    const data2 = await api.get(`/product/gettopbyyear/${shopId}/5`)
    const newDataProduct = data2.data.data
    newDataProduct.sort((x, y) => {
      return y.total_sales - x.total_sales
    })
    setProducts(newDataProduct)

    let arrStatus = [
      { status: "AGUARDANDO PAGAMENTO", total: 0 },
      { status: "CANCELADO", total: 0 },
      { status: "EM TRÂNSITO", total: 0 },
      { status: "ENTREGUE", total: 0 },
      { status: "PENDENTE", total: 0 }
    ]

    const orderStatusRes = await api.get(`/order/status/${shopId}`)
    if (orderStatusRes.data.data.length) {
      orderStatusRes.data.data.map(status => {
        const findStatus = arrStatus.find(item => item.status === status.status)
        if (findStatus !== undefined) {
          findStatus.total = status.total
        }
      })
      setOrder(arrStatus)
    }

    setLoadPage(false)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {loadPage ? (
        <div
          style={{
            backgroundColor: " #fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <>
          <div className="page-container">
            <div className="page-align">
              <div style={{ maxHeight: "100%" }} className="infinite-scroll">
                <Topo name="Acompanhamento das vendas" />
                {hasPro ? (
                  <div className={classes.topOption}>
                    <div className={classes.topSelector2}>
                      <div className={classes.topSelected}>Ano</div>
                      <div
                        className={classes.topSelector}
                        onClick={() => {
                          props.setPosition(1)
                        }}
                        aria-hidden="true"
                      >
                        <div>Mensal</div>
                        {/* <div style={{ color: '#EA3458', fontSize: '1em' }}>PRO</div> */}
                      </div>
                      <div
                        className={classes.topSelector}
                        onClick={() => {
                          props.setPosition(2)
                        }}
                        aria-hidden="true"
                      >
                        <div>7 dias</div>
                      </div>
                      <div
                        className={classes.topSelector}
                        onClick={() => {
                          props.setPosition(3)
                        }}
                        aria-hidden="true"
                      >
                        <div>Hoje</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={classes.topOption}>
                    <div className={classes.topSelector2}>
                      <div className={classes.topSelected}>Ano</div>
                      <div
                        className={classes.topSelector}
                        onClick={() => {
                          history.push("/planos")
                        }}
                        aria-hidden="true"
                      >
                        <div>Mensal</div>
                        <div style={{ color: "#EA3458", fontSize: "1em" }}>PRO</div>
                      </div>
                      <div
                        className={classes.topSelector}
                        onClick={() => {
                          history.push("/planos")
                        }}
                        aria-hidden="true"
                      >
                        <div>7 dias</div>
                        <div style={{ color: "#EA3458", fontSize: "1em" }}>PRO</div>
                      </div>
                      <div
                        className={classes.topSelector}
                        onClick={() => {
                          history.push("/planos")
                        }}
                        aria-hidden="true"
                      >
                        <div>Hoje</div>
                        <div style={{ color: "#EA3458", fontSize: "1em" }}>PRO</div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="">
                  <div className={classes.cardInfo}></div>
                  {sales !== 0 && <CardInfo data={sales} />}
                  {products.length <= 0 ? (
                    <div className={classes.noVisitors}>
                      <img
                        src={noVisitors}
                        alt="sem produtos"
                        style={{
                          marginTop: "10px",
                          marginBottom: "12px",
                          maxWidth: "165px",
                          maxHeight: "115px"
                        }}
                      />
                      <span className={classes.infoText}>
                        Você ainda não teve nenhuma venda.
                      </span>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <div style={{ color: "#EA3458", fontWeight: "bold" }}>
                        TOP 5{" "}
                      </div>
                      <div style={{ color: "#353535", fontWeight: "500" }}>
                        - Produtos mais vendidos
                      </div>
                    </div>
                  )}
                  <div className={classes.cardProduto}>
                    <CardProduto products={products} />
                  </div>
                  <div>
                    {charts.length <= 0 ? (
                      ""
                    ) : (
                      <div className={classes.cardBar}>
                        <CardVendas charts={charts} />
                      </div>
                    )}
                    {orders.length <= 0 ? (
                      ""
                    ) : (
                      <div>
                        <CardPedidos orders={orders} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
