import Avatar from "@material-ui/core/Avatar"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import { SimpleImg } from "react-simple-img"
import btnFacebook from "../../../../../../Assets/Home/btnFacebook.svg"
import btnTwitter from "../../../../../../Assets/Home/btnTwitter.svg"
import coroinha from "../../../../../../Assets/Home/coroinha.png"
import groupFiles from "../../../../../../Assets/Home/groupFiles.svg"
import { useSettings } from "../../../../../../ContextAPI/settingsContext"
import { useStyles } from "./style"

export default function LeftMenuHeader({ alertLink }) {
  const classes = useStyles()
  const { lojinhaInfo } = useSettings()

  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  function copyText() {
    const element = `https://${lojinhaInfo.url}.uppy.app`

    navigator.clipboard.writeText(element)

    alertLink()
  }

  return (
    <div>
      <section style={{ display: "flex" }}>
        {hasPro ? (
          <>
            <SimpleImg
              width={75}
              height="auto"
              placeholder={false}
              className={classes.coroinha}
              src={coroinha}
              alt="Borda Coroinha"
            />
            <Avatar
              alt="logo-pro"
              src={lojinhaInfo && lojinhaInfo.md_url ? lojinhaInfo.md_url : ""}
              className={classes.large}
            />
          </>
        ) : (
          <div className={classes.avatarContent}>
            <Avatar
              alt="logo-free"
              src={lojinhaInfo && lojinhaInfo.md_url ? lojinhaInfo.md_url : ""}
              className={classes.large}
            />
            <span>gratuito</span>
          </div>
        )}
        <div className={classes.headerInfos}>
          <p className={classes.headerName} style={{ lineBreak: "anywhere" }}>
            {lojinhaInfo && lojinhaInfo.name ? lojinhaInfo.name : ""}
          </p>
          <p className={classes.headerSite} style={{ lineBreak: "anywhere" }}>
            https://
            {lojinhaInfo && lojinhaInfo.name ? lojinhaInfo.url : ""}
            .uppy.app
          </p>
        </div>
      </section>

      <section className={classes.headerButtons}>
        <button className={classes.button} type="button">
          <a
            id="copy"
            href={
              lojinhaInfo.url
                ? `https://${lojinhaInfo.url}.uppy.app`
                : `https://${localStorage.getItem("name").toLowerCase()}.uppy.app`
            }
            target="_blank"
            rel="noreferrer"
          >
            <span>Visitar</span>
          </a>
        </button>
        <button
          className={classes.button}
          style={{ marginLeft: "26.5px", cursor: "copy" }}
          type="button"
          onClick={copyText}
        >
          <span>
            Copiar link
            <SimpleImg
              width={15}
              height="auto"
              placeholder={false}
              src={groupFiles}
              style={{ marginLeft: "6.54px" }}
              alt="img"
            />
          </span>
        </button>
      </section>

      <section className={classes.headerMedias}>
        <span>Compartilhar: </span>
        <div>
          <a
            href={`https://www.facebook.com/sharer.php?u=https://${lojinhaInfo.url}.uppy.app`}
            target="_blank"
            rel="noreferrer"
            data-action="share/whatsapp/share"
          >
            <SimpleImg
              width={20}
              height="auto"
              placeholder={false}
              src={btnFacebook}
              style={{ marginLeft: "13px" }}
              alt="Btn Facebook"
            />
          </a>

          <a
            href={`https://twitter.com/intent/tweet?url=https://${lojinhaInfo.url}.uppy.app`}
            target="_blank"
            rel="noreferrer"
          >
            <SimpleImg
              width={20}
              height="auto"
              placeholder={false}
              src={btnTwitter}
              style={{ marginLeft: "9.5px" }}
              alt="Btn Twitter"
            />
          </a>
          <a
            href={`https://wa.me/?text=https://${lojinhaInfo.url}.uppy.app`}
            target="_blank"
            data-action="share/whatsapp/share"
            rel="noreferrer"
          >
            <WhatsAppIcon
              style={{
                color: "#fff",
                backgroundColor: "#3DC34F",
                padding: "5px",
                fontSize: "0.75rem",
                borderRadius: "50px",
                marginLeft: "9.5px"
              }}
            />
          </a>
        </div>
      </section>
    </div>
  )
}
