import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => ({
  topoContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: "10px",
    position: "relative"
  },

  topoDots: {
    display: "flex",
    justifyContent: "center",
    height: "50px",
    alignItems: "center",
    flexDirection: "column",
    marginLeft: "-10px"
  },

  topoSpace: {
    width: "10px",
    hidden: "true"
  }
}))
