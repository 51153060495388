import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles(theme => ({
    uploadContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        textAlign: "center",
        color: "#555",
        fontSize: "1rem",
        width: "100%",
        alignItems: "center",
    },
    buttonAddImage: {
        minWidth: "65%",
        height: "50px",
        boxShadow:
            "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        marginTop: "5px",
        marginBottom: "15px",
        textTransform: "none",
        backgroundColor: "#EA3458",
        border: "none",
        color: "#FFF",
        fontSize: "0.875rem",
        borderRadius: "4px",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            backgroundColor: "#EA3458",
            opacity: "0.8",
        },
    },
}))