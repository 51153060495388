import { Button, CircularProgress, Dialog, TextField } from "@material-ui/core"
import Switch from "@material-ui/core/Switch"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import pagseguroIcon from "../../../../Assets/svg/pagseguro-logo.svg"
import CustomAlert from "../../../../Componentes/Alerts/CustomAlert"

import api from "../../../../Services/api"
import { useStyles } from "./style"

import TopPagseguro from "../../../../Componentes/TopPagseguro"

function CheckoutPagseguro() {
  const classes = useStyles()
  const history = useHistory()

  const [enablePaymentOption, setEnablePaymentOption] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [leaving, setLeaving] = useState(false)

  const [currentPayment, setCurrentPayment] = useState({})
  const [installment, setInstallment] = useState("1")
  const [credentialEmail, setCredentialEmail] = useState("")
  const [credentialToken, setCredentialToken] = useState("")

  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("success")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [auth, setAuth] = useState([])
  const [ paymentsActive, setPaymentsActive] = useState()

  const shopId = localStorage.getItem("id")

  const installments = [
    {
      value: "1",
      label: "1 parcela sem juros"
    },
    {
      value: "2",
      label: "2 parcelas sem juros"
    },
    {
      value: "3",
      label: "3 parcelas sem juros"
    },
    {
      value: "4",
      label: "4 parcelas sem juros"
    },
    {
      value: "5",
      label: "5 parcelas sem juros"
    },
    {
      value: "6",
      label: "6 parcelas sem juros"
    },
    {
      value: "7",
      label: "7 parcelas sem juros"
    },
    {
      value: "8",
      label: "8 parcelas sem juros"
    },
    {
      value: "9",
      label: "9 parcelas sem juros"
    },
    {
      value: "10",
      label: "10 parcelas sem juros"
    },
    {
      value: "11",
      label: "11 parcelas sem juros"
    },
    {
      value: "12",
      label: "12 parcelas sem juros"
    }
  ]

  const handleChangeInstallments = event => {
    setInstallment(event.target.value)
  }

  const handleChangeEmail = event => {
    const email = event.target.value.toLowerCase()
    setCredentialEmail(email)
  }

  const handleChangeToken = event => {
    setCredentialToken(event.target.value)
  }

  const leavingClear = () => {
    setLeaving(false)
    setInstallment(1)
    setCredentialEmail("")
    setCredentialToken("")
  }

  const handleClose = async () => {
    history.goBack()
  }

  async function integrationVerify() {
    const { data } = await api
      .get(`payhow/pagseguro/verify/${shopId}`)
      .then(response => response)
      .catch(error => console.log(error))
    return data.data
  }

  async function getInformations() {
    setLoading(true)
    const { data } = await api.get(`/lojinha/payment/get/${shopId}`)
    setPaymentsActive(data.data.payment_data.payhow)
    const paymentData = data.data
    setEnablePaymentOption(paymentData?.payment_data?.payhow?.pagseguro === 0)
    setCurrentPayment(paymentData)
    const integration = await api.get(`/payhow/info/${shopId}`)
    const pagseguro = integration?.data?.data.filter(obj => {
      return obj.name === "PagBank"
    })
    if (pagseguro) {
      setIsEdit(true)
      setEnablePaymentOption(pagseguro[0].enable)
      setCredentialToken(pagseguro[0].credentials.token)
      setCredentialEmail(pagseguro[0].credentials.email)
      setInstallment(pagseguro[0].credentials.installments)
    }
    setLoading(false)
  }

  async function updatePayment(active) {
    const body = {
      lojinha_id: shopId,
      payment_active: active ? "pagseguro" : paymentsActive.mercadopago === 1 ? "mercadopago" : "manual",
      payment_data: {
        ...currentPayment.payment_data,
        payhow: {
          pagseguro: active ? 1 : 0,
          mercadopago: 0,
          gateway: 0
        }
      }
    }

    localStorage.setItem("active_checkout", active ? "pagseguro" : "manual")

    await api.put(`/lojinha/payment/update/${currentPayment.id}`, body)
  }

  async function saveData() {
    setLoading(true)
    if (credentialToken.length >= 5 && credentialEmail.length >= 5) {
      const body = {
        payment_id: localStorage.getItem("payment_id"),
        enable: enablePaymentOption === true || enablePaymentOption === 1 ? 1 : 0,
        credentials: {
          token: credentialToken,
          email: credentialEmail,
          installments: installment
        }
      }
      await api.post(`/payhow/pagseguro/${shopId}`, body)
      let IntegrationAuth = await integrationVerify()
      if (IntegrationAuth.authenticated === 1 && IntegrationAuth.enable === 1) {
        setLoading(false)
        setShowModalAlert(true)
        setAlertType("success")
        setAlertMessage(
          isEdit
            ? "Integração com PagBank alterada com sucesso!"
            : "Integração com PagBank criada com sucesso!"
        )
        updatePayment(true)
        setTimeout(() => {
          history.goBack()
        }, 2000)
      } else if (
        IntegrationAuth.authenticated === 1 &&
        IntegrationAuth.enable === 0 &&
        (enablePaymentOption === false || enablePaymentOption === 0)
      ) {
        setLoading(false)
        setShowModalAlert(true)
        setAlertType("success")
        setAlertMessage("Alterado para checkout manual!")
        updatePayment(false)
        setTimeout(() => {
          history.goBack()
        }, 2000)
      } else {
        setAlertMessage(
          isEdit
            ? "Erro ao editar integração com PagBank !"
            : "Erro ao criar integração com PagBank !"
        )
        setAlertType("error")
        setShowModalAlert(true)
        setLoading(false)
        updatePayment(false)
      }
    } else {
      setAlertMessage("Preencha os campos corretamente.")
      setAlertType("error")
      setShowModalAlert(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getInformations()
  }, [])

  return (
    <div className="page-container">
      <div className="page-align">
        <TopPagseguro
          name="Receber pedidos com cartão de crédito"
          handleBack={handleClose}
        />

        <div className={`${classes.pagseguroOrders}  infinite-scroll`}>
          {loading ? (
            <div
              style={{
                display: "flex",
                height: "100%",
                minHeight: "100%",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <>
              <div className={`${classes.pagseguroOrders}__enablePaymentOption`}>
                <div>
                  <h2>Habilitar esta opção de Pagamento</h2>
                  <Switch
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    // onClick={() => {
                    //   if (localStorage.getItem("active_checkout") === "payhow") {
                    //     setEnablePaymentOption(true)
                    //   } else {
                    //     setEnablePaymentOption(prevState => !prevState)
                    //   }
                    // }}
                    onClick={() => {
                      setEnablePaymentOption(prevState => !prevState)
                    }}
                    checked={enablePaymentOption}
                  />
                </div>
                <p>
                  Em parceria com o <img src={pagseguroIcon} alt="pagseguro-icon" />
                </p>
              </div>

              {enablePaymentOption === true || enablePaymentOption === 1 ? (
                <>
                  <div className={`${classes.pagseguroOrders}__installments`}>
                    <p>Total máximo de parcelas sem juros</p>
                    <TextField
                      variant="outlined"
                      select
                      color="secondary"
                      fullWidth
                      value={installment}
                      onChange={handleChangeInstallments}
                      SelectProps={{
                        native: true
                      }}
                    >
                      {installments.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                    {installment >= 2 ? (
                      <>
                        <p style={{ color: "#D62B2B" }}>
                          &#9888; Você selecionou uma forma de pagamento com
                          parcelamento sem juros. Essas taxas serão descontadas
                          automaticamente dos seus ganhos, para maiores informações
                          consulte o portal do PagBank.
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={`${classes.pagseguroOrders}__registeredEmail`}>
                    <p>E-mail de cadastro no PagBank</p>
                    <TextField
                      variant="outlined"
                      fullWidth
                      color="secondary"
                      value={credentialEmail}
                      onChange={handleChangeEmail}
                    ></TextField>
                  </div>

                  <div className={`${classes.pagseguroOrders}__token`}>
                    <p>Cole abaixo o token gerado pela PagBank:</p>
                    <TextField
                      variant="outlined"
                      multiline
                      color="secondary"
                      rows={6}
                      fullWidth
                      value={credentialToken}
                      onChange={handleChangeToken}
                    ></TextField>
                  </div>

                  <div className={`${classes.pagseguroOrders}__information`}>
                    <p>
                      Não sabe como obter essas informações?{" "}
                      <a
                        href="https://blog.uppyapp.com.br/2022/09/como-integrar-o-pagseguro-na-uppy-app/"
                        target="__blank"
                      >
                        clique aqui
                      </a>
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </div>

        <div className="footer-column">
          <hr style={{ width: "100%", margin: "20px 0" }} />
          <Dialog className={classes.modalLeaving} open={leaving}>
            <div>
              <h2>Tem certeza que deseja sair?</h2>
              <p>
                Sua conexão com o PagBank não foi concluída devido ao email ou
                token serem inválidos.
              </p>
              <p style={{ color: "red" }}>
                Se você sair seu checkout voltará para o manual!
              </p>
            </div>
            <Button
              className={classes.btnFooterPink}
              disabled={loading}
              onClick={() => handleClose()}
              fullWidth
              variant="contained"
              color="secondary"
            >
              sim, sair sem atualizar
            </Button>
            <Button
              className={classes.btnFooterWhite}
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={leavingClear}
            >
              não, obrigado.
            </Button>
          </Dialog>
          <Button
            className={classes.btnFooterPink}
            disabled={loading}
            onClick={() =>
              setTimeout(() => {
                saveData()
              }, 1500)
            }
            fullWidth
            variant="contained"
            color="secondary"
          >
            Salvar Informações
          </Button>
        </div>
        <CustomAlert
          type={alertType}
          alertText={alertMessage}
          show={showModalAlert}
          close={() => setShowModalAlert(false)}
        />
      </div>
    </div>
  )
}

export default CheckoutPagseguro

//
