import { makeStyles } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import React, { useEffect, useState } from "react"

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: "center",
    padding: "10px",
    "& > * + *": {
      marginTop: theme.spacing(2)
    },
    width: "320px",
    textAlign: "center",
    height: "48px!important",
    position: "absolute",
    bottom: "15%",
    left: "calc(50% - 170px)",
    margin: "0 auto",
    zIndex: "999999",
    display: "flex",
    alignItems: "center"
  }
}))

export default function CustomAlert({ type = "success", alertText, show, close }) {
  const classes = useStyles()
  const [showAlert, setShowAlert] = useState(false)
  // fecha o alerta
  const handleClose = () => {
    setShowAlert(false)
    close()
  }
  const handleOpen = () => {
    setShowAlert(true)
    const timer = setTimeout(() => {
      handleClose()
    }, 2000)
    return () => clearTimeout(timer)
  }

  useEffect(() => {
    if (show === true) handleOpen()
  }, [show])

  return (
    <div
      className={classes.root}
      style={{ display: showAlert === true ? "flex" : "none" }}
    >
      {showAlert === true ? (
        <Alert
          variant="filled"
          severity={type}
          style={{ width: "100%!important", height: "100%!important" }}
        >
          {alertText}
        </Alert>
      ) : (
        <div style={{ width: "100%!important", height: "100%!important" }}></div>
      )}
    </div>
  )
}
