import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => ({
  productContainer: {
    display: "flex",
    marginBottom: "21px",

    "& h2": {
      fontWeight: "500",
      fontSize: "24px",
      lineHeight: "28px",
      marginRight: "9px"
    }
  },

  productContent: {
    display: "flex",
    alignItems: "center"
  },

  productInfos: {
    alignSelf: "start",
    marginLeft: "10px",

    "& p": {
      fontWeight: "500",
      fontSize: "14px",
      margin: "0"
    },

    "& small": {
      fontWeight: "400",
      fontSize: "12px"
    }
  },

  noProductsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 10px",

    "& button": {
      marginTop: "17px",
      marginBottom: "15px",
      width: "197px",
      height: "40px",
      background: "#FFFFFF",
      border: "2px solid #EA3458",
      borderRadius: "5px",
      fontSize: "16px",
      fontWeight: "500",
      color: "#EA3458"
    }
  },

  infoText: {
    textAlign: "center",
    marginTop: "11px",
    fontSize: "14px",
    fontWeight: "400"
  },

  helpText: {
    marginTop: "3.5px",
    fontSize: "16px",
    fontWeight: "500",
    color: "#EA3458"
  }
}))
