import { useDevice } from "../../ContextAPI/deviceContext"

export default function MobileDeviceText() {
  const { checkDevice } = useDevice()

  return (
    <>
      {checkDevice().isMobile === undefined &&
      checkDevice().isTablet === undefined ? (
        <p
          style={{
            position: "absolute",
            right: "20px",
            fontWeight: "400",
            fontSize: "0.7em",
            color: "#a5a5a5"
          }}
        >
          Uso do sistema recomendado para celular
        </p>
      ) : (
        ""
      )}
    </>
  )
}
