import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core"
import React, { useEffect, useState } from "react"

import CloseIcon from "@material-ui/icons/Close"
import { globalStyles } from "../CssGlobal"
import { button } from "../Inputs/TextFild"
import useStyles from "./styles"

import { useHistory } from "react-router-dom"

import CircularProgress from "@material-ui/core/CircularProgress"
import api from "../../Services/api"

export default function PagSeguroModal({ isOpen, handleClose }) {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const shopId = localStorage.getItem("id")

  async function updatePayment() {
    setLoading(true)
    const body = {
      lojinha_id: shopId
    }

    let res = await api.post(`payhow/register`, body)
    if (res) {
      localStorage.setItem("payhow_id", res.data.payhow_id)
      localStorage.setItem("payhow_token", res.data.token)
    }
    setLoading(false)
    history.push("/checkout-menu")
  }

  return (
    <div className="page-container">
      <div className="page-align">
        <div className="infinite-scroll">
          <div aria-hidden="true">
            <Dialog
              open={isOpen}
              onClose={(event, reason) => {
                if (reason && reason === "backdropClick") return
                handleClose()
              }}
              aria-labelledby="responsive-dialog-title"
              className={classes.PlanosAlertaModal}
            >
              <DialogTitle id="responsive-dialog-title">
                <div className={classes.PlanosAlertaModalHeader}>
                  <h2>Vamos ativar seu novo checkout!</h2>
                  {loading ? (
                    ""
                  ) : (
                    <button type="button" onClick={handleClose}>
                      <CloseIcon
                        style={{
                          fontSize: "100%",
                          fontWeight: "bold",
                          color: "#EA3458",
                          cursor: "pointer"
                        }}
                      />
                    </button>
                  )}
                </div>
              </DialogTitle>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    minHeight: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2rem"
                  }}
                >
                  <CircularProgress color="secondary" />
                  <p
                    style={{
                      fontSize: "100%",
                      fontWeight: "300",
                      textAlign: "center"
                    }}
                  >
                    Aguarde, Estamos preparando o seu checkout automático!.
                  </p>
                  <span
                    style={{
                      fontSize: "90%",
                      textAlign: "center",
                      color: "#EA3458",
                      fontWeight: "600"
                    }}
                  >
                    Não saia da tela, isso pode levar alguns minutos.
                  </span>
                </div>
              ) : (
                <>
                  <DialogContent>
                    <DialogContentText className={classes.PlanosAlertaModalBody}>
                      <p>
                        Para prosseguir, primeiro precisamos sincronizar sua conta
                        <span> UPPY.</span>
                      </p>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    {button({
                      name: `Quero prosseguir!`,
                      funcao: () => updatePayment()
                    })}
                  </DialogActions>
                </>
              )}
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  )
}
