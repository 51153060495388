import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  imgFees: {
    width: "100%",
    marginTop: "40px"
  },
  title: {
    color: "#EA3558",
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px"
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 17,
    marginTop: "20px",

    "& span": {
      fontSize: 14,
      fontWeight: "500",
      color: "#3498db",
      textDecoration: "underline"
    }
  },
  button: {
    maxWidth: "300px"
  },
  description: {
    fontWeight: "400"
  }
}))
