import React, { useEffect, useState } from "react"
import { Button, Switch, CircularProgress } from "@material-ui/core"
import { globalStyles } from "../../../../Componentes/CssGlobal"
import { useStyles } from "./style"
import Topo from "../../../../Componentes/TopCategorias"
import api from "../../../../Services/api"
import CustomAlert from "../../../../Componentes/Alerts/CustomAlert"
import { useHistory } from "react-router-dom"

export default function InstagramShopping() {
  const classesGlobal = globalStyles()
  const classes = useStyles()

  const [switchValue, setSwitchValue] = useState(1)
  const [loading, setLoading] = useState(true)
  const [alertType, setAlertType] = useState("")
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [saving, setSaving] = useState(false)

  const history = useHistory()

  function getData() {
    const lojinha_id = localStorage.getItem("id")
    api.get(`/configuration/show/${lojinha_id}`).then(res => {
      const switchTrocado = res.data.data[0].insta_check
      setSwitchValue(switchTrocado)
      setLoading(false)
    })
  }

  const body = {
    insta_check: !switchValue
  }

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  function saveSwitch() {
    const id = localStorage.getItem("config_id")
    api.put(`/configuration/update/${id}`, body)
  }

  async function activeSwitch() {
    if (switchValue === 0) {
      setSwitchValue(1)
      setTimeout(() => {
        saveSwitch()
      }, 300)
    } else {
      setSwitchValue(0)
      setTimeout(() => {
        saveSwitch()
      }, 300)
    }
  }

  async function sentEmail() {
    setSaving(true)

    let res = await api.get(
      `/mail/instructions/${localStorage.getItem("id")}`
    )

    if (res) {
      setAlertType("success")
      setShowAlertOk(true)
      setTimeout(() => {
        history.goBack()
      }, 1500)
    } else {
      setSaving(false)
      setAlertType("error")
      setShowAlertError(true)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {loading ? (
        <div
          style={{
            backgroundColor: " #fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <>
          <div className="page-container">
            <div className="page-align">
              <div>
                <Topo name="Instagram Shopping" />
              </div>
              <div className="infinite-scroll">
                <div className={classes.Box}>
                  <div className={classes.titleText}>
                    Habilitar Instagram Shopping
                  </div>
                  <div className={classes.switchChange}>
                    <Switch
                      checked={switchValue}
                      onClick={() => {
                        setTimeout(() => {
                          activeSwitch()
                        }, 200)
                      }}
                    />
                  </div>
                </div>

                {switchValue === 1 ? (
                  <div>
                    <div>
                      <Button
                        className={classes.btnEnviarEmail}
                        variant="contained"
                        disabled={saving}
                        onClick={() => {
                          sentEmail()
                        }}
                      >
                        Enviar passo a passo por e-mail
                      </Button>
                    </div>
                    <div>
                      <h3>
                        Você irá turbinar suas vendas com o Instagram Shopping! Não
                        deixe de conferir nosso tutorial de integração e também
                        nossas dicas de marketing no blog. Bora vender mais?
                      </h3>
                    </div>
                    <div className={classes.hyperlink}>
                      <a
                        className={classes.link}
                        href="https://blog.uppyapp.com.br/2021/11/como-adicionar-a-sacolinha-do-instagram-na-loja-online-uppy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Clique aqui para acessar nosso tutorial
                      </a>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <CustomAlert
                        type={alertType}
                        alertText={
                          alertType === "success"
                            ? "Email enviado com sucesso!"
                            : "Algo deu errado!"
                        }
                        show={showAlertOk || showAlertError}
                        close={() => closeAlerts()}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
