import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  orderBy: {
    "& p": {
      margin: "0px 0px 7px 0px",
      fontSize: "14px",
      fontWeight: "700"
    },

    "&__buttonsContainer": {
      display: "flex",

      "& button": {
        width: "100%",
        color: "#051626",
        border: "solid 1px #051626",
        borderRadius: "0px",

        "& .MuiButton-label": {
          textTransform: "none",
          fontSize: "14px !important",
          fontWeight: "400"
        }
      },

      "& button:nth-child(1)": {
        borderRadius: "5px 0 0 5px"
      },

      "& button:nth-child(2)": {
        borderRadius: "0 5px 5px 0"
      }
    }
  },

  noVisitors: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "20px"
  },

  infoText: {
    textAlign: "center",
    marginTop: "11px",
    fontSize: "0.875em",
    color: "#555555",
    fontWeight: "bold"
  },

  boxContainer: {
    borderBottom: "1px solid #F3F3F3",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column"
  },

  boxConteudo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10px",
    cursor: "pointer",
    flexDirection: "row"
  },

  btnalinhamento: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10px"
  },

  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },

  title: {
    display: "flex",
    alignItems: "left",
    color: "#EA3458",
    fontSize: "1.0em",
    fontWeight: "500",
    marginTop: "10px"
  },

  subtitle: {
    display: "flex",
    color: "#6C6C6C",
    fontSize: "0.8em",
    marginTop: "5px",
    marginBottom: "5px"
  }
}))
