import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  variationContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "30px 0px"
  },
  variationContainerHeader: {
    display: "flex",
    flexDirection: "column"
  },
  variationDescription: {
    fontSize: "1rem",
    color: "#555555",
    marginBottom: "20px",
    marginTop: "10px",
    fontWeight: "bold",
    "& >*": {
      textAlign: "center",
      margin: 0
    }
  },
  variationFlatDesign: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px 60px 0px"
  },
  btnAddVariations: {
    color: "#EA3458",
    height: "50px",
    margin: "20px auto",
    border: "solid 2px #EA3458",
    textTransform: "none",
    fontWeight: "bold",
    width: "80% !important"
  },
  variationTagsList: {
    display: "flex",
    width: "100%",
    alignItems: "center"
  },
  variationOptions: {
    fontSize: "1rem",
    color: "#555555",
    marginBottom: "20px",
    fontWeight: "bold"
  },
  variationTags: {
    display: "flex",
    margin: "0px 10px",
    padding: "5px",
    backgroundColor: "#EA3458"
  },
  variationTagsTitle: {
    color: "#fff",
    margin: "0px 5px"
  },
  variationBtnEdit: {
    display: "flex",
    backgroundColor: "#EA3458",
    borderRadius: "50px",
    marginLeft: "15px",
    width: "35px",
    height: "35px",
    justifyContent: "center",
    alignItems: "center"
  },

  /* MODAL EDIT TYPES */
  PlanosAlertaModal: {
    "& .MuiDialogTitle-root": {
      padding: "0px 10px"
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "480px"
    }
  },
  PlanosAlertaModalHeader: {
    display: "flex",
    "& h2": {
      fontSize: "2rem",
      fontWeight: "700",
      flex: 1,
      textAlign: "center",
      paddingLeft: "30px"
    },
    "& button": {
      border: "none",
      fontSize: "2rem",
      fontFamily: "cursive",
      cursor: "pointer",
      backgroundColor: "transparent",
      color: "#EA3458",
      fontWeight: 900
    }
  },
  PlanosAlertaModalBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      fontSize: "1rem",
      fontWeight: "300",
      textAlign: "center"
    },
    "& span": {
      color: "#EA3458",
      fontWeight: "600"
    },
    "& img": {
      width: "100%",
      maxWidth: "170px",
      margin: "0px 0"
    }
  },

  btnCancelarStyle: {
    width: "45%",
    border: "solid 2px #EA345880",
    color: "#EA345880",
    borderRadius: "5px",
    backgroundColor: "#fff",
    height: "40px",
    fontSize: "16px"
  },

  btnExcluirStyle: {
    transition: ".3s ease-out",
    width: "45%",
    border: "solid 2px #EB1111",
    color: "#fff",
    borderRadius: "5px",
    backgroundColor: "#EB1111",
    height: "40px",
    fontSize: "16px"
  },

  btnDisable: {
    transition: ".3s ease-out",
    width: "45%",
    border: "solid 2px #cccccc",
    color: "#a4a4a4",
    borderRadius: "5px",
    backgroundColor: "#cccccc",
    height: "40px",
    fontSize: "16px"
  }
}))
