import React from "react"
import { IoIosArrowBack } from "react-icons/io"
import { useStyles } from "./style"

export default function TopProdutos({ isEdit, handleBack, name, handleDelete }) {
  const classes = useStyles()

  return (
    <div className={classes.top}>
      <IoIosArrowBack className={classes.arrowBack} onClick={() => handleBack()} />
      <h2>{name}</h2>
      <button
        style={{
          visibility: isEdit !== true ? "hidden" : "visible",
          cursor: "pointer"
        }}
        className={classes.btnDeletar}
        onClick={handleDelete}
      >
        Deletar
      </button>
    </div>
  )
}
