import update from "immutability-helper"
import { useCallback, useEffect, useState } from "react"
import { useProduct } from "../../../../../../../../ContextAPI/productContext"
import conexao from "../../../../../../../../Services/api"
import CardFotos from "../CardFotos"
import CustomAlert from "../../../../../../../../Componentes/Alerts/CustomAlert"
import { readFile } from "../../../../../../../../utils"
import { useSettings } from "../../../../../../../../ContextAPI/settingsContext"
import addPictureIcon from "../../../../../../../../Assets/svg/addPictureIcon.svg"
import { useStyles } from "./style"
import ModalFoto from "../../ModalFoto"
import CircularProgressWithLabel from "../../../../../../../../Componentes/CircularProgressWithLabel"
import Cropper from "../../../../../../../../Componentes/Cropper"
import PlanosAlertaModal from "../../../../../../../Planos/Alertas/AlertaModal"
import { useHistory } from "react-router-dom"

const style = {
  display: "flex",
  flexWrap: "wrap",
  height: "max-content",
  width: "100%",
  margin: "0 0 20px 0",
  justifyContent: 'center'
}

function FotosContainer() {
  const classes = useStyles()
  const { currentVariation, setCurrentVariation } = useProduct()
  const [imageSrc, setImageSrc] = useState(null)
  const [openCrop, setOpenCrop] = useState(false)
  const [loading, setLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [showAlert, setShowAlert] = useState(false)
  const [openModalPhoto, setOpenModalPhoto] = useState(false)
  const [fullImage, setFullImage] = useState({
    dndID: null,
    url: "",
    index: "",
    order: null
  })
  const [openModal, setOpenModal] = useState(false)
  const { lojinhaInfo } = useSettings()
  const history = useHistory()

  /* useEffect(() => {
    handleCardPosition(currentVariation.images)
  }, [currentVariation.images]) */

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = currentVariation.images[dragIndex]
      setCurrentVariation(oldState => {
        return {
          ...oldState,
          images: update(oldState.images, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard]
            ]
          })
        }
      })
    },
    [
      currentVariation &&
        currentVariation.images !== undefined &&
        currentVariation.images
    ]
  )

  /* ON UPLOAD */
  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let imageDataUrl = await readFile(file)
      setImageSrc(imageDataUrl)
      e.target.value = null
      setOpenCrop(true)
    }
  }

  /* HANDLE CROP IMAGE  */
  const handleCroppedImage = image => {
    imageUpload(imageSrc, image)
    setOpenCrop(false)
    setImageSrc(null)
  }
  /* REMOVE PHOTO */
  const removePhotoFromArray = i => {
    const filteredPhotos = currentVariation.images.filter(
      (item, index) => index !== i
    )
    setCurrentVariation(oldState => {
      return { ...oldState, images: filteredPhotos }
    })
  }

  function closePhoto() {
    setOpenModalPhoto(false)
  }
  /* SAVE IMAGES ON SERVER  */
  const imageUpload = async (src, min) => {
    setLoading(true)

    const er = /[^a-zA-Zs]/g
    let shop_name = localStorage.getItem("name").replace(er, "").toLocaleLowerCase()

    const payload = {
      path: shop_name,
      photo: src,
      crop: min
    }

    try {
      const { data: { data } } = await conexao.post("/image/upload", payload, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total))
          setUploadProgress(progress)
        }
      })

      setCurrentVariation(oldState => ({
        ...oldState,
        images: [
          ...oldState.images,
          {
            dndID: oldState.images.length + 1,
            url: data.url,
            xlg_url: data.xlg_url,
            lg_url: data.lg_url,
            md_url: data.md_url,
            sm_url: data.sm_url,
            url_min: data.sm_url,
            order: null
          }
        ]
      }))
    } catch ({ response }) {
      if (response.status === 413) {
        setShowAlert(true)
      }
    } finally {
      setLoading(false)
      setUploadProgress(0)
    }
  }

  const renderCard = (item, index) => {
    return (
      <CardFotos
        key={item.dndID}
        id={item.dndID}
        index={index}
        image={item.md_url}
        handleDelete={removePhotoFromArray}
        moveCard={moveCard}
      />
    )
  }

  /* OPEN PRO MODAL */
  function openPlanos() {
    setOpenModal(true)
  }
  /* CLOSE PRO MODAL */
  function handleCloseModal() {
    setOpenModal(false)
  }
  /* REDIRECT TO PRO PAGE */
  function planPro() {
    history.push("/planos")
  }

  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  return (
    <>
      {loading ? (
        <div className={classes.progress}>
          <CircularProgressWithLabel
            value={uploadProgress}
            size={50}
            color="secondary"
          />
        </div>
      ) : (
        <div style={style}>
          {currentVariation &&
            currentVariation.images &&
            currentVariation.images.map((item, index) => renderCard(item, index))
          }

          {currentVariation &&
            currentVariation.images !== undefined &&
            currentVariation.images.length < 5 && (
              <>
                <label
                  onClick={() => {
                    hasPro === false && currentVariation.images.length >= 1
                      ? openPlanos()
                      : ""
                  }}
                  aria-hidden="true"
                  htmlFor="new-photo"
                  className={classes.areaBtn}
                >
                  <img
                    src={addPictureIcon}
                    width={64}
                    height="auto"
                    alt="addPictureIcon-icon"
                  />
                </label>
                {hasPro === false && currentVariation.images.length >= 1 ? (
                  ""
                ) : (
                  <input
                    hidden="true"
                    id="new-photo"
                    type="file"
                    accept="image/*"
                    onChange={onFileChange}
                  />
                )}
              </>
            )}

          <Cropper
            src={imageSrc}
            open={openCrop}
            close={() => setOpenCrop(false)}
            callback={handleCroppedImage}
          />

          {openModal === true && (
            <PlanosAlertaModal
              isOpen={openModal}
              handleClose={handleCloseModal}
              isPush={planPro}
            />
          )}

          <CustomAlert
            type="error"
            alertText="Imagem muito grande."
            show={showAlert}
            close={() => setShowAlert(false)}
          />
        </div>
      )}
    </>
  )
}

export default FotosContainer
