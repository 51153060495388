import { CircularProgress } from "@material-ui/core"
import Switch from "@material-ui/core/Switch"
import { useEffect, useState } from "react"
import ReactQuill from "react-quill"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { button } from "../../../Componentes/Inputs/TextFild"
import Topo from "../../../Componentes/TopCategorias"
import deliveryPolicy from "../../../Services/api/deliveryPolicy"
import { useStyles } from "./style"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export default function FormasDeEntrega() {
  const [enableInputs, setEnableInputs] = useState(false)
  const [textAreaValue, setTextAreaValue] = useState("")
  const [alertType, setAlertType] = useState("")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const handleRedirect = () => {
    history.replace("/personalizar-lojinha/complementos")
    history.goBack()
  }

  const formats = [
    "background",
    "bold",
    "color",
    "font",
    "code",
    "italic",
    "link",
    "size",
    "strike",
    "script",
    "underline",
    "blockquote",
    "header",
    "indent",
    "list",
    "align",
    "direction",
    "code-block",
    "formula"
  ]
  async function getDelivery() {
    setLoading(true)
    try {
      const { data } = await deliveryPolicy.get()
      setEnableInputs(data.data.complements_delivery_status)
      setTextAreaValue(data.data.complements_delivery.text)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  function sendData() {
    setLoading(true);
    try {
      const textValue = textAreaValue.replace(/<[^>]*>/g, "").trim();
      
      if (!enableInputs || textValue !== "") {
        deliveryPolicy.put({
          complements_delivery_status: enableInputs,
          complements_delivery: {
            status: enableInputs,
            text: textAreaValue
          }
        });
  
        setAlertType("success");
        setAlertMessage("Atualizado com sucesso!");
        setShowModalAlert(true);
        setTimeout(() => {
          handleRedirect();
        }, 1000);
      } else {
        setAlertType("error");
        setAlertMessage("Preencha o campo antes de salvar.");
        setShowModalAlert(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  

  useEffect(() => {
    getDelivery()
  }, [])

  return (
    <div className="page-container">
      <div className="page-align">
        <div>
          <Topo name="Formas de entrega" />
          {loading ? (
            <div
              style={{
                backgroundColor: "#f9f9f9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%"
              }}
            >
              <CircularProgress color="secondary" size="40px" />
            </div>
          ) : (
            <>
              <div className={classes.ContainerForms}>
                <div className={classes.InputBox}>
                  <div className={classes.InputLabel}>
                    <label>Ativar</label>
                    <Switch
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onClick={() => {
                        setEnableInputs(!enableInputs)
                      }}
                      checked={enableInputs}
                    />
                  </div>

                  {enableInputs ? (
                    <h3 className={classes.Subtitle}>
                      Descritivo sobre a política de formas de entrega da sua loja:
                    </h3>
                  ) : null}

                  {enableInputs ? (
                    <ReactQuill
                      theme="snow"
                      value={textAreaValue}
                      readOnly={!enableInputs}
                      onChange={setTextAreaValue}
                      formats={formats}
                    />
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="footer-column">
          <hr style={{ width: "100%" }} />
          {button({
            name: "Salvar alterações",
            funcao: sendData,
            disabled: loading
          })}
        </div>
      </div>
      <CustomAlert
        type={alertType}
        alertText={alertMessage}
        show={showModalAlert}
        close={() => setShowModalAlert(false)}
      />
    </div>
  )
}
