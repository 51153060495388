import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => ({
  banner: {
    display: "flex",
    height: "60px",
    background: "#051626",
    justifyContent: "space-between"
  },

  bannerPro: {
    display: "flex",
    height: "60px",
    background: "#051626",
    justifyContent: "space-between"
  },

  bannerWrapper: {
    marginLeft: "10px"
  },

  imgBanner: {
    display: "flex",
    padding: "5px"
  },

  titleBanner: {
    margin: "5px 0px",
    color: "#fff"
  },

  subtitleBanner: {
    margin: "5px 0px 0px",
    color: "#fff",
    paddingRight: "20px",
    fontSize: "0.9rem"
  }
}))
