import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  CardWrapper: {
    width: "100%",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    padding: "10px 30px",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "5px",
    boxSizing: "border-box",
    border: "2px solid #F3F3F3",
  },

 
}))


