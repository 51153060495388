import ApiRequest from "../index"

const idLojinha = localStorage.getItem("id")

const lojinhaRequest = {
  create: async data => ApiRequest.post(`lojinha/create`, { ...data }),
  put: async data => ApiRequest.put(`lojinha/update/${idLojinha}`, {...data}),
  show: async () => ApiRequest.get(`lojinha/show/${idLojinha}`),
}

export default lojinhaRequest
