export default function Pix(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill={props.fill ? "#a4a4a4" : " #EA3458"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3064 18.9323C18.3444 18.9323 17.4398 18.5578 16.7596 17.8778L13.0822 14.2005C12.8241 13.9416 12.374 13.9424 12.1159 14.2005L8.42511 17.8913C7.74485 18.5711 6.84019 18.9457 5.87822 18.9457H5.15356L9.81099 23.6032C11.2656 25.0577 13.6239 25.0577 15.0786 23.6032L19.7495 18.9323H19.3064Z"
        fill={props.Color}
      />
      <path
        d="M5.87822 5.92512C6.8402 5.92512 7.74486 6.29979 8.425 6.9797L12.1158 10.6711C12.3816 10.9369 12.8157 10.9381 13.0822 10.6708L16.7595 6.99307C17.4398 6.31316 18.3444 5.93861 19.3064 5.93861H19.7494L15.0786 1.26782C13.6239 -0.18679 11.2656 -0.18679 9.81099 1.26782L5.15369 5.92512H5.87822Z"
        fill={props.Color}
      />
      <path
        d="M23.6127 9.80159L20.7901 6.97897C20.728 7.00388 20.6607 7.01945 20.5897 7.01945H19.3064C18.6429 7.01945 17.9934 7.28849 17.5247 7.75759L13.8472 11.4351C13.5032 11.7792 13.0511 11.9514 12.5993 11.9514C12.1471 11.9514 11.6953 11.7792 11.3512 11.4354L7.66001 7.74422C7.19113 7.27511 6.54178 7.00607 5.87824 7.00607H4.30025C4.23302 7.00607 4.17005 6.99016 4.11067 6.96779L1.27687 9.80159C-0.177739 11.2561 -0.177739 13.6144 1.27687 15.0691L4.11055 17.9028C4.16994 17.8805 4.23302 17.8645 4.30025 17.8645H5.87824C6.54178 17.8645 7.19113 17.5955 7.66001 17.1264L11.3508 13.4356C12.018 12.769 13.1808 12.7687 13.8472 13.4359L17.5247 17.1129C17.9934 17.5821 18.6429 17.8512 19.3064 17.8512H20.5897C20.6608 17.8512 20.728 17.8666 20.7902 17.8915L23.6127 15.0691C25.0673 13.6144 25.0673 11.2561 23.6127 9.80159Z"
        fill={props.Color}
      />
    </svg>
  )
}
