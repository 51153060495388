import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField
} from "@material-ui/core"
import axios from "axios"
import React, { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../../../Componentes/Alerts/CustomAlert"
import TopModal from "../../../../Componentes/TopModal"
import { StateGlobal } from "../../../../ContextAPI/index"
import { useQuery } from "../../../../hooks/useQuery"
import { useStyles } from "./style"
import { FiSearch } from "react-icons/fi"

export default function SelecionarEstado() {
  const classes = useStyles()
  const history = useHistory()
  let query = useQuery()

  const {
    setFreeShippingStatesData,
    freeShippingStatesData,
    setFixedShippingStatesData,
    fixedShippingStatesData,
    setPickupShippingStatesData,
    pickupShippingStatesData
  } = StateGlobal()

  const [selectedStates, setSelectedStates] = useState([])
  const [stateIBGE, setStateIBGE] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [alertMsg, setAlertMsg] = useState("Salvo com sucesso")
  const [alertType, setAlertType] = useState("success")
  const [showAlert, setShowAlert] = useState(false)
  const [loading, setloading] = useState(true)

  const searchStates = async States => {
    await axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome`
      )
      .then(res => {
        let newStates = res.data.map(state => ({
          state_name: state.nome,
          UF: state.sigla
        }))
        setStateIBGE(newStates)
      })
    setloading(false)
  }

  const handleChange = (e, item) => {
    let newArr = []
    const stateAlredyExists = selectedStates.some(state => state === item.UF)

    if (stateAlredyExists) {
      newArr = selectedStates.filter(state => state !== item.UF)
    }
    setSelectedStates(oldState =>
      stateAlredyExists ? [...newArr] : [...oldState, item.UF]
    )
  }

  const filteredStates = useMemo(
    () =>
      stateIBGE.filter(state =>
        state.state_name.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [stateIBGE, searchTerm]
  )

  function handleSave() {
    let currentQuery = query.get("from")

    if (currentQuery === "freeShipping") {
      setFreeShippingStatesData(selectedStates)
    } else if (currentQuery === "fixedShipping") {
      setFixedShippingStatesData(selectedStates)
    } else {
      setPickupShippingStatesData(selectedStates)
    }

    setShowAlert(true)
    setAlertType("success")
    setAlertMsg("Salvo com sucesso!")

    setTimeout(() => {
      history.goBack()
    }, 1500)
  }

  useEffect(() => {
    searchStates()

    let currentQuery = query.get("from")

    if (currentQuery === "freeShipping") {
      setSelectedStates(freeShippingStatesData)
    } else if (currentQuery === "fixedShipping") {
      setSelectedStates(fixedShippingStatesData)
    } else {
      setSelectedStates(pickupShippingStatesData)
    }
  }, [])

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <TopModal handleClose={() => history.goBack()} name="Selecionar estado" />
          <div className="infinite-scroll">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  minHeight: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <>
                <TextField
                  name=""
                  onChange={e => setSearchTerm(e.target.value)}
                  value={searchTerm}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  size="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FiSearch />
                      </InputAdornment>
                    )
                  }}
                  style={{ margin: "10px 0px 20px", backgroundColor: "#fff" }}
                />
                <FormGroup>
                  {stateIBGE.length === 0 ? (
                    <p style={{ paddingTop: "20px" }}>Estados não encontrados.</p>
                  ) : (
                    filteredStates.map(states => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedStates.find(el => el === states.UF) !==
                              undefined
                            }
                            onChange={e => handleChange(e, states)}
                            name={states.state_name}
                          />
                        }
                        label={states.state_name}
                        style={{
                          borderBottom: "1px solid #d9d9d9",
                          marginBottom: "10px"
                        }}
                      />
                    ))
                  )}
                </FormGroup>
              </>
            )}
          </div>

          <div className="footer-column">
            <hr style={{ width: "100%", margin: "20px 0" }} />
            <Button
              className={classes.btnFooterPink}
              onClick={() => handleSave()}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Salvar e sair
            </Button>
          </div>
        </div>
      </div>
      {/* ALERT COMPONENT */}
      <CustomAlert
        type={alertType}
        alertText={alertMsg}
        show={showAlert}
        close={() => setShowAlert(false)}
      />
    </>
  )
}
