import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  toggledTitle: {
    display: "flex",
    justifyContent: "space-between"
  },
  divbox: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px"
  },
  switch: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  EtapaCadastroContainer: {
    padding: "2rem 0 1rem"
  },
  FormEndereco: {
    "& div": {
      display: "flex",
      flexDirection: "column",
      margin: "5px 0"
    },
    "& > div": {
      margin: "5px 0"
    },
    "& label": {
      fontSize: "0.8rem",
      padding: "5px 0",
      fontWeight: "400",
      color: "#555555"
    },
    "& input": {
      border: "1px solid #D0D0D0",
      borderRadius: "5px",
      height: "40px",
      "&:focus": {
        borderRadius: 4,
        border: "none",
        outlineColor: "#EA3458cc"
      }
    }
  },
  FormGroup: {
    flexDirection: "row!important",
    justifyContent: "space-between",
    "& .cidadeInput": {
      width: "79%",

    },
    "& .estadoInput": {
      width: "20%"
    },
    "& .numeroInput": {
      width: "25%"
    },
    "& .bairroInput": {
      width: "74%"
    },
    "& .prefixoInput": {
      width: "25%"
    },
    "& .telefoneInput": {
      width: "100%"
    },

  },
  title: {
    margin: "10px 0",
    fontWeight: "500",
    color: "#555555"
  },

  formStyleInput: {
    "& input": {
      border: "1px solid #D0D0D0",
      borderRadius: "5px",
      height: "40px",
      "&:focus": {
        borderRadius: 4,
        border: "none",
        outlineColor: "#EA3458cc"
      }
    }
  }
}))
