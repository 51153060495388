import ApiRequest from "../index"
const requestsFrases = {
    getUserFrases: async () => ApiRequest.get("v2/lojinha/phrases/list"),
    getAllFrases: async () => ApiRequest.get("v2/lojinha/phrases/list/all"),
    updateUserFrase: async (item, phrase_id) => ApiRequest.put("v2/lojinha/phrases/update", {
        item_position: item,
        phrase_id: phrase_id
    })
}
export default requestsFrases
