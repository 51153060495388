import { Checkbox, MenuItem, NativeSelect, Select } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import React, { useEffect, useState } from "react"
import { globalStyles } from "../../../Componentes/CssGlobal"
import Topo from "../../../Componentes/TopModalCrop"
import { useProduct } from "../../../ContextAPI/productContext"
import { useStyles } from "./style"
import FormControl from "@material-ui/core/FormControl"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Slider from "@material-ui/core/Slider"
import InputBase from "@material-ui/core/InputBase"

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(4)
    }
  },
  input: {
    width: "100%",
    borderRadius: 5,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid  #EA3458",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    color: "#EA3458",
    fontWeight: "500",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#EA3458",
      boxShadow: "0 0 0 0.1rem #EA3458cc"
    }
  }
}))(InputBase)

const BootstrapInputFilter = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 5,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #EA3458",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#EA3458",
      boxShadow: "0 0 0 0.1rem #EA3458cc"
    }
  }
}))(InputBase)

export default function FiltrarProdutos(props) {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const {
    categoriesList,
    filterOptions,
    setQuery,
    setFilterOptions,
    categorySelect,
    setCategorySelect
  } = useProduct()

  const [orderBy, setOrderBy] = useState(2)
  const [showBy, setShowBy] = useState(1)
  const [ShowByCategory, setShowByCategory] = useState(0)

  const handleFilterChange = e => {
    const { name, value } = e.target
    if (name === "orderBy") {
      setOrderBy(Number(value))
      filterOptions[0] = Number(value)
    }
    if (name === "showBy") {
      setShowBy(Number(value))
      filterOptions[0] = Number(value)
    }
    if (name === "ShowByCategory") {
      setShowByCategory(Number(value))
      filterOptions[2] = Number(value)
    }
  }

  const handleSavedFilters = () => {
    setOrderBy(filterOptions[0])
    setShowBy(filterOptions[1])
    setShowByCategory(filterOptions[2])
  }
  const handleClear = () => {
    setOrderBy(null)
    setShowBy(1)
    setShowByCategory(0)
    setFilterOptions([1, 1, 0])
    setQuery({})
    props.fechar()
  }

  useEffect(() => {
    handleSavedFilters()
  }, [])

  const filterProducts = () => {
    let last = orderBy === 1 ? 1 : null
    let older = orderBy === 2 ? 1 : null
    let highlight = showBy === 2 ? 1 : null
    let category_id = ShowByCategory !== 0 ? ShowByCategory : null
    /* let min_price = showPrice[0]
    let max_price = showPrice[1] */
    let body = {}
    if (last !== null) body.last = last
    if (older !== null) body.older = older
    if (highlight !== null) body.highlight = highlight
    if (category_id !== null) body.category_id = category_id
    /* if (min_price !== null) body.min_price = min_price
    if (max_price !== null) body.max_price = max_price */
    setQuery(body)
    props.fechar()
  }

  /* const handleChange = (event, newValue) => {
    setShowPrice(newValue)
  } */

  function cleanCategory() {
    setCategorySelect([])
  }

  return (
    <div className="page-container">
      <div
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          marginRight: "20px",
          marginLeft: "20px"
        }}
      >
        <Topo name="Filtro" fechar={props.fechar} />
        <div className="infinite-scroll-filter">
          <div className={classes.filterOption}>
            <p className={classes.filterTitle}>Ordenar por:</p>
            <FormControl className={classes.margin}>
              <Select
                value={orderBy}
                onChange={e => handleFilterChange(e)}
                name="orderBy"
                input={<BootstrapInputFilter />}
              >
                <MenuItem value={1}>Mais recentes</MenuItem>
                <MenuItem value={2}>Mais antigos</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.filterOption}>
            <p className={classes.filterTitle}>Exibição de produtos:</p>

            <FormControl className={classes.margin}>
              <Select
                value={showBy}
                onChange={e => handleFilterChange(e)}
                name="showBy"
                input={<BootstrapInputFilter />}
              >
                <MenuItem value={1}>Todos os produtos</MenuItem>
                <MenuItem value={2}>Produtos em destaques</MenuItem>
                {/* <MenuItem value={3}>Produtos esgotados</MenuItem> */}
              </Select>
            </FormControl>
          </div>

          <div className={classes.filterOptionCategory}>
            <p className={classes.filterTitle}>Exibir por categoria:</p>
            <div style={{ display: "flex", margin: "15px 0" }}>
              <button
                style={{
                  backgroundColor: categorySelect.length ? "#f9f9f9" : "#EA3458",
                  color: categorySelect.length ? "#000" : "#f9f9f9"
                }}
                className={classes.FilterTagsLink}
                onClick={() => cleanCategory()}
              >
                Todos
              </button>
              <div className="FilterTags">
                {categoriesList !== null &&
                  categoriesList.map(category => (
                    <button
                      style={{
                        backgroundColor:
                          categorySelect === category.name ? "#EA3458" : "#f9f9f9",
                        color: categorySelect === category.name ? "#f9f9f9" : "#000"
                      }}
                      key={category.id}
                      className={classes.FilterTagsLink}
                      onClick={() => {
                        setShowByCategory(category.id)
                        setCategorySelect(category.name)
                      }}
                    >
                      {category.name}
                    </button>
                  ))}
              </div>
            </div>
          </div>

          {/* <p className={classes.filterTitle}>Preço:</p>

          <div className={classes.filterLabel}>
            <Slider
              defaultValue={5 / 100}
              min={0}
              max={10000}
              step={10}
              value={showPrice}
              color="secondary"
              onChange={handleChange}
              valueLabelDisplay="off"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "0.9rem",
                fontWeight: 600
              }}
            >
              <div>
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(showPrice[0])}
              </div>
              <div>
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(showPrice[1])}
              </div>
            </div>
          </div> */}
        </div>
        <div className={classes.footerFilter}>
          <hr style={{ width: "100%", margin: "0px" }} />
          <div className={classes.btnFilter}>
            <Button
              color="secondary"
              variant="contained"
              className={classes.btnFiltrar}
              onClick={() => filterProducts()}
            >
              Filtrar
            </Button>

            <Button className={classes.btnLimpar} onClick={() => handleClear()}>
              Limpar filtros
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
