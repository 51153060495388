import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  estoqueTitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "10px"
  },
  cardEstoqueVariacao: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #CCC"
  }
}))
