import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles({
    EmptyProducts: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        opacity: "0.7",
        marginTop: "11rem",
        "& p": {
            fontSize: "1rem",
            color: "#8b8b8b",
        },
        "& svg ": {
            color: "#8b8b8b",
            fontSize: "3rem",
        }
    }
})