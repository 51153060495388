import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  ContainerForms: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem"
  },
  InputBox: {
    "& input": {
      width: " 100%",
      border: "1px solid #D0D0D0",
      padding: "0.5rem 0.3rem",
      boxSizing: "border-box",
      borderRadius: "5px",
      margin: 0
    }
  },
  InputLabel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}))
