import "./styles.css"

import React, { useState } from "react"
import { CartesianGrid, YAxis, XAxis, LineChart, Line } from "recharts"

export default function CardVendas(props) {
  const [datacharts, setDataCharts] = useState(props.charts)

  return (
    <div className="body">
      <div className="box">
        <div className="titleCard"> Acompanhamento de vendas</div>
        <div className="boxCard">
          <div>
            <div className="Card1">
              <div className="">
                <LineChart
                  width={300}
                  height={200}
                  data={datacharts}
                  margin={{ top: 0, right: 20, left: 0, bottom: 0 }}
                >
                  <XAxis dataKey="day" />
                  <YAxis />
                  <CartesianGrid
                    stroke="#E4E4E4"
                    strokeDasharray="8"
                    vertical={false}
                  />

                  <Line
                    type="monotone"
                    dataKey="total_price"
                    stroke="#FDBED3"
                    fill="#E4E4E4"
                  />
                  <Line
                    type="monotone"
                    dataKey="total_sales"
                    stroke="#EA3458"
                    fill="#E4E4E4"
                  />
                </LineChart>
              </div>
            </div>
          </div>
        </div>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          Dias
        </p>
        <div className="legendCard">
          <div>
            <div
              style={{ backgroundColor: "#EA3458" }}
              className="legendCard2"
            ></div>
            Vendas por unidade
          </div>
          <div>
            <div
              style={{ backgroundColor: "#FDBED3" }}
              className="legendCard2"
            ></div>
            Vendas por R$
          </div>
        </div>
      </div>
    </div>
  )
}
