import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },

  large: {
    width: theme.spacing(8.35),
    height: theme.spacing(8.35)
  },

  headerContainer: {
    marginLeft: "20px",
    marginTop: "-30px"
  },

  headerContent: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px"
  },

  coroinha: {
    width: "75px",
    maxHeight: "90px !important",
    display: "block",
    zIndex: "2",
    marginRight: "-73px",
    marginTop: "-18px"
  },

  avatarContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "& span": {
      fontSize: "10px",
      fontWeight: "700",
      padding: "0.1px .8px 2px 1.8px",
      borderRadius: "11px",
      width: "40px",
      color: "#FFFFFF",
      background: "#EA3458",
      marginTop: "-13px",
      marginLeft: "-2px",
      textAlign: "center",
      zIndex: "2",
      lineHeight: "13px"
    }
  },

  headerInfos: {
    marginLeft: "18px"
  },

  headerName: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "0px",
    display: "flex",
    flexWrap: "wrap"
  },

  headerSite: {
    fontWeight: "400",
    fontSize: "14px",
    display: "flex",
    flexWrap: "wrap"
  },

  headerButtons: {
    marginTop: "22px",
    display: "flex",
    justifyContent: "left",
    alignItems: "center"
  },

  button: {
    background: "transparent",
    border: "2px solid #EA3458",
    borderRadius: "5px",
    // padding: '4px 20px',
    width: "140px",
    height: "3em",

    "& span": {
      fontWeight: "600",
      fontSize: "1.0em",
      color: "#EA3458",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center;"
    }
  },

  headerMedias: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    marginTop: "1.3rem",

    "& span": {
      fontWeight: "400",
      fontSize: "0.9em"
    }
  }
}))
