import React, { useEffect, useState } from "react"
import { useStyles } from "./style"
import FotosContainer from "./DragAndDropFotos/FotosContainer"

export default function VariacaoFotos() {
  const classes = useStyles()

  return (
    <>
      <p style={{ fontWeight: 500, color: "#555555" }}>
        Adicione até 5 fotos da variação do produto:
      </p>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "2fr",
          justifyItems: "center",
          marginBottom: "30px"
        }}
      >
        <div className={classes.positionFotos}>
          <FotosContainer />
        </div>
      </div>
    </>
  )
}
