import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles({
  positionImg: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px"
  },

  createImg: {
    width: "162px",
    height: "162px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#eaeaea",
    borderRadius: "50%",
    border: "dashed 2px",
    borderColor: "#c1c1c1"
  },
  previewImg: {
    width: "162px",
    height: "162px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#EA3458",
    borderRadius: "50%",
    border: "1px solid #EA3458"
  },
  positionLogo: {
    display: "flex",
    alignItems: "center"
  },

  defaultLogo: {
    width: "131px",
    height: "90px"
  },

  positionBtnAddLogo: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px"
  },

  input: {
    display: "none"
  },

  btnAddLogo: {
    width: "171px",
    height: "40px",
    backgroundColor: "#EA3458",
    color: "#fff",
    borderRadius: "5px",

    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "11px",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#ff5089c9"
    }
  },

  positionTextDescription: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "center"
  },

  textDescription: {
    textAlign: "center",
    fontSize: "0.8em",
    fontWeight: "400",
    color: "#737373"
  },
  progress: {
    display: "flex",
    height: "166px",
    justifyContent: "center",
    alignItems: "center"
  }
})
