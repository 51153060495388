import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  pedido: {
    marginTop: "5px",
    paddingBottom: "10px",
    borderBottom: "solid 1px #d5d5d5"
  },
  noVisitors: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "20px"
  },
  headerPedido: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "5%",
    paddingRight: "5%"
  },

  idPedido: {
    fontSize: "14px",
    fontWeight: "500"
  },

  dataPedido: {
    color: "#555555",
    fontSize: "12px",
    fontWeight: "400"
  },

  namePedido: {
    margin: 0,
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingBottom: "5px",
    paddingTop: "5px",

    fontWeight: "bold"
  },

  money: {
    fontWeight: "bold",
    fontSize: "14px"
  },

  estado: {
    fontWeight: "500",
    fontSize: "14px",
    marginLeft: "15px",
    paddingLeft: "5px",
    paddingRight: "5px",
    color: "#22543D",
    backgroundColor: "#C6F6D5",
    borderRadius: "5px"
  },

  footerPedido: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "5%",
    paddingRight: "5%"
  },

  Loading: {
    display: "flex",
    height: "500px",
    justifyContent: "center",
    alignItems: "center"
  },
  MoreBtn: {
    backgroundColor: "transparent",
    border: "none",
    color: " #EA3458",
    display: "flex",
    textAlign: "center",
    margin: "10px auto",
    fontSize: "1.2rem",
    padding: "5px 10px",
    borderRadius: "5px"
  },
  InfiniteScrollContainer: {
    padding: "5px 0"
  }
}))
