import React, { useEffect, useState, useRef } from "react"
import { useHistory } from "react-router-dom"

import { Button, TextField } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { FiSearch } from "react-icons/fi"
import CustomAlert from "../../../../Componentes/Alerts/CustomAlert"

import TopModal from "../../../../Componentes/TopModal"
import { StateGlobal } from "../../../../ContextAPI/index"
import { useQuery } from "../../../../hooks/useQuery"
import api from "../../../../Services/api"

import { useStyles } from "./style"
import xIcon from "../../../../Assets/svg/x-icon.svg"

export default function SelecionarCidade() {
  const classes = useStyles()
  const history = useHistory()
  let query = useQuery()

  const {
    freeShippingCitiesData,
    setFreeShippingCitiesData,

    fixedShippingCitiesData,
    setFixedShippingCitiesData,

    pickupShippingCitiesData,
    setPickupShippingCitiesData,

    setHasContext
  } = StateGlobal()

  const [countryStates, setCountryStates] = useState([])
  const [currentState, setCurrentState] = useState("")
  const [stateCities, setStateCities] = useState([])
  const [hasStateCities, setHasStateCities] = useState(false)
  const [selectedCities, setSelectedCities] = useState([])
  const [statesUf, setStatesUf] = useState([])
  const [alertMsg, setAlertMsg] = useState("Salvo com sucesso")
  const [alertType, setAlertType] = useState("success")
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    let currentQuery = query.get("from")

    if (currentQuery === "freeShipping") {
      freeShippingCitiesData.forEach(item => {
        setSelectedCities(prevState => ({
          ...prevState,
          [item.state]: item.cities
        }))
      })
    } else if (currentQuery === "fixedShipping") {
      fixedShippingCitiesData.forEach(item => {
        setSelectedCities(prevState => ({
          ...prevState,
          [item.state]: item.cities
        }))
      })
    } else {
      pickupShippingCitiesData.forEach(item => {
        setSelectedCities(prevState => ({
          ...prevState,
          [item.state]: item.cities
        }))
      })
    }

    fetchCountryStates()
  }, [])

  async function fetchCountryStates() {
    const { data } = await api.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome`
    )
    setCountryStates(data)
  }

  async function getCountryStatesByUf(uf) {
    try {
      const { data } = await api.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`
      )

      let parsedData = data.map(item => ({
        id: item.id,
        cityName: item.nome,
        stateName: item.microrregiao.mesorregiao.UF.nome,
        UF: item.microrregiao.mesorregiao.UF.sigla
      }))

      setStateCities(parsedData)
    } finally {
      setHasStateCities(true)
    }
  }

  function handleStateSelect(e) {
    let splitedValue = e.target.value.split("-")

    let state = splitedValue[0]
    let uf = splitedValue[1]

    setCurrentState(e.target.value)
    getCountryStatesByUf(uf)
    setStatesUf(prevState => [...prevState, { state, uf }])
  }

  function handleCitySelect(cityValue) {
    if (!cityValue) return

    setSelectedCities(prevState => ({
      ...prevState,
      [cityValue.UF]: [...(prevState[cityValue.UF] ?? []), cityValue.cityName]
    }))
  }

  function handleRemoveCity(stateName, cityIndex){
    selectedCities[stateName].splice(cityIndex, 1)

    if (!selectedCities[stateName].length) {
      delete selectedCities[stateName]
    }

    setSelectedCities({ ...selectedCities })
  }

  function handleSaveButton() {
    let arr = []
    let currentQuery = query.get("from")

    Object.keys(selectedCities).forEach(uf => {
      arr.push({
        state: statesUf.find(stateUf => stateUf.uf === uf)?.uf || uf,
        cities: selectedCities[uf]
      })
    })

    if(currentQuery === 'freeShipping'){
     setFreeShippingCitiesData(arr)
    }else if(currentQuery === 'fixedShipping'){
     setFixedShippingCitiesData(arr)
    }else{
      setPickupShippingCitiesData(arr)
    }

    setShowAlert(true)
    setAlertType("success")
    setAlertMsg("Salvo com sucesso!")
    setHasContext(true)

    setTimeout(() => {
      history.goBack()
    }, 1500)
  }

  function getStateName(uf) {
    let state = countryStates.find(countryState => countryState.sigla === uf)
    if (state) return state.nome
    return ""
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <TopModal handleClose={() => history.goBack()} name="Selecionar cidade" />

          <div className={`${classes.cities}  infinite-scroll`}>
            <TextField
              variant="outlined"
              fullWidth
              select
              value={currentState}
              onChange={e => handleStateSelect(e)}
              SelectProps={{ native: true }}
            >
              <option value="" selected disabled>
                Selecione o estado
              </option>

              {countryStates.map(state => (
                <option key={state.id} value={`${state.nome}-${state.sigla}`}>
                  {state.nome}
                </option>
              ))}
            </TextField>

            <Autocomplete
              id="tags-outlined"
              options={stateCities}
              getOptionLabel={option => option.cityName}
              getOptionSelected={(option, value) =>
                option.cityName === value.cityName
              }
              noOptionsText="Cidade não encontrada"
              popupIcon={<FiSearch />}
              disabled={!hasStateCities}
              onChange={(e, value) => handleCitySelect(value)}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Pesquisar cidade"
                />
              )}
            />

            <div className={`${classes.cities}__selectedCities`}>
              <h2>Cidades selecionadas:</h2>

              {selectedCities
                ? Object.entries(selectedCities).map((state, stateIndex) => (
                    <>
                      <div>
                        <span>Estado de {getStateName(state[0])}</span>

                        {state[1].map((city, cityIndex) => (
                          <p>
                            {city}
                            <img
                              aria-hidden
                              src={xIcon}
                              alt="x-icon"
                              onClick={() => handleRemoveCity(state[0], cityIndex)}
                            />
                          </p>
                        ))}
                        <hr />
                      </div>
                    </>
                  ))
                : ""}
            </div>
          </div>

          <div className="footer-column">
            <hr style={{ width: "100%", margin: "20px 0" }} />
            <Button
              className={classes.btnFooterPink}
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => handleSaveButton()}
            >
              Salvar e sair
            </Button>
          </div>
        </div>
      </div>

      <CustomAlert
        type={alertType}
        alertText={alertMsg}
        show={showAlert}
        close={() => setShowAlert(false)}
      />
    </>
  )
}
