import React, { useEffect, useState } from 'react'

export function InputCustom({
  style,
  type,
  name,
  value,
  callback,
  submit,
  maxLength,
  onFocus,
  noLabel
}) {
  const [isEmpty, setIsEmpty] = useState(false)

  const checkInput = e => {
    if (submit === true) {
      if (value) setIsEmpty(false)
      else {
        setIsEmpty(true)
        setTimeout(() => setIsEmpty(false), 2500)
      }
    } else {
      if (e.target.value === '') setIsEmpty(false)
      else setIsEmpty(true)
      callback(e)
    }
  }

  useEffect(() => {
    if (submit === true) checkInput()
  }, [submit])

  return (
    <>
      <input
        style={style}
        type={type}
        id={name}
        name={name}
        value={value}
        onFocus={e => (onFocus ? onFocus(e) : null)}
        onChange={e => checkInput(e)}
        /* max length only works on type tel */
        maxLength={maxLength}
        placeholder={
          maxLength && noLabel !== true ? `Limite de ${maxLength} caracteres` : ''
        }
      />
    </>
  )
}
