export default function Combinar(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      version="1"
      viewBox="0 0 512 512"
      fill={props.fill ? "#a4a4a4" : " #EA3458"}
      stroke={props.fill ? "#a4a4a4" : " #EA3458"}
    >
      <path
        d="M1045 4180c-91-45-182-151-555-644-242-321-245-421-21-605 86-70 105-81 115-70 7 8 208 267 445 577 359 468 430 566 420 578-25 30-175 138-224 160-62 29-127 31-180 4zM3922 4182c-62-25-244-161-238-178 9-23 861-1154 870-1154 15 0 182 138 208 172 49 65 60 97 56 163-6 97-54 178-289 495-339 457-392 511-507 517-38 2-72-3-100-15zM2110 3955c-118-14-291-27-383-29l-169-5-443-577-444-577 41-47 41-48 38 28c119 86 306 58 413-61 60-67 89-131 94-205l4-62 72-4c55-4 85-12 125-33 107-59 191-185 191-289v-43l65-6c123-12 236-92 293-207 23-48 27-67 27-147 0-69 3-93 13-93 27 0 109-50 149-92 92-95 119-227 69-335l-24-51 83-66c45-36 95-70 111-76 96-37 224 70 224 188 0 41-2 43-107 128-60 47-112 93-116 101-14 23 1 61 29 80 34 22 42 17 201-110 65-52 132-98 148-101 39-8 116 15 161 49 73 55 108 190 69 265-8 16-72 75-141 131-138 111-156 134-131 173 23 34 52 41 85 20 15-10 95-72 177-138 83-66 163-126 178-134 43-22 127-11 176 21 60 40 101 117 101 190 0 32-7 71-15 87-9 17-94 93-191 171-96 77-180 151-186 164-21 47 37 99 83 74 13-6 133-100 267-207l245-196h51c83 0 160 51 201 134 24 46 24 135 2 178-24 44-1090 897-1172 938-53 26-70 29-145 28-47-1-165-14-262-28-260-38-354-33-473 24-68 33-150 113-181 177-50 101-31 225 42 282 32 26 101 46 207 61 54 8 171 46 388 126 170 63 319 114 329 114 37 0 20 19-32 35-89 28-323 28-578 0z"
        transform="matrix(.1 0 0 -.1 0 512)"
        stroke={props.Color}
        fill={props.Color}
        strokeWidth="2"
        strokeLinejoin="round"
      ></path>
      <path
        d="M3493 3905c-83-32-199-51-438-70-110-9-232-23-270-31-39-7-205-64-370-125-232-85-322-114-395-125-52-8-110-21-127-29-63-28-57-111 13-185 75-80 196-117 326-100 35 4 151 20 258 35 162 22 205 24 255 16 117-20 179-63 704-483 275-219 504-398 511-398 23 0 212 100 286 152 94 64 217 185 206 202-32 55-878 1166-886 1165-6 0-39-11-73-24zM894 2621c-27-12-244-243-266-284-36-64-16-161 45-225 49-52 97-72 169-72h63l117 123c119 124 158 181 158 231 0 79-57 169-132 207-45 23-123 33-154 20zM1292 2239c-21-6-62-40-116-97-167-175-176-189-176-265 1-81 64-169 146-203 41-17 121-18 151-1 11 7 73 68 137 137 85 91 119 136 127 164 29 105-39 221-152 261-47 17-69 17-117 4zM1543 1743c-67-71-129-142-138-160-73-151 104-348 260-289 56 22 266 243 285 300 42 128-81 276-229 276h-56l-122-127zM1896 1328c-107-113-107-113-103-157 9-90 102-171 196-171 45 0 49 3 128 84 62 64 84 94 89 121 18 96-60 194-182 229-18 5-40-13-128-106z"
        transform="matrix(.1 0 0 -.1 0 512)"
        stroke={props.Color}
        fill={props.Color}
        strokeWidth="2"
        strokeLinejoin="round"
      ></path>
    </svg>
  )
}
