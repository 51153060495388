import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core"
import React from "react"

import { globalStyles } from "../CssGlobal"
import { button } from "../Inputs/TextFild"
import useStyles from "./style"
import CloseIcon from "@material-ui/icons/Close"

import usuarioFree from "../../Assets/img/usuario-free.png"
import { useHistory } from "react-router-dom"
import { SimpleImg } from "react-simple-img"
import { createLeedPro } from "../../utils"

export default function IntegrationAlertModal({ isOpen, handleClose, bannerId }) {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const history = useHistory()



  return (
    <div className="page-container">
      <div className="page-align">
        <div className="infinite-scroll">
          <div aria-hidden="true">
            <Dialog
              open={isOpen}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              className={classes.PlanosAlertaModal}
            >
              <DialogTitle id="responsive-dialog-title">
                <div className={classes.PlanosAlertaModalHeader}>
                  <h2>Oops...</h2>
                  <button type="button" onClick={handleClose}>
                    <CloseIcon
                      style={{
                        fontSize: "35px",
                        color: "#EA3458",
                        cursor: "pointer",
                        minWidth: "30px"
                      }}
                    />
                  </button>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText className={classes.PlanosAlertaModalBody}>
                  <SimpleImg
                    width="auto"
                    height="auto"
                    placeholder={false}
                    src={usuarioFree}
                    alt="usuario-free"
                  />
                  <p>Essa opção de entrega não está dispoinivel para você</p>
                  <p>
                    Para utilizar essa opção vamos ativar seu checkout com <span> CARTÃO DE CRÉDITO</span>
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {button({
                  name: `Quero conhecer a integração!`,
                  funcao: () => history.push("/menu-meios-de-pagamento")
                })}
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  )
}
