export default function ValeRefeicao(props) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill={props.fill ? "#a4a4a4" : " #EA3458"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75 3.625C5.375 3.625 5.125 4 5.125 4.25V7.375H3.875V4.25C3.875 3.875 3.625 3.625 3.25 3.625C2.875 3.625 2.625 4 2.625 4.25V7.375H1.375V4.25C1.375 3.875 1.125 3.625 0.75 3.625C0.375 3.625 0.125 4 0.125 4.25V8.375C0.125 9.5 1 10.375 2 10.5V19.25C2 20 2.5 20.5 3.25 20.5C4 20.5 4.5 20 4.5 19.25V10.5C5.5 10.375 6.375 9.5 6.375 8.375V4.25C6.375 4 6.125 3.625 5.75 3.625ZM8.25 4.25V11.75H9.5V19.25C9.5 20 10 20.5 10.75 20.5C11.5 20.5 12 20 12 19.25V0.5C9.875 0.5 8.25 2.125 8.25 4.25ZM17 3C15.25 3 13.875 4.875 13.875 7.125C13.75 8.625 14.5 10 15.75 10.875V19.25C15.75 20 16.25 20.5 17 20.5C17.75 20.5 18.25 20 18.25 19.25V10.875C19.5 10 20.25 8.625 20.125 7.125C20.125 4.875 18.75 3 17 3Z"
        fill={props.Color}
      />
    </svg>
  )
}
