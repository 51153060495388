import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles({
  EtapasTop: {
    paddingBottom: "1rem"
  },
  EtapaContainer: {
    height: "100%",
    minHeight: "100%",
    "& .EtapaHeader": {
      margin: "1rem 0 2rem",
      "& h2, & h3": {
        margin: 0,
        textAlign: "center"
      },
      "& h2": {
        fontSize: "1.5rem"
      },
      "& h3": {
        fontSize: "1rem"
      }
    }
  },
  top: {
    display: "flex",
    minWidth: "100%",
    justifyContent: "center",
    height: "50px",
    alignItems: "center",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
  dots: {
    display: "flex",
    justifyContent: "center",
    height: "50px",
    alignItems: "center",
    flexDirection: "column"
  }
})
export default useStyles
