import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Skeleton from "@material-ui/lab/Skeleton"
import React from "react"
import { SimpleImg } from "react-simple-img"
import totalPedidos from "../../../../Assets/Home/totalPedidos.svg"
import totalVendas from "../../../../Assets/Home/totalVendas.svg"
import visitasLojinha from "../../../../Assets/Home/visitasLojinha.svg"
import { useStyles } from "./style"

export default function LojinhaInformations({
  loading,
  shopInformations,
  sales,
  totalOrders
}) {
  const classes = useStyles()

  return (
    <div>
      {loading ? (
        <Grid container className={classes.informationsContainer} spacing={1}>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Skeleton variant="circle" width={40} height={40} />
              <Skeleton width="68%" height="10%" style={{ marginTop: "10px" }} />
              <Skeleton width="68%" height="10%" />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Skeleton variant="circle" width={40} height={40} />
              <Skeleton width="68%" height="10%" style={{ marginTop: "10px" }} />
              <Skeleton width="68%" height="10%" />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Skeleton variant="circle" width={40} height={40} />
              <Skeleton width="68%" height="10%" style={{ marginTop: "10px" }} />
              <Skeleton width="68%" height="10%" />
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.informationsContainer} spacing={1}>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <img src={totalPedidos} alt="Total Pedidos" />
              <span>{totalOrders}</span>
              <small>Total de Pedidos</small>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <SimpleImg
                width="auto"
                height="auto"
                placeholder={false}
                src={totalVendas}
                alt="Total Vendas"
              />
              <span style={{ fontSize: "14px" }}>
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(sales)}
              </span>
              <small>Total em R$ de vendas</small>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <SimpleImg
                width="auto"
                height="auto"
                placeholder={false}
                src={visitasLojinha}
                alt="Visitas Lojinha"
              />
              <span>{shopInformations}</span>
              <small>Visitas em sua loja</small>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  )
}
