import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles({
  inputContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    margin: "1rem 0"
  },
  inputEtapaCategoria: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    "& label": {
      width: "max-content",
      color: "#555555",
      fontSize: "0.8rem"
    },
    "& input": {
      height: "40px",
      boxSizing: "border-box",
      borderRadius: "5px",
      border: "2px solid #f2f2f2",
      fontSize: "1rem",
      "&:focus": {
        borderRadius: 4,
        border: "none",
        outlineColor: "#EA3458cc"
      }
    }
  },
  inputEtapaCategoriaIcon: {
    display: "flex",
    alignItems: "center",
    "& button": {
      padding: "15px 0  0 15px",
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer"
    }
  },
  CardWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    margin: "5px 0px",
    padding: "0px 10px",
    backgroundColor: "#fff",
    alignItems: "center",
    borderRadius: "5px",
    boxSizing: "border-box",
    border: "2px solid #F3F3F3"
  }
})
