import { useStyles } from "./style"
import Topo from "../../Componentes/TopCategorias"
import Switch from "@material-ui/core/Switch"
import { button } from "../../Componentes/Inputs/TextFild"
import { useEffect, useState } from "react"
import treatmentRequest from "../../Services/api/treatment"
import { CircularProgress } from "@material-ui/core"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import { validateEmail } from "../../Componentes/validates/Validate"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export default function Atendimento() {
  const classes = useStyles()

  const [alertType, setAlertType] = useState("")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [loading, setLoading] = useState(true)

  const [bodyTreatment, setBodyTreatment] = useState({
    complements_treatment: "",
    complements_treatment_status: false
  })
  const history = useHistory()
  const handleRedirect = () => {
    history.replace("/personalizar-lojinha/complementos")
    history.goBack()
  }
  async function handleSaveChanges() {
    if (!bodyTreatment.complements_treatment) {
      setAlertType("error")
      setAlertMessage("Preencha o email")
      setShowModalAlert(true)
      return
    }
    const isInvalidEmail = validateEmail(bodyTreatment.complements_treatment)
    if (isInvalidEmail) {
      setAlertType("error")
      setAlertMessage("Email inválido")
      setShowModalAlert(true)
      return
    }
    setLoading(true)
    try {
      const { data } = await treatmentRequest.putTreatment(bodyTreatment)
      setAlertType("success")
      setAlertMessage("Email salvo com sucesso!")
      setShowModalAlert(true)
      setTimeout(() => {
        handleRedirect()
      }, 1000)
    } catch (error) {
      console.error(error)
      setAlertType("error")
      setAlertMessage("Erro ao salvar email!")
      setShowModalAlert(true)
    } finally {
      setLoading(false)
    }
  }

  async function getData() {
    setLoading(true)
    try {
      const { data } = await treatmentRequest.getTreatments()
      setBodyTreatment({
        complements_treatment_status: data.data.complements_treatment_status,
        complements_treatment: data.data.complements_treatment
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <div>
            <Topo name="Atendimento" />
            {loading ? (
              <div
                style={{
                  backgroundColor: "#f9f9f9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%"
                }}
              >
                <CircularProgress color="secondary" size="40px" />
              </div>
            ) : (
              <>
                <div className={classes.InputBox}>
                  <div className={classes.InputLabel}>
                    <label>Email</label>
                    <Switch
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onClick={() => {
                        setBodyTreatment({
                          ...bodyTreatment,
                          complements_treatment_status:
                            !bodyTreatment.complements_treatment_status
                        })
                      }}
                      checked={bodyTreatment.complements_treatment_status}
                    />
                  </div>
                  <input
                    value={bodyTreatment.complements_treatment}
                    onChange={e => {
                      setBodyTreatment({
                        ...bodyTreatment,
                        complements_treatment: e.target.value.toLowerCase()
                      })
                    }}
                    disabled={!bodyTreatment.complements_treatment_status}
                  />
                  <p>
                    Cadastre seu email para receber o formulário de atendimento que
                    seu cliente irá preencher.
                  </p>
                </div>
              </>
            )}
          </div>

          <div className="footer-column">
            <hr style={{ width: "100%" }} />
            {button({
              name: "Salvar alterações",
              funcao: () => handleSaveChanges(),
              disabled: loading
            })}
          </div>
        </div>
      </div>
      <CustomAlert
        type={alertType}
        alertText={alertMessage}
        show={showModalAlert}
        close={() => setShowModalAlert(false)}
      />
    </>
  )
}
