import { CircularProgress } from "@material-ui/core"
import { useCallback, useEffect, useState } from "react"
import { useProduct } from "../../../../../../ContextAPI/productContext"
import api from "../../../../../../Services/api"
import CardProduct from "../ProductCard"
export default function ProductList({ relatedProducts, setRelatedProducts}) {
  const style = {
    height: "max-content",
    width: "100%",
    margin: "0 0 20px 0"
  }
  const [loading, setLoading] = useState(false)


  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = relatedProducts[dragIndex]

      setRelatedProducts(prevList => {
        const newList = [...prevList]
        newList.splice(dragIndex, 1)

        hoverIndex = Math.min(hoverIndex, newList.length)

        newList.splice(hoverIndex, 0, dragCard)
        return newList
      })
    },
    [relatedProducts]
  )
  function handleDelete(id) {
    setRelatedProducts(oldState =>
      oldState.filter(item => (item.id ? item.id : item.product_id !== id))
    )

  }

  return (
    <div style={style}>
      {loading ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            minHeight: "100%",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "40px"
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        relatedProducts.map((card, index) => (
          <div key={card.product_id}>
            <CardProduct
              index={index}
              id={card.product_id}
              text={card.product_title}
              image={card.product_image}
              relatedProducts={relatedProducts}
              handleDelete={handleDelete}
              // product_position={card.product_position}
              moveCard={moveCard}
            />
          </div>
        ))
      )}
    </div>
  )
}
