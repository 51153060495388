import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles({
  inputDescricaoContainer: {
    margin: "10px 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    fontSize: "1.4em"
  },
  inputDescricao: {
    resize: "none",
    height: "100px",
    borderRadius: "5px",
    border: "1px solid #D0D0D0",
    fontSize: "0.9em",
    "&:focus": {
      borderRadius: 4,
      border: "none",
      outlineColor: "#EA3458cc"
    }
  },
  inputLabel: {
    color: "#555555!important"
  },
  inputUrlContainer: {
    margin: "10px 0",
    display: "flex",
    width: "100%",
    alignItems: "flex-end",
    "& div": {
      display: "flex",
      flexDirection: "column",
      width: "50%"
    },
    "& input": {
      border: "1px solid #D0D0D0",
      borderRadius: "5px",
      height: "35px",
      boxSizing: "border-box",
      fontSize: "1.1rem"
    },
    "& span": {
      margin: "0 2px",
      fontSize: "1.2rem"
    }
  }
})
