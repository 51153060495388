import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  PlanosAlertaModal: {
    "& .MuiDialogTitle-root": {
      padding: "0px 10px"
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "480px"
    }
  },
  PlanosAlertaModalHeader: {
    display: "flex",
    "& h2": {
      fontSize: "2rem",
      fontWeight: "700",
      flex: 1,
      textAlign: "center",
      paddingLeft: "36px"
    },
    "& button": {
      border: "none",
      fontSize: "2rem",
      fontFamily: "cursive",
      cursor: "pointer",
      backgroundColor: "transparent",
      color: "#EA3458",
      fontWeight: 900
    }
  },
  PlanosAlertaModalBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      fontSize: "1rem",
      fontWeight: "300",
      textAlign: "center"
    },
    "& span": {
      color: "#EA3458",
      fontWeight: "600"
    },
    "& img": {
      width: "100%",
      maxWidth: "170px",
      margin: "10px 0"
    }
  }
})
export default useStyles
