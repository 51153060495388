import { makeStyles } from "@material-ui/core/styles"

export default makeStyles(() => ({
  logo: {
    display: "flex",
    marginTop: "-45px",
    justifyContent: "center",
    flexDirection: "column"
  },

  btnAddLogo: {
    color: "#fff",
    minWidth: "100%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "5px",
    marginBottom: "15px",
    textTransform: "none",
    backgroundColor: "#EA3458",

    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#ff0053c9"
    }
  },
  positionImg: {
    display: "flex",
    justifyContent: "center",
    marginTop: "50px"
  },

  creatImg: {
    width: "162px",
    height: "162px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#eaeaea",
    borderRadius: "50%",
    border: "dashed 2px",
    borderColor: "#c1c1c1"
  },
  previewImg: {
    width: "162px",
    height: "162px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#EA3458",
    borderRadius: "50%",
    border: "1px solid #EA3458"
  },
  positionLogo: {
    display: "flex",
    alignItems: "center"
  },

  defaultLogo: {
    width: "131px",
    height: "90px"
  },

  positionBtnAddLogo: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px"
  },

  input: {
    display: "none"
  },
  positionTextDescription: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "center"
  },

  textDescription: {
    textAlign: "center",
    fontSize: "0.8em",
    fontWeight: "400",
    color: "#737373"
  },

  progress: {
    display: "flex",
    height: "166px",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px"
  }
}))
