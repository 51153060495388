import { makeStyles } from "@material-ui/core/styles"
// import { button } from "../../Componentes/Inputs/TextFild"

export const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    backgroundColor: "#0D273D",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    border: "1px solid #0D273D",
    height: "30px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 10px",

    "& h3": {
      fontSize: "0.8rem",
      color: "#FFFFFF",
      fontWeight: "400"
    },

    "& span": {
      fontWeight: "bold",
      color: "#FFFFFF"
    }
  },

  logoContainer: {
    display: "flex",
    alignItems: "flex-end"
  },

  logo: {
    marginRight: "10px"
  },

  containerContent: {
    height: "70vh",
    border: "1px solid #0D273D",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    padding: "1rem",
    alignItems: "center",
    textAlign: "center"
  },

  title: {
    "& h3": {
      fontSize: "1.2rem",
      fontWeight: "bold",
      color: "#333333",
      marginBottom: "1rem"
    },

    "& h4": {
      fontWeight: "500",
      color: "#8D8D8D",
      marignTop: "0px"
    }
  },

  code: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px dashed #C0C0C0",
    borderRadius: "10px",

    "& h3": {
      margin: "0.6rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "90%"
    }
  },

  dateLimit: {
    fontSize: "0.8rem",
    marginTop: "5px",
    color: "#BABABA",
    marginBottom: "2rem"
  },

  Button: {},

  shareButton: {
    color: "#6DC89E",
    fontSize: "0.8rem"
  }
}))
