import "./style.css"

export default function Wave() {
  return (
    <div className="custom-shape-divider-top-1629206513">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 165"
        width="320"
        height="166"
        preserveAspectRatio="none"
      >
        <path
          d="M0 0H320C320 0 320 52 320 136C275.5 88.5 113 222.5 0 136C4.76837e-06 90.5 0 0 0 0Z"
          fill="url(#paint0_radial)"
        />
        <defs>
          <radialGradient
            id="paint0_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(190 83) rotate(56.595) scale(362.362 699.27)"
          >
            <stop stopColor="#EA3458" />
            <stop offset="1" stopColor="#EA3458" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  )
}
