import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { useState } from "react"
import { useStyles } from "./style"

export default function LabelStories({
  index,
  isStory,
  onDelete,
  categoryName,
  onSelectCategory,
  onEdit
}) {
  const classes = useStyles()
  const [showOptions, setShowOptions] = useState(false)

  const handleItemClick = () => {
    if (isStory) {
      setShowOptions(!showOptions)
    } else {
      onSelectCategory(index)
    }
  }

  const handleEditClick = () => {
    onEdit(index)
    setShowOptions(false)
  }

  const handleDeleteClick = () => {
    onDelete(index)
    setShowOptions(false)
  }

  return (
    <div
      className={classes.LabelItem}
      onClick={handleItemClick}
      style={{ cursor: "pointer" }}
    >
      <p>{isStory ? `Story ${index + 1} (${categoryName})` : `${categoryName}`}</p>
      {isStory ? <MoreVertIcon /> : <ArrowForwardIosIcon />}
      {showOptions && (
        <div className={`${classes.MoreOptions} ${showOptions ? "active" : ""}`}>
          <p onClick={handleEditClick}>Editar</p>
          <p onClick={handleDeleteClick}>Excluir</p>
        </div>
      )}
    </div>
  )
}
