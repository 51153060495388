import React, { useEffect } from "react"
import { useStyles } from "./style"
import { IoIosArrowBack } from "react-icons/io"
import { useHistory } from "react-router-dom"

export default function Categoria(props) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={classes.top}>
      <IoIosArrowBack
        style={{
          fontSize: "35px",
          color: "#EA3458",
          cursor: "pointer",
          minWidth: "40px"
        }}
        onClick={() => {
          props.rota ? history.push(props.rota) : history.goBack()
        }}
      />
      <div className={classes.dots}>
        <h2 style={{ paddingRight: "20px" }}>{props.name}</h2>
      </div>
      <div className={classes.space} />
    </div>
  )
}
