import React from "react"
import { useStyles } from "./style"
import { button } from "../../../../../Componentes/Inputs/TextFild"
import errorImg from "../../../../../Assets/img/error-new-checkout.png"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import { useHistory, useLocation } from "react-router-dom"

export default function ErrorPayment() {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { message } = location

  return (
    <>
      <div className={classes.container}>
        <h1>Ops... foi por pouco</h1>
        <img src={errorImg} alt="error-img" />
        <h3>
          Sentimos muito, mas houve um problema no processamento do seu pagamento.
          Por favor, verifique suas informações e tente novamente. Estamos torcendo
          por você!
        </h3>

        {message ? <h4>Erro: {message}</h4> : ""}

        <div className={classes.Button}>
          {button({
            name: `Tentar novamente`,
            color: "#52EB58",
            funcao: () =>
              history.push({
                pathname: "/planos/v2/checkout"
              })
          })}
        </div>

        <a className={classes.shareButton}>
          <WhatsAppIcon
            style={{
              color: "#3DC34F",
              backgroundColor: "#FFFFFF",
              padding: "5px",
              fontSize: "1.2rem",
              borderRadius: "50px",
              marginLeft: "9.5px"
            }}
          />
          Falar com o nosso atendimento
        </a>
      </div>
    </>
  )
}
