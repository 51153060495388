/* eslint-disable */
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { BiInfinite } from "react-icons/bi"
import { FaCocktail } from "react-icons/fa"
import {
  GiCutDiamond,
  GiHealthNormal,
  GiHighHeel,
  GiLaptop,
  GiLipstick
} from "react-icons/gi"
import { RiBearSmileFill } from "react-icons/ri"
import { useLocation } from "react-router-dom"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import { globalStyles } from "../../Componentes/CssGlobal"
import Password from "../../Componentes/Inputs/Password"
import { button, texto } from "../../Componentes/Inputs/TextFild"
import {
  telefone,
  celular,
  validasenha,
  validateEmail
} from "../../Componentes/validates/Validate"
import { campovazio } from "../../Componentes/validates/ValidateForm"
import conexao from "../../Services/api"
import Topo from "./componentes/TopoCadastro"
import { useStyles } from "./style"
import { CircularProgress } from "@material-ui/core"
import { LeadLoversApi } from "../../Services/api/leadlovers"


// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function Cadastro() {
  const classes = useStyles()
  const classesGlobal = globalStyles()
  const query = useQuery()

  const state = {
    nome_loja: { name: "nome_loja", funcao: campovazio, value: "", error: false },
    segmento: { name: "segmento", funcao: campovazio, value: "", error: false },
    whatsapp: { name: "whatsapp", funcao: campovazio, value: "", error: false },
    nome: { name: "nome", funcao: campovazio, value: "", error: false },
    email: { name: "email", funcao: validateEmail, value: "", error: false },
    password: { name: "password", funcao: validasenha, value: "", error: false }
  }

  const [next, setnext] = useState(0)
  const [form, setForm] = useState(state)
  const [updateDots, setUpdateDots] = useState(0)
  const [segmentos, setSegmentos] = useState([])
  const [coloricon, setColoricon] = useState("")
  const [checked, setChecked] = React.useState(false)
  const [alertType, setAlertType] = useState("")
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [utm, setUtm] = useState()
  const [loadPage, setLoadPage] = useState(false)


  /* const validaCelular = value => {

    if (value <= 14) {
      telefone(value)
    }
    if (value => 15) {
      celular(value)
    }

    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')
  } */

  const handleChange = event => {
    setChecked(event.target.checked)
  }

  const icons = [
    { name: "GiCutDiamond", icon: <GiCutDiamond />, active: false },
    { name: "FaCocktail", icon: <FaCocktail />, active: false },
    { name: "BiInfinite", icon: <BiInfinite />, active: false },
    { name: "RiBearSmileFill", icon: <RiBearSmileFill />, active: false },
    { name: "GrTechnology", icon: <GiLaptop />, active: false },
    { name: "GiHighHeel", icon: <GiHighHeel />, active: false },
    { name: "GiLipstick", icon: <GiLipstick />, active: false },
    { name: "GiHealthNormal", icon: <GiHealthNormal />, active: false }
  ]

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  useEffect(() => {
    const url_string = window.location.href
    const url = new URL(url_string)
    const data = url.searchParams.get("utm")
    setUtm(data)
  }, [])

    const bodyToLeadLovers= {
    email: form.email.value,
    name: form.nome.value,
    MachineCode: 707694,
    EmailSequenceCode: 1656725,
    SequenceLevelCode: 1,
    phone: form.whatsapp.value,
    AdditionalInfo: {
      utm: utm,
      loja: form.nome_loja.value
    }
  }

  async function Enviar() {
    if (form.nome_loja.value !== "" && form.nome.value !== "") {
      setLoadPage(true)
      // eslint-disable-next-line prettier/prettier
      const url = form.nome_loja.value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/ /g, "")
        .replace(/[^A-Z0-9]+/gi, "")
        .toLowerCase()
      const primeiroAcesso = query.get("pro") === "true" ? 2 : 1
      const res = await conexao.post("/lojinha/create", {
        first_time: primeiroAcesso,
        name: form.nome_loja.value,
        email: form.email.value,
        name_person: form.nome.value,
        whatsapp: form.whatsapp.value,
        password: form.password.value,
        url,
        segment_id: form.segmento.value,
        utm,
        payment_active: "manual",
        payment_data: {
          manual: {
          payment_type_list: [
            {
                type: "credit_card",
                name: "Cartão de crédito",
                active: 0
            },
            {
                type: "debit_card",
                name: "Cartão de débito",
                active: 0
            },
            {
                type: "pix",
                name: "PIX",
                active: 0
            },
            {
                type: "ticket",
                name: "Boleto bancário",
                active: 0
            },
            {
                type: "cash",
                name: "Dinheiro",
                active: 0
            },
            {
                type: "picpay",
                name: "Picpay",
                active: 0
            },
            {
                type: "meal_voucher",
                name: "Vale alimentação",
                active: 0
            },
            {
                type: "food_voucher",
                name: "Vale refeição",
                active: 0
            },
            {
                type: "to_match",
                name: "A combinar",
                active: 1
            }
        ]
        }
        }
      })
      if (res) {
        // const dataLovers = await LeadLoversApi.create(bodyToLeadLovers)
        localStorage.setItem("token", res.data.data.token)
        localStorage.setItem("token_cortado", res.data.data.token.substr(7))
        localStorage.setItem("email", res.data.data.email)
        localStorage.setItem("name", res.data.data.name)
        localStorage.setItem("user_id", res.data.data.user_id)
        localStorage.setItem("id", res.data.data.lojinha_id)
        localStorage.setItem("first_time", res.data.data.first_time)
        localStorage.setItem("tokenCreateAt", res.data.data.tokenCreateAt)
        localStorage.setItem("tokenExpMinutes", res.data.data.tokenExpMinutes)
        /* custom axios */
        const apiCustomRequest = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          headers: {
            Authorization: res.data.data.token ? res.data.data.token : "",
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*"
          }
        })


        const shippingSettings = await conexao.post(`lojinha/shipping/create`, {
          lojinha_id: res.data.data.lojinha_id,
          shipping_active: 1,
          shipping_data: {shipping_name: "A Combinar", value: 0, average_time: "1", average_time_type: "days"},
          shipping_type: "custom_shipping"
        })

        const resLojinhaConfigs = await apiCustomRequest.post(
          `configuration/create`,
          {
            lojinha_id: Number(res.data.data.lojinha_id),
            seo_check: 0,
            floating_button: 0,
            ask_document: 1,
            primary_color: "#3498DB",
            secondary_color: "#55EFC4",
            google_check: 0,
            insta_check: 0,
            seo_text: "",
            seo_description: "",
            seo_tags: "",
            facebook_pixel: "",
            facebook_check: 0,
            google_tag: "",
            url_instashop: ""
          }
        )

        if (resLojinhaConfigs) {
          localStorage.setItem("config_id", resLojinhaConfigs.data.data.id)
          setAlertMessage("Conta criada com sucesso.")
          setAlertType("success")
          setShowAlertOk(true)
          if (res && primeiroAcesso === 1) {
            window.location.href = "/home"
          } else if (res && primeiroAcesso === 2) {
            window.location.href = "/planos-contratacao"
          }
        }
      }
      setLoadPage(false)
    }
  }

  const [value, setValue] = React.useState("female")

  const handleChangeSegmento = event => {
    setValue(event.target.value)
    setColoricon(event.target.value)

    segmentos.map(item => {
      if (item.name === event.target.value) {
        setForm({
          ...form,
          segmento: { name: "segmento", value: item.id, error: false }
        })
      }
    })
  }

  function prox(e, item) {
    const { error, value, name, funcao } = item
    if (e === 6 && checked) {
      if (item.value === "") {
        setForm({
          ...form,
          [name]: { name, value, error: true, funcao }
        })
      } else {
        Enviar()
      }
    }

    if (checked && !error && value !== "" && e !== 6) {
      setnext(e)
      setUpdateDots(e)
    } else {
      if (e === 1) {
        setForm({
          ...form,
          [name]: {
            name,
            value,
            error: funcao(value),
            funcao
          }
        })
      }

      if (e === 5) {
        setForm({
          ...form,
          [name]: {
            name,
            value,
            error: funcao(value),
            funcao
          }
        })
      }
    }
  }

  function back(e) {
    if (checked) {
      setnext(e)
      setUpdateDots(e)
    }
  }

  function pegadados(e) {
    const { name, value } = e
    let newValue = value
    if (name === "whatsapp") {
      if (newValue.length <= 14) {
        newValue = telefone(newValue)
      } else if (newValue.length <= 15) {
        newValue = celular(newValue)
      }
    }
    if (name === "nome") {
      newValue = String(value).replace(/[0-9]/g, "")
    }
    const obj = form[name]
    const er = form[name].funcao(newValue)
    const att = { ...obj, error: er, value: newValue }
    setForm({ ...form, [name]: att })
  }

  function handleValidateWhatsapp() {
    if (form.whatsapp.value.length >= 14) {
      prox(3, form.whatsapp)
    } else {
      setAlertMessage("Preencha o telefone completo.")
      setAlertType("error")
      setShowAlertOk(true)
    }
  }

  function handleValidateName() {
    if (form.nome.value.length >= 3) {
      prox(4, form.nome)
    } else {
      setAlertMessage("Preencha um nome válido.")
      setAlertType("error")
      setShowAlertOk(true)
    }
  }

  function handleValidateUrl() {
    // eslint-disable-next-line prettier/prettier
    const url = form.nome_loja.value.replace(/[^A-Z0-9]+/gi, "").toLowerCase()

    conexao.get(`/lojinha/verify/${url}`).then(res => {
      if (res.data.data.length === 0) {
        prox(1, form.nome_loja)
      } else {
        setAlertMessage("Esse nome já existe.")
        setAlertType("error")
        setShowAlertOk(true)
      }
    })
  }

  async function handleValidateEmail() {
    const emailValidate = {
      email: form.email.value
    }
    const res = await conexao.post(`/user/verifyemail`, emailValidate)
    if (res.data.message === "e-mail ja utilizado") {
      setAlertMessage("Email já esta sendo utilizado no sistema.")
      setAlertType("error")
      setShowAlertOk(true)
    } else {
      prox(5, form.email)
    }
  }

  useEffect(() => {
    const arr = []

    conexao
      .get("/segment")
      .then(res => {
        res.data.data.map(item => {
          icons.map(icone => {
            if (item.icon === icone.name) {
              arr.push({
                id: item.id,
                name: item.name,
                icon: icone.icon,
                active: false
              })
            }
          })
        })
        setSegmentos(arr)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <>
      {next === 0 ? (
        <div className="page-container">
          <div className="page-align">
            <div>
              <Topo prox={back} id={updateDots} />

              {/* <Password/> */}
              {texto({
                name: "nome_loja",
                title: "Qual o nome da sua loja?",
                value: form.nome_loja.value,
                pegadados,
                error: form.nome_loja.error
              })}
            </div>

            <div>
              <div className={classes.termosDeUso}>
                <div className={classesGlobal.FooterRow}>
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <h3>
                    Declaro que li e concordo com os{" "}
                    <a
                      style={{
                        color: "#EA3458",
                        cursor: "pointer",
                        fontWeight: "bold"
                      }}
                      href="https://uppyapp.com.br/papers/termouso/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Termos de Uso{" "}
                    </a>
                    e{" "}
                    <a
                      style={{
                        color: "#EA3458",
                        cursor: "pointer",
                        fontWeight: "bold"
                      }}
                      href="https://uppyapp.com.br/papers/politicasprivacidade/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Política de Privacidade
                    </a>
                  </h3>
                </div>
              </div>

              <CustomAlert
                type={alertType}
                alertText={alertMessage}
                show={showAlertOk || showAlertError}
                close={() => closeAlerts()}
              />
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }}></hr>

              {button({
                name: "Próximo",
                color:
                  form.nome_loja.error === false &&
                  checked &&
                  form.nome_loja.value !== ""
                    ? "#EA3458"
                    : "#cccccc",
                fontcolor:
                  form.nome_loja.error === false &&
                  checked &&
                  form.nome_loja.value !== ""
                    ? "#FFFFFF"
                    : "#A4A4A4",
                funcao: () => {
                  handleValidateUrl()
                }
              })}
            </div>
          </div>
        </div>
      ) : next === 1 ? (
        <div className="page-container">
          <div className="page-align">
            <div>
              <Topo prox={back} id={updateDots} />

              <p
                style={{
                  marginTop: "25px",
                  marginBottom: "15px",
                  fontSize: "1em"
                }}
              >
                Qual o segmento da sua loja?
              </p>
            </div>

            <div className="infinite-scroll">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={value}
                onChange={handleChangeSegmento}
                style={{ width: "99%" }}
              >
                {segmentos
                  ? segmentos.map(item => {
                      return (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <div
                                style={{
                                  marginRight: "20px",
                                  marginLeft: "20px",
                                  color:
                                    item.name === coloricon ? "#ff4583" : "#A4A4A4",
                                  fontSize: "25px"
                                }}
                              >
                                {item.icon}
                              </div>
                              <div
                                style={{
                                  color:
                                    item.name === coloricon ? "#353535" : "#A4A4A4",
                                  fontSize: "14px"
                                }}
                              >
                                {item.name}
                              </div>
                            </div>
                            <FormControlLabel
                              value={item.name}
                              control={<Radio />}
                            />
                          </div>
                          <hr style={{ width: "96%" }} />
                        </>
                      )
                    })
                  : ""}
              </RadioGroup>
            </div>

            <div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }}></hr>
              {button({
                name: "Próximo",
                color: form.segmento.value !== "" && checked ? "#EA3458" : "#cccccc",
                fontcolor:
                  form.segmento.value !== "" && checked ? "#FFFFFF" : "#A4A4A4",
                funcao: () => {
                  prox(2, form.segmento)
                }
              })}
            </div>
          </div>
        </div>
      ) : next === 2 ? (
        <div className="page-container">
          <div className="page-align">
            <div>
              <Topo prox={back} id={updateDots} />

              {texto({
                name: "whatsapp",
                title: "Qual o celular ou whatsapp da sua loja?",
                value: form.whatsapp.value.toLocaleLowerCase(),
                pegadados,
                maxlength: 15,
                error: form.whatsapp.error
              })}
            </div>

            <CustomAlert
              type={alertType}
              alertText={alertMessage}
              show={showAlertOk || showAlertError}
              close={() => closeAlerts()}
            />

            <div>
              <div className={classesGlobal.FooterRow}>
                <p>
                  Este número de contato será utilizado para receber seus pedidos e
                  notificações do sistema.
                </p>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }}></hr>
              {button({
                name: "Próximo",
                color:
                  form.whatsapp.error === false &&
                  checked &&
                  form.whatsapp.value !== ""
                    ? "#EA3458"
                    : "#cccccc",
                fontcolor:
                  form.whatsapp.error === false &&
                  checked &&
                  form.whatsapp.value !== ""
                    ? "#FFFFFF"
                    : "#A4A4A4",
                funcao: () => handleValidateWhatsapp()
              })}
            </div>
          </div>
        </div>
      ) : next === 3 ? (
        <div className="page-container">
          <div className="page-align">
            <div>
              <Topo prox={back} id={updateDots} />

              {texto({
                name: "nome",
                title: "Como gostaria de ser chamado?",
                value: form.nome.value,
                pegadados,
                maxlength: "32",
                error: form.nome.error
              })}
            </div>

            <CustomAlert
              type={alertType}
              alertText={alertMessage}
              show={showAlertOk || showAlertError}
              close={() => closeAlerts()}
            />

            <div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }}></hr>
              {button({
                name: "Próximo",
                color:
                  form.nome.error === false && checked && form.nome.value !== ""
                    ? "#EA3458"
                    : "#cccccc",
                fontcolor:
                  form.nome.error === false && checked && form.nome.value !== ""
                    ? "#FFFFFF"
                    : "#A4A4A4",
                funcao: () => handleValidateName()
              })}
            </div>
          </div>
        </div>
      ) : next === 4 ? (
        <div className="page-container">
          <div className="page-align">
            <div>
              <Topo prox={back} id={updateDots} />

              {texto({
                name: "email",
                title: "Qual o seu E-mail?",
                value: form.email.value.toLocaleLowerCase(),
                pegadados,
                maxlength: "50",
                error: form.email.error
              })}
            </div>

            <CustomAlert
              type={alertType}
              alertText={alertMessage}
              show={showAlertOk || showAlertError}
              close={() => closeAlerts()}
            />

            <div>
              <div className={classesGlobal.FooterRow}>
                <p>
                  Este e-mail será usado para acessar e administrar sua conta Uppy.
                </p>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }}></hr>
              {button({
                name: "Próximo",
                color:
                  form.email.error === false && checked && form.email.value !== ""
                    ? "#EA3458"
                    : "#cccccc",
                fontcolor:
                  form.email.error === false && checked && form.email.value !== ""
                    ? "#FFFFFF"
                    : "#A4A4A4",
                funcao: () => {
                  handleValidateEmail()
                }
              })}
            </div>
          </div>
        </div>
      ) : next === 5 ? (
        <>
        {loadPage ? (
          <div
            style={{
              backgroundColor: " #fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%"
            }}
          >
            <CircularProgress color="secondary" size="40px" />
          </div>
        ) :(
        <div className="page-container">
          <div className="page-align">


            <div>
              <Topo prox={back} id={updateDots} />

              <Password
                pegadados={pegadados}
                error={form.password.error}
                label="Crie uma senha"
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <CustomAlert
                  type={alertType}
                  alertText={alertMessage}
                  show={showAlertOk || showAlertError}
                  close={() => closeAlerts()}
                />
              </div>
            </div>


            <div>
              <div className={classesGlobal.FooterRow}>
                <p>A senha deve ter no mínimo 6 caracteres.</p>
              </div>
              <hr style={{ width: "100%", borderColor: "#d4d4d445" }}></hr>
              {button({
                name: "Concluir",
                color:
                  form.password.error === false &&
                  checked &&
                  form.password.value !== ""
                    ? "#EA3458"
                    : "#cccccc",
                fontcolor:
                  form.password.error === false &&
                  checked &&
                  form.password.value !== ""
                    ? "#FFFFFF"
                    : "#A4A4A4",
                funcao: () => {
                  prox(6, form.password)
                }
              })}
            </div>
          </div>
        </div>
        )}
        </>
      ) : (
        ""
      )}
    </>
  )
}
