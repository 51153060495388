import { useStyles } from "./style"
import Topo from "../../../Componentes/TopCategorias"
import { useLocation } from "react-router-dom"
import * as React from "react"
import CategoryComponent from "../../CategoriasNova/Components/index"
import { AddCircle } from "@material-ui/icons"
import subCategoriasRequest from "../../../Services/api/subcategorias"
import { CircularProgress } from "@material-ui/core"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"

export default function Subcategorias() {
  const classes = useStyles()
  const { state } = useLocation()
  const { title, id, from } = state.state
  const [subCategories, setSubCategories] = React.useState([])
  const [newSubCategoryName, setNewSubCategoryName] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [alertType, setAlertType] = React.useState("")
  const [showModalAlert, setShowModalAlert] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState("")
  async function getSubCategorias() {
    setLoading(true)
    try {
      const { data } = await subCategoriasRequest.getSubCategorias(id)
      setSubCategories(data.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  async function sendNewSubCategory() {
    if (newSubCategoryName.trim() !== "") {
      try {
        await subCategoriasRequest.postSubCategoriasCreate({
          category_id: id,
          sub_name: newSubCategoryName
        })
        setAlertType("success")
        setAlertMessage("Subcategoria adicionada com sucesso!")
        setShowModalAlert(true)
        setNewSubCategoryName("")
        getSubCategorias()
      } catch (error) {
        console.error(error)
      } finally {
      }
    }
  }

  React.useEffect(() => {
    getSubCategorias()
  }, [])

  const handleDeleteSubCategory = async subcategoryId => {
    setLoading(true)
    try {
      await subCategoriasRequest.deleteSubCategoriasDelete(subcategoryId)
      getSubCategorias()
      setAlertType("success")
      setAlertMessage("Subcategoria excluída com sucesso!")
      setShowModalAlert(true)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={`${classes.mainContainer} page-container`}>
      <div className="page-align">
        <div className="infinite-scroll">
          <Topo
            name={title}
            show={true}
            rota={
              from === "sessao-guiada" ? "/sessao-guiada" : "/gerenciar-categorias"
            }
            stepSessaoGuiada={from === "sessao-guiada" ? 5 : null}
          />
          <div className={classes.BoxSwitch}>
            <input
              className={classes.InputAddCircle}
              placeholder="Nome da Subcategoria"
              value={newSubCategoryName}
              onChange={e => setNewSubCategoryName(e.target.value)}
            />
            <AddCircle
              onClick={sendNewSubCategory}
              style={{ color: "#EA3458", fontSize: "50px", cursor: "pointer" }}
            />
          </div>
          {loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "20px 0px"
              }}
            >
              <CircularProgress style={{ color: "#EA3458" }} />
            </div>
          ) : (
            subCategories?.map((category, index) => (
              <div className={classes.ContainerMap}>
                <CategoryComponent
                  key={category?.sub_id}
                  index={index}
                  id={category?.sub_id}
                  name={category?.sub_name}
                  categories={subCategories}
                  handleDeleteCategory={handleDeleteSubCategory}
                  deleteicon={true}
                  setSubCategories={setSubCategories}
                />
                <div>
                  <CustomAlert
                    type={alertType}
                    alertText={alertMessage}
                    show={showModalAlert}
                    close={() => setShowModalAlert(true)}
                  />
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  )
}
