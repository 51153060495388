import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core"
import React from "react"
import { useHistory } from "react-router-dom"
import { SimpleImg } from "react-simple-img"
import imgHomePro from "../../Assets/img/ImgBannerHomePro.png"
import { createLeedPro } from "../../utils"
import { globalStyles } from "../CssGlobal"
import { button } from "../Inputs/TextFild"
import useStyles from "./styles"

export default function BannerProHome({ isOpen, handleClose, bannerId }) {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const history = useHistory()

  const hasLeedPro = () => {
    createLeedPro(bannerId)
  }

  return (
    <div className="page-container">
      <div className="page-align">
        <div className="infinite-scroll">
          <div onClick={hasLeedPro} aria-hidden="true">
            <Dialog
              open={isOpen}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              className={classes.PlanosAlertaModal}
            >
              <DialogTitle id="responsive-dialog-title">
                <div className={classes.PlanosAlertaModalHeader}>
                  <h2>Seu limite de produtos foi atingido</h2>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText className={classes.PlanosAlertaModalBody}>
                  <SimpleImg
                    width="auto"
                    height="auto"
                    placeholder={false}
                    src={imgHomePro}
                    alt="usuario-free"
                  />
                  <p>Você atingiu o limite de <span> 5 produtos </span> cadastrados em sua loja utilizando o nosso plano gratuito.</p>

                </DialogContentText>
              </DialogContent>
              <DialogActions>
              {button({
                name: `ativar produtos ilimitados`,
                color: "#52EB58",
                fontcolor:"#000",
                funcao: () => history.push("/planos/v2/checkout")

              })}
                                        </DialogActions>
              <DialogActions style={{justifyContent: "center", textDecoration: 'underline', marginBottom: "1rem", color: '#777777'}}>
                <a
                onClick={() => handleClose()}
                role="button"
                tabIndex={0}
                onKeyDown={() => handleClose()}>
                  continuar utilizando o plano gratuito
                </a>

              </DialogActions>

            </Dialog>
          </div>
        </div>
      </div>
    </div>
  )
}
