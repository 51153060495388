import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles(theme => ({
  enableMercadoPagoIntegrationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  pagseguroOrders: {
    "&__enablePaymentOption": {
      marginTop: "10px",
      paddingBottom: "10px",
      "& div": {
        display: "flex",
        justifyContent: "space-between"
      }}},
  partnerContainer: {
    display: "flex",
    alignItems: "center"
  },
  buttonSave: {
    color: "#fff",
    minWidth: "100%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "5px",
    marginBottom: "15px",
    textTransform: "none",
    backgroundColor: "#EA3458",

    "&:hover": {
      boxShadow: "none !important",
      backgroundColor: "#EA3458 !important"
    }
  },
  paymentOptionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    marginTop: "3rem"
  },
  creditCardPaymentOptionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem"
  },
  paymentOptionSwitchContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  switchLabelContainer: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem"
  },

}))
