import { Button, TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import CustomAlert from "../../../../Componentes/Alerts/CustomAlert"
import { globalStyles } from "../../../../Componentes/CssGlobal"
import TopCategorias from "../TopCategorias"
import { useStyles } from "./style"
import { useProduct } from "../../../../ContextAPI/productContext"
import { useHistory } from "react-router-dom"

export default function Home() {
  const classesGlobal = globalStyles()
  const { saveNewCategory } = useProduct()
  const classes = useStyles()
  const [Text, setText] = useState("")
  const [Validate, setValidate] = useState("")
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [alertType, setAlertType] = useState("")
  const history = useHistory()

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  function Enviar() {
    if (Text !== "") {
      saveNewCategory(Text)
      setText("")
      setAlertType("success")
      setShowAlertOk(true)
      setTimeout(() => {
        history.goBack()
      }, 1500)
    } else {
      setAlertType("error")
      setShowAlertError(true)
    }
  }

  useEffect(() => {
    setValidate(Text)
  }, [Text])

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <div>
            <TopCategorias name="Adicionar categoria" />
          </div>
          <div className="infinite-scroll">
            <TextField
              style={{ backgroundColor: "" }}
              label="Nome da categoria"
              name="description"
              error=""
              helperText=""
              value={Text}
              onChange={e => setText(e.target.value)}
              size="small"
              variant="standard"
              color="secondary"
              fullWidth
              inputProps={{ maxLength: 15 }}
              placeholder="Limite de 15 caracteres"
            ></TextField>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <CustomAlert
              type={alertType}
              alertText={
                alertType === "success"
                  ? "Categoria salva com sucesso!"
                  : "Algo deu errado!"
              }
              show={showAlertOk || showAlertError}
              close={() => closeAlerts()}
            />
          </div>
          <div className="footer-column">
            <hr style={{ width: "96%" }} />
            <Button
              className={
                Validate === "" ? classes.btnSalvarDisable : classes.btnSalvar
              }
              onClick={() => {
                Validate === "" ? "" : Enviar()
              }}
            >
              Adicionar
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
