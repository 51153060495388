import React from "react"
import { IoIosArrowBack } from "react-icons/io"
import { useHistory } from "react-router-dom"
import { useStyles } from "./style"
import { useProduct } from "../../ContextAPI/productContext"

export default function Categoria({ rota, name, show, noPadding, stepSessaoGuiada, ...props }) {
  const classes = useStyles()
  const history = useHistory()
  const { setTabSession } = useProduct()

  const handleRedirect = (rota, stepSessaoGuiada) => {
    if(rota) {
      if(stepSessaoGuiada) {
        setTabSession(stepSessaoGuiada)
      }
      history.push(rota)
      return
    }
    history.goBack()
  }

  return (
    <>
      {(show === true || show === undefined ) ? (
        <div className={classes.top}>
          <>
            <IoIosArrowBack
              style={{
                fontSize: "35px",
                color: "#EA3458",
                cursor: "pointer",
                minWidth: "30px",
                marginLeft: "-10px"
              }}
              onClick={() => {
                handleRedirect(rota, stepSessaoGuiada)
              }}
              {...props}
            />
            <div className={classes.dots}>
              <h2 style={{ paddingRight: !noPadding && "15px", fontSize: 15 }}>
                {name}
              </h2>
            </div>
            <div className={classes.space} />
          </>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}