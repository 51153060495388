import { useStyles } from "./style"
import { SimpleImg } from "react-simple-img"
import NotFoundImg from "../../Assets/svg/notFound.svg"
import logoRosa from "../../Assets/img/logorosa.png"

export default function NotFound() {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.flexColumn}>
            <SimpleImg src={logoRosa} width="150" height="95" placeholder={false} />
            <p
              style={{
                fontSize: "1.2rem",
                margin: "1.0em 0 0",
                textAlign: "center"
              }}
            >
              Não encontramos o que você procurou...
            </p>
          </div>
          <div style={{ margin: "30px auto" }}>
            <SimpleImg
              src={NotFoundImg}
              height="400"
              width="450"
              placeholder={false}
            />
          </div>
          <div className={classes.flexColumn}>
            <a href="/home">
              <span className={classes.LinkButton}>Voltar para home</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
NotFound

// tesetpush
