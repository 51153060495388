import React, { createContext, useContext, useEffect, useState } from "react"
import categoriasRequest from "../Services/api/categorias"

export const ProductContext = createContext({})

export function useProduct() {
  const context = useContext(ProductContext)
  if (!context) {
    throw new Error("useProduct must be used inside ProductProvider")
  }

  return context
}

export function ProductProvider(props) {
  const { children } = props

  /* CONTEXT STATES */

  /* tipo de variação de produto */
  const [variableType, setVariableType] = useState([])

  /* variações geradas de produtos */
  const [variables, setVariables] = useState([])

  /* lista de todas categorias da loja */
  const [categoriesList, setCategoriesList] = useState([])

    /* lista de todas categorias e subs selecionadas da loja */
    const [categoriesListSelected, setCategoriesListSelected] = useState([])

  /* produto atual para criação e edição  */
  const [currentProduct, setCurrentProduct] = useState(null)

  /* variação atual para criação e edição  */
  const [currentVariation, setCurrentVariation] = useState(null)

  /* filtro de produtos */
  const [filterOptions, setFilterOptions] = useState([1, 1, 0])

  /* filtro de produtos por preço */
  const [showPrice, setShowPrice] = useState([0, 5000])
  /* filtro de produtos por categoria */
  const [categorySelect, setCategorySelect] = useState([])

  /* query de busca de produtos */
  const [query, setQuery] = useState({})

  /* lista de todos os produtos da loja */
  const [allProductList, setAllProductList] = useState([])

  /* lista de todas as variações da loja */
  const [allVariationList, setAllVariationList] = useState([])

  /* lista de produtos top_sales para estatisticas */
  const [mostWantedProducts, setMostWantedProducts] = useState([])

  // Lista de Produtos filtrador por categoria nos stories

  const [storiesProductsFiltredByCategory, setStoriesProductsFiltredByCategory] =
    useState([])
  /* lista de produtos destaque */
  const [productsHighlightList, setProductsHighlightList] = useState([])

  // lista de produtos de mais vendas
  const [salesHighlightList, setSalesHighlightList] = useState([])

  /* lista de produtos top_sales para next */
  const [productsTopSaleList, setProductsTopSaleList] = useState([])

  /* query de busca de pedidos */
  const [queryOrder, setQueryOrder] = useState({})

  /* verifica se é uma edição de produto  */
  const [isEdit, setIsEdit] = useState(false)

  /* verifica se é uma edição de uma variação  */
  const [isEditVariation, setIsEditVariation] = useState(false)

  /* identificação das abas de produto */
  const [tabSession, setTabSession] = useState(1)

  // identificação de lista de mais vendidos
  const [vendidosList, setVendidosList] = useState([])

  // Loading
  const [isLoading, setIsLoading] = useState(false)

  const [tabNumber, setTabNumber] = useState(1)

  /* CONTEXT FUNCTIONS */

  /* get all categories */
  const getCategoriesList = async () => {
    const catRes = await categoriasRequest.get()
    if (catRes.data.error !== true) setCategoriesList([...catRes.data.data])
  }

  /* salva uma nova categoria */
  const saveNewCategory = async name => {
    const newCatRes = await categoriasRequest.create(name)
    if (newCatRes) getCategoriesList()
  }

  /* deleta uma categoria */
  const deleteCategory = async id => {
    const deleteRes = await categoriasRequest.delete(id)
    if (deleteRes) getCategoriesList()
  }

  /* procuto se o produto esta na lista de destaques */
  const searchProductHighlight = productId => {
    let selectProduct = productsHighlightList.find(
      produto => produto.id === productId
    )
    if (selectProduct !== undefined) {
      return true
    }
    return false
  }

  /* procuro se o produto esta na lista de top_sales */
  const searchProductTopSale = productId => {
    let selectProduct = productsTopSaleList.find(produto => produto.id === productId)
    if (selectProduct !== undefined) {
      return true
    }
    return false
  }

  /* ao carregar aplicação faz um request para api buscando categorias e produtos */
  useEffect(() => {
    getCategoriesList()
  }, [])

  return (
    <ProductContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        categoriesListSelected,
        setCategoriesListSelected,
        categoriesList,
        setCategoriesList,
        saveNewCategory,
        deleteCategory,
        variableType,
        setVariableType,
        variables,
        setVariables,
        getCategoriesList,
        currentProduct,
        setCurrentProduct,
        isEdit,
        setIsEdit,
        filterOptions,
        setFilterOptions,
        query,
        setQuery,
        mostWantedProducts,
        setMostWantedProducts,
        queryOrder,
        setQueryOrder,
        allProductList,
        setAllProductList,
        productsHighlightList,
        setProductsHighlightList,
        productsTopSaleList,
        setProductsTopSaleList,
        searchProductHighlight,
        tabNumber,
        setTabNumber,
        searchProductTopSale,
        showPrice,
        setShowPrice,
        categorySelect,
        setCategorySelect,
        currentVariation,
        setCurrentVariation,
        isEditVariation,
        setIsEditVariation,
        allVariationList,
        setAllVariationList,
        tabSession,
        setTabSession,
        storiesProductsFiltredByCategory,
        setStoriesProductsFiltredByCategory,
        isLoading,
        setIsLoading,
        vendidosList,
        setVendidosList,
        salesHighlightList,
        setSalesHighlightList
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
