import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => ({
  informationsContainer: {
    flexGrow: 1,
    marginTop: "1.2rem"
  },

  paper: {
    height: 110,
    width: "100%",
    boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",

    "& img": {
      marginTop: "2px"
    },

    "& span": {
      color: "#EA3458",
      fontWeight: "700",
      fontSize: "14px!important",
      paddingTop: "7px"
    },

    "& small": {
      fontWeight: "400",
      fontSize: "12px",
      padding: "5px"
    }
  }
}))
