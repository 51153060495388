import React, { useEffect, useRef, useState } from "react"
import Switch from "@material-ui/core/Switch"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import Topo from "../../Componentes/TopCategorias"
import { useStyles } from "./style"
import { Button, CircularProgress } from "@material-ui/core"
import ImageUploaderCrop from "../../Componentes/ImageUploaderCrop"
import AdvancedCropper from "../../Componentes/AdvancedCropper"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import requestInstitucional from "../../Services/api/institucional"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export default function InstitutionalInfo() {
  const [status, setStatus] = useState(true)
  const [institucionalImage, setInstitucionalImage] = useState("")
  const [src, setSrc] = useState("")
  const [openCrop, setOpenCrop] = useState(false)
  const [text, setText] = useState("")
  const [alertType, setAlertType] = useState("")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const classes = useStyles()
  const fileInputRefInstitucional = useRef(null)
  const history = useHistory()
  const handleRedirect = () => {
    history.push("/personalizar-lojinha")
  }

  const handleGetInstitucionalInfo = async () => {
    try {
      const response = await requestInstitucional.getInstitucionalInfos()
      const institucionalInfos = response.data.data
      setStatus(institucionalInfos.institutionals_status === 1 ? true : false)
      setInstitucionalImage(institucionalInfos.institutionals_image)
      setText(institucionalInfos.institutionals_body)
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (src) {
      setOpenCrop(true)
    }
  }, [src])

  useEffect(() => {
    handleGetInstitucionalInfo()
  }, [])

  const handleChangeStatus = () => {
    setStatus(prevStatus => !prevStatus)
  }

  const handleChangeImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        setSrc(reader.result)
      }
    }
  }

  const handleButtonClick = () => {
    fileInputRefInstitucional.current.click()
  }

  const handleSaveInstitucionalInfos = async () => {
    setIsLoading(true)
    try {
      if (!text.replace(/<[^>]*>/g, "").trim()) {
        setAlertType("error")
        setAlertMessage("Digite o texto antes de salvar.")
        setShowModalAlert(true)
      } else {
        await requestInstitucional.updateInstitucionalInfos(
          status,
          institucionalImage,
          text
        )
        setAlertType("success")
        setAlertMessage("Informações atualizadas com sucesso!")
        setShowModalAlert(true)
        setTimeout(() => {
          handleRedirect()
        }, 1000)
      }
    } catch (error) {
      setAlertType("error")
      setAlertMessage("Erro ao atualizar informações!")
      setShowModalAlert(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <Topo rota={"/personalizar-lojinha"} name="Institucional" />
          <div className="infinite-scroll">
            {isLoading ? (
              <div
                style={{
                  backgroundColor: " #fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%"
                }}
              >
                <CircularProgress color="secondary" size="40px" />
              </div>
            ) : (
              <>
                <div className={classes.mainContainer}>
                  <div className={classes.HeaderMain}>
                    <p>Ativar</p>
                    <Switch
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onClick={handleChangeStatus}
                      checked={status}
                    />
                  </div>
                  {status && (
                    <>
                      <ImageUploaderCrop
                        image={institucionalImage}
                        width="870"
                        height="580"
                        setImage={setInstitucionalImage}
                        handleChangeImage={handleChangeImage}
                        fileInputRef={fileInputRefInstitucional}
                        handleButtonClick={handleButtonClick}
                        buttonText="Adicionar imagem"
                        description="Tamanho ideal para a imagem"
                      />
                      <div className={classes.TextArea}>
                        <span>Digite abaixo o texto sobre a sua loja:</span>
                        <ReactQuill
                          theme="snow"
                          value={text}
                          onChange={setText}
                          modules={{ toolbar: false }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="footer-column">
            <hr style={{ width: "100%" }} />
            <Button
              onClick={handleSaveInstitucionalInfos}
              className={classes.buttonSave}
              type="submit"
              variant="contained"
            >
              Salvar informações
            </Button>
          </div>
        </div>
        <CustomAlert
          type={alertType}
          alertText={alertMessage}
          show={showModalAlert}
          close={() => setShowModalAlert(false)}
        />
      </div>
      {openCrop && (
        <AdvancedCropper
          setSrc={setSrc}
          src={src}
          close={() => setOpenCrop(false)}
          open={openCrop}
          setImage={setInstitucionalImage}
          width={870}
          height={580}
        />
      )}
    </>
  )
}
