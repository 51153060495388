import React, { useState, useEffect } from "react"
import { Button, CircularProgress, Switch, TextField } from "@material-ui/core"
import { globalStyles } from "../../../Componentes/CssGlobal"
import { useStyles } from "./style"
import Topo from "../../../Componentes/TopCategorias"
import api from "../../../Services/api"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { useHistory } from "react-router-dom"

export default function GoogleAnalytics() {
  const classesGlobal = globalStyles()
  const classes = useStyles()

  const [switchValue, setSwitchValue] = useState(1)
  const [text, setText] = useState()
  const [loading, setLoading] = useState(true)
  // const [alertType, setAlertType] = useState("")
  // const [showAlertOk, setShowAlertOk] = useState(false)
  // const [showAlertError, setShowAlertError] = useState(false)
  const [alertType, setAlertType] = useState("success")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [saving, setSaving] = useState(false)
  const history = useHistory()

  function getData() {
    const lojinha_id = localStorage.getItem("id")
    api.get(`/configuration/show/${lojinha_id}`).then(res => {
      setText(res.data.data[0].google_tag)
      setSwitchValue(res.data.data[0].google_check)
      setLoading(false)
    })
  }

  // const closeAlerts = () => {
  //   setShowAlertOk(false)
  //   setShowAlertError(false)
  // }

  function activeSwitch() {
    if (switchValue === 0) {
      setSwitchValue(1)
    } else {
      setSwitchValue(0)
    }
  }

  const body = {
    google_tag: switchValue === 0 ? "" : text,
    google_check: switchValue
  }

  function dataSave() {
    setSaving(true)

    if(body.google_tag === ""){
      setAlertMessage("Campo vazio!")
      setAlertType("error")
      setShowModalAlert(true)
      return
    }

    const id = localStorage.getItem("config_id")
    api
      .put(`/configuration/update/${id}`, body)
      .then(res => {
        setAlertMessage("Dados salvos com sucesso!")
        setAlertType("success")
        setShowModalAlert(true)
        setTimeout(() => {
          history.goBack()
        }, 1500)
      })
      .catch(error => {
        setSaving(false)
        setAlertMessage("Algo deu errado!")
        setAlertType("error")
        setShowModalAlert(true)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {loading ? (
        <div
          style={{
            backgroundColor: " #fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <>
          <div className="page-container">
            <div className="page-align">
              <div>
                <Topo name="Google Analytics" />
              </div>
              <div className="infinite-scroll">
                <div className={classes.Box}>
                  <div className={classes.titleText}>Habilitar Google Analytics</div>
                  <div className={classes.switchChange}>
                    <Switch
                      checked={switchValue}
                      onClick={() => {
                        activeSwitch()
                      }}
                    />
                  </div>
                </div>

                {switchValue === 1 ? (
                  <div>
                    <div className={classes.fonteTitulo}>ID do Google Analytics</div>
                    <div>
                      <TextField
                        style={{ backgroundColor: "#fff" }}
                        name="name"
                        error=""
                        helperText=""
                        value={text}
                        onChange={e => setText(e.target.value)}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        inputProps={{ maxLength: 500 }}
                      />
                    </div>
                    <div className={classes.Box}>
                      <h3>
                        Ative a opção de tracking do Google Anatytics em sua lojinha,
                        insira aqui seu ID de acompanhamento (UA-000000-0):
                      </h3>
                    </div>
                    <div className={classes.hyperlink}>
                      <a
                        className={classes.link}
                        href=" https://blog.uppyapp.com.br/2021/11/como-instalar-a-tag-do-google-analytics-na-sua-loja-online-uppy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Clique aqui para acessar nosso tutorial
                      </a>
                    </div>
                  </div>
                ) : (
                  ""
                )}
{/*
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <CustomAlert
                    type={alertType}
                    alertText={
                      alertType === "success"
                        ? "Dados salvos com sucesso!"
                        : "Algo deu errado!"
                    }
                    show={showAlertOk || showAlertError}
                    close={() => closeAlerts()}
                  />
                </div> */}

                  {showModalAlert ? (
                    <CustomAlert
                      type={alertType}
                      alertText={alertMessage}
                      show={showModalAlert}
                      close={() => setShowModalAlert(false)}
                    />
                  ) : (
                    ""
                  )}
              </div>

              <div className="footer-column">
                <hr style={{ width: "100%" }} />
                <Button
                  className={classes.btnSalvar}
                  variant="contained"
                  disabled={saving}
                  onClick={() => {
                    dataSave()
                  }}
                >
                  Salvar alterações
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
