import moment from "moment"
import Resizer from "react-image-file-resizer"
import api from "../Services/api"

export async function readFile(fullFile) {
  let file = await fileResize(fullFile)
  if (file)
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener("load", () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  return null
}

export function fileResize(file) {
  return new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      1600,
      1600,
      "PNG",
      100,
      0,
      uri => {
        resolve(uri)
      },
      "file"
    )
  })
}

export function isLogin() {
  if (
    localStorage.getItem("tokenCreateAt") !== null &&
    localStorage.getItem("tokenExpMinutes") !== null
  ) {
    let isExpired = moment
      .duration(
        moment(localStorage.getItem("tokenCreateAt")).add(
          localStorage.getItem("tokenExpMinutes"),
          "minutes"
        )
      )
      .asMinutes()

    if (isExpired <= moment.duration(moment()).asMinutes()) {
      logout()
    }
  }
  return localStorage.getItem("token") !== null
}

export function logout() {
  localStorage.clear()
  document.location.reload()
}

export function createLeedPro(banner_id) {
  api
    .post("/pro_lead", { banner_id, user_id: localStorage.getItem("user_id") })
    .then(res => {
      console.log("leed_criado")
    })
    .catch(error => {
      console.log(error)
    })
}

export function camelize(str) {
  return str.replace(/\W+(.)/g, function (match, chr) {
    return chr.toUpperCase()
  })
}


export const formatCnpj = (value) => {
  return value
    .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura os dois últimos 2 números, com um - antes dos dois números
}
export function formatPhone(phoneNumber) {

  return phoneNumber
  .replace(/\D/g,'')
  .replace(/(\d{2})(\d)/,"($1) $2")
  .replace(/(\d)(\d{4})$/,"$1-$2")
}
