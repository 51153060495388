import { CircularProgress } from "@material-ui/core"
import moment from "moment"
import { useEffect, useState } from "react"
import { BiCopy } from "react-icons/bi"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../../../../Componentes/Alerts/CustomAlert"
import { button } from "../../../../../Componentes/Inputs/TextFild"
import api from "../../../../../Services/api"
import { useStyles } from "./style"

export default function Pix() {
  const classes = useStyles()
  const history = useHistory()
  const couponCheckout = sessionStorage.getItem("cupom_checkout")
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [alertText, setAlertText] = useState("")
  const [loadPage, setLoadPage] = useState(true)
  const [pixData, setPixData] = useState()
  const [paid, setPaid] = useState(false)
  const [isActive, setIsActive] = useState(true)

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(pixData?.payload)
    setAlertText("Pix copiado com sucesso")
    setShowAlertOk(true)
  }

  async function getPixInfo() {
    try {
      const data = await api.post("/lojinha/asaas/planos/pix", {
        lojinha_id: localStorage.getItem("id"),
        cupom: couponCheckout
      })
      setPixData(data.data)
      localStorage.setItem("pix_id", data.data.payment_id)
    } catch (error) {
      const { message } = error.response.data
      if (message === "Você já é um usuário Pro") {
        history.push({
          pathname: "/planos/v2/checkout/success"
        })
      }
      console.log("error", error)
    } finally {
      setLoadPage(false)
      setAlertText("Erro no pix, entre em contato com o suporte")
      showAlertError(true)
    }
  }

  async function getPixStatus() {
    try {
      const data = await api.get(
        `/lojinha/asaas/planos/status/${localStorage.getItem("pix_id")}`
      )
      if (data.data.status === "RECEIVED") {
        setPaid(true)
        history.push({
          pathname: "/planos/v2/checkout/success"
        })
      }
    } catch (error) {
      console.log("error", error)
      setAlertText("Erro no pix, entre em contato com o suporte")
      showAlertError(true)
    }
  }

  useEffect(() => {
    getPixInfo()
  }, [])

  useEffect(() => {
    let intervalID

    const startInterval = () => {
      intervalID = setInterval(() => {
        getPixStatus()
      }, 15000)
    }

    const stopInterval = () => {
      clearInterval(intervalID)
    }

    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopInterval()
        setIsActive(false)
      } else if (isActive) {
        startInterval()
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange)
    startInterval()

    return () => {
      stopInterval()
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [isActive])

  return (
    <>
      {loadPage ? (
        <div
          style={{
            backgroundColor: "#f9f9f9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "80vh"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <>
          <div className={classes.header}>
            <h3>Ativação aguardando pagamento</h3>
          </div>
          <div className={classes.containerContent}>
            <div className={classes.title}>
              <h3>Estamos aguardando o pagamento</h3>
              {paid === false ? (
                <CircularProgress color="secondary" size="20px" />
              ) : (
                ""
              )}

              <h4>
                Copie o código abaixo para pagar via Pix em qualquer aplicativo
                habilitado
              </h4>
            </div>

            <a href="#" onClick={() => copyToClipboard()}>
              <div className={classes.code}>
                <h3>{pixData?.payload}</h3>
                <BiCopy style={{ marginRight: "0.3rem" }} color="#EA3458" />
              </div>
            </a>

            <h3 className={classes.dateLimit}>
              Pague até: {moment(pixData?.expirationDate).format("DD/MM/YYYY")} às{" "}
              {moment(pixData?.expirationDate).format("HH:mm")}
            </h3>

            <div className={classes.Button}>
              {button({
                name: `Copiar código PIX`,
                color: "#52EB58",
                funcao: () => copyToClipboard()
              })}
            </div>

            {/* <h2 className={classes.shareButton}>Compartilhar código</h2> */}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <CustomAlert
              type="success"
              alertText={alertText}
              show={showAlertOk || showAlertError}
              close={() => closeAlerts()}
            />
          </div>
        </>
      )}
    </>
  )
}
