import CheckIcon from "@material-ui/icons/Check"
import React from "react"
import { useSettings } from "../../../ContextAPI/settingsContext"
import { useStyles } from "./style"

export default function ColorSelect({ type, setSelectedPrimaryColor, setSelectedSecondaryColor }) {
  const classes = useStyles()
  const handleColor = color => {
    handlePrimaryColor(color)
    setSelectedPrimaryColor({
      hex: "",
      rgb: { r: "", g: "", b: "", a: "" },
      hsv: { s: "", v: "", h: "" }
    })
  }

  const handleColor2 = color => {
    handleSecondaryColor(color)
    setSelectedSecondaryColor({
      hex: "",
      rgb: { r: "", g: "", b: "", a: "" },
      hsv: { s: "", v: "", h: "" }
    })
  }
    const {
      primaryColorsPallete,
      secondaryColorsPallete,
      primaryColor,
      secondaryColor,
      handlePrimaryColor,
      handleSecondaryColor
    } = useSettings()

    return (
      <div className={classes.SelectWrapper}>
        {type === "primary" &&
          primaryColorsPallete.map(color => (
            <button
              type="button"
              onClick={() => handleColor(color)}
              className={classes.ColorBtn}
              style={{ backgroundColor: color }}
            >
              {color === primaryColor && (
                <CheckIcon style={{ color: "#fff", fontSize: "2rem" }} />
              )}
            </button>
          ))}
        {type === "secondary" &&
          secondaryColorsPallete.map(color => (
            <button
              type="button"
              onClick={() => handleColor2(color)}
              className={classes.ColorBtn}
              style={{ backgroundColor: color }}
            >
              {color === secondaryColor && (
                <CheckIcon style={{ color: "#fff", fontSize: "2rem" }} />
              )}
            </button>
          ))}
      </div>
    )
  }

