import { useEffect, useCallback } from "react"
import React from "react"
import { useState } from "react"
import Confetti from "react-confetti"
import Trophy from "../../../../../Assets/img/trophy.png"
import { useStyles } from "./style"
import { Button } from "@material-ui/core"

export default function SuccessPayment() {
  const [confettiShow, setConfettiShow] = useState(true)
  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => {
      setConfettiShow(true)
    }, 8000)
  })

  setTimeout(() => {
    setConfettiShow(false)
  }, 3000)

  return (
    <>
      <div className={classes.container}>
        <Confetti numberOfPieces="300" recycle={confettiShow} />
        <img src={Trophy} alt="troféu" />
        <div>
          <h1>Agora você é PRO</h1>
          <h2>
            Explore as incríveis funcionalidades exclusivas disponíveis para você,
            aproveite ao máximo!
          </h2>
        </div>
        <Button
          style={{
            color: "#FFFFFF",
            border: "#FFFFFF 3px solid",
            width: "80%",
            height: "8%",
            fontSize: "2rem",
            marginTop: "1rem",
            borderRadius: "50px"
          }}
          variant="outlined"
          onClick={() => {
            window.location.href = "/home"
          }}
        >
          Começar agora
        </Button>
      </div>
    </>
  )
}
