import { InputAdornment, InputBase } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import Cancel from "@material-ui/icons/Cancel"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import addProductIcon from "../../Assets/svg/addProductIcon.svg"
import filtro from "../../Assets/svg/filtro.svg"
import InfiniteScroll from "../../Componentes/InfiniteScroll"
import TopoMeusProdutos from "../../Componentes/TopoMeusProdutos"
import { useProduct } from "../../ContextAPI/productContext"
import { useSettings } from "../../ContextAPI/settingsContext"
import api from "../../Services/api"
import ModalOops from "../Planos/Alertas/AlertaModal"
import Filtro from "./FiltroListagemProduto"
import ModalExcluirProduto from "./ModalExcluirProduto"
import { useDevice } from "../../ContextAPI/deviceContext"
import { useStyles } from "./style"

export default function Listagem() {
  const classes = useStyles()
  const history = useHistory()
  const { lojinhaInfo, getHasProduct } = useSettings()
  const { setCurrentProduct, setIsEdit, query, setQuery } = useProduct()
  const [searchText, setSearchText] = useState("")
  const [openFilter, setOpenFilter] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [idProduct, setIdProduct] = useState(null)
  const [modalOops, setModalOops] = useState(false)
  const [productList, setProductList] = useState([])
  const { checkDevice } = useDevice()
  const [clearSearch, setClearSearch] = useState(false)

  function planPro() {
    history.push("/planos")
  }

  function openModalPro() {
    setModalOops(true)
  }

  function closeModalPro() {
    setModalOops(false)
  }

  useEffect(() => {
    setCurrentProduct(null)
    setIsEdit(false)
    getHasProduct()
    getAllProducts()
  }, [])

  function handleProductEdit(product) {
    setIsEdit(true)
    history.push({ pathname: "/produto", id: product.id })
  }

  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  function handleSearch() {
    if (searchText !== "") {
      setQuery({ search: searchText, last: 1 })
      setClearSearch(true)
    } else {
      setSearchText("")
      setQuery({})
    }
  }

  function handleProduct(product) {
    setIdProduct(product)
    handleOpenModal()
  }

  function handleOpenModal() {
    setOpenModal(true)
  }

  function handleCloseModal() {
    window.location.reload()
  }

  function handleClearSearch() {
    setSearchText("")
    setQuery({})
    setClearSearch(false)
  }

  const getAllProducts = async () => {
    const resProduct = await api.get(`/product/getall/${localStorage.getItem("id")}`)
    setProductList(resProduct.data.data)
  }

  return (
    <>
      {!openFilter ? (
        <>
          <div className="page-container">
            <div className="page-align">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 20px 0 10px"
                }}
              >
                <TopoMeusProdutos name="Meus Produtos" />
                <div
                  style={{
                    cursor: "pointer"
                  }}
                  onClick={() => setOpenFilter(true)}
                  aria-hidden="true"
                >
                  <img width={28} height="auto" src={filtro} alt="filtro-icon" />
                </div>
              </div>

              <div style={{ paddingBottom: "5px" }}>
                <div className={classes.search}>
                  <InputBase
                    placeholder="Procurar produto..."
                    classes={{ root: classes.inputRoot }}
                    endAdornment={
                      <InputAdornment position="end">
                        {clearSearch ? (
                          <button
                            style={{
                              backgroundColor: "transparent",
                              border: "none"
                            }}
                            onClick={handleClearSearch}
                          >
                            <Cancel />
                          </button>
                        ) : (
                          <button
                            style={{
                              backgroundColor: "transparent",
                              border: "none"
                            }}
                            onClick={handleSearch}
                          >
                            <SearchIcon />
                          </button>
                        )}
                      </InputAdornment>
                    }
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                  />
                </div>
              </div>
              <InfiniteScroll
                handleDelete={handleProduct}
                handleEdit={handleProductEdit}
                hideAddButton={openModal}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  zIndex: 99999
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    bottom: "15px"
                  }}
                >
                  {openModal ? null : (
                  <button
                    onClick={() => {
                      if (productList.length >= 5 && !hasPro) {
                        openModalPro();
                      } else {
                        history.push("/produto");
                      }
                    }}
                    style={{ border: "none", backgroundColor: "transparent" }}
                  >
                    <img
                      src={addProductIcon}
                      width={64}
                      height="auto"
                      alt="addProductIcon-icon"
                    />
                  </button>
                )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Filtro fechar={() => setOpenFilter(false)} />
      )}

      {openModal ? (
        <ModalExcluirProduto
          isOpen={handleOpenModal}
          idProduct={idProduct}
          handleClose={handleCloseModal}
        />
      ) : (
        ""
      )}

      {modalOops && (
        <ModalOops isOpen={modalOops} handleClose={closeModalPro} isPush={planPro} />
      )}
    </>
  )
}
