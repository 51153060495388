import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  btnFooterWhite: {
    height: "50px",
    backgroundColor: "#fff"
  },
  btnFooterPink: {
    height: "50px",
    marginBottom: "20px"
  },
  deliveryLabel: {
    color: "#A4A4A4",
    width: "100%",
    textAlign: "center",
    margin: "0",
    minHeight: "25px"
  },
  header: {
    boxShadow: "0px 5px 4px 1px #d9d9d9",
    width: "100vw",
    maxWidth: "600px",
    marginLeft: " -20px",
    marginBottom: "10px",
    "& .MuiTabs-flexContainer > *": {
      fontSize: "0.8rem"
    }
  },
  modalLeaving: {
    textAlign: "center",
    "& .MuiDialog-paper": {
      bottom: "0",
      position: "fixed!important",
      margin: "0",
      padding: "10px 20px",
      width: "100%",
      boxSizing: "border-box",
      borderRadius: "15px 15px 0px 0px"
    }
  },
  deliveryTabPanel: {
    "& button": {
      minHeight: "max-content"
    },
    "& .MuiTab-textColorSecondary:not(.Mui-selected)": {
      color: "#353535"
    },
    '& .PrivateTabIndicator-root-375': {
      height: '3px'
    }
  },
  headerOrder: {
    padding: '20px 20px 12px 20px',
    display: 'flex',
    justifyContent: 'space-between',

    '& p': {
      margin: 0,
      fontWeight: '600',
      fontSize: '14px'
    }
  }
}))
