import { useStyles } from "./style"
import Topo from "../../Componentes/TopCategorias"
import { Button, CircularProgress } from "@material-ui/core"
import { useEffect, useRef, useState } from "react"
import { globalStyles } from "../../Componentes/CssGlobal"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import { useHistory } from "react-router-dom"
import api from "../../Services/api/index"
import noVisitors from "../../Assets/Home/noProducts.svg"

export default function Notificacoes() {
  const classes = useStyles()
  const history = useHistory()
  const listInnerRef = useRef()
  const [orderBy, setOrderBy] = useState({ allNotifications: 1 })
  const [notificationList, setNotificationList] = useState([])
  const [noReadNotificationList, setNoReadNotificationList] = useState([])
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  // const { setQueryOrder, queryOrder } = ({allNotifications: 1 })

  // useEffect(() => {
  //   if (queryOrder.noRead) {
  //     setOrderBy({ noRead: queryOrder.noRead})
  //   }

  //   if (queryOrder.allNotifications) {
  //     setOrderBy({ allNotifications: queryOrder.allNotifications})
  //   }
  // }, [])

  async function getInformationsData() {
    setLoading(true)
    api.get(`/notification/list/${localStorage.getItem("user_id")}`).then(res => {
      const { data } = res.data.data

      setNotificationList(data)
      setLoading(false)
    })
  }

  async function getNoRead() {
    setLoading(true)
    api
      .get(`/notification/list/noread/${localStorage.getItem("user_id")}`)
      .then(res => {
        const { data } = res.data.data

        setNoReadNotificationList(data)
        setLoading(false)
      })
  }

  const loadNextPage = async (refresh = false) => {
    if (refresh === true) {
      const { data } = await api.get(
        `/notification/list/${localStorage.getItem("user_id")}?page=${page}`
      )
      setNotificationList([...data.data.data])
      if (data.data.next_page_url !== null) {
        setHasMore(true)
        setPage(oldState => oldState + 1)
      } else setHasMore(false)
    } else {
      const { data } = await api.get(
        `/notification/list/${localStorage.getItem("user_id")}?page=${page}`
      )
      setNotificationList(oldState => [...oldState, ...data.data.data])
      if (data.data.next_page_url !== null) {
        setHasMore(true)
        setPage(oldState => oldState + 1)
      } else setHasMore(false)
    }
    setLoading(false)
    return true
  }

  async function handleeUpdateRead(notificationId, redirect, routes) {
    let body = {
      target_read: 1
    }

    api.put(`/notification/update/${notificationId}`, body)

    redirect === "webpage"
      ? history.push({ pathname: "/notificacoes-externas", id: notificationId })
      : redirect === "internal"
      ? history.push(`/${routes}`)
      : history.push("/meus-pedidos")
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (
        Math.floor(scrollTop + clientHeight) === scrollHeight &&
        hasMore === true
      ) {
        loadNextPage()
      }
    }
  }

  useEffect(() => {
    /* getInformationsData() */
    loadNextPage(true)
  }, [])


  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <Topo name="Notificações" />
          </div>
          <div className={`${classes.orderBy}__buttonsContainer`}>
            <Button
              style={{
                background: orderBy.allNotifications === 1 && "#051626",
                color: orderBy.allNotifications === 1 && "white",
                opacity: "0.9"
              }}
              onClick={() => {
                setOrderBy({ allNotifications: 1 })
                getInformationsData()
              }}
            >
              Tudo
            </Button>

            <Button
              style={{
                background: orderBy.noRead === 1 && "#051626",
                color: orderBy.noRead === 1 && "white",
                opacity: "0.9"
              }}
              onClick={() => {
                setOrderBy({ noRead: 1 })
                getNoRead()
              }}
            >
              Não lidas
            </Button>
          </div>
          <div
            className="infinite-scroll"
            onScroll={() => onScroll()}
            ref={listInnerRef}
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  minHeight: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <>
                {orderBy.noRead ? (
                  noReadNotificationList.length === 0 ? (
                    <div className={classes.noVisitors}>
                      <img
                        src={noVisitors}
                        alt="sem produtos"
                        style={{
                          marginTop: "10px",
                          marginBottom: "12px",
                          maxWidth: "165px",
                          maxHeight: "115px"
                        }}
                      />
                      <span className={classes.infoText}>
                        🔎 nenhuma notificação não lida encontrada
                      </span>
                    </div>
                  ) : (
                    noReadNotificationList?.map((item, index) => {
                      return (
                        <>
                          <div
                            className={classes.boxConteudo}
                            onClick={() => {
                              handleeUpdateRead(item.id, item.type, item.url)
                            }}
                            aria-hidden="true"
                          >
                            <div className={classes.textContainer}>
                              {item.target_read === 0 ? (
                                <div>
                                  <FiberManualRecordIcon
                                    style={{
                                      margin: "0 15px",
                                      color: "#3498DB",
                                      fontSize: "1.9rem"
                                    }}
                                  />
                                </div>
                              ) : (
                                <div>
                                  <RadioButtonUncheckedIcon
                                    style={{ margin: "0 20px", color: "#3498DB" }}
                                  />
                                </div>
                              )}

                              <div style={{ maxWidth: "90%" }}>
                                <div className={classes.title}>{item.title}</div>
                                <div className={classes.btnalinhamento}>
                                  <div className={classes.subtitle}>
                                    {item.content}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ArrowForwardIosIcon fontSize="small" color="disabled" />
                          </div>
                          <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                        </>
                      )
                    })
                  )
                ) : notificationList.length === 0 ? (
                  <div className={classes.noVisitors}>
                    <img
                      src={noVisitors}
                      alt="sem produtos"
                      style={{
                        marginTop: "10px",
                        marginBottom: "12px",
                        maxWidth: "165px",
                        maxHeight: "115px"
                      }}
                    />
                    <span className={classes.infoText}>
                      🔎 você ainda não tem nenhuma notificação
                    </span>
                  </div>
                ) : (
                  notificationList?.map((item, index) => {
                    return (
                      <>
                        <div
                          className={classes.boxConteudo}
                          onClick={() => {
                            handleeUpdateRead(item.id, item.type, item.url)
                          }}
                          aria-hidden="true"
                        >
                          <div className={classes.textContainer}>
                            {item.target_read === 0 ? (
                              <div>
                                <FiberManualRecordIcon
                                  style={{
                                    margin: "0 15px",
                                    color: "#3498DB",
                                    fontSize: "1.9rem"
                                  }}
                                />
                              </div>
                            ) : (
                              <div>
                                <RadioButtonUncheckedIcon
                                  style={{ margin: "0 20px", color: "#3498DB" }}
                                />
                              </div>
                            )}

                            <div style={{ maxWidth: "90%" }}>
                              <div className={classes.title}>{item.title}</div>
                              <div className={classes.btnalinhamento}>
                                <div className={classes.subtitle}>
                                  {item.content}
                                </div>
                              </div>
                            </div>
                          </div>
                          <ArrowForwardIosIcon fontSize="small" color="disabled" />
                        </div>
                        <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                      </>
                    )
                  })
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
