import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  cardPhoto: {
    width: "140px",
    height: "120px",
    backgroundColor: "#EAEAEA",
    borderRadius: "5px",
    border: "2px dashed #A4A4A4",
    display: "flex",
    justifyContent: "center",
    margin: "15px 5px 10px 5px"
  },

  areaBtn: {
    cursor: "pointer",
    width: "140px",
    height: "120px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#EAEAEA",
    borderRadius: "5px",
    border: "2px dashed #A4A4A4",
    margin: "15px 9px 10px 9px"
  },

  imgProduto: {
    width: "83px",
    height: "65px",
    objectFit: "cover",
    borderRadius: "5px",
    margin: "2px 5px 2px 5px",
    cursor: "pointer"
  },
  positionFotos: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start"
  }
}))
