/* eslint-disable no-empty */
import { Checkbox, CircularProgress } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import { globalStyles } from "../../../../Componentes/CssGlobal"
import { useStyles } from "../../style"
import Boleto from "../Assets/Boleto"
import CartoesdeCredito from "../Assets/CartaoCredito"
import CartaoDebito from "../Assets/CartaoDebito"
import Dinheiro from "../Assets/Dinheiro"
import PicPay from "../Assets/picpay"
import Pix from "../Assets/pix"
import ValeAlimentacao from "../Assets/ValeAlimentacao"
import ValeRefeicao from "../Assets/ValeRefeicao"
import Combinar from "../Assets/Combinar"
import conexao from "../../../../Services/api"

export default function ListaMeiosPagamentos({ getBody }) {
  const classesGlobal = globalStyles()
  const classes = useStyles()

  const lojinha_id = localStorage.getItem("id")

  const [loadPage, setLoadPage] = useState(true)
  const [data, setData] = useState()
  const [ids, setIds] = useState([])
  const [dataInfo, setDataInfo] = useState()



  const icones = {
     "credit_card": <CartoesdeCredito /> ,
     "debit_card": <CartaoDebito /> ,
     "ticket": <Boleto /> ,
     "picpay": <PicPay /> ,
     "food_voucher": <ValeRefeicao /> ,
     "meal_voucher": <ValeAlimentacao /> ,
     "pix": <Pix /> ,
     "cash": <Dinheiro /> ,
     "to_match": <Combinar />
  }



  const iconesColorido = {
    "credit_card": <CartoesdeCredito fill="#EA3458" /> ,
    "debit_card": <CartaoDebito fill="#EA3458"/> ,
    "ticket": <Boleto fill="#EA3458"/> ,
    "picpay": <PicPay fill="#EA3458"/> ,
    "food_voucher": <ValeRefeicao fill="#EA3458"/> ,
    "meal_voucher": <ValeAlimentacao fill="#EA3458"/> ,
    "pix": <Pix fill="#EA3458"/> ,
    "cash": <Dinheiro fill="#EA3458"/> ,
    "to_match": <Combinar fill="#EA3458"/>
}

  function translate(name) {
    let translateName = ''
    switch (name){
    case "credit_card": translateName = "Cartão de crédito"; break;
    case "debit_card": translateName = "Cartão de Débito"; break;
    case "ticket": translateName =  "Boleto"; break;
    case "picpay": translateName = "PicPay"; break;
    case "food_voucher" : translateName = "Vale Refeição"; break;
    case "meal_voucher": translateName = "Vale Alimentação"; break;
    case "pix": translateName = "Pix"; break;
    case "cash": translateName = "Dinheiro"; break;
    case "to_match": translateName = "A combinar"; break;

    default: translateName = ''; break;
    }
    return translateName
  }

  async function getPaymentes() {
    const dataPaymentSave = await conexao.get(`/lojinha/payment/get/${lojinha_id}`)
    const newDataPayment = dataPaymentSave.data.data.payment_data.manual.payment_type_list
    setDataInfo(newDataPayment)
    setData(newDataPayment)
    setLoadPage(false)
  }
  useEffect(() => {
    getPaymentes()
  }, [])

  function getCheck(index) {

    setDataInfo((oldState) => {
      oldState[index] = { ...oldState[index], active: oldState[index].active === 0 ? 1 : 0}
      return [
        ...oldState
      ]
    })

  }

  // corpo para requisição

  useEffect(() => {
    getBody(dataInfo)
  }, [dataInfo])

  return (
    <>
      {loadPage ? (
        <div
          style={{
            backgroundColor: " #F9F9F9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <>
          {

          dataInfo.map((payment, index) => {
            return (
              <div className={classes.rowPagamento}>
                <div className={classes.textInfo}>
                  <div className={classes.paymentIcon}>
                    {payment.active ? icones[payment.type] : iconesColorido[payment.type]}
                  </div>
                  <div className={classes.paymentText}>{payment.name}</div>
                </div>
                <div className={classes.paymentCheckbox}>
                  <Checkbox
                    id={payment.name}
                    checked={payment.active}
                    name={payment.type}
                    onChange={e => getCheck(index)}
                  />
                </div>
              </div>
            )
          })
          }
        </>
      )}
    </>
  )
}
