import { makeStyles } from "@material-ui/core/styles"
import bkgPro from "../../Assets/svg/bkg-pro.svg"

export const useStyles = makeStyles({

  BkgImgMobile: {
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${bkgPro})`,
    backgroundColor: "#182C40",
    width: "80vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },

  BkgImgDesktop: {
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${bkgPro})`,
    /* backgroundColor: "#182C40", */
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },

  PlanosAlertaModal: {
    "& .MuiDialogTitle-root": {
      padding: "0px"
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "100%"
    }
  },
  PlanosAlertaModalHeader: {
    backgroundColor: "#182C40",
    display: "flex",
    alignItems: "center",
    paddingTop: "1rem",
    width: "100%",
    "& h2": {
      textAlign: "center",
      margin: 0,
      padding: 0,
      fontSize: "80%",
      flex: 1
    },
    "& button": {
      width: "10%",
      margin: 0,
      padding: 0,
      border: "none",
      backgroundColor: "transparent"
    }
  },
  PlanosAlertaModalBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      fontSize: "100%",
      fontWeight: "300",
      textAlign: "center"
    },
    "& span": {
      color: "#EA3458",
      fontWeight: "600"
    },
    "& img": {
      width: "100%",
      maxWidth: "170px",
      margin: "10px 0"
    }
  }
})
export default useStyles
