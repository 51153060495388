import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    mainContainer: {
        padding: "2.5rem",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
    },
    bannerContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "0.25rem"
    },

    bannerButton: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "3.5rem",
        backgroundColor: "#fff",
        border: "1px solid #D0D0D0",
        borderRadius: "0.3rem",
        justifyContent: "space-between",
        padding: "1.5rem",
        cursor: "pointer",
        fontSize: "1rem",
        color: "#555",
        transition: "all 0.2s ease-in-out",
    }
}))