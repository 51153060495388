export default function PicPay(props) {
  return (
    <svg
      width="25"
      height="23"
      viewBox="0 0 25 23"
      fill={props.fill ? "#a4a4a4" : " #EA3458"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.149 0.653076V8.50412H25V0.653076H17.149ZM18.4573 1.96141H23.6917V7.19578H18.4573V1.96141ZM19.7656 3.26974V5.88745H22.3833V3.26974H19.7656ZM3.92708 4.57808V7.88537H7.44375C9.675 7.88537 10.9417 8.9687 10.9417 10.9468C10.9417 12.975 9.675 14.0833 7.44375 14.0833H3.92604V7.88537H0V22.3479H3.92604V17.3895H7.64479C12.1583 17.3895 14.7437 14.9395 14.7437 10.8062C14.7437 6.87808 12.1583 4.57912 7.64583 4.57912L3.92708 4.57808Z"
        fill={props.Color}
      />
    </svg>
  )
}
