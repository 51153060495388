import { useHistory } from "react-router-dom"
import { useStyles } from "../style"
import requestBanners from "../../../Services/api/banners"
import { useState, useEffect } from "react"
import Topo from "../../../Componentes/TopCategorias"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { CircularProgress } from "@material-ui/core"
import ArrowRight from "../../../Assets/svg/arrowRight.svg"
const initialBanners = [
    { banner_position: "1" },
    { banner_position: "2" },
]
export default function SecondaryBanners() {
    const history = useHistory()
    const [secondaryBanners, setSecondaryBanners] = useState(initialBanners)
    const [isLoading, setIsLoading] = useState(true)
    const [alertType, setAlertType] = useState("")
    const [showAlertOk, setShowAlertOk] = useState(false)
    const [showAlertError, setShowAlertError] = useState(false)
    const classes = useStyles()
    const getBanners = async () => {
        try {
            const { data } = await requestBanners.getSecondaryBanners()
            const receivedBanners = data.data
            setSecondaryBanners(currentBanners =>
                currentBanners.map(banner =>
                    receivedBanners.find(receivedBanner => receivedBanner.banner_position == banner.banner_position) || banner
                ))
            setIsLoading(false)
        } catch (e) {
            setAlertType("error")
            setShowAlertOk(true)
        }
    }
    const closeAlerts = () => {
        setShowAlertOk(false)
        setShowAlertError(false)
    }
    useEffect(() => {
        getBanners()
    }, [])
    return (
        <>
            <div className="page-container">
                <div className="page-align" style={{ justifyContent: "flex-start" }}>
                    <div>
                        <Topo rota={"/personalizar-lojinha"} name="Banners secundarios" />
                    </div>
                    <div className="infinite-scroll">
                        {isLoading ? (
                            <div
                                style={{
                                    backgroundColor: " #fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    height: "100%"
                                }}
                            >
                                <CircularProgress color="secondary" size="40px" />
                            </div>
                        ) : (
                            <div className={classes.mainContainer}>
                                {secondaryBanners.map((banner, index) => (
                                    <div key={index} className={classes.bannerContainer}>
                                        <label style={{ fontSize: "1.25rem", color: "#555" }}>
                                            Banner {banner.banner_position}
                                        </label>
                                        <button
                                            className={classes.bannerButton}
                                            onClick={() => history.push({
                                                pathname: "/adicionar-banners",
                                                state: { banner, type: "secondary" }
                                            })}
                                        >
                                            {banner.banner_name || "Adicionar banner"}
                                            <img src={ArrowRight} alt="arrow" />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <CustomAlert
                    type={alertType}
                    alertText={
                        alertType === "success"
                            ? ""
                            : "Erro ao carregar banners secundarios!"
                    }
                    show={showAlertOk || showAlertError}
                    close={() => closeAlerts()}
                />
            </div>
        </>
    )
}