import React, { useEffect } from "react"
import { IoIosArrowBack } from "react-icons/io"
import { useStyles } from "./style"
import { useHistory } from "react-router-dom"

export default function TopoCadastro(props) {
  const classes = useStyles()
  const history = useHistory()

  const arr = [0, 1, 2, 3, 4, 5]

  useEffect(() => {}, [props])

  return (
    <div className={classes.top}>
      <IoIosArrowBack
        style={{
          fontSize: "35px",
          color: "#EA3458",
          cursor: "pointer",
          minWidth: "40px",
          marginRight: "10px"
        }}
        onClick={() => {
          let teste = props.id - 1
          if (teste < 0) {
            history.push("/")
          } else {
            props.prox(teste)
          }
        }}
      />
      <div className={classes.dots}>
        <h2>Cadastro em 6 passos</h2>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {arr.map(item => {
            if (item === props.id) {
              return (
                <div
                  style={{
                    background: "#EA3458",
                    margin: "3px",
                    height: "3px",
                    minWidth: "35px",
                    borderRadius: "5px",
                    marginTop: "5px",
                    boxShadow:
                      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
                  }}
                ></div>
              )
            }
            return (
              <div
                style={{
                  background: "#d8d8d8",
                  margin: "3px",
                  height: "3px",
                  minWidth: "35px",
                  borderRadius: "5px",
                  marginTop: "5px"
                }}
              ></div>
            )
          })}
        </div>
      </div>

      <div className={classes.space} />
    </div>
  )
}
