import React, { useState } from 'react'

import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { FormHelperText } from '@material-ui/core'

export default function PasswordInput({ getPassword, error, passwordType }) {
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false
  })

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })

    getPassword({ name: passwordType, value: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <>
      <OutlinedInput
        id="outlined-adornment-password"
        type={values.showPassword ? 'text' : 'password'}
        value={values.password}
        onChange={handleChange('password')}
        error={error}
        size="small"
        color="secondary"
        helperText="Preencha o campo CEP."
        fullWidth
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {values.showPassword ? (
                <Visibility style={{ color: '#353535' }} />
              ) : (
                <VisibilityOff style={{ color: '#353535' }} />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText style={{ color: 'red' }}>
        {error ? 'A senha precisa ter mais de 6 caracteres' : ''}
      </FormHelperText>
    </>
  )
}
