import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  index: {
    display: "flex",
    flexDirection: "row"
  },

  ExibindoPedido: {
    width: "50%",
    display: "flex",
    justifyContent: "center"
  },

  tipoFiltro: {
    fontSize: "12px"
  },

  Filtro: {
    width: "50%",
    textAlign: "center",
    paddingTop: "7px"
  },

  text: {
    color: " #EA3458",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  },
  search: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  searchIcon: {
    margin: "0 15px",
    height: "100%",
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#EA3458",
    cursor: "pointer",
    fontSize: "1.8rem"
  },
  inputRoot: {
    border: "solid 1px  #d0d0d0",
    height: "40px",
    borderRadius: "5px ",
    flex: "1",
    padding: "0 5px"
  }
}))
