import { TextField } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import InputBase from "@material-ui/core/InputBase"
import NativeSelect from "@material-ui/core/NativeSelect"
import { withStyles } from "@material-ui/core/styles"
import React, { useState, useEffect } from "react"
import TopFiltro from "../../../Componentes/TopFiltros"
import { useProduct } from "../../../ContextAPI/productContext"
import { StateGlobal } from "../../../ContextAPI/index"
import { useStyles } from "./style"

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(4)
    }
  },
  input: {
    width: "100%",
    borderRadius: 5,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid  #051626",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    color: "#051626",
    fontWeight: "500",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#051626",
      boxShadow: "0 0 0 0.1rem #051626cc"
    }
  }
}))(InputBase)

export default function Listagem() {
  const classes = useStyles()

  const { setQueryOrder, queryOrder } = useProduct()
  const { setOrderPagesIndex } = StateGlobal()

  const [orderBy, setOrderBy] = useState({})
  const [filterBody, setFilterBody] = useState({
    lojinha_id: localStorage.getItem("id")
  })

  function clearState() {
    setQueryOrder({})
    setOrderPagesIndex(0)
  }

  useEffect(() => {
    setFilterBody({
      started_at: queryOrder.started_at,
      finished_at: queryOrder.finished_at,
      payment_status: queryOrder.payment_status,
      status: queryOrder.status,
    })

    if (queryOrder.value) {
      setOrderBy({ value: queryOrder.value})
    }

    if (queryOrder.older) {
      setOrderBy({ older: queryOrder.older})
    }

    if (queryOrder.last) {
      setOrderBy({ last: queryOrder.last})
    }
  }, [])

  function filterData() {
    let data = {
      ...filterBody,
      ...orderBy
    }

    setQueryOrder(data)
    setOrderPagesIndex(0)
  }

  function handleDate(e) {
    const { name, value } = e.target
    let date = ""

    date = value.split("-")
    date = `${date[2]}/${date[1]}/${date[0]}`

    setFilterBody(prevState => ({ ...prevState, [name]: date }))
  }

  function formatDate(date) {
    let newDate = date && date.split("/")
    return date && `${newDate[2]}-${newDate[1]}-${newDate[0]}`
  }

  return (
    <div className="page-container">
      <div className="page-align">
        <TopFiltro name="Filtro" />

        <div className="infinite-scroll">
          <div className={classes.orderBy}>
            <p>Ordenar Por:</p>

            <div className={`${classes.orderBy}__buttonsContainer`}>
              <Button
                style={{
                  background: orderBy.last === 1 && "#051626",
                  color: orderBy.last === 1 && "white",
                  opacity: "0.9"
                }}
                onClick={() => setOrderBy({ last: 1 })}
              >
                Mais recentes
              </Button>

              <Button
                style={{
                  background: orderBy.older === 1 && "#051626",
                  color: orderBy.older === 1 && "white",
                  opacity: "0.9"
                }}
                onClick={() => setOrderBy({ older: 1 })}
              >
                Mais antigos
              </Button>

              <Button
                style={{
                  background: orderBy.value === 1 && "#051626",
                  color: orderBy.value === 1 && "white",
                  opacity: "0.9"
                }}
                onClick={() => setOrderBy({ value: 1 })}
              >
                Valor
              </Button>
            </div>
          </div>

          <div className={classes.filterByDate}>
            <p>Exibir por data:</p>

            <div className={`${classes.filterByDate}__pickerContainer`}>
              <div className={`${classes.filterByDate}__datePicker`}>
                <p>De:</p>
                <TextField
                  color="secondary"
                  name="started_at"
                  id="date"
                  type="date"
                  value={formatDate(filterBody.started_at)}
                  onChange={e => handleDate(e)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>

              <div className={`${classes.filterByDate}__datePicker`}>
                <p>Até:</p>
                <TextField
                  color="secondary"
                  name="finished_at"
                  id="date"
                  type="date"
                  value={formatDate(filterBody.finished_at)}
                  onChange={e => handleDate(e)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
            </div>
          </div>

          <div className={classes.filterByStatus}>
            <div
              style={{ marginBottom: "60px" }}
              className={`${classes.filterByStatus}__statusSelect`}
            >
                <p>Exibir por status de pagamento:</p>

              <FormControl>
                <NativeSelect
                  id="demo-customized-select-native"
                  value={filterBody.payment_status}
                  onChange={e => {
                    setFilterBody(prevState => ({
                      ...prevState,
                      payment_status: e.target.value === 'all' ? '' : e.target.value
                    }))
                  }}
                  input={<BootstrapInput />}
                >
                  <option value="all">Todos</option>
                  <option value="pending">Pendente</option>
                  <option value="processing">Processando</option>
                  <option value="canceled">Cancelado</option>
                  <option value="paid">Pago</option>
                  <option value="failed">Erro no pagamento</option>
                </NativeSelect>
              </FormControl>
            </div>

            <div className={`${classes.filterByStatus}__statusSelect`}>
              <p>Exibir por status de entrega:</p>

              <FormControl>
                <NativeSelect
                  id="demo-customized-select-native"
                  value={filterBody.status}
                  onChange={e => {
                    setFilterBody(prevState => ({
                      ...prevState,
                      status: e.target.value === 'TODOS' ? '' : e.target.value
                    }))
                  }}
                  input={<BootstrapInput />}
                >
                  <option value="TODOS">Todos</option>
                  <option value="PENDENTE">Pendente</option>
                  <option value="ENTREGUE">Entregue</option>
                  <option value="CANCELADO">Cancelado</option>
                  <option value="EM TRANSITO">Em Trânsito</option>
                  <option value="AGUARDANDO PAGAMENTO">Aguardando Pagamento</option>
                </NativeSelect>
              </FormControl>
            </div>
          </div>
        </div>

        <div className="footer-column">
          <hr style={{ width: "100%" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%"
            }}
          >
            <Button
              className={classes.btnLimpar}
              onClick={() => {
                clearState()
              }}
            >
              Limpar filtros
            </Button>
            <Button
              className={classes.btnFilter}
              onClick={() => {
                filterData()
              }}
            >
              Filtrar
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
