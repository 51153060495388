import React from "react"
import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  filtroContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  filtro: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: "35px",
    "& button": {
      backgroundColor: "transparent",
      margin: "0 15px",
      padding: "10px",
      color: "#EA3458",
      border: "none",
      borderRadius: "10px"
    }
  },
  default: {
    color: "#EA3458!important",
    border: "1px solid #EA3458!important"
  },
  active: {
    color: "#fff!important",
    backgroundColor: "#EA3458!important"
  },
  btnLimpar: {
    color: "#EA3458",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "10px",
    border: "solid 2px #EA3458",
    textTransform: "none",
    fontWeight: "500",
    marginBottom: "10px",
    width: "98% !important",
    hover: "none"
  },

  btnFiltrar: {
    color: "#FFF",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "10px",
    backgroundColor: "#EA3458",
    textTransform: "none",
    fontWeight: "500",
    marginBottom: "10px",
    width: "98% !important"
  },

  footerFilter: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "0.5rem"
  },
  btnFilter: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-around"
  },

  FilterTagsLink: {
    border: "1px solid #000",
    height: "30px",
    minWidth: "max-content",
    display: "flex",
    margin: "5px 5px",
    textAlign: "center",
    justifyContent: "center",
    borderRadius: "10px",
    padding: "5px 10px",
    fontSize: "1rem",
    fontWeight: 400,
    backgroundColor: "#f9f9f9"
  },

  filterOption: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "15px 0 25px 0",
    "& .li": {
      fontSize: "2rem"
    }
  },

  filterOptionCategory: {
    margin: "20px 0 10px 0"
  },

  filterTitle: {
    fontSize: "1.2rem",
    fontWeight: 500,
    color: "#353535"
  }

  /* filterLabel: {
    "& .MuiSlider-valueLabel": {
      width: "30px!important",
      height: "30px",
      background: "#EA3458!important",
      position: "absolute!important",
      transform: "rotate(1deg)!important",
      top: "10px!important",
      left: "-9px!important"
    },
    "& .PrivateValueLabel-circle": {
      width: "30px!important",
      height: "30px",
      borderRadius: "50% 50% 50% 0!important",
      background: "#EA3458!important",
      position: "absolute!important",
      transform: "rotate(-45deg)!important",
      top: "-40px!important",
      left: "-9px!important"
    },

    "& .PrivateValueLabel-circle-61": {
      width: "30px!important",
      height: "30px",
      background: "#EA3458!important",
      position: "absolute!important",
      transform: "rotate(1deg)!important",
      top: "10px!important",
      left: "-9px!important"
    }
  } */
}))
