/**
 * @param {File} image - Image File Object
 * @param {Object} pixelCrop - pixelCrop Object provided by react-image-crop
 */

export default function getCroppedImg(image, crop) {
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")
  ctx.drawImage(image, 0, 0)
  canvas.width = crop.width
  canvas.height = crop.height

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  const width = crop.width * scaleX
  const height = crop.height * scaleY

  canvas.width = width
  canvas.height = height
  ctx.imageSmoothingQuality = "high"
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    width,
    height
  )

  const base64Image = canvas.toDataURL("image/png")
  return base64Image
}
