import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles({
  positionImg: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px"
  },

  createImg: {
    width: "120px",
    height: "120px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#eaeaea",
    borderRadius: "50%",
    border: "dashed 2px",
    borderColor: "#c1c1c1"
  },
  previewImg: {
    width: "120px",
    height: "120px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#EA3458",
    borderRadius: "50%",
    border: "1px solid #EA3458"
  },
  positionLogo: {
    display: "flex",
    alignItems: "center"
  },

  defaultLogo: {
    width: "100px",
    height: "70px"
  },
  EtapaVisualizarInfos: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "20px auto",
    "& h2": {
      color: "#353535",
      fontSize: "1.5rem"
    },
    "& h3": {
      fontSize: "1rem",
      color: "#555555"
    }
  },
  EtapaVisualizarLinkLojinha: {
    margin: "20px auto",
    "& fieldset": {
      borderColor: "#EA3458!important",
      borderRadius: "10px!important"
    }
  },
  EtapaVisualizarCompartilhar: {
    display: "flex",
    margin: "20px auto",
    alignItems: "center",
    "& h3": {
      color: "#353535",
      fontSize: "0.9rem",
      fontWeight: "500",
      marginRight: "3px"
    },
    "& div": {
      display: "flex",
      marginRight: "6px"
    }
  }
})
export default useStyles
