import React, { useState, useEffect } from "react"
import { Button, CircularProgress, Switch, TextField } from "@material-ui/core"
import { globalStyles } from "../../../../Componentes/CssGlobal"
import { useStyles } from "./style"
import Topo from "../../../../Componentes/TopCategorias"
import api from "../../../../Services/api"
import CustomAlert from "../../../../Componentes/Alerts/CustomAlert"
import { useHistory } from "react-router-dom"

export default function VerificacaoDominio() {
  const classesGlobal = globalStyles()
  const classes = useStyles()

  const [text, setText] = useState()
  const [loading, setLoading] = useState(true)
  const [idMeta, setIdMeta] = useState()
  // const [alertType, setAlertType] = useState("")
  // const [showAlertOk, setShowAlertOk] = useState(false)
  // const [showAlertError, setShowAlertError] = useState(false)

  const [alertType, setAlertType] = useState("success")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [saving, setSaving] = useState(false)

  const history = useHistory()

  function getData() {
    const lojinha_id = localStorage.getItem("id")
    api.get(`/metadata/show/${lojinha_id}`).then(res => {
      setText(res.data.data.metadata)
      setIdMeta(res.data.data.id)
      setLoading(false)
    })
  }

  // const closeAlerts = () => {
  //   setShowAlertOk(false)
  //   setShowAlertError(false)
  // }

  const body = {
    metadata: text
  }

  function dataSave() {
    setSaving(true)

    if(body.metadata === ""){
      setAlertMessage("Campo vazio!")
      setAlertType("error")
      setShowModalAlert(true)
      return
    }

    api
      .put(`/metadata/update/${idMeta}`, body)

      .then(res => {
        setAlertMessage("Dados salvos com sucesso!")
        setAlertType("success")
        setShowModalAlert(true)
        setTimeout(() => {
          history.goBack()
        }, 1500)
      })
      .catch(error => {
        setSaving(false)
        setAlertMessage("Algo deu errado!")
        setAlertType("error")
        setShowModalAlert(true)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {loading ? (
        <div
          style={{
            backgroundColor: " #fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <>
          <div className="page-container">
            <div className="page-align">
              <div>
                <Topo name="Verificação de Domínio" />
              </div>
              <div className="infinite-scroll">
                <div>
                  <div className={classes.fonteTitulo}>
                    Código de verificação do domínio
                  </div>
                  <div>
                    <TextField
                      style={{ backgroundColor: "#fff" }}
                      name="idFace"
                      error=""
                      helperText=""
                      value={text}
                      onChange={e => setText(e.target.value)}
                      size="small"
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      inputProps={{ maxLength: 500 }}
                    />
                  </div>
                  <div className={classes.Box}>
                    <h3>
                      Para a verificação do seu domínio no facebook cole aqui a
                      metatag em HTML fornecida no seu gerenciador de anúncios. Após
                      colar a metatag clique em salvar informações. Agora basta
                      voltar em seu gerenciador de anúncios e clicar em verificar
                      domínio. Esta verificação pode levar até 48h para ficar ativa.
                    </h3>
                  </div>
                  <div className={classes.hyperlink}>
                    <a
                      className={classes.link}
                      href="https://blog.uppyapp.com.br/2021/11/como-verificar-meu-dominio-da-uppy-no-facebook/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Clique aqui para acessar nosso tutorial
                    </a>
                  </div>
                </div>

                {/* <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <CustomAlert
                    type={alertType}
                    alertText={
                      alertType === "success"
                        ? "Dados salvos com sucesso!"
                        : "Algo deu errado!"
                    }
                    show={showAlertOk || showAlertError}
                    close={() => closeAlerts()}
                  />
                </div> */}


                  {/* ALERT COMPONENT */}
                  {showModalAlert ? (
                    <CustomAlert
                      type={alertType}
                      alertText={alertMessage}
                      show={showModalAlert}
                      close={() => setShowModalAlert(false)}
                    />
                  ) : (
                    ""
                  )}
              </div>

              <div className="footer-column">
                <hr style={{ width: "100%" }} />
                <Button
                  className={classes.btnSalvar}
                  variant="contained"
                  disabled={saving}
                  onClick={() => {
                    dataSave()
                  }}
                >
                  Salvar alterações
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
