import {
  Button,
  CircularProgress,
  InputAdornment,
  Switch,
  TextField
} from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../../../../Componentes/Alerts/CustomAlert"
import TopProdutos from "../../../../../Componentes/TopProdutos"
import { current } from "../../../../../Componentes/validates/Validate"
import { useProduct } from "../../../../../ContextAPI/productContext"
import variationsRequest from "../../../../../Services/api/variacoes"
import ModalExcluirVariacao from "../ModalExcluirVariacao"
import { useStyles } from "./style"
import VariacaoFotos from "./VariacaoFotos"

export default function ProdutoVariacao(props) {
  const { id, tabSessionProps } = (props.location && props.location) || {}
  const {
    currentVariation,
    setCurrentVariation,
    currentProduct,
    isEditVariation,
    setIsEditVariation
  } = useProduct()
  const classes = useStyles()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [typesInputTag, setTypesInputTag] = useState({})
  /* ALERT STATES */
  const [alertType, setAlertType] = useState("success")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [checkPromotionalPrice, setCheckPromotionalPrice] = useState(false)

  /* ON LOAD VERIFY IF IS EDIT */
  useEffect(() => {
    if (isEditVariation === true) {
      handleEditProductVariation()
    }
    return () => {
      setCurrentVariation(null)
      setIsEditVariation(false)
    }
  }, [])

  const handleFormChange = e => {
    const { name, value } = e.target
    let newValue = value
    if (name === "price" || name === "promotional_price") newValue = current(value)
    setCurrentVariation(oldState => {
      return { ...oldState, [name]: newValue }
    })
  }
  const handleDelete = () => {
    setOpenModalDelete(true)
  }

  const clearStates = () => {
    setCurrentVariation(null)
    setIsEditVariation(false)
    history.goBack()
  }

  const handleFormChangeTypeVariation = (e, idType) => {
    const { value } = e.target
    const hasVaritation = currentVariation.types.find(
      el => el.id_variation_type === idType
    )
    if (hasVaritation !== undefined) {
      let filteredTypes = currentVariation.types.filter(
        type => type.id_variation_type !== idType
      )
      setCurrentVariation(oldState => {
        return {
          ...oldState,
          types: [
            ...filteredTypes,
            {
              id_variation_type: idType,
              tag: String(value).toLocaleUpperCase()
            }
          ]
        }
      })
      setTypesInputTag(oldState => {
        return {
          ...oldState,
          [idType]: value
        }
      })
    } else {
      setCurrentVariation(oldState => {
        return {
          ...oldState,
          types: [
            ...oldState.types,
            {
              id_variation_type: idType,
              tag: String(value).toLocaleUpperCase()
            }
          ]
        }
      })
      setTypesInputTag(oldState => {
        return {
          ...oldState,
          [idType]: value
        }
      })
    }
  }

  async function saveVariation() {
    let isEmpty = checkRequiredInputs()
    if (isEmpty) return
    setLoading(true)
    const body = {
      id_product: currentProduct.id,
      types: currentVariation.types ? currentVariation.types : [],
      price:
        currentVariation.price !== undefined &&
        currentVariation.price !== null &&
        String(currentVariation.price).length
          ? Number(currentVariation.price)
          : null,
      promotional_price:
        currentVariation.promotional_price !== undefined &&
        currentVariation.promotional_price !== null &&
        String(currentVariation.promotional_price).length &&
        checkPromotionalPrice
          ? Number(currentVariation.promotional_price)
          : null,
      quantity: currentVariation.quantity ? currentVariation.quantity : null,
      images: orderImages()
    }

    const variationRequest = isEditVariation
      ? await variationsRequest.put(currentVariation.id, body)
      : await variationsRequest.create(body)
    if (variationRequest.data.error === false) {
      setAlertMessage(
        isEditVariation
          ? "Variação editada com sucesso!"
          : "Variação criada com sucesso!"
      )
      setAlertType("success")
      setShowModalAlert(true)

      setTimeout(() => clearStates(), 1500)
    } else {
      setAlertMessage("Erro ao criar variação!")
      setAlertType("error")
      setShowModalAlert(true)
    }
    setLoading(false)
  }

  function checkRequiredInputs() {
    let error = false
    if (currentVariation) {
      if (
        currentVariation.types.length !==
        currentProduct.variation_type_selected.length
      )
        error = true
      if (error === true) {
        setAlertMessage("Preencha os campos obrigatórios.")
        setAlertType("error")
        setShowModalAlert(true)
      }
      return error
    }

    return error
  }

  function orderImages(fotosArray) {
    let orderedImagesArray = []
    if (isEditVariation && fotosArray) {
      orderedImagesArray = fotosArray.map((image, index) => {
        image.order = index + 1
        image.dndID = index + 1
        return image
      })
      return orderedImagesArray
    }
    orderedImagesArray = currentVariation.images.map((image, index) => {
      image.order = index + 1
      image.dndID = index + 1
      return image
    })
    return orderedImagesArray
  }

  /* EDIT VARIATION */
  const handleEditProductVariation = async () => {
    const selectedProductVariation = await variationsRequest.get(id)
    if (selectedProductVariation) {
      let bodyVariation = selectedProductVariation.data.data.types.map(type => {
        setTypesInputTag(oldState => {
          return {
            ...oldState,
            [type.id_variation_type]: type.tag
          }
        })
        return {
          id_product_variation: type.id_product_variation,
          id_variation_type: type.id_variation_type,
          tag: type.tag
        }
      })
      setCheckPromotionalPrice(
        !!(
          selectedProductVariation.data.data &&
          selectedProductVariation.data.data.promotional_price
        )
      )
      setCurrentVariation({
        ...selectedProductVariation.data.data,
        types: bodyVariation
      })
      setLoading(false)
    }
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <div className={classes.header}>
            <TopProdutos
              name="Variações do produto"
              handleBack={clearStates}
              handleDelete={handleDelete}
              isEdit={isEditVariation}
            />
          </div>
          <div className="infinite-scroll">
            {loading ? (
              <div
                style={{
                  backgroundColor: " #f9f9f9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  margin: "50px 0px"
                }}
              >
                <CircularProgress color="secondary" size="40px" />
              </div>
            ) : (
              <>
                <VariacaoFotos />
                <div>
                  <h3>Características do produto</h3>

                  <div>
                    {currentVariation &&
                      currentProduct &&
                      currentProduct.variation_type_selected.map(variation => (
                        <>
                          <h3 style={{ color: "#F21C62" }}>{variation.name}</h3>
                          <TextField
                            id={variation.id_variation_type}
                            name={variation.name}
                            style={{ backgroundColor: "#fff" }}
                            size="small"
                            variant="outlined"
                            value={typesInputTag[
                              variation.id_variation_type
                            ]?.toLocaleUpperCase()}
                            onChange={e =>
                              handleFormChangeTypeVariation(
                                e,
                                variation.id_variation_type
                              )
                            }
                            color="secondary"
                            fullWidth
                            inputProps={{ maxLength: 30 }}
                          />
                        </>
                      ))}
                  </div>

                  <div>
                    <h3>Preço</h3>
                    <TextField
                      style={{ width: "65%", backgroundColor: "#fff" }}
                      name="price"
                      type="tel"
                      value={new Intl.NumberFormat("pt-BR", {
                        minimumFractionDigits: 2
                      }).format(currentVariation?.price || 0)}
                      size="small"
                      onChange={e => handleFormChange(e)}
                      variant="outlined"
                      color="secondary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        )
                      }}
                    />
                  </div>
                  <div>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <h3>Preço em promoção</h3>
                      <Switch
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        name="highlight"
                        checked={checkPromotionalPrice}
                        onClick={e =>
                          setCheckPromotionalPrice(!checkPromotionalPrice)
                        }
                      />
                    </div>
                    {checkPromotionalPrice === true && (
                      <TextField
                        style={{ width: "65%", backgroundColor: "#fff" }}
                        name="promotional_price"
                        type="tel"
                        value={new Intl.NumberFormat("pt-BR", {
                          minimumFractionDigits: 2
                        }).format(currentVariation?.promotional_price || 0)}
                        size="small"
                        variant="outlined"
                        onChange={e => handleFormChange(e)}
                        color="secondary"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          )
                        }}
                      />
                    )}
                  </div>
                  <div>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <h3>Estoque da variação do produto</h3>
                    </div>
                    <TextField
                      name="quantity"
                      style={{ width: "30%", backgroundColor: "#fff" }}
                      size="small"
                      variant="outlined"
                      value={currentVariation && currentVariation.quantity}
                      color="secondary"
                      onChange={e => handleFormChange(e)}
                      fullWidth
                      inputProps={{ maxLength: 30 }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="footer-column">
            <hr style={{ width: "100%", margin: "20px 0" }} />
            <Button
              className={classes.btnFooterPink}
              disabled={loading}
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => saveVariation()}
            >
              Salvar variação
            </Button>
          </div>
        </div>
      </div>
      {/* ALERT COMPONENT */}
      {showModalAlert ? (
        <CustomAlert
          type={alertType}
          alertText={alertMessage}
          show={showModalAlert}
          close={() => setShowModalAlert(false)}
        />
      ) : (
        ""
      )}
      {/* MODAL DELETE VARIATION */}
      {openModalDelete ? (
        <ModalExcluirVariacao
          isOpen={openModalDelete}
          idVariacao={
            currentVariation && currentVariation.id ? currentVariation.id : null
          }
          handleClose={() => setOpenModalDelete(false)}
        />
      ) : (
        ""
      )}
    </>
  )
}
