import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles({
  BannerSejaProContainer: {
    margin: "15px 0",
    cursor: "pointer",
    textAlign: "center"
  },
  BannerSejaProImg: {
    width: "100%",
    height: "auto",
    maxWidth: "500px",
    boxSizing: "border-box",
    backgroundSize: "cover"
  },
  BannerSejaProImgLite: {
    width: "100%",
    maxWidth: "500px",
    boxSizing: "border-box",
    backgroundSize: "cover"
  },
  DesktopBannerSejaProImg: {
    width: "100%",
    height: "auto",
    boxSizing: "border-box",
    backgroundSize: "cover"
  },
  DesktopBannerSejaProImgLite: {
    width: "100%",
    boxSizing: "border-box",
    backgroundSize: "cover"
  },

  BannerSejaProImgHome: {
    width: "90%",
    height: "auto",
    maxWidth: "500px",
    boxSizing: "border-box",
    backgroundSize: "cover"
  },
  DesktopBannerSejaProImgHome: {
    width: "85%",
    height: "auto",
    boxSizing: "border-box",
    backgroundSize: "cover"
  }
})
export default useStyles
