import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  PlanosAlertaModal: {
    "& .MuiDialogTitle-root": {
      padding: "0px 10px"
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "480px"
    }
  },
  PlanosAlertaModalHeader: {
    display: "flex",
    "& h2": {
      fontSize: "2rem",
      fontWeight: "700",
      flex: 1,
      textAlign: "center",
      paddingLeft: "30px"
    },
    "& button": {
      border: "none",
      fontSize: "2rem",
      fontFamily: "cursive",
      cursor: "pointer",
      backgroundColor: "transparent",
      color: "#EA3458",
      fontWeight: 900
    }
  },
  PlanosAlertaModalBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      fontSize: "1rem",
      fontWeight: "300",
      textAlign: "center"
    },
    "& span": {
      color: "#EA3458",
      fontWeight: "600"
    },
    "& img": {
      width: "100%",
      maxWidth: "170px",
      margin: "0px 0"
    }
  },

  btnCancelarStyle: {
    width: "45%",
    border: "solid 2px #f50057",
    color: "#f50057",
    borderRadius: "5px",
    backgroundColor: "#fff",
    height: "40px",
    fontSize: "16px"
  },

  btnExcluirStyle: {
    transition: ".3s ease-out",
    width: "45%",
    border: "solid 2px #EB1111",
    color: "#fff",
    borderRadius: "5px",
    backgroundColor: "#EB1111",
    height: "40px",
    fontSize: "16px"
  },

  btnDisable: {
    transition: ".3s ease-out",
    width: "45%",
    border: "solid 2px #cccccc",
    color: "#a4a4a4",
    borderRadius: "5px",
    backgroundColor: "#cccccc",
    height: "40px",
    fontSize: "16px"
  }
})
export default useStyles
