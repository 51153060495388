import ApiRequest from "../index"

const requestBanners = {
    getPrimaryBanners: async () => ApiRequest.get(("v2/lojinha/banners/primary")),
    getSecondaryBanners: async () => ApiRequest.get(("v2/lojinha/banners/secondary")),
    sendBanners: async (bannerName, bannerType, bannerPosition, bannerDesktop, bannerMobile) => ApiRequest.post("v2/lojinha/banners/create", {
        banner_name: bannerName,
        banner_type: bannerType,
        banner_position: bannerPosition,
        banner_desktop: bannerDesktop,
        banner_mobile: bannerMobile
    }),
    updateBanners: async (bannerID, bannerName, bannerDesktop, bannerMobile) => ApiRequest.put("v2/lojinha/banners/update", {
        banner_id: bannerID,
        banner_name: bannerName,
        banner_desktop: bannerDesktop,
        banner_mobile: bannerMobile
    }),
    deleteBanner: async (bannerID) => ApiRequest.delete(`v2/lojinha/banners/delete/${bannerID}`)
}

export default requestBanners