export function IconPorcentagem({ width, height, color1, color2 }) {
  return (
    <svg
      style={{ marginTop: "-8px", marginRight: "-2.5px" }}
      width={width}
      height={height}
      viewBox="0 0 52 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H42C47.5228 0 52 4.47715 52 10V49L35 33L0 0Z" fill={color2} />
      <path
        d="M31.6 23L29.8 21.2L42.4 8.6L44.2 10.42L31.6 23ZM41.2 23.3C40.54 23.3 39.9367 23.14 39.39 22.82C38.85 22.4933 38.4167 22.06 38.09 21.52C37.7633 20.9733 37.6 20.3667 37.6 19.7C37.6 19.0467 37.7667 18.4467 38.1 17.9C38.4333 17.3533 38.8733 16.9167 39.42 16.59C39.9667 16.2633 40.56 16.1 41.2 16.1C41.86 16.1 42.4633 16.2633 43.01 16.59C43.5567 16.91 43.99 17.3433 44.31 17.89C44.6367 18.43 44.8 19.0333 44.8 19.7C44.8 20.3667 44.6367 20.9733 44.31 21.52C43.99 22.06 43.5567 22.4933 43.01 22.82C42.4633 23.14 41.86 23.3 41.2 23.3ZM41.2 21C41.4333 21 41.6467 20.94 41.84 20.82C42.04 20.7 42.2 20.5433 42.32 20.35C42.44 20.15 42.5 19.9333 42.5 19.7C42.5 19.4667 42.44 19.2533 42.32 19.06C42.2 18.86 42.04 18.7 41.84 18.58C41.6467 18.46 41.4333 18.4 41.2 18.4C40.9667 18.4 40.75 18.46 40.55 18.58C40.3567 18.7 40.2 18.86 40.08 19.06C39.96 19.2533 39.9 19.4667 39.9 19.7C39.9 19.9333 39.96 20.15 40.08 20.35C40.2 20.5433 40.3567 20.7 40.55 20.82C40.75 20.94 40.9667 21 41.2 21ZM32.8 15.5C32.14 15.5 31.5367 15.34 30.99 15.02C30.4433 14.6933 30.0067 14.26 29.68 13.72C29.36 13.1733 29.2 12.5667 29.2 11.9C29.2 11.2467 29.3667 10.6467 29.7 10.1C30.0333 9.55333 30.4733 9.11667 31.02 8.79C31.5667 8.46333 32.16 8.3 32.8 8.3C33.46 8.3 34.06 8.46333 34.6 8.79C35.1467 9.11 35.5833 9.54333 35.91 10.09C36.2367 10.63 36.4 11.2333 36.4 11.9C36.4 12.5667 36.2367 13.1733 35.91 13.72C35.5833 14.26 35.1467 14.6933 34.6 15.02C34.06 15.34 33.46 15.5 32.8 15.5ZM32.8 13.2C33.0333 13.2 33.2467 13.14 33.44 13.02C33.64 12.9 33.8 12.7433 33.92 12.55C34.04 12.35 34.1 12.1333 34.1 11.9C34.1 11.6667 34.04 11.4533 33.92 11.26C33.8 11.06 33.64 10.9 33.44 10.78C33.2467 10.66 33.0333 10.6 32.8 10.6C32.5667 10.6 32.35 10.66 32.15 10.78C31.9567 10.9 31.8 11.06 31.68 11.26C31.56 11.4533 31.5 11.6667 31.5 11.9C31.5 12.1333 31.56 12.35 31.68 12.55C31.8 12.7433 31.9567 12.9 32.15 13.02C32.35 13.14 32.5667 13.2 32.8 13.2Z"
        fill={color1}
      />
    </svg>
  )
}
