import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useStyles } from "./style";
import { useProduct } from "../../../../ContextAPI/productContext";

export default function FichaTecnica() {
    const { currentProduct, setCurrentProduct } = useProduct()
    const [text, setText] = useState(currentProduct !== null && currentProduct.product_datasheet
        ? currentProduct.product_datasheet
        : null);
    const classes = useStyles();
    const formats = [
        'background',
        'bold',
        'color',
        'font',
        'code',
        'italic',
        'link',
        'size',
        'strike',
        'script',
        'underline',
        'blockquote',
        'header',
        'indent',
        'list',
        'align',
        'direction',
        'code-block',
        'formula'
      ];
    useEffect(() => {
        setCurrentProduct({ ...currentProduct, product_datasheet: text })
    }, [text])

    return (
        <div className={classes.textareaContainer}>
            <span>Descritivo sobre a ficha técnica do seu produto:</span>
            <ReactQuill theme="snow" value={text} onChange={setText} formats={formats} />
        </div>
    )
}