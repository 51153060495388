import { TextField, Button } from "@material-ui/core"
import React, { useState } from "react"
import { createTheme , ThemeProvider, makeStyles } from "@material-ui/core/styles"

const theme = createTheme ({
  palette: {
    secondary: {
      light: "#E7366A",
      main: "#E7366A",
      dark: "#D93364",
      contrastText: "#fff"
    },
    primary: {
      light: "#EA3458",
      main: "#EA3458",
      dark: "#EA3458",
      contrastText: "#fff"
    }
  }
})

export const texto = props => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <TextField
          type="text"
          id="standard-adornment-password"
          label={props.title}
          name={props.name}
          variant="standard"
          style={{ minWidth: "100%", marginTop: "40px" }}
          error={props.error}
          value={props.value}
          inputProps={{ maxLength: props.maxlength ? props.maxlength : 30 }}
          placeholder={`Limite de ${
            props.maxlength ? props.maxlength : 30
          } caracteres`}
          margin="dense"
          helperText={props.error ? props.error : ""}
          onChange={e => {
            props.pegadados(e.target)
          }}
        />
      </ThemeProvider>
    </>
  )
}

export const texto2 = props => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <TextField
          type="text"
          id="standard-adornment-password"
          label={props.title}
          name={props.name}
          variant="outlined"
          style={{ minWidth: "100%", backgroundColor: "#fff" }}
          error={props.error}
          value={props.value}
          margin="dense"
          helperText={props.error ? props.error : ""}
          onChange={e => {
            props.pegadados(e.target)
          }}
        />
      </ThemeProvider>
    </>
  )
}

export const button = props => {
  return (
    <>
      <Button
        disabled={props.disabled}
        style={{
          // marginTop: '10px',
          // minWidth:'100%' ,
          // background:props.color ? props.color : '#EA3458' ,
          // color:props.fontcolor ? props.fontcolor: '#FFFFFF' ,
          // textTransform:'none',
          // marginBottom: '15px',
          // paddingLeft: '20px',
          // paddingRight: '20px',
          // boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
          minWidth: "100%",
          height: props.height ? props.height : "50px",
          boxShadow:
            "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
          marginTop: "5px",
          marginBottom: "8px",
          textTransform: "none",
          background: props.disabled
            ? "#D9D9D9"
            : props.color
            ? props.color
            : "#EA3458",
          color: props.disabled
            ? "#aaa"
            : props.fontcolor
            ? props.fontcolor
            : "#FFFFFF",

          "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "14px",
            fontWeight: "500"
          },

          "&:hover": {
            backgroundColor: "#ff5089c9"
          }
        }}
        error={props.error}
        type={props.type}
        margin="dense"
        helperText={props.error ? props.error : ""}
        onClick={props.funcao}
      >
        {props.name}
      </Button>
    </>
  )
}

export const buttonSecundary = props => {
  return (
    <Button
      name={props.name}
      style={{
        color: "#EA3458",
        minWidth: "100%",
        height: "50px",
        boxShadow:
          "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        border: "solid 2px #EA3458",
        marginBottom: "0 0 15px!important",
        textTransform: "none",
        backgroundColor: "#fff"
      }}
      error={props.error}
      type={props.type}
      margin="dense"
      helperText={props.error ? "Campo precisa ser prenchido" : ""}
      onClick={props.funcao}
    >
      {props.name}
    </Button>
  )
}
