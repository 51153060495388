import { CircularProgress } from "@material-ui/core"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"
import React, { useEffect, useState } from "react"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { globalStyles } from "../../../Componentes/CssGlobal"
import { button } from "../../../Componentes/Inputs/TextFild"
import { useProduct } from "../../../ContextAPI/productContext"
import { useStyles } from "./styles"
import CategoriasNova from "../../CategoriasNova"
import CategoryComponent from "../../CategoriasNova/Components"

export default function EtapaCategorias({ handleStep, from }) {
  const { saveNewCategory, deleteCategory, categoriesList } = useProduct()
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const [newCategory, setNewCategory] = useState("")
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)

  const handleSaveCategory = () => {
    setLoading(true)
    if (newCategory !== "") {
      saveNewCategory(newCategory)
      setNewCategory("")
    } else {
      setLoading(false)
      setAlert(true)
      setTimeout(() => {
        setAlert(false)
      }, [2500])
    }
  }

  useEffect(() => {
    setLoading(false)
  }, [categoriesList])

  const deleteCategoryById = id => {
    setLoading(true)
    deleteCategory(id)
  }

  const saveCategories = () => {
    setShowAlertOk(true)
    handleStep("proximo")
  }

  const closeAlert = () => {
    setShowAlertOk(false)
  }

  return (
    <>
      <div className="infinite-scroll">
        <div className="EtapaHeader">
          <h2>Crie suas categorias</h2>
          <h3>e organize sua loja:</h3>
        </div>
        <CategoriasNova
        from={from} 
        showTopo={false}
        handleStep={handleStep}/>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
          {/* alertas */}
          <CustomAlert
            alertText="Categorias salvas com sucesso!"
            show={showAlertOk}
            close={() => closeAlert()}
          />
        </div>
      </div>
      <div className="footer-column">
        {button({
          funcao: () => saveCategories(),
          name: `Próximo`
        })}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <button
            onClick={() => handleStep("backStep")}
            className="voltarLink"
            style={{ margin: 0 }}
          >
            Voltar etapa
          </button>

          <button
            onClick={() => handleStep("nextStep")}
            className="voltarLink"
            style={{ margin: 0, marginLeft: "auto" }}
          >
            Pular etapa
          </button>
        </div>
      </div>
    </>
  )
}
