import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => ({
  Secondary: {
    display: "flex",
    backgroundColor: "#f9f9f9",
    flexDirection: "column",
    height: "100%",
    minHeight: "100%",
    justifyContent: "space-between"
  },
  Box: {
    marginTop: "10px"
  },

  boxContainer: {
    borderBottom: "1px solid #F3F3F3",
    boxSizing: "border-box",
    // paddingLeft: '20px',
    paddingRight: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  titleText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "500",
    fontSize: "1.2em",
    color: "#353535",
    lineHeight: "16px"
  },

  arrowForward: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },

  descriptionText: {
    display: "flex",
    fontSize: "0.8em",
    color: "#989898",
    marginBottom: "20px",
    marginTop: "10px",
    alignItems: "center",
    justifyContent: "space-between"
  },

  bannerSejaPro: {
    flexbox: "flex",
    height: "200px",
    borderRadius: "5px",
    fontSize: "24px",
    fontWeight: "400",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#C4C4C4",
    marginTop: "40px",
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "20px"
  },

  /// //////////////////////////////////////////////

  btnAddLogo: {
    color: "#fff",
    minWidth: "100%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "5px",
    marginBottom: "15px",
    textTransform: "none",
    backgroundColor: "#EA3458",

    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#ff5089c9"
    }
  }
}))
