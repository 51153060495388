import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  version: {
    fontFamily: "sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "12px"
  },
  positionRight: {
    display: "flex",
    justifyContent: "flex-end"
  },

  img: {
    // position: 'absolute',
    // top: '30px',
    paddingTop: "50px",
    display: "flex",
    justifyContent: "center"
  },

  imgCentral: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "25px",
    paddingBottom: "30px"
  },

  buttonCria: {
    backgroundColor: "#EA3458",
    color: "#fff",
    textTransform: "none",
    fontWeight: "bold",
    width: "280px",
    height: "50px",

    "& .MuiButton-label": {
      fontSize: "14px",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#ff0053c9"
    }
  },

  buttonAcessa: {
    marginTop: "25px",
    color: "#EA3458",
    borderColor: "#EA3458",
    textTransform: "none",
    // fontWeight:'bold',
    marginBottom: "15px",
    width: "280px",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
    // boxShadow: '-6px 9px 17px 3px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
  },

  entrar: {
    backgroundColor: "#EA3458",
    color: "#fff",
    textTransform: "none",
    marginBottom: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "50px"
  },

  widthBTN: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    paddingBottom: "15px"
  },

  esqueciPassword: {
    fontFamily: "sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "12px",
    color: "#EA3458",
    cursor: "pointer"
  },

  positionLeft: {
    display: "flex",
    justifyContent: "flex-end"
  },

  textfield: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },

  height100vh: {
    height: "100%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },

  textCriar: {
    fontFamily: "sans-serif",
    fontSize: "0.9em",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "12px",
    textAlign: "center"
  },

  widthTextCriar: {
    paddingBottom: "20px"
  }
}))
