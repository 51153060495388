import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  LinkButton: {
    backgroundColor: "#ea3458",
    border: "none",
    borderRadius: "5px",
    height: "40px",
    width: "140px",
    cursor: "pointer",
    color: "#fff",
    padding: "5px",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem"
  },

  flexColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },

  container: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    padding: "0 20px",
    maxHeight: "100vh",
    overflow: "scroll",
    paddingBottom: "60px",
    justifyContent: "center"
  },

  wrapper: {
    backgroundColor: "#fff",
    width: "100%",
    height: "100vh",
    display: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}))
