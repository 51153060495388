import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  Box: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "5px"
  },

  BoxKeyWords: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "5px"
  },

  inputKeywords: {
    width: "90%"
  },

  boxWords: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "1.1em",
    marginTop: "10px",
    marginLeft: "5px"
  },

  btnAdd: {
    display: "flex",
    alignitems: "center",
    marginTop: "15px",
    border: "none",
    background: "transparent"
  },

  btnDelete: {
    display: "flex",
    alignitems: "center",
    // SmarginTop: '10px',
    border: "none",
    background: "transparent"
  },

  titleText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "500",
    fontSize: "14px"
  },

  switchChange: {
    display: "flex",
    alignItems: "center"
  },

  fonteTitulo: {
    fontSize: "14px",
    color: "#555555",
    display: "flex",
    marginTop: "10px"
  },

  btnSalvar: {
    color: "#fff",
    minWidth: "100%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "5px",
    marginBottom: "15px",
    textTransform: "none",
    backgroundColor: "#EA3458",

    "& .MuiButton-label": {
      textTransform: "initial",
      fontSize: "14px",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#ff5089c9"
    }
  }
}))
