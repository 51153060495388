import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  pagseguroOrders: {
    '&__enablePaymentOption': {
      marginTop: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& p': {
        fontSize: '16px',
        fontWeight: '500'
      }
    },

    '&__token': {
      '& p': {
        margin: '10px 0 6px 0'
      },
    },

    '&__tokenStatus': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '8px',

      '& p': {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px !important',

        '& strong': {
          marginLeft: '5px'
        }
      },

      '& button': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '39px',
        height: '37px',
        border: '2.5px solid #EA3458',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',

        '& img': {
          width: '20px',
        }
      }
    },

    '&__information': {
      margin: '40px 0 15px 0',
      bottom: '0',

      '& p': {
        color: '#1877F2',
        fontSize: '14px',
        fontWeight: '500',

        '& a': {
          color: '#1877F2',
          textDecoration: 'underline'
        }
      }
    },

    '&__integrationSetup': {
      width: '100%',
      border: '2px solid #EA3458',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      padding: '0 0 0 23px',
      margin: '40px 0 0 0',
      backgroundColor: '#f9f9f9',
      height: '46px',
      fontSize: '14px',
      fontWeight: '500',
      color: '#EA3558',

      '& img': {
        marginRight: '10px'
      }
    }
  },

  integrationError: {
    fontSize: '10px',
    color: '#EC413D',
    marginTop: '3px !important'
  },

  btnSave: {
    color: "#fff",
    minWidth: "48%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "10px",
    textTransform: "none",
    fontWeight: "bold",
    marginBottom: "10px",
    backgroundColor: "#EA3458",

    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#ff0053c9"
    }
  },

  disabledButton: {
    background: '#aaa',
    color: '#fff'
  },

  disabled: {
    borderColor: '#aaa !important',

    '& img': {
      filter: 'invert(69%) sepia(15%) saturate(0%) hue-rotate(252deg) brightness(96%) contrast(88%)'
    }
  },

  rotateAnimation: {
    animation: '$sync-button-rotate 1700ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear'
  },

  '@keyframes sync-button-rotate': {
    'from': {
      transform: 'rotate(0deg)'
    },

    'to': {
      transform: 'rotate(-360deg)'
    }
  }
}))


