import React from "react"
import { IoMdClose } from "react-icons/io"
import { useHistory } from "react-router-dom"
import { useStyles } from "./style"

export default function Categoria({ setOpenMenuLeft }) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={classes.topoContainer}>
      <IoMdClose
        style={{
          fontSize: "35px",
          color: "#EA3458",
          cursor: "pointer",
          minWidth: "30px",
          marginRight: "10px"
        }}
        onClick={() => {
          history.push("/home")
        }}
      />
    </div>
  )
}
