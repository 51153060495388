export default function ValeAlimentacao(props) {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill={props.fill ? "#a4a4a4" : " #EA3458"}
      stroke={props.fill ? "#a4a4a4" : " #EA3458"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.20837 5.29175H18.573C18.864 5.29176 19.1519 5.35276 19.4179 5.47082C19.6839 5.58887 19.9223 5.76137 20.1176 5.97718C20.3129 6.19299 20.4608 6.44733 20.5518 6.72381C20.6428 7.00028 20.6748 7.29276 20.6459 7.58237L20.0209 13.8324C19.9695 14.3464 19.7289 14.8231 19.3458 15.1697C18.9628 15.5164 18.4646 15.7084 17.948 15.7084H8.00004C7.51824 15.7086 7.05127 15.5418 6.67863 15.2364C6.30599 14.931 6.05072 14.5059 5.95629 14.0334L4.20837 5.29175Z"
        stroke={props.Color}
        fill={props.Color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M4.20837 5.29167L3.36462 1.91354C3.30819 1.68828 3.17812 1.48834 2.99505 1.34547C2.81198 1.20261 2.58642 1.12501 2.35421 1.125H1.08337"
        stroke={props.Color}
        fill={props.Color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33337 19.875H9.41671"
        stroke={props.Color}
        fill={props.Color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6667 19.875H17.7501"
        stroke={props.Color}
        fill={props.Color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
