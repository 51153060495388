import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  image: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  text: {
    margin: '20px 0 20px 0',
    textAlign: 'center',
    fontSize: "12px"
  },

  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 13,

    '& span': {
        fontSize: 14,
        fontWeight: '500',
        color: '#3498db',
        textDecoration: 'underline'
    }
  }
}))
