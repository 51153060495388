import React from "react"
import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  TopContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0",
    marginButton: "5px"
  },
  FiltroButton: {
    width: "40%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "700",
    flexDirection: "row",
    backgroundColor: "transparent",
    color: "#EA3458",
    fontSize: "1.1rem",
    border: "none",
    cursor: "pointer"
  },
  AddButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#EA3458",
    width: "45%",
    height: "40px",
    // boxShadow:
    //   '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    border: "solid 2px #EA3458",
    borderRadius: "5px",
    backgroundColor: "transparent",
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: "600"
  },
  /* search component */
  search: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "& .MuiInputAdornment-root": {
      borderLeft: "1px solid #efefef",
      paddingLeft: "10px",
      height: "3rem"
    }
  },
  searchIcon: {
    margin: "0 15px",
    height: "100%",
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#EA3458",
    cursor: "pointer",
    fontSize: "1.8rem"
  },
  inputRoot: {
    border: "solid 1px  #d0d0d0",
    height: "40px",
    borderRadius: "5px ",
    flex: "1",
    padding: "0 5px"
  },
  btnAddProductRoot: {
    position: "fixed",
    zIndex: 9999999,
    bottom: "25px"
  },
  btnAddProduct: {
    position: "absolute",
    right: "-90vw",
    bottom: "25px",
  },

}))
