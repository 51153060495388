import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  partner: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    marginBottom: 11,

    "& p": {
        fontSize: '10px',
        color: '#555'
    }
  },

  paymentsMethods: {
    '& img': {
        width: '100%'
    },

    '& span': {
        fontSize: 10,
        color: '#0091d2',
        fontWeight: '500'
    }
  },

  activeCheckout: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20
  }
}))

