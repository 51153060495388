import ArrowRight from "../../Assets/svg/arrowRight.svg"
import { useHistory } from "react-router-dom"
import Topo from "../../Componentes/TopCategorias"
import { useEffect, useState } from "react"
import requestsFrases from "../../Services/api/userFrases"
import { useStyles } from "./style"
import { CircularProgress } from "@material-ui/core"

export default function FrasesDestaques() {
    const history = useHistory()
    const [userFrases, setUserFrases] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const classes = useStyles()

    const getUserFrases = async () => {
        try {
            const res = await requestsFrases.getUserFrases()
            setUserFrases(res.data.data)
        } catch (e) {
            console.log(e)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getUserFrases()
    }, [])

    return (
        <div className="page-container">
            <div className="page-align" style={{ justifyContent: "flex-start" }}>
                <div>
                    <Topo rota={"/personalizar-lojinha"} name="Frases em destaque" />
                </div>
                <div className="infinite-scroll">{
                    isLoading ? (
                        <div
                            style={{
                                backgroundColor: " #fff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%"
                            }}
                        >
                            <CircularProgress color="secondary" size="40px" />
                        </div>
                    ) : (
                        <div className={classes.mainContainer}>
                            {
                                userFrases.map((uf, i) => {
                                    const [itemKey] = Object.keys(uf);
                                    const item = uf[itemKey];
                                    return (
                                        <div key={i} className={classes.fraseContainer}>
                                            <label style={{ fontSize: "1.25rem", color: "#555" }}>
                                                Frase {i + 1}
                                            </label>
                                            <button
                                                className={classes.fraseButton}
                                                onClick={() => history.push({
                                                    pathname: `/frases-destaques/opcoes`,
                                                    state: {
                                                        data: {
                                                            item: itemKey,
                                                            phrase_id: item.phrase_id
                                                        }
                                                    }
                                                })}
                                            >
                                                <div style={{ display: "flex", gap: "2rem", }}>
                                                    <div style={{ display: "flex", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: item.phrase_icon }} />
                                                    <p style={{ fontSize: "1rem", color: "#555", }}>
                                                        {item.phrase_text ? item.phrase_text : "Não selecionado"}
                                                    </p>
                                                </div>
                                                <img src={ArrowRight} alt="arrow" />
                                            </button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }
                </div>
            </div>
        </div>
    )
}