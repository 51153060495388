import React from "react"
import { IoIosArrowBack } from "react-icons/io"
import { useHistory } from "react-router-dom"
import { useStyles } from "./style"
import CloseIcon from "@material-ui/icons/Close"

export default function Categoria({ rota, name, ...props }) {
  const classes = useStyles()
  const history = useHistory()
  return (
    <div className={classes.top}>
      <CloseIcon
        style={{
          fontSize: "40px",
          color: "#EA3458",
          cursor: "pointer",
          minWidth: "30px",
          marginLeft: "0px"
        }}
        onClick={() => {
          props.close()
        }}
        {...props}
      />
      <div className={classes.dots}>
        <h2 style={{ paddingRight: "15px" }}>{name}</h2>
      </div>
      <div className={classes.space} />
    </div>
  )
}
