import React, { useEffect } from "react"
import { useStyles } from "./style"
import { IoIosArrowBack } from "react-icons/io"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import "./style.css"
import { useHistory } from "react-router-dom"

export default function Categoria(props) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={classes.top}>
      <div>
        <IoIosArrowBack
          style={{
            fontSize: "35px",
            color: "#EA3458",
            cursor: "pointer",
            minWidth: "30px"
          }}
          onClick={() => {
            history.goBack()
          }}
        />
      </div>
      <div className={classes.dots}>
        <h2>{props.name}</h2>
      </div>
      <div style={{ width: "1px" }}></div>
      {/* <MoreVertIcon color="secondary" /> */}
    </div>
  )
}
