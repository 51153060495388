import { TextField } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Switch from "@material-ui/core/Switch"
import axios from "axios"
import { useEffect, useState } from "react"
import { useStyles } from "./style"

export function RetiradaLocal({
  handleWithdrawalFormData,
  withdrawalInformations,
  hasEmptyZipCode,
  retirada
}) {
  const classes = useStyles()

  const [hasEnableWithdrawal, setHasEnableWithdrawal] = useState(retirada)
  const [enableWithdrawalFormData, setEnableWithdrawalFormData] = useState({})

  useEffect(() => {
    if (withdrawalInformations !== undefined)
      setEnableWithdrawalFormData(withdrawalInformations)
  }, [withdrawalInformations])

  const searchPostalCode = async postalCode => {
    if (postalCode.length === 8) {
      await axios
        .get(`https://viacep.com.br/ws/${postalCode.replace(/\D/g, "")}/json/`)
        .then(res => {
          setEnableWithdrawalFormData({
            ...enableWithdrawalFormData,
            zip_code: res.data.cep,
            street: res.data.logradouro,
            neighborhood: res.data.bairro,
            city: res.data.localidade,
            state: res.data.uf
          })
        })
    }
  }

  useEffect

  return (
    <section>
      <div className={classes.toggledTitle}>
        <h2>Habilitar retirada do produto no local</h2>
        <Switch
          inputProps={{ "aria-label": "secondary checkbox" }}
          onClick={() => {
            setHasEnableWithdrawal(!hasEnableWithdrawal)
            handleWithdrawalFormData(
              {
                ...enableWithdrawalFormData
              },
              !hasEnableWithdrawal
            )
          }}
          checked={hasEnableWithdrawal}
        />
      </div>

      {hasEnableWithdrawal ? (
        <>
          <div>
            <h3>CEP do local</h3>
            <TextField
              name="title"
              onChange={e => {
                setEnableWithdrawalFormData({
                  ...enableWithdrawalFormData,
                  zip_code: e.target.value
                })
                searchPostalCode(e.target.value)
                handleWithdrawalFormData(
                  {
                    ...enableWithdrawalFormData,
                    zip_code: e.target.value
                  },
                  hasEnableWithdrawal
                )
              }}
              size="small"
              variant="outlined"
              color="secondary"
              type="tel"
              value={enableWithdrawalFormData.zip_code || ""}
              error={hasEmptyZipCode}
              helperText={hasEmptyZipCode ? "Preencha o campo CEP." : ""}
              fullWidth
              style={{ backgroundColor: "#fff" }}
              inputProps={{ maxLength: 9 }}
            />
          </div>

          <div>
            <h3>Endereço</h3>
            <TextField
              name="title"
              onChange={e => {
                setEnableWithdrawalFormData({
                  ...enableWithdrawalFormData,
                  street: e.target.value
                })
                handleWithdrawalFormData(
                  {
                    ...enableWithdrawalFormData,
                    street: e.target.value
                  },
                  hasEnableWithdrawal
                )
              }}
              size="small"
              variant="outlined"
              color="secondary"
              value={enableWithdrawalFormData.street || ""}
              placeholder="Ex: Rua Correia de Lemos"
              fullWidth
              style={{ backgroundColor: "#fff" }}
            />
          </div>

          <div>
            <h3>Número</h3>
            <TextField
              name="title"
              onChange={e => {
                setEnableWithdrawalFormData({
                  ...enableWithdrawalFormData,
                  number: e.target.value
                })
                handleWithdrawalFormData(
                  {
                    ...enableWithdrawalFormData,
                    number: e.target.value
                  },
                  hasEnableWithdrawal
                )
              }}
              size="small"
              variant="outlined"
              type="number"
              value={enableWithdrawalFormData.number || ""}
              color="secondary"
              fullWidth
              style={{ backgroundColor: "#fff" }}
              inputProps={{ maxLength: 10 }}
            />
          </div>

          <div>
            <h3>Bairro</h3>
            <TextField
              name="title"
              onChange={e => {
                setEnableWithdrawalFormData({
                  ...enableWithdrawalFormData,
                  neighborhood: e.target.value
                })
                handleWithdrawalFormData(
                  {
                    ...enableWithdrawalFormData,
                    neighborhood: e.target.value
                  },
                  hasEnableWithdrawal
                )
              }}
              size="small"
              variant="outlined"
              color="secondary"
              value={enableWithdrawalFormData.neighborhood || ""}
              fullWidth
              style={{ backgroundColor: "#fff" }}
            />
          </div>

          <div>
            <h3>Complemento</h3>
            <TextField
              name="title"
              onChange={e => {
                setEnableWithdrawalFormData({
                  ...enableWithdrawalFormData,
                  complement: e.target.value
                })
                handleWithdrawalFormData(
                  {
                    ...enableWithdrawalFormData,
                    complement: e.target.value
                  },
                  hasEnableWithdrawal
                )
              }}
              size="small"
              variant="outlined"
              color="secondary"
              value={enableWithdrawalFormData.complement || ""}
              placeholder="Apto 3, terceiro andar"
              fullWidth
              style={{ backgroundColor: "#fff" }}
              inputProps={{ maxLength: 50 }}
            />
          </div>

          <div style={{ flexGrow: 1, marginBottom: "15px" }}>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <h3>Cidade</h3>
                <TextField
                  name="title"
                  onChange={e => {
                    setEnableWithdrawalFormData({
                      ...enableWithdrawalFormData,
                      city: e.target.value
                    })
                    handleWithdrawalFormData(
                      {
                        ...enableWithdrawalFormData,
                        city: e.target.value
                      },
                      hasEnableWithdrawal
                    )
                  }}
                  size="small"
                  variant="outlined"
                  color="secondary"
                  value={enableWithdrawalFormData.city || ""}
                  fullWidth
                  style={{ backgroundColor: "#fff" }}
                />
              </Grid>
              <Grid item xs={3}>
                <h3>Estado</h3>
                <TextField
                  name="title"
                  onChange={e => {
                    setEnableWithdrawalFormData({
                      ...enableWithdrawalFormData,
                      state: e.target.value
                    })
                    handleWithdrawalFormData(
                      {
                        ...enableWithdrawalFormData,
                        state: e.target.value
                      },
                      hasEnableWithdrawal
                    )
                  }}
                  size="small"
                  variant="outlined"
                  color="secondary"
                  value={enableWithdrawalFormData.state || ""}
                  fullWidth
                  style={{ backgroundColor: "#fff" }}
                  inputProps={{ maxLength: 2 }}
                />
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <></>
      )}
    </section>
  )
}
