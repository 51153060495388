import { InputAdornment, TextField } from "@material-ui/core"
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import React, { useEffect, useState } from "react"
import { SimpleImg } from "react-simple-img"
import facebookIcon from "../../../Assets/svg/facebook.svg"
import twitterIcon from "../../../Assets/svg/twitter.svg"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { button, buttonSecundary } from "../../../Componentes/Inputs/TextFild"
import lojinhaRequest from "../../../Services/api/lojinha"
import useStyles from "./styles"
import LogoCinza from "../../../Assets/img/logoCinza.png"

export default function EtapaVisualizar({ handleStep, lojinhaShowInfo, onSetStep }) {
  const classes = useStyles()
  const [lojinhaShow, setLojinhaShow] = useState({})  
  const [alertType, setAlertType] = useState("")
  const [showAlert, setShowAlert] = useState(false)

  const closeAlerts = () => {
    setShowAlert(false)
  }

  function alertLink() {
    setAlertType("success")
    setShowAlert(true)
  }

  useEffect(() => {
    if (
      localStorage.getItem("first_time") === "1" ||
      localStorage.getItem("first_time") === null
    ) {
      handleCompletedGuide()
    }
    setLojinhaShow(lojinhaShowInfo)
  }, [])

  const handleCompletedGuide = async () => {
    const lojinhaPutRes = await lojinhaRequest.put({ first_time: 0 })
    if (lojinhaPutRes.data.error === false) {
      localStorage.setItem("first_time", 0)
    }
  }

  return (
    <>
      <div className="infinite-scroll">
        <div className="EtapaHeader">
          <h2>Tudo pronto!</h2>
          <h3>Sua loja está pronta para uso</h3>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {lojinhaShow.md_url ? (
            <SimpleImg
              width="auto"
              height="auto"
              placeholder={false}
              alt="logoDefault"
              className={classes.previewImg}
              src={lojinhaShow.md_url}
            />
          ) : (
            <div className={classes.createImg}>
              <div className={classes.positionLogo}>
                <SimpleImg
                  width="auto"
                  height="auto"
                  placeholder={false}
                  alt="logoDefault"
                  className={classes.defaultLogo}
                  src={LogoCinza}
                />
              </div>
            </div>
          )}
        </div>
        <div className={classes.EtapaVisualizarInfos}>
          <h2>
            {lojinhaShow.name ? lojinhaShow.name : localStorage.getItem("name")}
          </h2>
          <h3 style={{ display: "flex", alignItems: "center" }}>
            <WhatsAppIcon style={{ color: "#A4A4A4", marginRight: "10px" }} />
            {lojinhaShow.name ? lojinhaShow.whatsapp : "(99) 9 9999-9999"}
          </h3>
        </div>
        <div className={classes.EtapaVisualizarLinkLojinha}>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <button
                    style={{ backgroundColor: "transparent", border: "none" }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        lojinhaShow.url
                          ? `https://${lojinhaShow.url}.uppy.app`
                          : `https://${localStorage
                              .getItem("name")
                              .toLowerCase()}.uppy.app`
                      )
                      alertLink()
                    }}
                  >
                    <FileCopyOutlinedIcon style={{ color: "#EA3458" }} />
                  </button>
                </InputAdornment>
              )
            }}
            name="link-lojinha"
            size="small"
            type="text"
            value={
              lojinhaShow.url
                ? `https://${lojinhaShow.url}.uppy.app`
                : `https://${localStorage.getItem("name").toLowerCase()}.uppy.app`
            }
            variant="outlined"
            color="secondary"
            fullWidth
          />
        </div>
        <div className={classes.EtapaVisualizarCompartilhar}>
          <h3 style={{ marginBottom: "15px" }}>Compartillhar:</h3>
          <div>
            <a
              href={`https://www.facebook.com/sharer.php?u=${`https://${lojinhaShow.url}.uppy.app`}`}
              target="_blank"
              rel="noreferrer"
            >
              <SimpleImg
                width={28}
                height="auto"
                placeholder={false}
                src={facebookIcon}
                alt="facebook-icon"
              />
            </a>
          </div>
          <div>
            <a
              href={`https://twitter.com/intent/tweet?url=https://${lojinhaShow.url}.uppy.app`}
              target="_blank"
              rel="noreferrer"
            >
              <SimpleImg
                width={28}
                height="auto"
                placeholder={false}
                src={twitterIcon}
                alt="twitter-icon"
              />
            </a>
          </div>
          <div>
            <a
              href={`https://wa.me/?text=https://${lojinhaShow.url}.uppy.app`}
              target="_blank"
              data-action="share/whatsapp/share"
              rel="noreferrer"
            >
              <WhatsAppIcon
                style={{
                  color: "#fff",
                  backgroundColor: "#3DC34F",
                  padding: "5px",
                  fontSize: "1.2rem",
                  borderRadius: "50px"
                }}
              />
            </a>
          </div>
        </div>
        <div style={{ margin: "15px auto" }}>
          <a
            href={
              lojinhaShow.url
                ? `https://${lojinhaShow.url}.uppy.app`
                : `https://${localStorage.getItem("name").toLowerCase()}.uppy.app`
            }
            target="_blank"
            rel="noreferrer"
          >
            {buttonSecundary({
              name: `Visitar`,
              funcao: () => {
                /* window.location.href = '/home' */
              }
            })}
          </a>
        </div>
      </div>
      <div className="footer-column">
        {button({
          name: `Página Inicial`,
          funcao: () => {
            window.location.href = "/home"
          }
        })}
        <button
          onClick={() => onSetStep(6) }
          className="voltarLink"
          style={{ margin: 0, textAlign: "left", width: "100%" }}
        >
          Voltar etapa
        </button>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <CustomAlert
          type={alertType}
          alertText="Link copiado com sucesso"
          show={showAlert}
          close={() => closeAlerts()}
        />
      </div>
    </>
  )
}
