import { useStyles } from "./style"
import Topo from "../../Componentes/TopCategorias"
import Switch from "@material-ui/core/Switch"
import { button } from "../../Componentes/Inputs/TextFild"
import { useEffect, useState } from "react"
import mediasRequest from "../../Services/api/medias"
import { CircularProgress } from "@material-ui/core"
import CustomAlert from '../../Componentes/Alerts/CustomAlert'
import { formatPhone } from "../../utils/index"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export default function MidiasSociais() {
  const classes = useStyles()

  const [alertType, setAlertType] = useState("")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [loading, setLoading] = useState(true)
  const history = useHistory();
  const handleRedirect = () => {
    history.push("/personalizar-lojinha");
  };
  const [bodyMedias, setBodyMedias] = useState({
    medias_instagram: "",
    medias_instagram_status: false,
    medias_facebook: "",
    medias_facebook_status: false,
    medias_tiktok: "",
    medias_tiktok_status: false,
    medias_whatsapp: "",
    medias_whatsapp_status: false
  })

  async function handleSaveChanges() {
    setLoading(true);
  
    const mandatoryFields = [
      { name: 'medias_instagram', status: 'medias_instagram_status' },
      { name: 'medias_facebook', status: 'medias_facebook_status' },
      { name: 'medias_tiktok', status: 'medias_tiktok_status' },
      { name: 'medias_whatsapp', status: 'medias_whatsapp_status' },
    ];
  
    const hasEmptyMandatoryField = mandatoryFields.some(field => 
      bodyMedias[field.status] && !bodyMedias[field.name]
    );
  
    if (hasEmptyMandatoryField) {
      setAlertType("error");
      setAlertMessage("Por favor, preencha todos os campos obrigatórios antes de salvar.");
      setShowModalAlert(true);
      setLoading(false)
    } else {
      try {
        const { data } = await mediasRequest.putMedias(bodyMedias);
        setAlertType("success");
        setAlertMessage("Mídias sociais salvas com sucesso!");
        setShowModalAlert(true);
        setTimeout(() => {
          handleRedirect();
        }, 1000);
      } catch (error) {
        setAlertType("error");
        setAlertMessage("Erro ao salvar Mídias sociais!");
        setShowModalAlert(true);
      } finally {
        setLoading(false);
      }
    }
  }
  

  async function getData() {
    setLoading(true)
    try {
      const { data } = await mediasRequest.getMedias()
      setBodyMedias({
        medias_instagram_status: data.data.medias_instagram_status ,
        medias_facebook_status: data.data.medias_facebook_status ,
        medias_tiktok_status: data.data.medias_tiktok_status ,
        medias_whatsapp_status: data.data.medias_whatsapp_status ,
        medias_instagram: data.data.medias_instagram,
        medias_facebook: data.data.medias_facebook,
        medias_tiktok: data.data.medias_tiktok,
        medias_whatsapp: data.data.medias_whatsapp
      })
    } catch (error) {
      console.error("Erro:", error.message)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <>
    <div className="page-container">
      <div className="page-align">
        <div>
          <Topo name="Mídias Sociais" />
          {loading ? (
            <div
              style={{
                backgroundColor: "#f9f9f9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%"
              }}
            >
              <CircularProgress color="secondary" size="40px" />
            </div>
          ) : (
            <>
              <div className={classes.ContainerForms}>
                <div className={classes.InputBox}>
                  <div className={classes.InputLabel}>
                    <label>Instagram</label>
                    <Switch
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onClick={() => {
                        setBodyMedias({
                          ...bodyMedias,
                          medias_instagram_status:
                            !bodyMedias.medias_instagram_status
                        })
                      }}
                        checked={bodyMedias?.medias_instagram_status !== undefined ? bodyMedias?.medias_instagram_status : false}
                      />
                  </div>
                  <input
                    value={bodyMedias.medias_instagram}
                    onChange={e => {
                      setBodyMedias({
                        ...bodyMedias,
                        medias_instagram: e.target.value
                      })
                    }}
                    disabled={!bodyMedias.medias_instagram_status}
                  />
                </div>
                <div className={classes.InputBox}>
                  <div className={classes.InputLabel}>
                    <label>Facebook</label>
                    <Switch
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onClick={() => {
                        setBodyMedias({
                          ...bodyMedias,
                          medias_facebook_status: !bodyMedias.medias_facebook_status
                        })
                      }}
                      checked={bodyMedias?.medias_facebook_status !== undefined ? bodyMedias?.medias_facebook_status : false}
                    />
                  </div>
                  <input
                    value={bodyMedias.medias_facebook}
                    onChange={e => {
                      setBodyMedias({
                        ...bodyMedias,
                        medias_facebook: e.target.value
                      })
                    }}
                    disabled={!bodyMedias.medias_facebook_status}
                  />
                </div>
                <div className={classes.InputBox}>
                  <div className={classes.InputLabel}>
                    <label>Tiktok</label>
                    <Switch
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onClick={() => {
                        setBodyMedias({
                          ...bodyMedias,
                          medias_tiktok_status: !bodyMedias.medias_tiktok_status
                        })
                      }}
                      checked={bodyMedias?.medias_tiktok_status !== undefined ? bodyMedias?.medias_tiktok_status : false}
                    />
                  </div>
                  <input
                    value={bodyMedias.medias_tiktok}
                    onChange={e => {
                      setBodyMedias({ ...bodyMedias, medias_tiktok: e.target.value })
                    }}
                    disabled={!bodyMedias.medias_tiktok_status}
                  />
                </div>
                <div className={classes.InputBox}>
                  <div className={classes.InputLabel}>
                    <label>Whatsapp</label>
                    <Switch
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onClick={() => {
                        setBodyMedias({
                          ...bodyMedias,
                          medias_whatsapp_status: !bodyMedias.medias_whatsapp_status
                        })
                      }}
                      checked={bodyMedias?.medias_whatsapp_status !== undefined ? bodyMedias?.medias_whatsapp_status : false}
                    />
                  </div>
                  <input
                    value={formatPhone(bodyMedias.medias_whatsapp || "")}
                    onChange={e => {
                      setBodyMedias({
                        ...bodyMedias,
                        medias_whatsapp: e.target.value
                      })
                    }}
                    disabled={!bodyMedias.medias_whatsapp_status}
                    maxLength={15}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <div className="footer-column">
          <hr style={{ width: "100%" }} />
          {button({
            name: "Salvar alterações",
            funcao: () => handleSaveChanges(),
            disabled: loading
          })}
        </div>
      </div>
    </div>
      <CustomAlert
      type={alertType}
      alertText={alertMessage}
      show={showModalAlert}
      close={() => setShowModalAlert(false)}
    />
    </>
  )
}
