import "./styles.css"

import React, { useState } from "react"
import { BarChart, Bar, CartesianGrid, YAxis, XAxis } from "recharts"

export default function CardVendas(props) {
  const [datacharts, setDataCharts] = useState(props.charts)
  return (
    <div className="body">
      <div className="box">
        <div className="titleCard"> Acompanhamento de vendas</div>
        <div className="boxCard">
          <div>
            <div className="Card1">
              <div className="cardTeste">
                <BarChart
                  width={300}
                  height={200}
                  data={datacharts}
                  barCategoryGap="30%"
                  margin={{ top: 0, right: 0, left: 30, bottom: 0 }}
                >
                  <CartesianGrid
                    stroke="#E4E4E4"
                    strokeDasharray="8"
                    vertical={false}
                  />

                  <Bar
                    dataKey="total_price"
                    fill="#FDBED3"
                    radius={[10, 10, 0, 0]}
                  />
                </BarChart>
              </div>

              <BarChart
                width={300}
                height={200}
                data={datacharts}
                barCategoryGap="30%"
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <Bar dataKey="total_sales" fill="#EA3458" radius={[10, 10, 0, 0]} />
              </BarChart>
            </div>
            {/* <div className="Card2">
              <BarChart
                width={300}
                height={200}
                data={datacharts}
                barCategoryGap="30%"
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <Bar dataKey="total_sales" fill="#EA3458" radius={[10, 10, 0, 0]} />
              </BarChart>
            </div> */}
          </div>
        </div>
        <div className="legendCard1">
          {datacharts.map((item, index) => {
            return <div className="legendCard1">{item.month}</div>
          })}
        </div>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          Meses
        </p>
        <div className="legendCard">
          <div>
            <div
              style={{ backgroundColor: "#EA3458" }}
              className="legendCard2"
            ></div>
            Vendas por unidade
          </div>
          <div>
            <div
              style={{ backgroundColor: "#FDBED3" }}
              className="legendCard2"
            ></div>
            Vendas por R$
          </div>
        </div>
      </div>
    </div>
  )
}
