import { makeStyles } from "@material-ui/core/styles"
import { IoIosArrowForward } from "react-icons/io"

export const useStyles = makeStyles(theme => ({
    button: ({ outlined }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '13px',
        width: '100%',
        maxWidth: '252px',
        height: '45px',
        background: 'linear-gradient(90deg, rgba(0,145,210,1) 35%, rgba(71,198,255,1) 100%)',
        color: '#fff',
        borderRadius: 30,
        border: outlined ? '2px solid #fff' : 'none',
        outline: outlined ? '1px solid #0091d2' : 'none',
    })
}))

export default function CheckoutButton({ children, onClick, outlined, hasIcon }) {
    const classes = useStyles({ outlined })

    return (
        <button onClick={onClick} className={classes.button}>
            {children}
            {hasIcon && <IoIosArrowForward size={20} />}
        </button>
    )
}





