import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  title: {
    color: '#EA3558',
    fontWeight: 500
  },

  status: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "8px",

    "&__color": {
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      marginRight: "5px"
    },

    "& p": {
      display: "flex",
      alignItems: "center",
      marginTop: "10px !important",
      height: '19px',

      "& strong": {
        marginLeft: "5px"
      }
    },

    "& button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "39px",
      height: "37px",
      border: "2.5px solid #EA3458",
      borderRadius: "5px",
      backgroundColor: "#f9f9f9",

      "& img": {
        width: "20px"
      }
    }
  },

  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 30,

    '& a': {
      marginTop: 22,
      color: '#3498DB',
      fontSize: 14,
      fontWeight: 500,
      textDecoration: 'underline'
    }
  },

  helperText: {
    margin: 0,
    fontSize: '11.3px',
    color: '#555'
  },

  rotateAnimation: {
    animation: "$sync-button-rotate 1700ms",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear"
  },

  "@keyframes sync-button-rotate": {
    from: {
      transform: "rotate(0deg)"
    },

    to: {
      transform: "rotate(-360deg)"
    }
  }
}))
