import ApiRequest from "../index"

const retiradaRequest = {
  create: async data =>
    ApiRequest.post(`/lojinha/shipping/create`, {
      ...data
    }),
  put: async (id, data) =>
    ApiRequest.put(`/lojinha/shipping/update/${id}`, {
      ...data
    }),

  get: async id => ApiRequest.get(`/lojinha/shipping/get/${id}/pickup`),

}

export default retiradaRequest

