import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles({
  PrimeiroAcessoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%"
  },
  PrimeiroAcessoHeader: {
    margin: "0.5rem 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& h2": {
      color: "#EA3458",
      fontSize: "2.1rem",
      fontWeight: "900"
    },
    "& h3": {
      color: "#555555",
      fontSize: "1.1rem"
    }
  },
  PrimeiroAcessoImg: {
    width: "100%",
    margin: "1rem 0",
    maxWidth: "420px",
    maxHeight: "350px"
  },
  PrimeiroAcessoTop: {
    display: "flex",
    alignItems: "center",
    padding: "20px 0",
    // marginBottom: '2rem',
    "& button": {
      backgroundColor: "transparent",
      border: "none"
    }
  },
  LogoRosa: {
    margin: "auto"
  }
})
export default useStyles
