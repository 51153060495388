import { Button, CircularProgress } from "@material-ui/core"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../../../../Componentes/Alerts/CustomAlert"
import TopPagseguro from "../../../../../Componentes/TopPagseguro"
import { useStyles } from "./style"
import feesImg from "../../Assets/fees.png"
import CheckoutButton from "../../../../../Componentes/CheckoutButton"

export default function FeesAndTariffs() {
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("success")
  const [showModalAlert, setShowModalAlert] = useState(false)

  const handleClose = async () => {
    history.goBack()
  }

  return (
    <div className="page-container">
      <div className="page-align">
        <TopPagseguro name="Taxas e tarifas" handleBack={handleClose} />

        <div className={`${classes.pagseguroOrders}  infinite-scroll`}>
          {loading ? (
            <div
              style={{
                display: "flex",
                height: "100%",
                minHeight: "100%",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <>
              <img
                src={feesImg}
                alt="a plataforma que te conecta com as vendas"
                className={classes.imgFees}
              />

              <p className={classes.title}>Minhas taxas e tarifas:</p>
              <h3 className={classes.description}>
                Sua taxas e tarifas foram definidas durante a ativação da sua conta
                ou a última negociação realizada com a nossa equipe. Em caso de
                dúvidas clique no botão abaixo para mais detalhes.
              </h3>

              <div className={classes.footer}>
                <CheckoutButton>Detalhes das tarifas e taxas</CheckoutButton>
                <span>deseja melhorar suas tarifas?</span>
              </div>
            </>
          )}
        </div>

        <CustomAlert
          type={alertType}
          alertText={alertMessage}
          show={showModalAlert}
          close={() => setShowModalAlert(false)}
        />
      </div>
    </div>
  )
}
