import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  areaBtn: {
    cursor: "pointer",
    width: "136px",
    height: "120px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#EAEAEA",
    borderRadius: "5px",
    border: "2px dashed #A4A4A4",
    margin: "5px 10px"
  },

  style: {
    display: "flex",
    flexWrap: "wrap",
    height: "max-content",
    width: "100%",
    margin: "0 0 20px 0",
    justifyContent: "center"
  },

  progress: {
    display: "flex",
    height: "154px",
    minHeight: "100%",
    justifyContent: "center",
    alignItems: "center"
  }
}))
