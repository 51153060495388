import React from "react"
import { useStyles } from "./style"
import { IoIosArrowBack } from "react-icons/io"

export default function TopVariationType({ handleBack, name }) {
  const classes = useStyles()

  return (
    <div className={classes.top}>
      <IoIosArrowBack className={classes.arrowBack} onClick={() => handleBack()} />

      <h2>{name}</h2>

      <div className={classes.space} />
    </div>
  )
}
