import './styles.css'

import React, { useState } from 'react'
import { BarChart, Bar, CartesianGrid, YAxis, XAxis } from 'recharts'

export default function CardVisitas(props) {
  const [datacharts, setDataCharts] = useState(props.charts)

  return (
    <div className="body">
      <div className="box">
        <div className="titleCard"> Visitas à loja</div>
        <div className="boxCard">
          <div className="Card2">
            <BarChart
              width={300}
              height={200}
              data={datacharts}
              barCategoryGap="30%"
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            >
              <CartesianGrid stroke="#E4E4E4" strokeDasharray="8" vertical={false} />
              <YAxis allowDecimals={false} />
              <XAxis dataKey="year" />
              <Bar dataKey="tracking_total" fill="#EA3458" radius={[10, 10, 0, 0]} />
            </BarChart>
          </div>
        </div>
        <p
          style={{
            fontWeight: 'bold',
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          Anos
        </p>
        {/* <div className="legendCard1">
          {datacharts.map((item, index) => {
            return (
              <>
                <div>{item.year}</div>
              </>
            )
          })}
        </div> */}
        <div className="legendCard">
          <div>
            <div
              style={{ backgroundColor: '#EA3458' }}
              className="legendCard2"
            ></div>
            Visitas
          </div>
        </div>
      </div>
    </div>
  )
}
