import { makeStyles } from "@material-ui/core/styles"
import { BorderBottom } from "@material-ui/icons"

export const useStyles = makeStyles(theme => ({
  rowPagamento: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "solid 1px #d1d1d1"
  },

  textPayment: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    color: "#EA3458",
    fontWeight: "700",
    fontSize: "14px",
    cursor: "pointer"
  },

  textInfo: {
    display: "flex"
  },

  paymentIcon: {
    margin: "10px"
  },

  paymentText: {
    margin: "10px",
    fontWeight: "400",
    fontSize: "14px",
    marginTop: "15px"
  },

  paymentCheckbox: {
    margin: "10px"
  },

  btnAddLogo: {
    color: "#fff",
    minWidth: "100%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "5px",
    marginBottom: "15px",
    textTransform: "none",
    backgroundColor: "#EA3458",

    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#ff5089c9"
    }
  },
  modalLeaving: {
    textAlign: "center",
    "& .MuiDialog-paper": {
      bottom: "0",
      position: "fixed!important",
      margin: "0",
      padding: "10px 20px",
      width: "100%",
      boxSizing: "border-box",
      borderRadius: "15px 15px 0px 0px"
    }
  },
  btnSave: {
    color: "#fff",
    minWidth: "48%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "10px",
    textTransform: "none",
    fontWeight: "bold",
    marginBottom: "10px",
    backgroundColor: "#EA3458",

    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#ff0053c9"
    }
  },
  btnFooterWhite: {
    height: "50px",
    backgroundColor: "#fff"
  },
  btnFooterPink: {
    height: "50px",
    marginBottom: "20px"
  }
}))
