export default function CartoesdeCredito(props) {
  return (
    <svg
      width="30"
      height="25"
      viewBox="0 0 25 19"
      fill={props.fill ? "#a4a4a4" : " #EA3458"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1875 5.59375C17.1875 5.38655 17.2698 5.18784 17.4163 5.04132C17.5628 4.89481 17.7615 4.8125 17.9688 4.8125H21.0938C21.301 4.8125 21.4997 4.89481 21.6462 5.04132C21.7927 5.18784 21.875 5.38655 21.875 5.59375V7.15625C21.875 7.36345 21.7927 7.56216 21.6462 7.70868C21.4997 7.85519 21.301 7.9375 21.0938 7.9375H17.9688C17.7615 7.9375 17.5628 7.85519 17.4163 7.70868C17.2698 7.56216 17.1875 7.36345 17.1875 7.15625V5.59375Z"
        fill={props.fill ? "#a4a4a4" : props.fill}
      />
      <path
        d="M3.125 0.125C2.2962 0.125 1.50134 0.45424 0.915291 1.04029C0.32924 1.62634 0 2.4212 0 3.25L0 15.75C0 16.5788 0.32924 17.3737 0.915291 17.9597C1.50134 18.5458 2.2962 18.875 3.125 18.875H21.875C22.7038 18.875 23.4987 18.5458 24.0847 17.9597C24.6708 17.3737 25 16.5788 25 15.75V3.25C25 2.4212 24.6708 1.62634 24.0847 1.04029C23.4987 0.45424 22.7038 0.125 21.875 0.125H3.125ZM23.4375 3.25V11.0625H1.5625V3.25C1.5625 2.8356 1.72712 2.43817 2.02015 2.14515C2.31317 1.85212 2.7106 1.6875 3.125 1.6875H21.875C22.2894 1.6875 22.6868 1.85212 22.9799 2.14515C23.2729 2.43817 23.4375 2.8356 23.4375 3.25ZM21.875 17.3125H3.125C2.7106 17.3125 2.31317 17.1479 2.02015 16.8549C1.72712 16.5618 1.5625 16.1644 1.5625 15.75V14.1875H23.4375V15.75C23.4375 16.1644 23.2729 16.5618 22.9799 16.8549C22.6868 17.1479 22.2894 17.3125 21.875 17.3125Z"
        fill={props.fill ? "#a4a4a4" : props.fill}
      />
    </svg>
  )
}
