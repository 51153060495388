import React from "react"
import { useStyles } from "../TopCategorias/style"
import { StateGlobal } from "../../ContextAPI/index"
import CloseIcon from "@material-ui/icons/Close"

export default function Categoria(props) {
  const classes = useStyles()
  const { setOrderPagesIndex } = StateGlobal()

  return (
    <div className={classes.top}>
      <div className={classes.space} />
      <div className={classes.dots}>
        <h2>{props.name}</h2>
      </div>
      <CloseIcon
        style={{
          fontSize: "35px",
          color: "#EA3458",
          cursor: "pointer",
          minWidth: "30px"
        }}
        onClick={() => {
          setOrderPagesIndex(0)
        }}
      />
    </div>
  )
}
