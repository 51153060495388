import React, { useRef } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useStyles } from "./style"
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined"
import { SimpleImg } from "react-simple-img"
import { useProduct } from "../../../../ContextAPI/productContext"
import { KeyboardArrowDown } from "@material-ui/icons"
import { KeyboardArrowUp } from "@material-ui/icons"

const CardDestaques = ({
  key,
  id,
  text,
  index,
  image,
  handleDelete,
  priority,
  moveCard
}) => {
  const ref = useRef(null)
  const classes = useStyles()
  const { productsHighlightList, setProductsHighlightList } = useProduct()
  const [{ handlerId }, drop] = useDrop({
    accept: "Card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })
  const [{ isDragging }, drag] = useDrag({
    type: "Card",
    item: () => {
      return { id, index }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })
  const lastIndex = productsHighlightList.length - 1;

  const opacity = isDragging ? 0.5 : 1

  drag(drop(ref))
  return (
    <div
      ref={ref}
      className={classes.CardWrapper}
      style={{
        opacity,
        transform: isDragging ? "rotate(2deg)" : "rotate(0deg)",
        margin: isDragging ? "15px 0px" : "5px 0px",
        boxShadow: isDragging
          ? "1px 1px 10px rgba(0, 0, 0, 0.5)"
          : "0px 3px 10px rgba(0, 0, 0, 0.1)"
      }}
      data-handler-id={handlerId}
    >
      <div>
      <div>
      {index > 0 && (
        <KeyboardArrowUp
          style={{
            color: "#FF8BB2",
            fontSize: "2.5rem",
            cursor: "pointer",
          }}
          onClick={() => moveCard(index, index - 1)}
        />
      )}
    </div>
    <div>
      {index < lastIndex && (
        <KeyboardArrowDown
          style={{
            color: "#FF8BB2",
            fontSize: "2.5rem",
            cursor: "pointer",
          }}
          onClick={() => moveCard(index, index + 1)}
        />
      )}
    </div>
    </div>
      <img
        width="auto"
        height="auto"
        placeholder={false}
        src={image}
        alt="produto-imagem"
        style={{
          marginLeft: "1rem",
          maxWidth: "4.5rem",
          maxHeight: "4.5rem",
          borderRadius: "5px"
        }}
      />
      <h2 style={{ flex: 1, textAlign: "center" }}>{text}</h2>
      <button
        onClick={() => handleDelete(id)}
        style={{ background: "transparent", border: "none" , fontSize: "1rem"}}
      >
        <DeleteOutlineOutlinedIcon
          style={{
            fontSize: "1.8em",
            color: "#051626",
            cursor: "pointer",
            minWidth: "30px",
            border: "none",
            background: "transparent"
          }}
        />
      </button>
    </div>
  )
}

export default CardDestaques
