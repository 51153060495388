import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  Box: {
    display: "flex",
    justifyContent: "space-between"
  },

  titleText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "500",
    fontSize: "1.0em"
  },

  switchChange: {
    display: "flex",
    alignItems: "center"
  },

  btnWhats: {
    color: "#fff",
    minWidth: "100%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "15px",
    marginBottom: "10px",
    textTransform: "none",
    backgroundColor: "#3ABD3F",

    "& .MuiButton-label": {
      textTransform: "initial",
      fontSize: "1.0em",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#3ABD3F"
    }
  },

  btnServicos: {
    color: "#051626",
    minWidth: "100%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "15px",
    marginBottom: "10px",
    textTransform: "none",
    textcolor: "#051626",
    borderColor: "#051626",

    "& .MuiButton-label": {
      textTransform: "initial",
      fontSize: "1.0em",
      fontWeight: "500"
    }
  },

  hyperLink: {
    textcolor: "#EA3458",
    marginTop: "10px",
    textDecorationLine: "underline"
  }
}))
