import { Button } from "@material-ui/core"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import { globalStyles } from "../../Componentes/CssGlobal"
import Topo from "../../Componentes/TopCategorias"
import { useSettings } from "../../ContextAPI/settingsContext"
import conexao from "../../Services/api"
import ListaMeiosPagamentos from "./components/ListaFormasPagamentos"
import { useStyles } from "./style"

export default function PaymentsMethods() {
  const history = useHistory()
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const { getPayment } = useSettings()
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [alertType, setAlertType] = useState("")

  const [DataBody, setDataBody] = useState()
  const [recebeE, setRecebeE] = useState()
  const [saving, setSaving] = useState(false)

  const id = localStorage.getItem("id")

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  function getBody(e) {
    setRecebeE(e)
  }

  function PostDados() {
    setSaving(true)

    const ids = []

    recebeE.map((item, index) => {
      if (item.checked) {
        ids.push(item.id)
      }
    })

    const tranformArr = ids.toString()
    const NewArr = tranformArr.replace(/,/g, "|")

    const body = {
      names: NewArr,
      lojinha_id: id
    }

    conexao
      .post("/lojinha/payment/create", body)
      .then(res => {
        getPayment()
        setAlertType("success")
        setShowAlertOk(true)
        setTimeout(() => {
          history.goBack()
        }, 1500)
      })
      .catch(error => {
        setSaving(false)
        setAlertType("error")
        setShowAlertOk(true)
      })
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <div>
            <Topo name="Meios de Pagamento" />
          </div>
          <div className="infinite-scroll">
            <ListaMeiosPagamentos getBody={getBody} />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              {/* alertas */}
              <CustomAlert
                type={alertType}
                alertText={
                  alertType === "success"
                    ? "Pagamento salvo com sucesso!"
                    : "Algo deu errado!"
                }
                show={showAlertOk || showAlertError}
                close={() => closeAlerts()}
              />
            </div>
          </div>
          <div className="footer-column">
            <Button
              type="submit"
              className={classes.btnAddLogo}
              variant="contained"
              disabled={saving}
              onClick={() => {
                PostDados()
              }}
            >
              Salvar alterações
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
