import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import React, { useEffect, useState } from "react"
import { useStyles } from "./style"

export default function ModalExcluir(props) {
  const classes = useStyles()
  const [open, setOpen] = useState(props.open)

  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  return (
    <div style={{ width: "100%" }}>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.teste}>
          <Button
            onClick={() => {
              props.close()
              setOpen(false)
            }}
            variant="contained"
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              props.delete(props.id)
              props.close()
              props.gif()
            }}
            variant="contained"
            color=" secondary "
            autoFocus
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
