import CircularProgress from "@material-ui/core/CircularProgress"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import AddCircleIcon from "../../Assets/svg/add_circle.svg"
import Topo from "../../Componentes/TopCategorias"
import storiesRequest from "../../Services/api/stories"
import DescriptionIcon from "@material-ui/icons/Description"
import ModalExcluir from "../Categoria/Componentes/ModalExcluir/index.js"
import LabelStories from "./components/LabelStories"
import { useStyles } from "./style"
export default function Stories() {
  const classes = useStyles()
  const history = useHistory()
  const [storyList, setStoryList] = useState([{}])
  const [open, setOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const getData = async () => {
    try {
      setIsLoading(true)
      const { data } = await storiesRequest.getStoryList()
      setStoryList(data.data)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }
  const handleAddStory = () => {
    history.push("/categorias-stories")
  }

  const handleDeleteStory = index => {
    const currentStory = storyList[index]
    const currentId = currentStory.story_id
    setIdToDelete(currentId)
    setOpen(true)
  }
  const deleteStory = () => {
    try {
      storiesRequest.delStory(idToDelete)
    } catch (err) {}
    getData()
  }
  const handleEdit = index => {
    const currentStory = storyList[index]
    const currentStoryId = currentStory.story_id
    const currentCategoryId = currentStory.category_id
    history.push({
      pathname: "/produtos-stories",
      state: { currentStoryId, currentCategoryId }
    })
  }

  const loading = () => {
    setIsLoading(true)
  }

  function close() {
    setOpen(false)
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <div className="page-container">
        <div className="page-align infinite-scroll">
          <section className={classes.Section}>
            <header>
             <Topo name="Stories" rota={"/personalizar-lojinha"}/>
            </header>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  minHeight: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "40px"
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <main className="">
                <div className={classes.StoryContainer}>
                {storyList.length === 0 ?
                    <div className={classes.EmptyStories}>
                      <p>Não há stories ainda, comece agora mesmo!</p>
                      <DescriptionIcon />
                    </div>
                    : storyList.map((item, index) => {
                      return (
                        <LabelStories
                          key={index}
                          index={index}
                          isStory
                          onDelete={handleDeleteStory}
                          categoryName={item.category_name}
                          onEdit={handleEdit}
                        />
                      )
                    })}
                </div>
              </main>
            )}
            <button className={classes.AddStory} onClick={handleAddStory}>
              <img src={AddCircleIcon} />
            </button>
          </section>
        </div>
      </div>

      <ModalExcluir
        open={open}
        title="Excluir Story"
        desc="Tem certeza que deseja excluir esse story?"
        delete={deleteStory}
        id={idToDelete}
        close={close}
        gif={loading}
      />
    </>
  )
}
