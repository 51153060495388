import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "300px",
    height: "380px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    margin: "8%",
    boxShadow: theme.shadows[5],
    "@media (min-width:1024px)": {
      // eslint-disable-line no-useless-computed-key
      margin: "20%"
    }
  },

  positionModal: {
    display: "flex",
    height: "100%",
    minHeight: "100%",
    justifyContent: "center",
    alignItems: "center"
  },

  titleText: {
    display: "flex",
    flexDirection: "column"
  },

  title: {
    margin: "15px 15px 0px 15px",
    fontWeight: "700",
    color: "#353535"
  },

  text: {
    margin: "15px",
    fontWeight: "400",
    fontSize: "14px",
    textAlign: "justify"
  },

  textExcluir: {
    display: "flex",
    marginLeft: "25px",
    marginRight: "25px",
    justifyContent: "center",
    width: "100%",

    "& .MuiFormControl-root": {
      width: "90%"
    }
  },

  groupBtn: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-around"
  },

  btnModal: {
    width: "50%",
    height: "50px",
    margin: "10px"
  },

  btnCancelarStyle: {
    width: "100%",
    border: "solid 2px #EA345880",
    color: "#EA345880",
    borderRadius: "5px",
    backgroundColor: "#fff",
    height: "50px",
    fontSize: "16px"
  },

  btnExcluirStyle: {
    transition: ".3s ease-out",
    width: "100%",
    border: "solid 2px #EB1111",
    color: "#fff",
    borderRadius: "5px",
    backgroundColor: "#EB1111",
    height: "50px",
    fontSize: "16px"
  },
  btnDisable: {
    transition: ".3s ease-out",
    width: "100%",
    border: "solid 2px #cccccc",
    color: "#a4a4a4",
    borderRadius: "5px",
    backgroundColor: "#cccccc",
    height: "50px",
    fontSize: "16px"
  }
}))
