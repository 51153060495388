import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  Box: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "5px"
  },

  titleText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "500",
    fontSize: "1.0em"
  },

  switchChange: {
    display: "flex",
    alignItems: "center"
  },

  fonteTitulo: {
    fontSize: "1.0em",
    color: "#555555",
    display: "flex",
    marginTop: "10px"
  },

  hyperLink: {
    textcolor: "#EA3458",
    fontSize: "1.0em",
    marginTop: "10px"
  },

  btnSalvar: {
    color: "#fff",
    minWidth: "100%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "5px",
    marginBottom: "15px",
    textTransform: "none",
    backgroundColor: "#EA3458",

    "& .MuiButton-label": {
      textTransform: "initial",
      fontSize: "1.0em",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#ff5089c9"
    }
  },

  link: {
    textDecoration: "underline",
    color: "#EA3458"
  }
}))
