import { useState } from "react"

import Topo from "../../../../Componentes/TopCategorias"
import { button } from "../../../../Componentes/Inputs/TextFild"
import PasswordInput from "./Components/passwordInput"

import { useStyles } from "./style"
import { globalStyles } from "../../../../Componentes/CssGlobal"
import usuarioRequest from "../../../../Services/api/usuario"
import CustomAlert from "../../../../Componentes/Alerts/CustomAlert"
import { useHistory } from "react-router-dom"

export default function ChangePassword({ setIsChangePassword }) {
  const classesGlobal = globalStyles()
  const classes = useStyles()

  const [alertType, setAlertType] = useState("")
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [saving, setSaving] = useState(false)
  const history = useHistory()

  const data = {
    currentPassword: { value: "", error: false },
    newPassword: { value: "", error: false },
    repetNewPassword: { value: "", error: false }
  }

  const [formData, setFormData] = useState(data)

  function validasenha(e) {
    if (e.length < 6) {
      return true
    }
    if (e === "") {
      return true
    }
    return false
  }

  function getPassword(e) {
    const { name, value } = e

    setFormData({
      ...formData,
      [name]: { value, error: validasenha(value) }
    })
  }

  async function handleSaveChanges() {
    setSaving(true)

    if (formData.newPassword.value === formData.repetNewPassword.value) {
      let changePasswordRes = await usuarioRequest.changePassword({
        id: localStorage.getItem("user_id"),
        email: localStorage.getItem("email"),
        new_password: formData.newPassword.value,
        old_password: formData.currentPassword.value
      })

      if (
        changePasswordRes.data.error === false &&
        changePasswordRes.data.message &&
        changePasswordRes.data.message === "senha invalida"
      ) {
        setSaving(false)

        setAlertMessage("Senha atual inválida!")
        setShowAlert(true)
        setAlertType("error")
      } else if (changePasswordRes.data.error === false) {
        setAlertMessage("Senha alterada com sucesso!")
        setShowAlert(true)
        setAlertType("success")
        setTimeout(() => {
          history.push("/meu-perfil")
        }, 1500)
      } else {
        setSaving(false)
        setAlertMessage("Erro ao alterar senha!")
        setShowAlert(true)
        setAlertType("error")
      }
    } else {
      setSaving(false)
      setAlertMessage("Senhas novas não conferem!")
      setShowAlert(true)
      setAlertType("error")
    }
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <div>
            <Topo
              onClick={() => setIsChangePassword(false)}
              name="Alterar senha de acesso"
            />
          </div>
          <div className="infinite-scroll">
            <div className={classes.formBody}>
              <div style={{ marginTop: "25px", marginBottom: "22px" }}>
                <p>Senha atual</p>
                <PasswordInput
                  getPassword={getPassword}
                  passwordType="currentPassword"
                  error={formData.currentPassword.error}
                />
              </div>

              <div style={{ marginBottom: "22px" }}>
                <p>Nova senha</p>
                <PasswordInput
                  getPassword={getPassword}
                  passwordType="newPassword"
                  error={formData.newPassword.error}
                />
              </div>

              <div style={{ marginBottom: "22px" }}>
                <p>Confirmar nova senha</p>
                <PasswordInput
                  getPassword={getPassword}
                  passwordType="repetNewPassword"
                  error={formData.repetNewPassword.error}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <CustomAlert
                  type={alertType}
                  alertText={alertMessage}
                  close={() => setShowAlert(false)}
                  show={showAlert}
                />
              </div>
            </div>
          </div>

          <div className="footer-column">
            <hr style={{ width: "100%", borderColor: "#E4E4E4", opacity: 0.3 }} />

            {button({
              name: "Salvar Alterações",
              funcao: () => handleSaveChanges(),
              disabled: saving
            })}
          </div>
        </div>
      </div>
    </>
  )
}
