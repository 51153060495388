import Modal from "@material-ui/core/Modal"
import { makeStyles } from "@material-ui/core/styles"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"
import React from "react"
import { SimpleImg } from "react-simple-img"

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "auto",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    margin: "8%",
    boxShadow: theme.shadows[5],

    "@media (min-width:1024px)": {
      // eslint-disable-line no-useless-computed-key
      margin: "20%"
    }
  },

  positionModal: {
    display: "flex",
    height: "100%",
    minHeight: "100%",
    justifyContent: "center",
    alignItems: "center"
  },

  imgExib: {
    width: "100%"
  },

  localLixo: {
    marginTop: "-44px",
    display: "flex",
    height: "40px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#00000099"
    // borderRadius: '0px 0px 15px 15px',
  },

  imgLixo: {
    color: "#fff",
    fontSize: "28px",
    cursor: "pointer"
  }
}))

export default function ModalFoto(props) {
  const classes = useStyles()

  const body = (
    <div className={classes.paper} style={{ zIndex: 10000 }}>
      <div className={classes.localExib}>
        <SimpleImg
          width="auto"
          height="auto"
          placeholder={false}
          className={classes.imgExib}
          alt="teste"
          src={props.src}
        />
      </div>
      <div className={classes.localLixo}>
        <DeleteOutlineIcon
          close={props.close}
          onClick={() => {
            props.excluir(props.index)
          }}
          className={classes.imgLixo}
        />
      </div>
    </div>
  )

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ zIndex: 10000 }}
      >
        <div
          id="modal"
          onClick={() => {
            props.close()
          }}
          className={classes.positionModal}
          aria-hidden
        >
          {body}
        </div>
      </Modal>
    </div>
  )
}
