import React, { createContext, useContext, useEffect, useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { TouchBackend } from "react-dnd-touch-backend"
import { deviceDetect } from "react-device-detect"

// commit

export const DeviceContext = createContext({})

export function useDevice() {
  const context = useContext(DeviceContext)
  if (!context) {
    throw new Error("useDevice must be used inside DeviceProvider")
  }

  return context
}

export function DeviceProvider(props) {
  const { children } = props

  const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth <= 499)

  const handleIsMobile = value => {
    setIsMobileDevice(value)
  }

  const handleResize = () => {
    if (window.innerWidth <= 499 && isMobileDevice === false) handleIsMobile(true)
    else if (window.innerWidth >= 499 && isMobileDevice === true)
      handleIsMobile(false)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [isMobileDevice])

  function checkDevice() {
    return deviceDetect()
  }

  return (
    <DeviceContext.Provider
      value={{
        isMobileDevice,
        handleIsMobile,
        checkDevice
      }}
    >
      <DndProvider
        backend={
          window.innerWidth <= 499 || isMobileDevice ? TouchBackend : HTML5Backend
        }
      >
        {children}
      </DndProvider>
    </DeviceContext.Provider>
  )
}
