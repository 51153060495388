import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
    Modal: {
        "& .MuiDialog-paper": {
            maxHeight: "none",
            margin: "0",
            width: "100%",
            borderRadius: "0",
            boxShadow: "0px 12px 10px 5px #d9d9d9",
            height: "100%!important"
        },
        
        "& .MuiDialogActions-root": {
            display: "block"
        },
        
        "& .MuiBackdrop-root": {
            backgroundColor: "#00000000"
        }, 
        "& .MuiDialog-scrollPaper": {
            opacity: "1 !important",
        }
    },

    mainContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
        textAlign: "center",
        margin: "30px 40px",
    },

    alertContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
    },

    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
        width: "100%"
    },

    buttonRed: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#EA3458",
        color: "#FFF",
        height: "50px",
        border: "none",
        width: "100%",
        borderRadius: "5px",
        fontSize: "19px",
        fontWeight: "500",
        outline: "none",
    },

    buttonWhite: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFF",
        color: "#051626",
        height: "50px",
        border: "1px solid #051626",
        width: "100%",
        borderRadius: "5px",
        fontSize: "19px",
        fontWeight: "500",
        outline: "none",
    }
})
export default useStyles