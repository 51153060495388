import React, { useState } from "react"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import EditIcon from "@material-ui/icons/Edit"
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined"
import { makeStyles } from "@material-ui/core"
import defaultImage from "../../Assets/img/noProductImg.png"
import { SimpleImg } from "react-simple-img"

const useStyles = makeStyles({
  ProductCardContainer: {
    display: "flex",
    width: "100%",
    cursor: "pointer"
  },
  ProductCard: {
    display: "flex",
    alignItems: "center",
    flex: 1
  },
  ButtonActionsContainer: {
    display: "flex",
    padding: "5px",
    justifyContent: "flex-end",
    alignItems: "center",
    "& button": {
      border: "none",
      backgroundColor: "transparent"
    }
  }
})

function ProductCard({ product, handleEdit, isActive }) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div
        onClick={() => handleEdit(product)}
        key={product.id}
        className={classes.ProductCardContainer}
        aria-hidden="true"
        style={{opacity: isActive ? 1 : 0.5}}
      >
        <div
          className={classes.ProductCard}
          style={{ marginLeft: isOpen ? "-30px" : "0" }}
        >
          <SimpleImg
            alt="Imagem do produto"
            height={75}
            width={75}
            placeholder={false}
            src={
              product.images?.length > 0 ? product.images[0].sm_url : defaultImage
            }
            style={{ borderRadius: "5px", border: "1px solid #D8D8D8" }}
          />
          <div style={{ maxWidth: "250px", marginLeft: "0.6rem" }}>
            <h5
              style={{
                fontSize: "1rem",
                maxWidth: "250px",
                overflowWrap: "break-word",
                paddingTop: "10px",
                color: "#353535"
              }}
            >
              {product.title}
            </h5>
            <h6
              style={{
                fontSize: "0.8rem",
                maxWidth: "22ch",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
              }}
            >
              {product.description}
            </h6>
            {/*             {product.promotional_price !== null ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '250px',
                  overflowWrap: 'break-word'
                }}
              >
                <h6
                  id="price"
                  style={{
                    fontSize: '0.8rem',
                    textDecoration: 'line-through',
                    color: '#0006',
                    fontWeight: '500'
                  }}
                >
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(product.price)}
                </h6>
                <h6
                  id="promotional_price"
                  style={{ fontSize: '0.8rem', color: '#EA3458', fontWeight: '500' }}
                >
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(product.promotional_price)}
                </h6>
              </div>
            ) : (
              <h6 id="price" style={{ fontSize: '0.8rem', fontWeight: '500' }}>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(product.price)}
              </h6>
            )} */}
            <h6
              id="price"
              style={{
                fontSize: "0.8rem",
                fontWeight: "500",
                padding: "10px 0 10px 0"
              }}
            >
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL"
              }).format(product.price)}
            </h6>
          </div>
        </div>
        {/* BUTTON ACTIONS */}
        {/* <div className={classes.ButtonActionsContainer}>
          <div hidden={isOpen}>
            <button
              onClick={() => setIsOpen(true)}
              style={{
                boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '5px'
              }}
            >
              <MoreVertIcon
                style={{
                  color: '#EA3458',
                  cursor: 'pointer'
                }}
              />
            </button>
          </div>
          <div
            hidden={!isOpen}
            style={{
              display: isOpen ? 'flex' : 'none',
              boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)',
              padding: '5px',
              borderRadius: '5px'
            }}
          >
            <button onClick={() => setIsOpen(false)}>
              <MoreHorizIcon
                style={{ color: '#A4A4A4', cursor: 'pointer', fontSize: '1.2rem' }}
              />
            </button>
            <span style={{ color: '#A4A4A4' }}>|</span>
            <button onClick={() => handleEdit(product)}>
              <EditIcon
                style={{ color: '#EA3458', cursor: 'pointer', fontSize: '1.2rem' }}
              />
            </button>
            <button onClick={() => handleDelete(product)}>
              <DeleteOutlineOutlinedIcon
                style={{ color: '#051626', cursor: 'pointer', fontSize: '1.2rem' }}
              />
            </button>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default ProductCard
