import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import React, { useEffect, useState } from "react"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import conexao from "../../../Services/api"
import useStyles from "./style"

export default function ModalExcluirProduto({ isOpen, handleClose, idProduct }) {
  const classes = useStyles()
  const [cancel, setCancel] = useState()
  const [btnDisable, setBtnDisable] = useState(true)
  const [alertType, setAlertType] = useState("")
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)

  function validateBtn() {
    if (String(cancel).toLocaleUpperCase() === "EXCLUIR") {
      setBtnDisable(false)
    } else {
      setBtnDisable(true)
    }
  }

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  useEffect(() => {
    validateBtn()
  }, [cancel])

  async function handleProduct() {
    if (String(cancel).toLocaleUpperCase() === "EXCLUIR") {
      const resDel = await conexao.delete(`/product/${idProduct}`)
      if (resDel) {
        setAlertType("success")
        setShowAlertOk(true)
        setTimeout(() => {
          handleClose(true)
          setBtnDisable(false)
        }, 1500)
      }
    } else {
      setAlertType("error")
      setShowAlertError(true)
      setBtnDisable(false)
    }
  }

  return (
    <div className="page-container">
      <div className="page-align">
        <div className="infinite-scroll">
          <div>
            <Dialog
              open={isOpen}
              onClose={() => handleClose(false)}
              aria-labelledby="responsive-dialog-title"
              className={classes.PlanosAlertaModal}
            >
              <DialogTitle id="responsive-dialog-title">
                <div className={classes.PlanosAlertaModalHeader}>
                  <h2>Atenção</h2>
                  <button type="button" onClick={() => handleClose(false)}>
                    <CloseIcon style={{ fontSize: "30" }} />
                  </button>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText className={classes.PlanosAlertaModalBody}>
                  <p>
                    Ao excluir um produto, todos os dados referentes a ele serão
                    apagados definitivamente do banco de dados e da sua conta. <br />
                    Você não terá mais acesso ao produto e nem às informações
                    inseridas nele.
                    <br /> <br />
                  </p>
                  <p>
                    Você gostaria realmente de excluir seu produto? Caso queira
                    preeencha o campo abaixo para habilitar a exclusão.
                  </p>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginLeft: "30px",
                      marginBottom: "5px"
                    }}
                  >
                    <spam
                      style={{
                        color: "#555555",
                        fontWeight: "400",
                        fontSize: "11px"
                      }}
                    >
                      Para prosseguir digite <b>“EXCLUIR”</b>
                    </spam>
                  </div>

                  <div style={{ width: "90%" }}>
                    <TextField
                      id="outlined-size-small"
                      value={cancel}
                      onChange={e =>
                        setCancel(
                          String(e.target.value)
                            .replace(/\s/g, "")
                            .toLocaleUpperCase()
                        )
                      }
                      variant="outlined"
                      size="small"
                      color="secondary"
                      fullWidth
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    {showAlertOk ? (
                      <CustomAlert
                        type={alertType}
                        alertText={
                          alertType === "success"
                            ? "Produto excluído com sucesso!"
                            : "Algo deu errado!"
                        }
                        show={showAlertOk || showAlertError}
                        close={() => closeAlerts()}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-around",
                      marginTop: "15px"
                    }}
                  >
                    <button
                      className={classes.btnCancelarStyle}
                      onClick={() => {
                        handleClose(false)
                      }}
                    >
                      Voltar
                    </button>
                    {btnDisable ? (
                      <button className={classes.btnDisable} onClick={() => {}}>
                        Excluir
                      </button>
                    ) : (
                      <button
                        className={classes.btnExcluirStyle}
                        onClick={() => {
                          handleProduct()
                        }}
                      >
                        Excluir
                      </button>
                    )}
                  </div>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  )
}
