import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  titleDelivery: {
    fontWeight: 500,
    color: "#EA3458",

    '& span': {
      color: '#555555'
    }
  },
  btnCopyDelivery: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "2.2rem",
    backgroundColor: "transparent",
    color: "#3498DB",
    border: "1px solid #3498DB",
    borderRadius: "5px",
    fontWeight: 500,
    textAlign: "center"
  },
  btnTicketSend: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "3.2rem",
    backgroundColor: "#3498DB",
    color: "#fff",
    border: "1px solid #3498DB",
    borderRadius: "5px",
    fontWeight: 500,
    textAlign: "center"
  }
}))
