import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => ({
  footerContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    marginBottom: "7px",
    alignItems: "center"
  },

  footerItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& img": {
      cursor: "pointer"
    },
    "& p": {
      fontWeight: "400",
      fontSize: "12px",
      margin: "0"
    }
  }
}))
