import { CircularProgress } from "@material-ui/core"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import logo_payhow from "../../../Assets/svg/logo_payhow.svg"
import pagseguro from "../../../Assets/svg/pagseguro.svg"
import { globalStyles } from "../../../Componentes/CssGlobal"
import Topo from "../../../Componentes/TopCategorias"
import api from "../../../Services/api"
import { useStyles } from "./style"
import { useSettings } from "../../../ContextAPI/settingsContext"
import mercadoPagoLogo from "../../../Assets/img/mercado-pago-logo.png"

export default function Checkout() {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const history = useHistory()
  const { lojinhaInfo } = useSettings()
  const [loading, setLoading] = useState(true)
  const [payhowStatus, setPayhowStatus] = useState({})
  const [paymentActive, setPaymentActive] = useState({
    pagseguro: 0,
    gateway: 0,
    mercadopago: 0
  })
  const shopId = localStorage.getItem("id")

  async function getDataPayment() {
    const paymentActiveRes = await api.get(`lojinha/payment/get/${shopId}`)
    const payhow_payments = paymentActiveRes?.data?.data?.payment_data?.payhow

    setPaymentActive({
      pagseguro: payhow_payments?.pagseguro === 1,
      gateway: payhow_payments?.gateway === 1,
      mercadopago: payhow_payments?.mercadopago === 1
    })

    setLoading(false)
  }

  async function getPayhowStatus() {
    setLoading(true)
    const {
      data: { data }
    } = await api.get(`/payhow/gateway/status/${localStorage.getItem("id")}`)
    setPayhowStatus(data)
  }

  useEffect(() => {
    getDataPayment()
    getPayhowStatus()
  }, [])

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <Topo name="Checkout" />
          </div>
          <div className="infinite-scroll">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  minHeight: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <>
                <div
                  className={classes.Box}
                  style={{ cursor: "pointer" }}
                  aria-hidden="true"
                  onClick={() => history.push("/pagseguro")}
                >
                  <div className={classesGlobal.fonteTitulo}>
                    Receber pedidos com cartão de crédito
                  </div>
                  <div className={classes.btnalinhamento}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column"
                      }}
                    >
                      <img
                        alt="logo pagseguro"
                        src={pagseguro}
                        style={{ margin: "10px" }}
                      />
                      {paymentActive.pagseguro ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <CheckCircleIcon
                            style={{ color: "#27AE60", fontSize: "1rem" }}
                          />
                          <p
                            style={{
                              color: "#27AE60",
                              margin: "5px 0px",
                              fontSize: "1rem"
                            }}
                          >
                            ativo
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className={classesGlobal.fonteSubtitulo}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      Em parceria com a PagBank receba suas vendas utilizando a opção
                      de cartões de crédito em nosso checkout
                    </div>

                    <ArrowForwardIosIcon fontSize="small" color="disabled" />
                  </div>
                  <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                </div>

                {/* <div
                  className={classes.Box}
                  style={{ cursor: "pointer" }}
                  aria-hidden="true"
                  onClick={() => history.push("/mercado-pago")}
                >
                  <div className={classesGlobal.fonteTitulo}>
                    Receber o dinheiro das suas vendas com o Mercado Pago
                  </div>
                  <div className={classes.btnalinhamento}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column"
                      }}
                    >
                      <img
                        alt="logo mercado pago"
                        src={mercadoPagoLogo}
                        style={{ margin: "10px", width: "80px" }}
                      />
                      {paymentActive.pagseguro ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <CheckCircleIcon
                            style={{ color: "#27AE60", fontSize: "1rem" }}
                          />
                          <p
                            style={{
                              color: "#27AE60",
                              margin: "5px 0px",
                              fontSize: "1rem"
                            }}
                          >
                            ativo
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className={classesGlobal.fonteSubtitulo}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      Em parceria com o Mercado Pago receba suas vendas utilizando a
                      opções de cartão de crédito e PIX em nosso checkout e receba o
                      dinheiro das suas vendas com o Mercado Pago.
                    </div>

                    <ArrowForwardIosIcon fontSize="small" color="disabled" />
                  </div>
                  <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                </div> */}

                <div
                  className={classes.Box}
                  style={{ cursor: "pointer" }}
                  aria-hidden="true"
                  onClick={() => history.push("/mercado-pago")}
                >
                  <div className={classesGlobal.fonteTitulo}>
                    Receber o dinheiro das suas vendas com o Mercado Pago
                  </div>
                  <div className={classes.btnalinhamento}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column"
                      }}
                    >
                      <img
                        alt="logo mercado pago"
                        src={mercadoPagoLogo}
                        style={{ margin: "10px", width: "80px" }}
                      />
                      {paymentActive?.mercadopago ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <CheckCircleIcon
                            style={{ color: "#27AE60", fontSize: "1rem" }}
                          />
                          <p
                            style={{
                              color: "#27AE60",
                              margin: "5px 0px",
                              fontSize: "1rem"
                            }}
                          >
                            ativo
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className={classesGlobal.fonteSubtitulo}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      Em parceria com o Mercado Pago receba suas vendas utilizando a
                      opções de cartão de crédito e PIX em nosso checkout e receba o
                      dinheiro das suas vendas com o Mercado Pago.
                    </div>

                    <ArrowForwardIosIcon fontSize="small" color="disabled" />
                  </div>
                  <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                </div>

                <div
                  className={classes.Box}
                  style={{
                    cursor: "pointer",
                    opacity: lojinhaInfo.feature_tester === 1 ? 1 : 0.5
                  }}
                  aria-hidden="true"
                  onClick={() => {
                    if (lojinhaInfo.feature_tester === 0) return
                    let url =
                      payhowStatus.kyc_status === 0
                        ? "/active-checkout"
                        : payhowStatus.kyc_status === 1 &&
                          payhowStatus.payhow_status === 0
                        ? "/active-payhow-registration"
                        : "/checkout-payhow"

                    history.push(url)
                  }}
                >
                  <div className={classesGlobal.fonteTitulo}>
                    Ativar checkout PAYHOW
                  </div>

                  <div className={classes.btnalinhamento}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column"
                      }}
                    >
                      <img
                        alt="logo payhow"
                        src={logo_payhow}
                        style={{ margin: "10px" }}
                      />
                      {paymentActive?.gateway ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <CheckCircleIcon
                            style={{ color: "#27AE60", fontSize: "1rem" }}
                          />
                          <p
                            style={{
                              color: "#27AE60",
                              margin: "5px 0px",
                              fontSize: "1rem"
                            }}
                          >
                            ativo
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={classesGlobal.fonteSubtitulo}>
                      Receba seus pagamentos em até 6 formas de pagamento diferentes:
                      Cartões de Crédito, Débito, Boleto bancário, PIX, Picpay, AME
                      Digital entre outras...
                    </div>
                    <ArrowForwardIosIcon fontSize="small" color="disabled" />
                  </div>
                  <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
