import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  CardContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #F3F3F3",
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: "5px",
    height: "42px",
    margin: "1rem 0rem"
  },
  CardText: {
    display: "flex",
    alignItems: "center",
    flex: "1",
    margin: "0 5px",
    "& > p": {
      fontWeight: "600",
      fontSize: "1.2rem"
    }
  },
  CardIcon: {
    display: "flex",
    alignItems: "center",
    margin: "0 5px"
  }
}))
