import { Button, CircularProgress, TextField } from "@material-ui/core"
import Switch from "@material-ui/core/Switch"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import TopPagseguro from "../../../../../Componentes/TopPagseguro"
import api from "../../../../../Services/api"
import ame from "../../Assets/ame.png"
import boleto from "../../Assets/boleto.png"
import mastercard from "../../Assets/mastercard.png"
import mercadopago from "../../Assets/mercadopago.png"
import picpay from "../../Assets/picpay.png"
import pix from "../../Assets/pix.png"
import visa from "../../Assets/visa.png"
import { useStyles } from "./style"

const installments = [
  {
    value: "1",
    label: "1 parcela sem juros"
  },
  {
    value: "2",
    label: "2 parcelas sem juros"
  },
  {
    value: "3",
    label: "3 parcelas sem juros"
  },
  {
    value: "4",
    label: "4 parcelas sem juros"
  },
  {
    value: "5",
    label: "5 parcelas sem juros"
  },
  {
    value: "6",
    label: "6 parcelas sem juros"
  },
  {
    value: "7",
    label: "7 parcelas sem juros"
  },
  {
    value: "8",
    label: "8 parcelas sem juros"
  },
  {
    value: "9",
    label: "9 parcelas sem juros"
  },
  {
    value: "10",
    label: "10 parcelas sem juros"
  },
  {
    value: "11",
    label: "11 parcelas sem juros"
  },
  {
    value: "12",
    label: "12 parcelas sem juros"
  }
]

export default function PayhowConfig() {
  const classes = useStyles()
  const history = useHistory()
  const shopId = localStorage.getItem("id")
  const paymentId = localStorage.getItem("payment_id")
  const [loading, setLoading] = useState(false)

  const [creditCardConfig, setCreditCardConfig] = useState({
    enable: 0,
    credentials: {
      anticipation: false,
      max_installments: 12
    }
  })
  const [pixConfig, setPixConfig] = useState({
    enable: 0,
    credentials: {
      notification_voice: false,
      notification_whatsapp: false,
      notification_sms_email: false
    }
  })
  const [boletoConfig, setBoletoConfig] = useState({
    enable: 0,
    credentials: {
      kit_pix: false,
      negativity_serasa: false,
      notification_voice: false,
      notification_whatsapp: false,
      notification_sms_email: false,
      discount_percentage: false,
      fine_percentage: false,
      interest_percentage: false
    }
  })

  const handleClose = async () => {
    history.goBack()
  }

  async function getInformation() {
    setLoading(true)
    try {
      const {
        data: { data }
      } = await api.get(`payhow/info/${shopId}`)

      const payments = data.map(data => {
        if (data.name === "payhow-gat" && data.type === "credit_card") {
          setCreditCardConfig({
            enable: data?.enable,
            credentials: { ...data?.credentials }
          })
        }
        if (data.name === "payhow-gat" && data.type === "boleto") {
          setBoletoConfig({
            enable: data?.enable,
            credentials: { ...data?.credentials }
          })
        }
        if (data.name === "payhow-gat" && data.type === "pix") {
          setPixConfig({
            enable: data?.enable,
            credentials: { ...data?.credentials }
          })
        }
      })

      setLoading(false)
    } catch (err) {
      console.log("error", err)
      setLoading(false)
    }
  }

  const handleCreditCardConfig = event => {
    const { name, value } = event.target
    if (name === "enable") {
      const switchValue = creditCardConfig.enable === 0 ? 1 : 0

      setCreditCardConfig(oldState => ({ ...oldState, enable: switchValue }))
    }
    if (name === "anticipation") {
      const switchValue =
        creditCardConfig?.credentials?.anticipation === true ? false : true
      let credentials = {
        ...creditCardConfig.credentials,
        anticipation: switchValue
      }
      setCreditCardConfig(oldState => ({ ...oldState, credentials }))
    }

    if (name === "installment") {
      let credentials = {
        ...creditCardConfig.credentials,
        max_installments: value
      }
      setCreditCardConfig(oldState => ({ ...oldState, credentials }))
    }
  }

  const handlePixConfig = event => {
    const { name, value } = event.target
    if (name === "enable") {
      const switchValue = pixConfig?.enable === 0 ? 1 : 0
      setPixConfig(oldState => ({ ...oldState, enable: switchValue }))
    }
  }

  const handleBoletoConfig = event => {
    const { name, value } = event.target
    if (name === "enable") {
      const switchValue = boletoConfig?.enable === 0 ? 1 : 0
      setBoletoConfig(oldState => ({ ...oldState, enable: switchValue }))
    }
    if (name === "sms-notification") {
      const switchValue = !boletoConfig?.credentials?.notification_sms_email

      let credentials = {
        ...boletoConfig.credentials,
        notification_sms_email: switchValue
      }
      setBoletoConfig(oldState => ({ ...oldState, credentials }))
    }
    if (name === "voice-notification") {
      const switchValue = !boletoConfig?.credentials?.notification_voice
      let credentials = {
        ...boletoConfig.credentials,
        notification_voice: switchValue
      }
      setBoletoConfig(oldState => ({ ...oldState, credentials }))
    }
    if (name === "whatsapp-notification") {
      const switchValue = !boletoConfig?.credentials?.notification_whatsapp
      let credentials = {
        ...boletoConfig.credentials,
        notification_whatsapp: switchValue
      }
      setBoletoConfig(oldState => ({ ...oldState, credentials }))
    }
  }

  async function saveData() {
    setLoading(true)
    const body = {
      payment_id: paymentId,
      credit_card: creditCardConfig,
      boleto: boletoConfig,
      pix: pixConfig
    }
    try {
      const data = await api.put(`payhow/gateway/${shopId}`, body)

      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getInformation()
  }, [])

  return (
    <div className="page-container">
      <div className="page-align">
        <TopPagseguro name="Configurações de Pagamento" handleBack={handleClose} />

        <div className={`${classes.pagseguroOrders}  infinite-scroll`}>
          {loading ? (
            <div
              style={{
                display: "flex",
                height: "100%",
                minHeight: "100%",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <>
              <div className={classes.titleWrapper}>
                <p className={classes.title}>Cartões de crédito</p>
                <img src={visa} alt="icone visa" />
                <img src={mastercard} alt="icone mastercard" />
              </div>
              <div className={`${classes.pagseguroOrders}__enablePaymentOption`}>
                <div>
                  <h2>Habilitar esta opção de Pagamento</h2>
                  <Switch
                    name="enable"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onClick={handleCreditCardConfig}
                    checked={!!creditCardConfig.enable}
                  />
                </div>
              </div>
              {creditCardConfig.enable === 1 && (
                <>
                  <div className={`${classes.pagseguroOrders}__installments`}>
                    <p>Total máximo de parcelas sem juros</p>
                    <TextField
                      variant="outlined"
                      select
                      color="secondary"
                      fullWidth
                      name="installment"
                      value={creditCardConfig?.credentials?.max_installments}
                      onChange={handleCreditCardConfig}
                      SelectProps={{
                        native: true
                      }}
                    >
                      {installments.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </div>

                  <div>
                    <div className={classes.textWrapper}>
                      <p className={classes.subtitle}>
                        Ativar antecipação do parcelamento
                      </p>
                      <Switch
                        name="anticipation"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onClick={handleCreditCardConfig}
                        checked={creditCardConfig?.credentials?.anticipation}
                      />
                    </div>
                    <p className={classes.description}>
                      Ativando essa opção qualquer pagamento recebido de forma
                      parcelada você receberá o valor total em até 31 dias a vista
                      conforme a cobrança das taxas e tarifas.
                    </p>
                  </div>
                </>
              )}
              <div className={classes.titleWrapper}>
                <p className={classes.title}>PIX</p>
                <img src={pix} alt="icone pix" />
              </div>
              <div className={`${classes.pagseguroOrders}__enablePaymentOption`}>
                <div>
                  <h2>Habilitar esta opção de Pagamento</h2>
                  <Switch
                    name="enable"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onClick={handlePixConfig}
                    checked={!!pixConfig.enable}
                  />
                </div>
              </div>
              <div className={classes.titleWrapper}>
                <p className={classes.title}>Boleto bancário</p>
                <img src={boleto} alt="icone boleto" />
              </div>
              <div className={`${classes.pagseguroOrders}__enablePaymentOption`}>
                <div>
                  <h2>Habilitar esta opção de Pagamento</h2>
                  <Switch
                    name="enable"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onClick={handleBoletoConfig}
                    checked={!!boletoConfig.enable}
                  />
                </div>
              </div>
              {boletoConfig.enable === 1 ? (
                <>
                  {/* <div>
                    <div className={classes.textWrapper}>
                      <p className={classes.subtitle}>
                        Ativar QR Code PIX na emissão do boleto
                      </p>
                      <Switch
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onClick={() => {
                          setEnableBillOption(!enableBillOption)
                        }}
                        checked={enableBillOption}
                      />
                    </div>
                    <p className={classes.description}>
                      Ativando essa opção o sistema fará junto a impressão do boleto
                      a inclusão do QR Code PIX para que seu cliente escolha o
                      pagamento via boleto bancário ou PIX.
                    </p>
                    <h3 className={classes.smallPrint}>
                      (Esta opção está sujeito a cobrança de taxas adicionais)
                    </h3>
                  </div> */}
                  <div>
                    <div className={classes.textWrapper}>
                      <p className={classes.subtitle}>
                        Notificação de cobrança via SMS e E-mail
                      </p>
                      <Switch
                        name="sms-notification"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onClick={handleBoletoConfig}
                        checked={boletoConfig.credentials?.notification_sms_email}
                      />
                    </div>
                    <p className={classes.description}>
                      Ativando essa opção o sistema fará envios automáticos por SMS e
                      E-mail notificando o seu cliente sobre a cobrança em aberto.
                    </p>
                    <h3 className={classes.smallPrint}>
                      (Esta opção está sujeito a cobrança de taxas adicionais)
                    </h3>
                  </div>
                  <div>
                    <div className={classes.textWrapper}>
                      <p className={classes.subtitle}>
                        Notificação de cobrança via Robô de Voz
                      </p>
                      <Switch
                        name="voice-notification"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onClick={handleBoletoConfig}
                        checked={boletoConfig.credentials?.notification_voice}
                      />
                    </div>
                    <p className={classes.description}>
                      Ativando essa opção o sistema fará ligações automáticas
                      notificando o seu cliente sobre a cobrança em aberto.
                    </p>
                    <h3 className={classes.smallPrint}>
                      (Esta opção está sujeito a cobrança de taxas adicionais)
                    </h3>
                  </div>
                  <div>
                    <div className={classes.textWrapper}>
                      <p className={classes.subtitle}>
                        Notificação de cobrança via Whatsapp
                      </p>
                      <Switch
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        name="whatsapp-notification"
                        onClick={handleBoletoConfig}
                        checked={boletoConfig.credentials?.notification_whatsapp}
                      />
                    </div>
                    <p className={classes.description}>
                      Ativando essa opção o sistema fará envios automáticos de
                      mensagens no Whatsapp do seu cliente notificando a cobrança em
                      aberto.
                    </p>
                    <h3 className={classes.smallPrint}>
                      (Esta opção está sujeito a cobrança de taxas adicionais)
                    </h3>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className={classes.soonWrapper}>
                <div className={classes.titleWrapper}>
                  <p className={classes.title}>Picpay</p>
                  <img src={picpay} alt="icone picpay" />
                </div>
                <div className={classes.titleSoon}>
                  <p>Em breve</p>
                </div>
              </div>

              <div className={`${classes.pagseguroOrders}__enablePaymentOption`}>
                <div>
                  <h2>Habilitar esta opção de Pagamento</h2>
                  <Switch
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    // onClick={() => {
                    //   setEnableBillOption(!enableBillOption)
                    // }}
                    checked="true"
                    disabled
                  />
                </div>
              </div>
              <div className={classes.soonWrapper}>
                <div className={classes.titleWrapper}>
                  <p className={classes.title}>AME Digital (cashback)</p>
                  <img src={ame} alt="icone ame" />
                </div>
                <div className={classes.titleSoon}>
                  <p>Em breve</p>
                </div>
              </div>
              <div className={`${classes.pagseguroOrders}__enablePaymentOption`}>
                <div>
                  <h2>Habilitar esta opção de Pagamento</h2>
                  <Switch
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    // onClick={() => {
                    //   setEnableBillOption(!enableBillOption)
                    // }}
                    checked="true"
                    disabled
                  />
                </div>
              </div>
              <div className={classes.soonWrapper}>
                <div className={classes.titleWrapper}>
                  <p className={classes.title}>Carteira Mercado Pago</p>
                  <img src={mercadopago} alt="icone mercadopago" />
                </div>
                <div className={classes.titleSoon}>
                  <p>Em breve</p>
                </div>
              </div>
              <div className={`${classes.pagseguroOrders}__enablePaymentOption`}>
                <div>
                  <h2>Habilitar esta opção de Pagamento</h2>
                  <Switch
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    // onClick={() => {
                    //   setEnableBillOption(!enableBillOption)
                    // }}
                    checked="true"
                    disabled
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <div className="footer-column">
          <hr style={{ width: "100%", margin: "20px 0" }} />
          <Button
            className={classes.btnFooterPink}
            disabled={loading}
            onClick={() =>
              setTimeout(() => {
                saveData()
              }, 1500)
            }
            fullWidth
            variant="contained"
            color="secondary"
          >
            Salvar Informações
          </Button>
        </div>
      </div>
    </div>
  )
}
