import React from "react"

function Click() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59"
      height="59"
      fill="none"
      viewBox="0 0 59 59"
    >
      <path
        stroke="#6C6C6C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M30.947 2v11.579M28.053 28.053L57 33.843l-8.684 5.789L57 48.316 48.316 57l-8.684-8.684L33.842 57l-5.79-28.947zM51.416 10.479l-8.188 8.187M10.479 51.416l8.188-8.188M2 30.947h11.579M10.479 10.479l8.188 8.187"
      ></path>
    </svg>
  )
}

export default Click
