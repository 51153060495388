import { useHistory } from "react-router-dom"
import produtosIcon from "../../../../Assets/Home/produtosIcon.svg"
import pedidosIcon from "../../../../Assets/Home/pedidosIcon.svg"
import estatisticasIcon from "../../../../Assets/Home/estatisticasIcon.svg"
import ajustesIcon from "../../../../Assets/Home/ajustesIcon.svg"
import { useStyles } from "./style"
import { SimpleImg } from "react-simple-img"

export default function HomeBottom() {
  const classes = useStyles()
  const history = useHistory()

  return (
    <section className={classes.footerContainer}>
      <div
        aria-hidden="true"
        onClick={() => history.push("/meus-produtos")}
        className={classes.footerItem}
      >
        <SimpleImg
          src={produtosIcon}
          alt="produtos-icon"
          width={25}
          height="auto"
          placeholder={false}
        />
        <p>Produtos</p>
      </div>
      <div
        aria-hidden="true"
        onClick={() => history.push("/meus-pedidos")}
        className={classes.footerItem}
      >
        <SimpleImg
          src={pedidosIcon}
          alt="produtos-icon"
          width={25}
          height="auto"
          placeholder={false}
        />
        <p>Pedidos</p>
      </div>
      <div
        aria-hidden="true"
        onClick={() => history.push("/estatisticas")}
        className={classes.footerItem}
      >
        <SimpleImg
          src={estatisticasIcon}
          alt=""
          width={20}
          height="auto"
          placeholder={false}
        />
        <p>Estatíscas</p>
      </div>
      <div
        aria-hidden="true"
        onClick={() => history.push("/configuracoes")}
        className={classes.footerItem}
      >
        <SimpleImg
          width={20}
          height="auto"
          placeholder={false}
          src={ajustesIcon}
          alt=""
        />
        <p>Ajustes</p>
      </div>
    </section>
  )
}
