import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end"
  },

  buttonWrapper: {
    display: "flex",
    gap: "between"
  },

  buttonCreditCard: props => ({
    background: "transparent",
    border: "2px solid ",
    borderColor: props.enable ? "#0D273D" : "#CCCCCC",
    borderRadius: "15px",
    // padding: '4px 20px',
    width: "6.5rem",
    height: "4rem",
    marginLeft: "0.3rem",

    "& span": {
      fontWeight: "600",
      fontSize: "0.8em",
      color: props.enable ? "#0D273D" : "#CCCCCC",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center;"
    }
  }),

  buttonPix: props => ({
    background: "transparent",
    border: "2px solid",
    borderColor: props.enable ? "#CCCCCC" : "#0D273D",
    borderRadius: "15px",
    // padding: '4px 20px',
    width: "6.5rem",
    height: "4rem",
    marginLeft: "0.3rem",

    "& img": {
      fill: props.enable ? "gray" : "#0D273D"
    },

    "& span": {
      fontWeight: "600",
      fontSize: "0.8em",
      color: props.enable ? "#CCCCCC" : "#0D273D",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center;"
    }
  }),

  icon: {
    color: "red",

    "& svg": {
      fill: "red"
    }
  },

  safeEnviroment: {
    display: "flex",
    border: "2px solid #63BD38",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "center",
    width: "6.5rem",
    height: "1.5rem",

    "& span": {
      color: "#63BD38",
      fontSize: "0.6rem",
      marginLeft: "0.2rem"
    }
  },

  componentContainer: {
    marginTop: "30px"
  },
  counter: {
    marginBottom: "1.25rem",
    "& p" : {
      color: "#8D8D8D",
      margin: 0,
    },
    "& span" : {
      color: "#F21C62",
      fontSize: "1.9rem",
      fontWeight: 600
    }
  },
  progressBar: {
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#EA3458", 
    },
  },
}))
