import AddIcon from "@material-ui/icons/Add"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { SimpleImg } from "react-simple-img"
import DefaultProduct from "../../../Assets/img/noProductImg.png"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { globalStyles } from "../../../Componentes/CssGlobal"
import { button } from "../../../Componentes/Inputs/TextFild"
import { useSettings } from "../../../ContextAPI/settingsContext"
import api from "../../../Services/api"
import ModalOops from "../../Planos/Alertas/AlertaModal"
import CreateProductModal from "./CreateProductModal"
import SelectCategoriesFirstAccess from "./CreateProductModal/SelectCategoriesFirstAccess"
import { useStyles } from "./styles"

export default function EtapaProduto({ handleStep }) {
  const classes = useStyles()
  const classesGlobal = globalStyles()
  const [productList, setProductList] = useState([])
  const { lojinhaInfo } = useSettings()
  const [openModal, setOpenModal] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [modalOops, setModalOops] = useState(false)
  const [openCategories, setOpenCategories] = useState(false)

  const history = useHistory()

  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  function planPro() {
    history.push("/planos")
  }

  useEffect(() => {
    getAllProducts()
  }, [])

  /* pega todos os produtos */
  const getAllProducts = async () => {
    const resProduct = await api.get(`/product/getall/${localStorage.getItem("id")}`)
    setProductList(resProduct.data.data)
  }

  const handleAddProduct = product => {
    getAllProducts()
    setOpenModal(false)
  }

  const removeProduct = async index => {
    const idProduct = productList[index].id
    setProductList(oldState => oldState.filter((el, i) => i !== index))
    const { data } = await api.delete(`/product/${idProduct}`)

    if (data) {
      getAllProducts()
    }
  }

  const handleCloseAddProductModal = () => {
    setTimeout(() => setOpenModal(false), 1500)
  }



  const checkProductList = () => {
    if (productList.length > 0) {
      setAlertType("success")
      setShowAlert(true)
      handleStep("proximo")
    } else {
      setAlertType("error")
      setShowAlert(true)
    }
  }
  const closeAlert = () => {
    setShowAlert(false)
  }

  function opemModal() {
    setModalOops(true)
  }

  function closeModal() {
    setModalOops(false)
  }

  function handleCloseCategoriesModal() {
    setOpenCategories(false)
  }

  const handleOpenCategoriesModal = () => {
    setOpenCategories(true)
  }

  return (
    <>
      <div className="infinite-scroll">
        <div className="EtapaHeader">
          <h2>Adicione seus produtos</h2>
          <h3>e comece a divulgar:</h3>
        </div>
        <div className={classes.ProdutosContainer}>
          <div className={classes.AdicionarProduto}>
            <button
              style={{ border: "none", backgroundColor: "transparent" }}
              onClick={() => {
                if (productList.length >= 5) {
                  hasPro ? setOpenModal(true) : opemModal()
                } else {
                  setOpenModal(true)
                }
              }}
            >
              <AddIcon />
              <h3>Adicionar produto</h3>
            </button>
          </div>

          {productList.length > 0 &&
            productList.map((product, index) => (
              <div className={classes.Produto}>
                {product !== undefined && product && product?.images[0] !== null ? (
                  <SimpleImg
                    width="auto"
                    height="auto"
                    placeholder={false}
                    className={classes.ProdutoImg}
                    src={product.images[0] && product.images[0].md_url}
                    alt="..."
                  />
                ) : (
                  <SimpleImg
                    width="auto"
                    height="auto"
                    placeholder={false}
                    className={classes.ProdutoImg}
                    src={DefaultProduct}
                    alt="..."
                  />
                )}

                <button
                  onClick={() => removeProduct(index)}
                  style={{ border: "none", backgroundColor: "transparent" }}
                >
                  <DeleteOutlineIcon style={{ color: "#051626" }} />
                </button>
              </div>
            ))}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <CustomAlert
            alertText={
              alertType === "success"
                ? "Produtos salvos com sucesso!"
                : "Salve um produto ou pule a etapa :)"
            }
            show={showAlert}
            type={alertType}
            close={() => closeAlert()}
          />
        </div>
      </div>

      <div className="footer-column">
        {button({
          funcao: () => checkProductList(),
          name: `Próximo`
        })}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <button
            onClick={() => handleStep("backStep")}
            className="voltarLink"
            style={{ margin: 0 }}
          >
            Voltar etapa
          </button>

          <button
            onClick={() => handleStep("nextStep")}
            className="voltarLink"
            style={{ margin: 0 }}
          >
            Pular etapa
          </button>
        </div>
      </div>
      {/* </div> */}
      {openModal && (
        <CreateProductModal
          isOpen={openModal}
          handleClose={handleCloseAddProductModal}
          addProduct={handleAddProduct}
          handleCloseCategoriesModal={handleCloseCategoriesModal}
          handleOpenCategoriesModal={handleOpenCategoriesModal}
        />
      )}

      {openCategories && (
        <SelectCategoriesFirstAccess
          open={openCategories}
          handleCloseCategoriesModal={handleCloseCategoriesModal}
        />
      )}

      {modalOops && (
        <ModalOops isOpen={modalOops} handleClose={closeModal} isPush={planPro} />
      )}

    </>
  )
}
