import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles({
  CreateProductModal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0px",
    "& .MuiFormControl-root": {
      margin: "15px 0"
    }
  },
  CreateProductModalHeader: {
    display: "flex",
    textAlign: "center",
    justifyContent: "space-between",
    "& h2": {
      fontSize: "1.5rem",
      fontWeight: "700",
      flex: 1,
      textAlign: "center",
      paddingLeft: "36px"
    },
    "& button": {
      border: "none",
      fontSize: "2rem",
      fontFamily: "cursive",
      cursor: "pointer",
      backgroundColor: "transparent",
      color: "#EA3458",
      fontWeight: 900
    }
  },

  modalInputs: {
    display: "flex",
    flexDirection: "column",
    fontSize: "1.2em",
    "& input": {
      borderRadius: "5px",
      height: "40px",
      padding: "0 5px",
      fontWeight: "none",
      "&:focus": {
        borderRadius: 4,
        border: "none",
        outlineColor: "#EA3458cc"
      }
    }
  },

  CreateProductInputGroup: {
    display: "flex",
    textAlign: "center",
    justifyContent: "space-between",
    margin: "1rem 0",
    "& >div": {
      maxWidth: "45%"
    }
  },
  btnTamanho: {
    marginTop: "10px 0",
    height: "50px"
  },
  previewImg: {
    width: "85px",
    height: "85px",
    margin: "auto",
    borderRadius: "10px"
  },
  defaultImg: {
    width: "85px",
    height: "85px",
    border: "1px dashed #555555",
    backgroundColor: "#f5f5f5",
    margin: "auto",
    borderRadius: "10px"
  },

  positionBtnImg: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px"
  },

  input: {
    display: "none"
  },
  btnAddImg: {
    width: "171px",
    height: "40px",
    backgroundColor: "#EA3458",
    color: "#fff",
    borderRadius: "5px",

    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "11px",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#ff5089c9"
    }
  },
  top: {
    display: "flex",
    minWidth: "100%",
    justifyContent: "space-between",
    height: "50px",
    alignItems: "center",
    paddingTop: "10px",
    paddingBottom: "10px"
  },

  Footer2: {
    position: "relative",
    bottom: "-5px",
    width: "100%",
    marginBottom: "60px"
  },
  newModal: {
    "& .MuiDialog-paper": {
      maxHeight: "none",
      margin: "0",
      width: "100%",
      borderRadius: "0",
      boxShadow: "none"
    },

    "& .MuiDialogActions-root": {
      display: "block"
    },

    "& .MuiBackdrop-root": {
      backgroundColor: "#00000000"
    }
  },

  btnSalvarDisable: {
    color: "#A4A4A4",
    minWidth: "100%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "5px",
    marginBottom: "15px",
    textTransform: "none",
    backgroundColor: "#CCCCCC",

    "& .MuiButton-label": {
      textTransform: "initial",
      fontSize: "14px",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#c5c5c5"
    }
  },
  progress: {
    display: "flex",
    height: "89px",
    justifyContent: "center",
    alignItems: "center"
  }
})
export default useStyles
