import { CircularProgress, InputAdornment, Switch, TextField } from "@material-ui/core"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { current } from "../../../../Componentes/validates/Validate"
import { useProduct } from "../../../../ContextAPI/productContext"
import { useStyles } from "./style"
import ImageUploaderCrop from "../../../../Componentes/ImageUploaderCrop"
import AdvancedCropper from "../../../../Componentes/AdvancedCropper"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export default function ProdutoInformacoes({ emptyRequired }) {
  const { currentProduct, setCurrentProduct, isLoading, categoriesListSelected, setIsLoading } = useProduct()
  const [productBanner, setProductBanner] = useState("")
  const [src, setSrc] = useState("")
  const [openCrop, setOpenCrop] = useState(false)
  const fileInputRefProduct = useRef(null)
  const classes = useStyles()
  const history = useHistory();

  useEffect(() => {
    if (currentProduct?.product_banner) {
      setProductBanner(currentProduct?.product_banner);
    }
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [currentProduct?.product_banner]);

  const handleFormChange = e => {
    const { name, value } = e.target
    let newValue = value
    if (name === "price" || name === "promotional_price") newValue = current(value)
    setCurrentProduct(oldState => {
      return { ...oldState, [name]: newValue }
    })
  }

  const handleCheckChange = e => {
    const { name, checked } = e.target
    setCurrentProduct(oldState => {
      return { ...oldState, [name]: checked }
    })
  }

  useEffect(() => {
    if (src) {
      setOpenCrop(true)
    }
  }, [src])

  const handleChangeImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setSrc(reader.result);
      };
    }
  }

  const handleButtonClick = () => {
    fileInputRefProduct.current.click()
  }

  useEffect(() => {
    setCurrentProduct({ ...currentProduct, product_banner: productBanner })
  }, [productBanner])

  const handleClick = () => {
    history.push("/selecionar-categorias");
  };

  return (
    <>
      {!openCrop ? (
        <>
          <h2>Informações do produto</h2>
          <p>
            Aqui você pode definir as informações básicas do produto como título e preço.
          </p>
          <h3>Título do produto</h3>
          <TextField
            name="title"
            style={{ backgroundColor: "#fff" }}
            required={emptyRequired.title}
            helperText={
              emptyRequired.title === true ? (
                <p style={{ color: "red", margin: 0, fontSize: "0.8rem" }}>
                  campo obrigatório
                </p>
              ) : (
                ""
              )
            }
            value={
              currentProduct !== null && currentProduct.title
                ? currentProduct.title
                : null
            }
            onChange={e => handleFormChange(e)}
            size="small"
            variant="outlined"
            color="secondary"
            fullWidth
            inputProps={{ maxLength: 60 }}
            placeholder="Limite de 60 caracteres"
          />
          <h3>Descrição</h3>
          <TextField
            style={{ backgroundColor: "#fff", width: "100%" }}
            multiline
            rows={4}
            name="description"
            value={currentProduct !== null ? currentProduct.description : ""}
            onChange={e => handleFormChange(e)}
            size="small"
            variant="outlined"
            color="secondary"
            inputProps={{ maxLength: 1000 }}
            placeholder="Limite de 1000 caracteres"
          />
          <h3>Banner descritivo</h3>
          {
            isLoading ? (
              <div
                style={{
                  backgroundColor: " #f9f9f9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%"
                }}
              >
                <CircularProgress color="secondary" size="40px" />
              </div>
            ) : (
              <ImageUploaderCrop
                image={productBanner}
                width="568"
                height="378"
                setImage={setProductBanner}
                handleChangeImage={(e) => handleChangeImage(e)}
                fileInputRef={fileInputRefProduct}
                handleButtonClick={handleButtonClick}
                buttonText="Adicionar imagem"
                description="Tamanho ideal para a imagem"
              />
            )}
          <h3>Categorias</h3>
          <button className={classes.buttonCategories}
            onClick={handleClick}
          >
            Selecionar categorias e subcategorias
            <ArrowForwardIosIcon style={{ color: "#A4A4A4", marginLeft: "20px" }} />
          </button>

          <div className={classes.PrecoQtd}>
            <div className={classes.Preco}>
              <h3>Preço</h3>
              <TextField
                style={{ width: "65%", backgroundColor: "#fff" }}
                name="price"
                type="tel"
                required={emptyRequired.price}
                helperText={
                  emptyRequired.price === true ? (
                    <p style={{ color: "red", margin: 0, fontSize: "0.8rem" }}>
                      campo obrigatório
                    </p>
                  ) : (
                    ""
                  )
                }
                value={new Intl.NumberFormat("pt-BR", {
                  minimumFractionDigits: 2
                }).format(currentProduct?.price || 0)}
                onChange={e => handleFormChange(e)}
                size="small"
                variant="outlined"
                color="secondary"
                InputProps={{
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>
                }}
              />
            </div>
          </div>
          <div>
            <h3>Preço em promoção</h3>
            <TextField
              name="promotional_price"
              type="tel"
              value={new Intl.NumberFormat("pt-BR", {
                minimumFractionDigits: 2
              }).format(currentProduct?.promotional_price || 0)}
              onChange={e => handleFormChange(e)}
              size="small"
              variant="outlined"
              color="secondary"
              style={{
                display: "flex",
                width: "65%",
                backgroundColor: "#fff"
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>
              }}
            ></TextField>
          </div>
          <div>
            <h3>SKU (código interno)</h3>
            <TextField
              name="sku"
              style={{ backgroundColor: "#fff" }}
              value={
                currentProduct !== null && currentProduct.sku ? currentProduct.sku : null
              }
              onChange={e => handleFormChange(e)}
              size="small"
              variant="outlined"
              color="secondary"
              fullWidth
              inputProps={{ maxLength: 30 }}
            // placeholder="Limite de 30 caracteres"
            />
            <p>
              SKU é o código interno do produto na loja e essa informação não será
              visível para o comprador
            </p>
          </div>
          <div>
            <h3>Código de barras</h3>
            <TextField
              name="codebar"
              style={{ backgroundColor: "#fff" }}
              value={
                currentProduct !== null && currentProduct.codebar
                  ? currentProduct.codebar
                  : null
              }
              onChange={e => handleFormChange(e)}
              size="small"
              variant="outlined"
              color="secondary"
              fullWidth
              inputProps={{ maxLength: 30 }}
            // placeholder="Limite de 30 caracteres"
            />
          </div>
          <div className={classes.Slides} style={{ alignItems: "center" }}>
            <div>
              <h3>Publicar produto?</h3>
              <Switch
                inputProps={{ "aria-label": "secondary checkbox" }}
                name="show_product"
                checked={currentProduct !== null && currentProduct.show_product}
                onClick={e => handleCheckChange(e)}
              />
            </div>
            <div>
              <p>Deseja deixar o produto a mostra na sua loja?</p>
            </div>
          </div>
        </>
      ) : (
        <AdvancedCropper
          setSrc={setSrc}
          src={src}
          close={() => setOpenCrop(false)}
          open={openCrop}
          setImage={setProductBanner}
          width={568}
          height={378}
          getUrlImage
        />
      )}
    </>
  )
}
