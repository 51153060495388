import { InputAdornment, OutlinedInput, TextField } from "@material-ui/core"
import { useState } from "react"
import dimensoes from "../../../../Assets/svg/dimensoes.svg"
import { useProduct } from "../../../../ContextAPI/productContext"
import { useStyles } from "./style"

export default function Dimensoes() {
  const classes = useStyles()

  const { currentProduct, setCurrentProduct } = useProduct()

  const handleChange = e => {
    const { name, value } = e.target
    let valor = parseFloat(value)

    valor = String(value)

    valor = valor.replace(/\D+/g, "")

    valor = valor.replace(/([0-9]{2})$/g, ".$1")

    if (valor.length === 3) {
      valor = `0${String(valor)}`
    } else if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2")
    } else if (valor[0] === "0" && valor.length >= 5) {
      valor = valor.substring(1, valor.length)
    }

    setCurrentProduct(oldState => ({
      ...oldState,
      dimension: {
        ...oldState.dimension,
        [name]: valor
      }
    }))

    if (valor === "NaN") {
      setCurrentProduct(oldState => ({
        ...oldState,
        dimension: {
          ...oldState.dimension,
          [name]: ""
        }
      }))
    }
  }

  return (
    <>
      <div>
        <div>
          <h2>Dimensões e peso para cálculo de entrega</h2>
          <p style={{ color: "#EA3458" }}>
            Restrições de envio: Limite de 200cm total (Largura + Comprimento +
            Altura) e 50kg máximo.
          </p>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <img src={dimensoes} alt="caixa dimensões" width="90%" height="100%" />
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <h3>Altura</h3>
          <TextField
            id="height"
            name="height"
            type="tel"
            inputmode="numeric"
            pattern="[0-9]*"
            step="0.01"
            color="secondary"
            fullWidth
            style={{ backgroundColor: "#fff" }}
            variant="outlined"
            size="small"
            value={
              currentProduct.dimension !== undefined &&
              currentProduct.dimension !== null &&
              currentProduct !== null &&
              currentProduct.dimension.height
                ? currentProduct.dimension.height
                : ""
            }
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>
            }}
          />
        </div>

        <div>
          <h3>Largura</h3>
          <TextField
            id="width"
            name="width"
            type="tel"
            inputmode="numeric"
            pattern="[0-9]*"
            step="0.01"
            min="0"
            color="secondary"
            fullWidth
            style={{ backgroundColor: "#fff" }}
            variant="outlined"
            size="small"
            value={
              currentProduct.dimension !== undefined &&
              currentProduct.dimension !== null &&
              currentProduct !== null &&
              currentProduct.dimension.width
                ? currentProduct.dimension.width
                : ""
            }
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>
            }}
          />
        </div>

        <div>
          <h3>Comprimento</h3>
          <TextField
            id="length"
            name="length"
            type="tel"
            inputmode="numeric"
            pattern="[0-9]*"
            step="0.01"
            color="secondary"
            fullWidth
            style={{ backgroundColor: "#fff" }}
            variant="outlined"
            size="small"
            value={
              currentProduct.dimension !== undefined &&
              currentProduct.dimension !== null &&
              currentProduct !== null &&
              currentProduct.dimension.length
                ? currentProduct.dimension.length
                : ""
            }
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>
            }}
          />
        </div>

        <div>
          <h3>Peso</h3>
          <TextField
            id="weight"
            name="weight"
            type="tel"
            inputmode="numeric"
            pattern="[0-9]*"
            step="0.01"
            color="secondary"
            variant="outlined"
            fullWidth
            style={{
              backgroundColor: "#fff"
            }}
            size="small"
            value={
              currentProduct.dimension !== undefined &&
              currentProduct.dimension !== null &&
              currentProduct !== null &&
              currentProduct.dimension.weight
                ? currentProduct.dimension.weight
                : ""
            }
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>
            }}
          />
        </div>
      </div>
    </>
  )
}
