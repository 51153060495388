import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  img: {
    // position: 'absolute',
    // top: '30px',
    marginTop: "10px",
    marginBottomo: "10px"
  },

  imgCentral: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "25px",
    paddingBottom: "25px"
  },

  buttonCria: {
    backgroundColor: "#FFF",
    color: "#EA3458",
    textTransform: "none",
    fontWeight: "bold"
  },
  buttonAcessa: {
    marginTop: "10px",
    color: "#fff",
    borderColor: "#fff",
    textTransform: "none",
    // fontWeight:'bold',
    marginBottom: "25px"
  },

  entrar: {
    backgroundColor: "#EA3458",
    color: "#fff",
    textTransform: "none",
    marginBottom: "10px",
    paddingLeft: "16px",
    paddingRight: "16px"
  },

  widthBTN: {
    width: "85%"
  },

  termosDeUso: {
    display: "flex",
    width: "100%",
    justifyContent: "center"
  }
}))
