import { StateGlobal } from "../../ContextAPI/index"
import { IoIosArrowBack } from "react-icons/io"

import { useStyles } from "./style"

export default function TopPedidos({ name, orderNumber }) {
  const classes = useStyles()
  const { setOrderPagesIndex } = StateGlobal()

  return (
    <div className={classes.top}>
      <IoIosArrowBack
        className={classes.arrowBack}
        onClick={() => setOrderPagesIndex(0)}
      />
      <div className={classes.titleTop}>
        <p className={classes.name}>{name}</p>
        <p className={classes.orderNumber}>{orderNumber}</p>
      </div>
      <div style={{ width: "1px" }}></div>
    </div>
  )
}
