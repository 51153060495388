import { useState } from "react"
import { IoMdClose } from "react-icons/io"
import { useStyles } from "./style"
import { useHistory } from "react-router-dom"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useDevice } from "../../ContextAPI/deviceContext"
import { bannersMobile, bannersDesktop } from "./utils"
import BoxCupom from "./Components/BoxCupom"
import Insertcoupon from "./Components/Insertcoupon"
import { useEffect } from "react"

export default function NewCheckout() {
  const classes = useStyles()
  const history = useHistory()
  const { checkDevice, isMobileDevice } = useDevice()
  const [switchComponents, setSwitchComponents] = useState(true)
  const [newPrice, setNewPrice] = useState(0)

  const handleSwitchComponents = () => {
    setSwitchComponents(!switchComponents)
  }

  useEffect(() => {
    sessionStorage.removeItem("cupom_checkout")
  }, [])
  const settings = {
    dots: true,
    dotsClass: `slick-dots ${classes.customDots}`,
    centerMode: true,
    centerPadding: "20px",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnFocus: true
  }
  return (
    <>
      <div className="page-container" style={{ padding: 0 }}>
        <div className="infinite-scroll">
          <div>
            <div className={classes.topoContainer}>
              <IoMdClose
                style={{
                  fontSize: "35px",
                  color: "#EA3458",
                  cursor: "pointer",
                  minWidth: "30px",
                  marginRight: "10px"
                }}
                onClick={() => {
                  history.push("/home")
                }}
              />
            </div>
            <div className={classes.PlanosTitle}>
              <h3>
                Vantagens do nosso plano <span>PRO</span>
              </h3>
            </div>

            {isMobileDevice ? (
              <Slider {...settings}>
                {bannersMobile.map(banner => (
                  <div className={classes.Banner}>
                    <img src={banner} alt="banner" />
                  </div>
                ))}
              </Slider>
            ) : (
              <Slider {...settings}>
                {bannersDesktop.map(banner => (
                  <div className={classes.Banner}>
                    <img src={banner} alt="banner" />
                  </div>
                ))}
              </Slider>
            )}
            {switchComponents ? (
              <Insertcoupon
                handleSwitchComponents={handleSwitchComponents}
                setNewPrice={setNewPrice}
              />
            ) : (
              <BoxCupom
                handleSwitchComponents={handleSwitchComponents}
                newPrice={newPrice}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
