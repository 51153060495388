import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => ({
  topoContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: "10px",
    position: "relative"
  },

  Wrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },

  PlanosTitle: {
    marginLeft: "15px",
    "& h3": {
      fontSize: "2rem",
      color: "#555555",
      fontWeight: "500",
      marginTop: "10px"
    },
    "& span": {
      color: "#EA3458",
      fontWeight: "bold"
    }
  },

  Banner: {
    width: "100%",
    marginTop: "20px",

    "& img": {
      width: "80%"
    }
  },

  customDots: {
    textAlign: "left",
    marginLeft: "20px",
    color: "#FF7E98",
    gap: "5px",

    "& before": {
      color: "#FF7E98"
    }
  },

  PlanComponent: {
    display: "flex",
    flexDirection: "row",
    alignItems:"center",
    borderColor: '#52EB58',
    borderWidth:'2px',
    borderStyle: 'solid',
    borderRadius: '10px',
    margin: '60px 20px 0px',

    "& h3": {
      fontSize: "1rem",
      color: "#555555",
      fontWeight: "500",
      textDecoration: "line-through",
      textDecorationThickness: "2px",
      marginTop: "10px"
    },
    "& span": {
      fontWeight: "bold",
      fontSize: "1.2rem"
    },
    "& h4": {
      fontSize: "0.8rem",
      fontWeight: "500",
      textDecoration: "line-through",
      textDecorationThickness: "2px",
      color: "#ADAAAA",
      marginTop: "10px"
    }
  },

  IconDisable: {
    width: "26px",
    height: "26px",
    border: "solid 2px #D9D9D9",
    borderRadius: "25px",
    margin: "0px 13px"
  },

  PlanComponentCupom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderColor: "#3498DB",
    borderWidth: "2px",
    borderStyle: "solid",
    borderRadius: "10px",
    margin: "20px 20px 40px",

    "& h3": {
      fontSize: "1rem",
      color: "#3498DB",
      fontWeight: "500",
      marginTop: "10px"
    },
    "& span": {
      fontWeight: "bold",
      fontSize: "1.2rem"
    },
    "& h4": {
      fontSize: "0.8rem",
      fontWeight: "500",
      color: "#ADAAAA",
      marginTop: "10px"
    }
  },

  BoxInfo: {
    display: "flex",
    alignItems: "center",
  },

  Button: {
    margin: "0 20px"
  },

  Footer: {
    textAlign: "center",
    marginBottom: "10px",
    "& h3": {
    fontSize: "0.8rem",
    color:"#C5C5C5",
    },

  },

  Cupom:{
     "& h3": {
    fontSize: "12px",
    color:"#555555",
    margin:"5px 20px ",
    cursor: "pointer",
    marginBottom: "26px",
    marginTop: "17px",
    
    
    },
    
  },

  Desconto:{
    margin:"0 20px",
  },
  
  Inputbtn:{
    width: "70%",
    height: "25px",
    border: "2px solid #D0D0D0",
    borderRadius:"5px",
    padding: "10px",

  },

  Btnblue:{
  width: "30%",  
  background: "#283575",
  color: "#fff",
  height:"47px",
  padding: "10px 20px",
  cursor: "pointer",
  border: "none",
  borderRadius:"5px",
  fontSize: "12px",
  fontWeight: "bold",
  
    
  },
  
  
  BoxCupom:{
    display: "flex", 
    alignItems: "center",
    gap: "10px",  
    marginBottom: "30px",
   
  }

}))
