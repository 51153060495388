import React from "react"
import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  btnLimpar: {
    color: "#EA3458",
    minWidth: "48%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "10px",
    border: "solid 2px #EA3458",
    textTransform: "none",
    fontWeight: "bold",
    marginBottom: "10px"
  },

  btnFilter: {
    color: "#fff",
    minWidth: "48%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "10px",
    textTransform: "none",
    fontWeight: "bold",
    marginBottom: "10px",
    backgroundColor: "#EA3458",

    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#ff0053c9"
    }
  },

  orderBy: {
    '& p': {
      margin: '0px 0px 7px 0px',
      fontSize: '14px',
      fontWeight: '700'
    },

    '&__buttonsContainer': {
      display: 'flex',

      '& button': {
        width: '100%',
        color: '#051626',
        border: "solid 1px #051626",
        borderRadius: 0,

        "& .MuiButton-label": {
          textTransform: "none",
          fontSize: "14px !important",
          fontWeight: '400'
        },
      },

      '& button:nth-child(1)': {
        borderRadius: '5px 0 0 5px'
      },

      '& button:nth-child(3)': {
        borderRadius: '0 5px 5px 0'
      }
    }
  },

  filterByDate: {
    marginTop: '40px',

    '& p': {
      margin: '0px 0px 7px 0px',
      fontSize: '14px',
      fontWeight: '700'
    },

    '&__pickerContainer': {
      marginTop: '10px',
      display: 'flex',
      justifyContent: 'space-between'
    },

    '&__datePicker': {
      '& p': {
        margin: 0,
        fontSize: '13px',
        fontWeight: '400'
      },

      '& .MuiInputBase-input': {
        width: '130px'
      }
    }
  },

  filterByStatus: {
    marginTop: '60px',

    '& p': {
      margin: '0px 0px 7px 0px',
      fontSize: '14px',
      fontWeight: '700'
    },

    '&__statusSelect': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }
}))
