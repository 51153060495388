import React, { useEffect, useState } from "react"
import InputMask from "react-input-mask"

export function CepInput({ style, value, callback, submit, name }) {
  const [isEmpty, setIsEmpty] = useState(false)

  const checkInput = e => {
    if (submit === true) {
      if (value) setIsEmpty(false)
      else setIsEmpty(true)
    } else {
      if (e.target.value !== "") setIsEmpty(false)
      else setIsEmpty(true)
      callback(e)
    }
  }

  useEffect(() => {
    if (submit === true) checkInput()
  }, [submit])

  return (
    <>
      <InputMask
        type="text"
        mask="99999-999"
        name={name}
        id="zipcode"
        value={value}
        style={style}
        onChange={e => checkInput(e)}
      >
        {inputProps => <input {...inputProps} />}
      </InputMask>
      <span
        hidden={isEmpty === false}
        style={{ color: "red", fontSize: "0.7rem", textAlign: "center" }}
      >
        Campo Obrigatório
      </span>
    </>
  )
}
