import React from "react"
import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  headerInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  pedidoInfo: {
    fontSize: "14px",
    fontWeight: "bold",
  },

  dataInfo: {
    color: "#555555",
    fontSize: "12px",
    fontWeight: "400"
  },

  statusInfo: {
    marginTop: "10px",

    '& p': {
      fontSize: "14px",
      fontWeight: "600"
    },

    '& .MuiFormControl-root': {
      width: '100%'
    }
  },

  genericoGrupo: {
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",

    '& p': {
      margin: 0,
      fontSize: '14px',
      fontWeight: '600',
      color: '#353535',
      marginBottom: '4.5px',

      '& span': {
        fontWeight: '500',
        marginLeft: '5px'
      }
    }
  },

  genericoText: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#353535",
    paddingBottom: "3px"
  },

  genericoInfo: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#353535",
    paddingBottom: "3px"
  },

  metodoPagamente: {
    fontSize: "14px",
    fontWeight: "bold",
    margin: "0",
    marginBottom: '7px',
  },

  metodoEntrega: {
    fontSize: "14px",
    fontWeight: "bold",
    margin: "0"
  },

  pedidoTotal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px"
  },

  total: {
    fontSize: "15px",
    fontWeight: "800",
    color: "#EA3458"
  },

  valor: {
    fontSize: "15px",
    fontWeight: "800",
    color: "#EA3458"
  },

  positionZap: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
    paddingBottom: "20px"
  },

  btnZap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#3ABD3F",
    border: "none",
    borderRadius: "5px",
    width: "100%",
    height: "56px",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer"
  },

  titleItem: {
    marginTop: "20px",
  },

  orderItens: {
    marginBottom: '15px',

    '& article ': {
      borderBottom: '1px solid #afafaf63',
      paddingBottom: '14px',
    },

    '& article:last-child': {
      border: 'none'
    }
  },

  itemGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },

  topico1: {
    width: "10%",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    marginTop: "20px"
  },

  topico2: {
    width: "65%",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    paddingLeft: "12px",
    marginTop: "20px",

    '&last-child':{
      color: 'red'
    },

    '& p': {
      margin: 0
    }
  },

  topico3: {
    width: "20%",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    marginTop: "20px"
  },

  areaText: {
    marginTop: "5px",
    width: "98%",
    "& .MuiFormControl-root": {
      width: "100%"
    }
  },

  positionBtnExcluir: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },

  btnExcluir: {
    display: "flex",
    justifyContent: "center",
    color: "#EB1111",
    width: "100%",
    fontWeight: "700",
    fontSize: "14px",
    marginBottom: "25px",
    marginTop: "25px",
    cursor: "pointer"
  },

  positionBtnAtt: {
    width: "100%",
    paddingTop: "10px",
    height: "50px"
  },

  btnAtt: {
    color: "#fff",
    fontWeight: "700",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#3ABD3F",
    width: "100%",
    height: "50px",
    border: "solid 2px #3ABD3F",
    borderRadius: "5px"
  },

  dialog: {
    textAlign: "center",

    "& .MuiDialog-paper": {
      bottom: "0",
      position: "fixed",
      margin: "0",
      padding: "10px 20px",
      width: "100%",
      boxSizing: "border-box",
      borderRadius: "15px 15px 0px 0px"
    },

    '& button': {
      textTransform: 'none',
      height: '52px',
      fontSize: '16px',

      '&:nth-child(2)': {
        backgroundColor: '#F21C62',
        marginBottom: '9px'
      },

      '&:nth-child(3)': {
        borderColor: '#F21C62',
        color: '#F21C62',
        fontWeight: '500 !important'
      }
    }
  }
}))
