import { useState, useEffect } from "react"

import api from "../../../../Services/api"

import Topo from "../../../../Componentes/TopCategorias"
import syncIcon from "../../../../Assets/svg/syncIcon.svg"
import CheckoutButton from "../../../../Componentes/CheckoutButton"
import { FiAlertTriangle } from "react-icons/fi"
import { IoIosCloseCircle } from "react-icons/io"

import { useStyles } from "./style"
import { CircularProgress } from "@material-ui/core"
import CustomAlert from "../../../../Componentes/Alerts/CustomAlert"

const AllRegistrationStatus = {
  incompleto: {
    status: "Dados incompletos",
    icon: (
      <FiAlertTriangle
        color="#fff"
        fill="#1877F2"
        size="19"
        style={{ marginRight: "5px" }}
      />
    ),
    color: null,
    text: `Seu cadastro ainda está em avaliação técnica pela nossa equipe de especialistas. Por favor pedimos que aguarde o nosso contato por e-mail, SMS, telefone ou whatsapp.`,
    hasButton: true
  },
  bloqueado: {
    status: "Bloqueado",
    icon: (
      <IoIosCloseCircle
        color="#fff"
        fill="#FF0C0C"
        size="19"
        style={{ marginRight: "5px" }}
      />
    ),
    color: null,
    text: `Infelizmente seu cadastro foi bloqueado devido a infringir as nossas regras e políticas de uso. Pedimos a gentileza para saber mais detalhes ou informações que acesse a sua conta Payhow e faça contato com o nosso time de atendimento.`,
    hasButton: true
  },
  aprovado: {
    status: "Aprovado!",
    icon: null,
    color: "#63BD38",
    text: `Seu cadastro está aprovado e você já está ativo para iniciar suas vendas utilizando a Payhow como plataforma de pagamentos.`,
    hasButton: true
  },
  reprovado: {
    status: "Reprovado",
    icon: null,
    color: "#D62B2B",
    text: `Infelizmente seu cadastro foi reprovado com a nossa equipe de análise. Para mais detalhes ou informações acesse o link abaixo e entre em contato com o nosso atendimento.`,
    hasButton: false
  },
  analisando: {
    status: "Em análise",
    icon: null,
    color: "#F8DF00",
    text: `Seu cadastro ainda está em avaliação técnica pela nossa equipe de especialistas. Por favor pedimos que aguarde o nosso contato por e-mail, SMS, telefone ou whatsapp.`,
    hasButton: false
  }
}

export default function ServiceAndRegistrationStatus() {
  const classes = useStyles()

  const [syncLoading, setSyncLoading] = useState(false)
  const [isLoading, setIsloading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [serviceStatus, setServiceStatus] = useState(null)
  const [registrationStatus, setRegistrationStatus] = useState({
    status: "",
    icon: null,
    color: null,
    text: "",
    hasButton: null
  })

  useEffect(() => {
    getServiceAndRegistrationStatus()
  }, [])

  async function getServiceAndRegistrationStatus(isSyncing = false) {
    isSyncing ? setSyncLoading(true) : setIsloading(true)

    try {
      const {
        data: { data }
      } = await api.get(`/payhow/gateway/status/${localStorage.getItem("id")}`)

      setServiceStatus(data.service_status)
      setRegistrationStatus(AllRegistrationStatus[data.registration_status])
    } catch (error) {
      setShowAlert(true)
    } finally {
      isSyncing ? setSyncLoading(false) : setIsloading(false)
    }
  }

  async function handleSyncStatus() {
    await getServiceAndRegistrationStatus(true)
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <Topo name="Status do serviço e situação cadastral" />
          </div>

          {isLoading ? (
            <div
              style={{
                backgroundColor: "#f9f9f9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%"
              }}
            >
              <CircularProgress color="secondary" size="40px" />
            </div>
          ) : (
            <div className="infinite-scroll">
              <h2 className={classes.title}>Status do serviço:</h2>
              <div className={classes.status} style={{ marginBottom: 48 }}>
                <p>
                  <div
                    className={`${classes.status}__color`}
                    style={{ background: serviceStatus ? "#63BD38" : "#D62B2B" }}
                  />
                  Status da integração:{" "}
                  <strong>{serviceStatus ? "Conectado!" : "Não conectado!"}</strong>
                </p>

                <button onClick={() => handleSyncStatus()}>
                  <img
                    src={syncIcon}
                    alt="sync-icon"
                    className={syncLoading && classes.rotateAnimation}
                  />
                </button>
              </div>

              <h2 className={classes.title}>Status cadastral:</h2>
              <div className={classes.status}>
                <p>
                  {registrationStatus?.icon ? (
                    registrationStatus.icon
                  ) : (
                    <div
                      className={`${classes.status}__color`}
                      style={{ background: registrationStatus?.color || "" }}
                    />
                  )}
                  Status do cadastro:{" "}
                  <strong>{registrationStatus?.status || ""}</strong>
                </p>
              </div>

              <p className={classes.helperText}>{registrationStatus?.text}</p>

              <div className={classes.footer}>
                {registrationStatus.hasButton && (
                  <CheckoutButton
                    outlined={registrationStatus.status === "Dados incompletos"}
                    hasIcon={registrationStatus.status === "Dados incompletos"}
                    onClick={() => {
                      const url =
                        registrationStatus.status === "Dados incompletos"
                          ? "https://uppy.deepdata.com.br/"
                          : "https://lojista.payhow.com.br/"

                      window.location.href = url
                    }}
                  >
                    {registrationStatus.status === "Dados incompletos"
                      ? "Concluir meu cadastro agora!"
                      : "Acessar minha conta payhow"}
                  </CheckoutButton>
                )}

                <a
                  href="https://payhow.com.br/faq"
                  style={{ marginTop: registrationStatus.hasButton ? "22px" : 0 }}
                >
                  ainda precisa de ajuda?
                </a>
              </div>
            </div>
          )}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <CustomAlert
            alertText="Algo deu errado!"
            show={showAlert}
            type="error"
            close={() => setShowAlert(false)}
          />
        </div>
      </div>
    </>
  )
}
