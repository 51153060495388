import React, { useState } from "react"
import CardAno from "./components/CardAno"
import CardHoje from "./components/CardHoje"
import CardMensal from "./components/CardMensal"
import CardSemanal from "./components/CardSemanal"
import { useStyles } from "./style"

export default function RelatorioVisitas() {
  const classes = useStyles()

  const [pos, setPos] = useState(0)

  function setPosition(e) {
    setPos(e)
  }

  const RelatorioVisita = () => {
    return (
      <>
        <CardAno setPosition={setPosition} />
      </>
    )
  }

  const Pages = [
    <RelatorioVisita setPosition={setPosition} />,
    <CardMensal setPosition={setPosition} />,
    <CardSemanal setPosition={setPosition} />,
    <CardHoje setPosition={setPosition} />
  ]

  return <>{Pages[pos]}</>
}
