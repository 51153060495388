import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import React from 'react'
import { SimpleImg } from 'react-simple-img'
import TrophyAnimation from '../../../Assets/gif/trophy-animation.gif'
import facebookIcon from '../../../Assets/svg/facebook.svg'
import twitterIcon from '../../../Assets/svg/twitter.svg'
import { button } from '../../../Componentes/Inputs/TextFild'
import { useSettings } from '../../../ContextAPI/settingsContext'
import useStyles from './styles'

export default function ProgressModal() {
  const classes = useStyles()
  const { lojinhaInfo } = useSettings()
  return (
    <div className="page-container">
      <div className="page-align">
        <div className="infinite-scroll">
          <div className={classes.PlanosAlertaContainer}>
            <div className={classes.PlanosAlertaHeaderMsg}>
              <h2 style={{ fontSize: '2rem' }}>Parabéns!</h2>
              <p>Você concluiu a configuração da sua loja.</p>
            </div>
            <div className={classes.PlanosAlertaImgWrapper}>
              <SimpleImg
                width="auto"
                height="auto"
                placeholder={false}
                src={TrophyAnimation}
                alt="Success Icon"
              />
            </div>
            <div className={classes.PlanosAlertaFooterMsg}>
              <h5 style={{ fontSize: '1.2rem' }}>
                Você deu mais um passo superimportante para aumentar ainda mais as
                suas vendas.
              </h5>
              <h3
                style={{
                  textAlign: 'center',
                  color: '#555555',
                  fontSize: '1rem',
                  marginTop: '35px'
                }}
              >
                Compartillhar minha loja:
              </h3>
              <div className={classes.EtapaVisualizarCompartilhar}>
                <div>
                  <a
                    href={`https://www.facebook.com/sharer.php?u=${`https://${lojinhaInfo.url}.uppy.app`}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SimpleImg
                      width={28}
                      height="auto"
                      placeholder={false}
                      src={facebookIcon}
                      alt="facebook-icon"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href={`https://twitter.com/intent/tweet?url=https://${lojinhaInfo.url}.uppy.app`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SimpleImg
                      width={28}
                      height="auto"
                      placeholder={false}
                      src={twitterIcon}
                      alt="twitter-icon"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href={`https://wa.me/?text=https://${lojinhaInfo.url}.uppy.app`}
                    target="_blank"
                    data-action="share/whatsapp/share"
                    rel="noreferrer"
                  >
                    <WhatsAppIcon
                      style={{
                        color: '#fff',
                        backgroundColor: '#3DC34F',
                        padding: '5px',
                        fontSize: '1.2rem',
                        borderRadius: '50px'
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-column">
          {button({
            name: `Entendi`,
            funcao: () => {
              window.location = '/home'
            }
          })}
        </div>
      </div>
    </div>
  )
}
