import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  PlanosAlertaModal: {
    "& .MuiDialogTitle-root": {
      padding: "0px 10px"
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "480px"
    }
  },
  PlanosAlertaModalHeader: {
    display: "flex",
    justifyContent: "flex-end",
    "& h2": {
      fontSize: "2rem",
      fontWeight: "700",
      flex: 1,
      textAlign: "center",
      paddingLeft: "36px"
    },
    "& button": {
      border: "none",
      fontFamily: "cursive",
      cursor: "pointer",
      backgroundColor: "transparent",
      color: "#EA3458",
      fontWeight: 900
    }
  },
  PlanosAlertaModalBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      fontSize: "1rem",
      fontWeight: "300",
      textAlign: "center"
    },
    "& span": {
      color: "#EA3458",
      fontWeight: "600"
    },
    "& img": {
      width: "100%",
      maxWidth: "170px",
      margin: "10px 0"
    }
  },
  isOkBtn: {
    color: "#fff",
    margin: "0px 20px 0px 20px",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "5px",
    marginBottom: "15px",
    textTransform: "none",
    backgroundColor: "#EA3458",

    "&:hover": {
      boxShadow: "none !important",
      backgroundColor: "#EA3458 !important"
    }
  }
})
export default useStyles
