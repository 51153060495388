import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  variationCardContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  variationImage: {
    borderRadius: "5px"
  },
  variationTagsList: {
    display: "flex"
  },
  variationTags: {
    display: "flex",
    margin: "0px 5px",
    padding: "5px",
    backgroundColor: "#EA3458",
    borderRadius: "10px"
  },
  variationTagsTitle: {
    color: "#fff",
    margin: "0px 5px"
  },
  variationDescription: {
    display: "flex",
    fontSize: "1.1rem"
  },
  variationPrice: {
    fontWeight: "bold",
    margin: "0px 5px",
    fontSize: "1rem"
  },
  variationPromotionalPrice: {
    fontWeight: "bold",
    margin: "5px 5px 5px 5px",
    fontSize: "1rem"
  },
  variationStorage: {
    fontWeight: "bold",
    margin: "0px 5px",
    fontSize: "1rem"
  }
}))
