import ApiRequest from "../index"

const storiesRequest = {
    getStoryList: async () => ApiRequest.get("v2/lojinha/stories/list"),
    delStory: async id => ApiRequest.delete(`/v2/lojinha/stories/delete/${id}`),
    getCategoryList: () => ApiRequest.get(`/v2/lojinha/category/main/list`),
    filterProducts: async (id) => ApiRequest.get(`/v2/lojinha/products/filter/category/${id}`),
    createStory: async (data) => ApiRequest.post(`/v2/lojinha/stories/create`, {...data}),
    updateStory: async (data) => ApiRequest.put(`/v2/lojinha/stories/update`, {...data}),
    openStory: async (id) => ApiRequest.get(`/v2/lojinha/stories/open/${id}`)
}

export default storiesRequest