import { CircularProgress } from "@material-ui/core"
import { useCallback, useEffect, useState } from "react"
import { useProduct } from "../../../../ContextAPI/productContext"
import storiesRequest from "../../../../Services/api/stories"
import ProductCard from "../ProductCard"
export default function ProductsList({ categoryId, storyId}) {
  const style = {
    height: "max-content",
    width: "100%",
    margin: "0 0 20px 0"
  }
  const {storiesProductsFiltredByCategory, setStoriesProductsFiltredByCategory} = useProduct()
  const [loading, setLoading] = useState(false)

  const getData = async () => {
    if(!storyId) return
    setLoading(true)
    try {
      const { data } = await storiesRequest.openStory(storyId)
      setStoriesProductsFiltredByCategory(data.data.story_products)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = storiesProductsFiltredByCategory[dragIndex]

      setStoriesProductsFiltredByCategory(prevList => {
        const newList = [...prevList]
        newList.splice(dragIndex, 1)

        hoverIndex = Math.min(hoverIndex, newList.length)

        newList.splice(hoverIndex, 0, dragCard)
        return newList
      })
    },
    [storiesProductsFiltredByCategory]
  )
  function handleDelete(id) {
    setStoriesProductsFiltredByCategory(oldState =>
      oldState.filter(item => (item.id ? item.id : item.product_id !== id))
    )

  }

  useEffect(() => {
    setStoriesProductsFiltredByCategory([])
    getData()
  }, [])


  return (
    <div style={style}>
      {loading ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            minHeight: "100%",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "40px"
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        storiesProductsFiltredByCategory.map((card, index) => (
          <div key={card.product_id}>
            <ProductCard
              index={index}
              id={card.product_id}
              text={card.product_title}
              image={card?.product_image}
              handleDelete={handleDelete}
              // product_position={card.product_position}
              moveCard={moveCard}
            />
          </div>
        ))
      )}
    </div>
  )
}
