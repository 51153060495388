import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  wrapperTop: {
    "& > div": {
      padding: "10px 0px !important"
    }
  },
  pagseguroOrders: {
    "&__enablePaymentOption": {
      // marginTop: "10px",
      paddingBottom: "20px",
      "& div": {
        display: "flex",
        justifyContent: "space-between"
      },

      "& p": {
        display: "flex",
        alignItems: "center",

        "& img": {
          width: "100px"
        }
      }
    },

    "&__installments": {
      marginTop: "-20px",

      "& p": {
        marginBottom: "6px"
      }
    },

    "&__registeredEmail": {
      "& p": {
        margin: "20px 0 6px 0"
      }
    },

    "&__token": {
      "& p": {
        margin: "20px 0 6px 0"
      }
    },

    "&__tokenStatus": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "8px",

      "& p": {
        display: "flex",
        alignItems: "center",
        marginTop: "10px !important",

        "& strong": {
          marginLeft: "5px"
        }
      },

      "& button": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "39px",
        height: "37px",
        border: "2.5px solid #EA3458",
        borderRadius: "5px",
        backgroundColor: "#f9f9f9",

        "& img": {
          width: "20px"
        }
      }
    },

    "&__information": {
      margin: "40px 0 15px 0",

      "& p": {
        color: "#1877F2",
        fontSize: "14px",
        fontWeight: "500",

        "& a": {
          color: "#1877F2",
          textDecoration: "underline"
        }
      }
    }
  },

  textWrapper: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "16px",
    alignItems: "center",
    margin: "10px 0px 5px"
  },

  soonWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  titleSoon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "90px",
    height: "20px",
    borderRadius: "17px",
    backgroundColor: "#F8B84E",

    "& p": {
      margin: "0px",
      color: "#FFFFFF",
      fontWeight: "500"
    }
  },

  title: {
    color: "#EA3558",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "10px 10px 10px 0px"
  },

  titleWrapper: {
    display: "flex",
    alignItems: "center",

    "& img": {
      margin: "5px 5px 5px",
      maxWidth: "30px",
      maxHeight: "30px"
    }
  },

  subtitle: {
    fontSize: "14px",
    margin: "0px"
  },

  description: {
    fontSize: "12px",
    margin: "5px 0px"
  },

  smallPrint: {
    fontSize: "10px",
    fontWeight: "regular"
  },

  integrationError: {
    fontSize: "10px",
    color: "#EC413D",
    marginTop: "3px !important"
  },

  btnSave: {
    color: "#fff",
    minWidth: "48%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "10px",
    textTransform: "none",
    fontWeight: "bold",
    marginBottom: "10px",
    backgroundColor: "#EA3458",

    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "14px",
      fontWeight: "500"
    },

    "&:hover": {
      backgroundColor: "#ff0053c9"
    }
  },
  btnFooterWhite: {
    height: "50px",
    backgroundColor: "#fff"
  },
  btnFooterPink: {
    height: "50px",
    marginBottom: "20px"
  },

  header: {
    width: "100vw",
    maxWidth: "600px",
    marginLeft: " -20px",
    marginBottom: "20px",
    "& .MuiTabs-flexContainer > *": {
      fontSize: "0.8rem"
    }
  },
  modalLeaving: {
    textAlign: "center",
    "& .MuiDialog-paper": {
      bottom: "0",
      position: "fixed!important",
      margin: "0",
      padding: "10px 20px",
      width: "100%",
      boxSizing: "border-box",
      borderRadius: "15px 15px 0px 0px"
    }
  }
}))
