import { Button, Dialog, Switch } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import { globalStyles } from "../../Componentes/CssGlobal"
import Topo from "../../Componentes/TopCategorias"
import { useSettings } from "../../ContextAPI/settingsContext"
import conexao from "../../Services/api"
import ListaMeiosPagamentos from "./components/ListaFormasPagamentos"
import { useStyles } from "./style"

export default function PagamentoWhatsApp() {
  const history = useHistory()
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const { getPayment } = useSettings()
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [active, setActive] = useState(true)
  const lojinha_id = localStorage.getItem("id")
  const [dataBody, setDataBody] = useState()
  const [recebeE, setRecebeE] = useState()
  const [saving, setSaving] = useState(false)
  const [payhowActive, setPawhowActive] = useState()
  const [openModal, setOpenModal] = useState(false)

  const id = localStorage.getItem("id")

  const [idPayment, setIdPayment] = useState("")

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  function getBody(e) {
    setRecebeE(e)
  }

  async function getId() {
    const pickId = await conexao.get(`/lojinha/payment/get/${lojinha_id}`)
    setIdPayment(pickId.data.data.id)
    setPawhowActive(pickId.data.data.payment_active)
    setDataBody(pickId.data.data?.payment_data)
    if (pickId.data.data.payment_active === "payhow") {
      setActive(false)
    }
  }

  async function PostDados() {
    setSaving(true)

    const body = {
      payment_active: "manual",
      lojinha_id: id,
      payment_data: {
        ...dataBody,
        manual: {
          payment_type_list: {
            ...recebeE
          }
        }
      }
    }

    conexao
      .put(`/lojinha/payment/update/${idPayment}`, body)
      .then(res => {
        getPayment()
        localStorage.setItem("active_checkout", "manual")
        setAlertType("success")
        setShowAlertOk(true)
        setTimeout(() => {
          history.goBack()
        }, 1500)
      })
      .catch(error => {
        setSaving(false)
        setAlertType("error")
        setShowAlertOk(true)
      })
  }

  useEffect(() => {
    getId()
  }, [])

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <div>
            <Topo name="Negociação manual via Whatsapp" />
          </div>
          <div className="infinite-scroll">
            <div className={classes.Slides} style={{ alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <h3>Habilitar esta opção de pagamento</h3>
                {payhowActive === "payhow" ? (
                  <>
                    <Switch
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      name="enbale_payment"
                      onClick={() => setOpenModal(true)}
                      checked={active}
                    />
                  </>
                ) : (
                  <>
                    <Switch
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      name="enbale_payment"
                      checked={active}
                    />
                  </>
                )}
              </div>
              <p style={{ color: "#D62B2B" }}>
                &#9888; Enquanto esta opção estiver ativa sua loja não utilizará os
                pagamentos automáticos da plataforma fazendo com que os pagamento não
                sejam automáticos e necessitem da sua negociação para receber suas
                vendas.
              </p>
            </div>
            {active ? (
              <>
                <h3>
                  Selecione abaixo as opções de pagamento aceitas para negociação em
                  sua loja:
                </h3>
                <ListaMeiosPagamentos getBody={getBody} />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  {/* alertas */}
                  <CustomAlert
                    type={alertType}
                    alertText={
                      alertType === "success"
                        ? "Pagamento salvo com sucesso!"
                        : "Algo deu errado!"
                    }
                    show={showAlertOk || showAlertError}
                    close={() => closeAlerts()}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="footer-column">
            <Dialog className={classes.modalLeaving} open={openModal}>
              <div>
                <h2>Tem certeza que deseja atualizar?</h2>
                <p>Seu checkout voltará para o manual!</p>
              </div>
              <Button
                className={classes.btnFooterPink}
                disabled={false}
                onClick={() => {
                  setActive(true)
                  setOpenModal(false)
                }}
                fullWidth
                variant="contained"
                color="secondary"
              >
                sim, quero atualizar
              </Button>
              <Button
                className={classes.btnFooterWhite}
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={() => {
                  history.goBack()
                }}
              >
                não, obrigado.
              </Button>
            </Dialog>
            <Button
              type="submit"
              className={classes.btnAddLogo}
              variant="contained"
              disabled={saving}
              onClick={() => {
                PostDados()
              }}
            >
              Salvar informações
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
