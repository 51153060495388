import React from "react"
import MaintanceImg from "../../Assets/svg/maintenance.svg"

function InMaintenance() {
  return (
    <div className="page-container">
      <div className="page-align">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <img src={MaintanceImg} alt="aplicativo-em-manutencao" width="100%" />
          <div style={{ textAlign: "center" }}>
            <h1 style={{ fontSize: "1.5rem", color: "hsl(208, 68%, 25%)" }}>
              Atualmente estamos atualizando o aplicativo!
            </h1>
            <h2 style={{ fontWeight: "1rem", color: "hsl(208, 68%, 35%)" }}>
              Estaremos de volta em breve...
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InMaintenance
