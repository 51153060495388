export default function Dinheiro(props) {
  return (
    <svg
      width="27"
      height="22"
      viewBox="0 0 25 16"
      fill={props.fill ? "#a4a4a4" : " #EA3458"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 3.625C10.4289 3.625 8.75 5.58359 8.75 8C8.75 10.416 10.4289 12.375 12.5 12.375C14.5703 12.375 16.25 10.4168 16.25 8C16.25 5.58359 14.5711 3.625 12.5 3.625ZM14.0625 10.1875C14.0625 10.3602 13.9227 10.5 13.75 10.5H11.25C11.0773 10.5 10.9375 10.3602 10.9375 10.1875V9.5625C10.9375 9.38984 11.0773 9.25 11.25 9.25H11.875V7.08437L11.8566 7.09648C11.8225 7.11926 11.7842 7.13509 11.744 7.14306C11.7037 7.15103 11.6623 7.15098 11.6221 7.14293C11.5818 7.13488 11.5436 7.11897 11.5095 7.09612C11.4754 7.07327 11.4462 7.04393 11.4234 7.00977L11.0766 6.48984C11.0538 6.45571 11.038 6.41743 11.03 6.37718C11.022 6.33693 11.0221 6.2955 11.0301 6.25526C11.0382 6.21503 11.0541 6.17678 11.0769 6.1427C11.0998 6.10862 11.1291 6.07937 11.1633 6.05664L11.7621 5.65742C11.9161 5.55476 12.097 5.49999 12.282 5.5H12.8125C12.9852 5.5 13.125 5.63984 13.125 5.8125V9.25H13.75C13.9227 9.25 14.0625 9.38984 14.0625 9.5625V10.1875ZM23.75 0.5H1.25C0.559766 0.5 0 1.05977 0 1.75V14.25C0 14.9402 0.559766 15.5 1.25 15.5H23.75C24.4402 15.5 25 14.9402 25 14.25V1.75C25 1.05977 24.4402 0.5 23.75 0.5ZM23.125 11.125C21.7441 11.125 20.625 12.2441 20.625 13.625H4.375C4.375 12.2441 3.25586 11.125 1.875 11.125V4.875C3.25586 4.875 4.375 3.75586 4.375 2.375H20.625C20.625 3.75586 21.7441 4.875 23.125 4.875V11.125Z"
        fill={props.Color}
      />
    </svg>
  )
}
