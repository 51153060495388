import CircularProgress from "@material-ui/core/CircularProgress"
import update from "immutability-helper"
import { useCallback, useEffect, useState } from "react"
import { useProduct } from "../../../../ContextAPI/productContext"
import api from "../../../../Services/api"
import CardDestaques from "../CardDestaques"
import defaultImage from "../../../../Assets/img/noProductImg.png"
import newsRequest from "../../../../Services/api/news"

const style = {
  height: "max-content",
  width: "100%",
  margin: "0 0 20px 0"
}

function DestaquesContainer({
  handleGetHighlightList,
  loading
}) {
  const { productsHighlightList, setProductsHighlightList } = useProduct()
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = productsHighlightList[dragIndex]

      setProductsHighlightList(prevList => {
        const newList = [...prevList]
        newList.splice(dragIndex, 1)

        hoverIndex = Math.min(hoverIndex, newList.length)

        newList.splice(hoverIndex, 0, dragCard)
        return newList
      })
    },
    [productsHighlightList]
  )

  async function handleDelete(id) {
    try {
      setProductsHighlightList(oldState => {
        const newState = oldState.filter(item =>
          item.id ? item.id !== id : item.product_id !== id
        )
        const productsIds = newState.map(product =>
          product.id ? product.id : product.product_id
        )

        newsRequest.putNewOption({
          news_body: productsIds
        })
        handleGetHighlightList()
      })
    } catch (error) {}
  }



  return (
    <div style={style}>
      {loading ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            minHeight: "100%",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "40px"
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        productsHighlightList?.map((card, index) => (
          <div key={card.id}>
            <CardDestaques
              index={index}
              id={card.id ?? card.product_id}
              text={card.title ?? card.product_title}
              image={card?.product_image ?? defaultImage}
              handleDelete={handleDelete}
              product_position={card.product_position}
              moveCard={moveCard}
            />
          </div>
        ))
      )}
    </div>
  )
}

export default DestaquesContainer
