import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => ({
  FormStore: {},
  FormStoreContainer: {
    display: "grid",
    gridTemplateColumns: "1fr",
    rowGap: "1rem",
    margin: "auto"
  },
  Container: {
    padding: "0 1.5rem",
    height: "100%",
    minHeight: "100vh", // Mínima altura da tela

    "& form": {
        paddingBottom: "3rem", // Espaço para o botão no rodapé
        position: "relative"
    }
  },
  InputBox: {
    "& input": {
        width:" 100%",
        border: "1px solid #D0D0D0",
        padding: "0.5rem 0.3rem",
        borderRadius: "5px",
        margin: 0,
        boxSizing: "border-box",
    }
  },
  InputLabel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& p": {
      fontSize: "0.8rem",
      fontWeight: 500,
      color: "#353535"
    }
  },
  InputForm: {
    backgroundColor: "#EA3458",
    fontWeight: 500,
    width: "100%",
    maxWidth: "280px",
    borderRadius: "6px",
    color: "#fff",
    padding: "1rem 0rem",
    border: "none",
    cursor: "pointer",

  },
  FooterContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff", // Cor de fundo opcional
    borderTop: "1px solid #E4E4E4",
    padding: "1rem 0",
  },
  ErrorMessage: {
    margin: "4px 0 0 0",
    fontSize: "0.7rem",
    color: "red"
  },
  BoxLoading: {
    width: "100%",
    position: "relative",
    "& .MuiCircularProgress-root": {
      position: "absolute",
      right: 0,
      top: 0
    }
  }
}))
