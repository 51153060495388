import {
  CircularProgress,
  FormControl,
  InputAdornment,
  NativeSelect,
  TextField
} from "@material-ui/core"
import InputBase from "@material-ui/core/InputBase"
import Switch from "@material-ui/core/Switch"
import { withStyles } from "@material-ui/core/styles"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { button } from "../../../Componentes/Inputs/TextFild"
import Topo from "../../../Componentes/TopCategorias"
import { current } from "../../../Componentes/validates/Validate"
import { StateGlobal } from "../../../ContextAPI/index"
import api from "../../../Services/api"
import { useStyles } from "./style"

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(4)
    }
  },
  input: {
    width: "100%",
    borderRadius: 5,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid  #EA3458",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    color: "#EA3458",
    fontWeight: "500",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#EA3458",
      boxShadow: "0 0 0 0.1rem #EA3458cc"
    }
  }
}))(InputBase)

export default function FreteGratis() {
  const classes = useStyles()

  const {
    freeShippingCitiesData,
    setFreeShippingCitiesData,
    freeShippingStatesData,
    setFreeShippingStatesData,
    freeShippingData,
    setFreeShippingData,
    firstTimeFree,
    setFirstTimeFree,
    enableFreeDelivery,
    setEnableFreeDelivery,
    enableFreeByPrice,
    setEnableFreeByPrice,
    hasContext
  } = StateGlobal()

  const [enableFreeByState, setEnableFreeByState] = useState(false)
  const [enableFreeByCity, setEnableFreeByCity] = useState(false)
  const [alertType, setAlertType] = useState("success")
  const [showAlert, setShowAlert] = useState(false)
  const [alertMsg, setAlertMsg] = useState("Salvo com sucesso")
  const [loading, setLoading] = useState(true)
  const shopId = localStorage.getItem("id")
  const history = useHistory()
  const [emptyRequired, setEmptyRequired] = useState(false)

  const handleBackward = () => {
    setTimeout(() => {
      clearContext()
      history.goBack()
    }, 2500)
  }

  const clearContext = () => {
    setEnableFreeDelivery(false)
    setFreeShippingCitiesData([])
    setFreeShippingStatesData([])
  }

  const getInformations = async () => {
    api.get(`/lojinha/shipping/get/${shopId}/free`).then(res => {
      const currentInformations = res.data.data
      if (currentInformations === null) {
        setFirstTimeFree(true)
        setLoading(false)
      } else {
        let new_time_type
        if (currentInformations.shipping_data.average_time_type === "days") {
          new_time_type = "DIAS"
        } else if (currentInformations.shipping_data.average_time_type === "hours") {
          new_time_type = "HORAS"
        } else {
          new_time_type = "MINUTOS"
        }

        const priceDataGet =
          currentInformations.shipping_data.free_shipping_above || 0
        const time_deliveryDataGet =
          currentInformations.shipping_data.average_time || ""
        const time_typeDataGet = new_time_type || ""
        const state_filterDataGet =
          currentInformations.shipping_data.state_filter || []
        const state_city_filterDataGet =
          currentInformations.shipping_data.state_city_filter || []

        setFreeShippingData({
          free_shipping_above: priceDataGet,
          time_delivery: time_deliveryDataGet,
          time_type: time_typeDataGet,
          state_filter: state_filterDataGet,
          state_city_filter: state_city_filterDataGet,
          shipping_id: currentInformations.id
        })

        if (priceDataGet > 0) {
          setEnableFreeByPrice(true)
        }

        if (state_city_filterDataGet.length > 0) {
          setEnableFreeByCity(true)
        }

        if (state_filterDataGet.length > 0) {
          setEnableFreeByState(true)
        }

        if (!freeShippingStatesData.length && !hasContext) {
          setFreeShippingStatesData(state_filterDataGet)
        }

        if (!freeShippingCitiesData.length && !hasContext) {
          setFreeShippingCitiesData(state_city_filterDataGet)
        }

        setEnableFreeDelivery(currentInformations.shipping_active)
      }

      setLoading(false)
    })
  }

  async function dataSave() {
    let new_time_type

    if (freeShippingData.time_type === "DIAS") {
      new_time_type = "days"
    } else if (freeShippingData.time_type === "HORAS") {
      new_time_type = "hours"
    } else {
      new_time_type = "minutes"
    }

    const bodyCreate = {
      lojinha_id: shopId,
      shipping_type: "free_shipping",
      shipping_active: enableFreeDelivery ? 1 : 0,
      shipping_data: {
        free_shipping_above:
          freeShippingData.free_shipping_above === 0
            ? 0
            : freeShippingData.free_shipping_above,
        average_time: freeShippingData.time_delivery,
        average_time_type: new_time_type,
        state_filter: enableFreeByState ? freeShippingStatesData : [],
        state_city_filter: enableFreeByCity ? freeShippingCitiesData : []
      }
    }

    const bodyUpdate = {
      lojinha_id: shopId,
      shipping_active: enableFreeDelivery ? 1 : 0,
      shipping_type: "free_shipping",
      shipping_data: {
        free_shipping_above: enableFreeByPrice
          ? freeShippingData.free_shipping_above
          : 0,
        average_time: freeShippingData.time_delivery,
        state_filter: enableFreeByState ? freeShippingStatesData : [],
        state_city_filter: enableFreeByCity ? freeShippingCitiesData : [],
        average_time_type: new_time_type
      }
    }
    if (
      bodyCreate.shipping_data.average_time === null ||
      bodyCreate.shipping_data.average_time === "" ||
      bodyUpdate.shipping_data.average_time === null ||
      bodyUpdate.shipping_data.average_time === ""
    ) {
      setEmptyRequired(true)
      setShowAlert(true)
      setAlertType("error")
      setAlertMsg("Preencha os campos obrigatórios")
      return
    }

    if (firstTimeFree === true) {
      let resCreate = await api.post(`/lojinha/shipping/create`, bodyCreate)

      if (resCreate.data.error === false) {
        setShowAlert(true)
        setAlertType("success")
        setAlertMsg("Salvo com sucesso!")
        setFirstTimeFree(false)
        handleBackward()
      } else {
        setShowAlert(true)
        setAlertType("error")
        setAlertMsg("Erro ao salvar informações")
      }
    } else {
      let res = await api.put(
        `/lojinha/shipping/update/${freeShippingData.shipping_id}`,
        bodyUpdate
      )
      if (res.data.error === false) {
        setShowAlert(true)
        setAlertType("success")
        setAlertMsg("Salvo com sucesso!")
        handleBackward()
      } else if (
        res.data.error === true &&
        res.data.message === "Existe apenas um checkout ativo!"
      ) {
        setShowAlert(true)
        setAlertType("error")
        setAlertMsg("Deixe pelo menos 1 frete ativado!")
      } else {
        setShowAlert(true)
        setAlertType("error")
        setAlertMsg("Erro ao salvar informações")
      }
    }
  }

  function selectCitiesPlaceholder(cities) {
    let newArr = []

    cities.forEach(city => {
      newArr.push(...city.cities)
    })

    return newArr.join(", ")
  }

  useEffect(() => {
    getInformations()
  }, [])

  useEffect(() => {
    setFreeShippingData(oldState => ({
      ...oldState,
      state_city_filter: freeShippingCitiesData
    }))
    if (freeShippingCitiesData.length > 0) {
      setEnableFreeByCity(true)
    }
  }, [freeShippingCitiesData])

  useEffect(() => {
    setFreeShippingData(oldState => ({
      ...oldState,
      state_filter: freeShippingStatesData
    }))
    if (freeShippingStatesData.length > 0) {
      setEnableFreeByState(true)
    }
  }, [freeShippingStatesData])

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <div>
            <Topo name="Frete Grátis" />
            <CustomAlert
              type={alertType}
              alertText={alertMsg}
              show={showAlert}
              close={() => setShowAlert(false)}
            />
          </div>
          <div className="infinite-scroll">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  minHeight: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <>
                <div className={classes.toggledTitle}>
                  <h2>Habilitar opção de frete grátis</h2>
                  <Switch
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onClick={() => {
                      setEnableFreeDelivery(!enableFreeDelivery)
                    }}
                    checked={enableFreeDelivery}
                  />
                </div>

                {enableFreeDelivery ? (
                  <>
                    <div className={classes.divbox}>
                      <div className={classes.switch}>
                        <h2>Frete grátis por valor do pedido?</h2>
                        <Switch
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          onClick={() => {
                            setEnableFreeByPrice(!enableFreeByPrice)
                          }}
                          checked={enableFreeByPrice}
                        />
                      </div>

                      {enableFreeByPrice ? (
                        <div style={{ marginBottom: "20px" }}>
                          <h4
                            style={{
                              margin: "10px 0",
                              fontWeight: "500",
                              color: "#555555"
                            }}
                          >
                            Frete grátis para pedidos com o valor acima de:
                          </h4>
                          <TextField
                            name="price"
                            onChange={e => {
                              setFreeShippingData({
                                ...freeShippingData,
                                free_shipping_above: current(
                                  e.target.value.substring(0, 20)
                                )
                              })
                            }}
                            size="small"
                            variant="outlined"
                            type="tel"
                            color="secondary"
                            value={
                              freeShippingData !== null &&
                              freeShippingData?.free_shipping_above &&
                              freeShippingData?.free_shipping_above !== "0,00"
                                ? new Intl.NumberFormat("pt-BR", {
                                    minimumFractionDigits: 2
                                  }).format(freeShippingData.free_shipping_above)
                                : "0,00"
                            }
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">R$</InputAdornment>
                              )
                            }}
                            style={{ backgroundColor: "#fff" }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div>
                      <h4
                        style={{
                          margin: "20px 0 10px",
                          fontWeight: "500",
                          color: "#555555"
                        }}
                      >
                        Tempo estimado para entrega:
                      </h4>
                      <div className={classes.switch}>
                        <TextField
                          name="shipping"
                          size="small"
                          helperText={
                            emptyRequired === true ? (
                              <p
                                style={{
                                  color: "red",
                                  margin: 0,
                                  fontSize: "0.8rem"
                                }}
                              >
                                campo obrigatório
                              </p>
                            ) : (
                              ""
                            )
                          }
                          color="secondary"
                          variant="outlined"
                          type="tel"
                          onChange={e => {
                            setFreeShippingData({
                              ...freeShippingData,
                              time_delivery: e.target.value.replace(/\D/g, "")
                            })
                          }}
                          value={freeShippingData?.time_delivery || ""}
                          style={{ backgroundColor: "#fff", width: "65%" }}
                        />
                        <div className={classes.dropdown}>
                          <FormControl>
                            <NativeSelect
                              id="demo-customized-select-native"
                              value={freeShippingData?.time_type || ""}
                              onChange={e => {
                                setFreeShippingData({
                                  ...freeShippingData,
                                  time_type: e.target.value
                                })
                              }}
                              input={<BootstrapInput />}
                            >
                              <option>MINUTOS</option>
                              <option>HORAS</option>
                              <option>DIAS</option>
                            </NativeSelect>
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className={classes.divbox}>
                        <div className={classes.switch}>
                          <h2>Frete grátis por região/estado?</h2>
                          <Switch
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            onClick={() => {
                              setEnableFreeByState(!enableFreeByState)
                            }}
                            checked={enableFreeByState}
                          />
                        </div>

                        {enableFreeByState ? (
                          <>
                            <h4
                              style={{
                                margin: "10px 0",
                                fontWeight: "500",
                                color: "#555555"
                              }}
                            >
                              Estados
                            </h4>
                            <Link to="/selecionar-estado?from=freeShipping">
                              <TextField
                                name="states"
                                type="text"
                                value={
                                  freeShippingStatesData.join(", ") ||
                                  "Selecionar estados"
                                }
                                size="medium"
                                disabled
                                placeholder="Selecionar estados"
                                variant="outlined"
                                color="secondary"
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  backgroundColor: "#fff",
                                  cursor: "pointer"
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <ArrowForwardIosIcon
                                        style={{ color: "#A4A4A4" }}
                                      />
                                    </InputAdornment>
                                  )
                                }}
                              ></TextField>
                            </Link>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div>
                        <div className={classes.divbox}>
                          <div className={classes.switch}>
                            <h2>Frete grátis por cidade?</h2>
                            <Switch
                              inputProps={{ "aria-label": "secondary checkbox" }}
                              onClick={() => {
                                setEnableFreeByCity(!enableFreeByCity)
                              }}
                              checked={enableFreeByCity}
                            />
                          </div>

                          {enableFreeByCity ? (
                            <>
                              <h4
                                style={{
                                  margin: "10px 0",
                                  fontWeight: "500",
                                  color: "#555555"
                                }}
                              >
                                Cidade
                              </h4>
                              <Link to="/selecionar-cidade?from=freeShipping">
                                <TextField
                                  name="states"
                                  type="text"
                                  value={
                                    freeShippingCitiesData.length > 0
                                      ? selectCitiesPlaceholder(
                                          freeShippingCitiesData
                                        )
                                      : "Selecionar cidades"
                                  }
                                  size="medium"
                                  disabled
                                  placeholder="Selecionar cidades"
                                  variant="outlined"
                                  color="secondary"
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    backgroundColor: "#fff",
                                    cursor: "pointer"
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <ArrowForwardIosIcon
                                          style={{ color: "#A4A4A4" }}
                                        />
                                      </InputAdornment>
                                    )
                                  }}
                                ></TextField>
                              </Link>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>

          <div className="footer-column">
            <hr style={{ width: "100%", borderColor: "#E4E4E4", opacity: 0.3 }} />

            {button({
              name: "Salvar Alterações",
              funcao: dataSave
            })}
          </div>
        </div>
      </div>
    </>
  )
}
