import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  Modal: {
    "& .MuiDialog-paper": {
      maxHeight: "none",
      margin: "0",
      width: "100%",
      borderRadius: "0",
      boxShadow: "0px 12px 10px 5px #d9d9d9",
      height: "100%!important"
    },

    "& .MuiDialogActions-root": {
      display: "block"
    },

    "& .MuiBackdrop-root": {
      backgroundColor: "#00000000"
    },

    "& .MuiDialog-scrollPaper": {
      opacity: "1 !important",
    }
  },

  cropButton: {
    color: "#fff",
    minWidth: "100%",
    height: "50px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    marginTop: "5px",
    marginBottom: "15px",
    textTransform: "none",
    backgroundColor: "#EA3458",

    '&:hover': {
      boxShadow: "none !important",
      backgroundColor: "#EA3458 !important"
    }
  }
}))
