import React from 'react'
import ErrorIcon from '../../../../../Assets/gif/error-animation.gif'
import useStyles from './style'
import { useState } from 'react'
import CustomAlert from '../../../../../Componentes/Alerts/CustomAlert'
import requestBanners from '../../../../../Services/api/banners'
import { useHistory } from 'react-router-dom'
import { CircularProgress, Dialog } from '@material-ui/core'
import { useProduct } from '../../../../../ContextAPI/productContext'

export default function AlertModal({ title, description, setOpenModal, setImage, close, cropImage, setCropImage, setSrc, bannerId, url, modalOpen, getUrlImage, getProductBannerUrl, sessaoGuiada }) {
  const classes = useStyles()
  const history = useHistory()
  const { setTabSession } = useProduct()
  const [alertType, setAlertType] = useState("")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleCrop = async() => {
    if (!getUrlImage) {
      setImage(cropImage)
    }
    else {
      setIsLoading(true)
      await getProductBannerUrl(cropImage)
    }
    setCropImage(null)
    setSrc(null)
    close()
    setOpenModal(false)
  }

  const handleDeleteBanner = async () => {
    setIsLoading(true)
    try {
      await requestBanners.deleteBanner(bannerId)
      setAlertType("success")
      setAlertMessage("Banner deletado com sucesso!")
      setShowModalAlert(true)
      if (sessaoGuiada) {
        setTabSession(4)
      }
      setTimeout(() => {
        history.push(url)
      }, 2000)
    } catch (e) {
      setAlertType("error")
      setAlertMessage("Erro ao deletar banner!")
      setShowModalAlert(true)
      setIsLoading(false)
    }
  }

  return (
    <>
      <Dialog className={classes.Modal} open={modalOpen}>
        {
          isLoading ? (
            <div
              style={{
                backgroundColor: " #fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%"
              }}
            >
              <CircularProgress color="secondary" size="40px" />
            </div>
          ) : (
            <div className='page-container'>
              <div className='page-align'>
                <div className="infinite-scroll">
                  <div className={classes.mainContainer}>
                    <h1>{title}</h1>
                    <div className={classes.alertContainer}>
                      <img style={{ width: "70%" }} src={ErrorIcon} alt="" />
                      <p style={{ fontSize: "20px", textAlign: "center" }}>{description}</p>
                    </div>
                  </div>
                </div>
                <div className="footer-column" style={{ marginBottom: "20px" }}>
                  <div className={classes.buttonContainer}>
                    <button className={classes.buttonRed} onClick={() => {setOpenModal(false)
                       close()}}>Não</button>
                    <button className={classes.buttonWhite} onClick={bannerId ? handleDeleteBanner : handleCrop}>Sim</button>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Dialog>
      <CustomAlert
        type={alertType}
        alertText={alertMessage}
        show={showModalAlert}
        close={() => setShowModalAlert(false)}
      />
    </>
  )
}
