import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import IconButton from "@material-ui/core/IconButton"
import Input from "@material-ui/core/Input"
import InputAdornment from "@material-ui/core/InputAdornment"
import InputLabel from "@material-ui/core/InputLabel"
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import React, { useState } from "react"
import { senhavalidate } from "../../validates/ValidateForm"

const useStyles = makeStyles(theme => ({
  textField: {
    minWidth: "100%"
  },

  teste2: {
    color: "primary"
  }
}))

const theme = createTheme({
  palette: {
    secondary: {
      light: "#E7366A",
      main: "#E7366A",
      dark: "#D93364",
      contrastText: "#fff"
    },
    primary: {
      light: "#EA3458",
      main: "#EA3458",
      dark: "#EA3458",
      contrastText: "#fff"
    }
  }
})

export default function InputAdornments(props) {
  const classes = useStyles()
  const [error, setError] = useState()
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false
  })

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
    props.pegadados({ name: "password", value: event.target.value })
    if (senhavalidate(event.target.value)) {
      setError(senhavalidate(event.target.value))
    } else {
      setError(senhavalidate(false))
    }
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <ThemeProvider theme={theme}>
      <FormControl style={{ minWidth: "100%", marginTop: "20px" }}>
        <InputLabel
          htmlFor="standard-adornment-password"
          style={{ marginTop: "20px" }}
        >
          {props.label}
        </InputLabel>
        <Input
          style={{ marginTop: "40px" }}
          className={classes.teste2}
          id="standard-adornment-password"
          type={values.showPassword ? "text" : "password"}
          value={values.password}
          onChange={handleChange("password")}
          error={props.error}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? (
                  <Visibility style={{ color: "#353535" }} />
                ) : (
                  <VisibilityOff style={{ color: "#353535" }} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText style={{ color: "red" }}>
          {props.error !== false ? props.error : ""}
        </FormHelperText>
      </FormControl>
    </ThemeProvider>
  )
}
