import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  headerInfos: {
    fontSize: "0.9rem",
    textAlign: "center"
  },

  rowType: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    borderBottom: "solid 1px #d1d1d1"
  },

  textInfo: {
    display: "flex"
  },

  typeText: {
    fontSize: "1.3rem",
    marginBottom: "3px"
  },

  typeCheckbox: {
    marginRight: "5px"
  },
  btnFooterPink: {
    height: "50px"
  }
}))
