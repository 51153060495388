import Skeleton from "@material-ui/lab/Skeleton"
import { useHistory } from "react-router-dom"
import noImageProduct from "../../../../Assets/img/noProductImg.png"
import noProducts from "../../../../Assets/Home/noProducts.svg"
import { useSettings } from "../../../../ContextAPI/settingsContext"
import FreeWantedProducts from "./components/freeWantedProducts/index"
import { useStyles } from "./style"
import { SimpleImg } from "react-simple-img"
import React from "react"

export default function LojinhaWantedProducts({ loading, products }) {
  const { lojinhaInfo } = useSettings()

  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2
  const classes = useStyles()
  const history = useHistory()
  return (
    <>
      {/* CARREGANDO INFORMACOES */}
      {loading === true && (
        <>
          {[1, 2, 3].map((arr, idx) => (
            <section style={{ marginTop: "15px" }}>
              <div className={classes.productContent}>
                <Skeleton
                  variant="circle"
                  width={50}
                  height={50}
                  style={{ borderRadius: "5px" }}
                />
                <div style={{ width: "100%", marginLeft: "10px" }}>
                  <Skeleton width="90%" />
                  <Skeleton width="90%" />
                </div>
              </div>
            </section>
          ))}
        </>
      )}
      {/* CASO USUARIO PRO */}
      {products.length > 0 &&
        hasPro === true &&
        products.slice(0, 5).map((arr, idx) => (
          <section style={{ marginTop: "15px" }}>
            <div className={classes.productContainer}>
              <h2>{idx + 1}º</h2>
              <div className={classes.productContent}>
                <SimpleImg
                  width={47}
                  height="auto"
                  placeholder={false}
                  src={
                    arr.images?.length > 0 ? arr.images[0].url_min : noImageProduct
                  }
                  alt="img Produto"
                />
                <div className={classes.productInfos}>
                  <p>{arr.product_title}</p>
                  <small>
                    Preço:{" "}
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    }).format(arr.price)}
                  </small>
                </div>
              </div>
            </div>
          </section>
        ))}
      {/* CASO USUARIO FREE */}
      {products.length > 0 && hasPro === false && (
        <div>
          <FreeWantedProducts products={products} />
        </div>
      )}
      {/* CASO NAO TENHA PRODUTOS MAIS VISTOS */}
      {products.length === 0 && (
        <section className={classes.noProductsContainer}>
          <SimpleImg
            width="auto"
            height="auto"
            placeholder={false}
            src={noProducts}
            alt="sem produtos"
            style={{ marginTop: "10px", marginBottom: "12px" }}
          />
          <span className={classes.infoText}>
            Você ainda não tem informações relevantes de procura.
          </span>
          <span className={classes.helpText}>Está precisando de ajuda?</span>
          <button
            type="button"
            onClick={() => history.push("/marketing/falar-com-especialista")}
          >
            Sim, estou!
          </button>
        </section>
      )}
    </>
  )
}
