export default function Boleto(props) {
  return (
    <svg
      width="30"
      height="25"
      viewBox="0 0 20 16"
      fill={props.fill ? "#a4a4a4" : " #EA3458"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H3V16H0V0Z" fill={props.Color} />
      <path d="M4 0H5V16H4V0Z" fill={props.Color} />
      <path d="M9 0H7V16H9V0Z" fill={props.Color} />
      <path d="M10 0H12V16H10V0Z" fill={props.Color} />
      <path d="M13 0H14V16H13V0Z" fill={props.Color} />
      <path d="M18 0H15V16H18V0Z" fill={props.Color} />
      <path d="M19 0H20V16H19V0Z" fill={props.Color} />
    </svg>
  )
}
