import React from "react"
import "./styles.css"
import defaultImage from "../../../../../../../Assets/img/noProductImg.png"
import { SimpleImg } from "react-simple-img"

export default function CardProdutoFirst(props) {
  return (
    <>
      {props.products.map((item, index) => {
        return (
          <div className="cardInfo">
            <div className={index === 0 ? "fristItem" : "itemCard"}>
              {index === 0 ? "" : index + 1}
            </div>
            <div className="image">
              <SimpleImg
                placeholder={false}
                src={item.images.length > 0 ? item.images[0].url_min : defaultImage}
                Alt={item.product_description}
                height={60}
                width={60}
              />
            </div>
            <div>
              <div className="textExtends">{item.title}</div>
              <div className="cardTextTotalPrice">
                Total:
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(item.total_price)}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column"
              }}
            >
              <div className="cardText3">{item.total_sales}</div>
              <div className="cardText1">vendas</div>
            </div>
          </div>
        )
      })}
    </>
  )
}
