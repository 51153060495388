import { Button, CircularProgress, Dialog } from "@material-ui/core"
import { useStyles } from "./style"
import Topo from "../TopModalCrop"
import { Cropper, RectangleStencil } from "react-advanced-cropper"
import "react-advanced-cropper/dist/themes/compact.css"
import "react-advanced-cropper/dist/style.css"
import { useRef, useState } from "react"
import AlertModal from "../../Pages/Banner/AddBanner/components/AlertModal"
import produtosRequest from "../../Services/api/produtos"

const AdvancedCropper = ({
  open,
  close,
  src,
  setSrc,
  setImage,
  width,
  height,
  getUrlImage
}) => {
  const classes = useStyles()
  const cropperRef = useRef(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [cropImage, setCropImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const onCrop = async () => {
    setIsLoading(true)
    if (cropperRef.current) {
      const cropWidth = cropperRef.current.getCoordinates()?.width
      const cropHeight = cropperRef.current.getCoordinates()?.height
      if (cropWidth < width || cropHeight < height) {
        setCropImage(cropperRef.current.getCanvas()?.toDataURL())
        setModalOpen(true)
        return
      }

      if (getUrlImage) {
        await getProductBannerUrl(cropperRef.current.getCanvas()?.toDataURL())
      } else {
        setImage(cropperRef.current.getCanvas()?.toDataURL())
      }
      setSrc(null)
      close()
    }
  }

  const getProductBannerUrl = async image => {
    try {
      const res = await produtosRequest.uploadBanner(image)
      setImage(res.data.data)
    } catch (e) {
      setIsLoading(false)
    }
  }

  const handleCloseDialog = () => {
    setSrc(null)
    close()
  }

  return (
    <>
      {!modalOpen ? (
        <>
          <Dialog className={classes.Modal} open={open}>
            {isLoading ? (
              <div
                style={{
                  backgroundColor: " #fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%"
                }}
              >
                <CircularProgress color="secondary" size="40px" />
              </div>
            ) : (
              <div className="page-container">
                <div className="page-align" style={{ justifyContent: "flex-start" }}>
                  <div>
                    <Topo
                      name="Recortar imagem"
                      type="button"
                      fechar={handleCloseDialog}
                    />
                  </div>
                  <div className="infinite-scroll">
                    <div>
                      <Cropper
                        ref={cropperRef}
                        src={src}
                        defaultCoordinates={{
                          width: width,
                          height: height
                        }}
                        stencilProps={{
                          resize: true,
                          handlers: true
                        }}
                        stencilComponent={RectangleStencil}
                        aspectRatio={width / height}
                      />
                    </div>
                  </div>
                  <div className="footer-column">
                    <hr style={{ width: "100%" }} />
                    <Button
                      onClick={onCrop}
                      className={classes.cropButton}
                      type="submit"
                      variant="contained"
                    >
                      Cortar
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Dialog>
        </>
      ) : (
        <AlertModal
          title={"Resolução da imagem"}
          description={
            "Sua imagem parece estar fora dos padrões, e com isso pode ser que ela perca qualidade na exibição da mesma na sua loja. Continuar mesmo assim?"
          }
          setImage={setImage}
          setOpenModal={setModalOpen}
          close={close}
          cropImage={cropImage}
          setCropImage={setCropImage}
          setSrc={setSrc}
          modalOpen={modalOpen}
          getUrlImage={getUrlImage ? true : false}
          getProductBannerUrl={getProductBannerUrl}
        />
      )}
    </>
  )
}

export default AdvancedCropper
