import ApiRequest from "../index"

const produtosRequest = {
  create: async data =>
    ApiRequest.post(`v2/lojinha/products/create`, {
      lojinha_id: Number(localStorage.getItem("id")),
      ...data
    }),
  put: async (id, data) =>
    ApiRequest.put(`/v2/lojinha/products/update`, {
      ...data
    }),
  delete: async id => ApiRequest.delete(`product/${id}`),
  get: async id => ApiRequest.get(`v2/lojinha/products/open/${id}`),
  getAll: async (page, query) =>
    ApiRequest.post(`/product/list?page=${page}`, {
      lojinha_id: localStorage.getItem("id"),
      ...query
    }),
  uploadBanner: async (product_banner) => ApiRequest.post(`v2/lojinha/products/banner/upload`, {
    product_banner: product_banner,
  }), 
}

export default produtosRequest
