import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  cities: {
    '& .MuiFormControl-root': {
      margin: '2px 0 0 0'
    },

    '& .MuiSelect-root': {
      color: '#747474'
    },

    '& .MuiAutocomplete-root': {
      margin: '15px 0 0 0',
    },

    '& .MuiAutocomplete-root *': {
      borderColor: '#D0D0D0 !important',
      outline: 'none !important',
      color: '#747474'
    },

    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'none'
    },

    '&__selectedCities': {
      margin: '30px 0 0 0',

      '& h2':  {
        color: '#EA3458',
        fontWeight: '500',
        fontSize: '15px'
      },

      '& span': {
        color: '#555555',
        fontSize: '12px',
        fontWeight: '700',
        display: 'block',
        margin: '15px 0 10px 0'
      },

      '& p': {
        border: '1px solid #EA3458',
        borderRadius: '5px',
        background: '#F9F9F9',
        fontSize: '11px',
        fontWeight: '500',
        color: '#EA3458',
        padding: '4px 5px',
        margin: '0 5px 5px 0',
        display: 'inline-block',

        '& img': {
          width: '8px',
          height: '8px',
          marginLeft: '4px'
        }
      },

      '& hr': {
        border: '1px solid rgba(204, 204, 204, 0.22)'
      }
    }
  },

  btnFooterPink: {
    height: "50px"
  }
}))
