export const paymentMethods = [
  {value: 'credit_card', name: 'Cartão de crédito'},
  {value: 'debit_card', name: 'Cartão de Debito'},
  {value: 'pix', name: 'PIX'},
  {value: 'boleto', name: 'Boleto Bancário'},
  {value: 'cash', name: 'Dinheiro,'},
  {value: 'picpay', name: 'PicPay'},
  {value: 'meal_voucher', name: 'Vale Alimentação'},
  {value: 'food_voucher', name: 'Vale Refeição'},
  {value: 'to_match', name: 'A Combinar'}
]

export const variationsArr = [
  {id: 1, name: "Cor"},
  {id: 2, name: "Tamanho"},
  {id: 3, name: "Peso"},
  {id: 4, name: "Idioma"},
  {id: 5, name: "Material"},
  {id: 6, name: "Voltagem"},
  {id: 7, name: "Volume"},
  {id: 8, name: "Modelo"}
]
