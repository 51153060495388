import ApiRequest from "../index"

const usuarioRequest = {
  login: async (email, password) => ApiRequest.post(`login`, { email, password }),
  logout: async () => ApiRequest.post(`logout`),
  changePassword: async data => ApiRequest.post(`user/changepassword`, { ...data }),
  changePasswordByEmail: async (email, token, new_password) =>
    ApiRequest.post(`user/changepassword`, {
      email,
      token,
      new_password
    })
}

export default usuarioRequest
