import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  top: {
    display: "flex",
    minWidth: "100%",
    justifyContent: "space-between",
    height: "50px",
    alignItems: "center",
    padding: "35px 20px 35px 10px",
    boxSizing: "border-box"
  },
  arrowBack: {
    fontSize: "35px",
    color: "#EA3458",
    cursor: "pointer",
    minWidth: "30px"
  },
  btnDeletar: {
    border: "none",
    color: "#EA3458",
    fontWeight: "700",
    backgroundColor: "transparent",
    minHeight: "44px",
    padding: "0"
  }
}))
