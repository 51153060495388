import React from "react"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { useHistory } from "react-router-dom"
import { globalStyles } from "../../Componentes/CssGlobal"
import BannerSejaPro from "../../Componentes/BannerSejaPro"
import { useSettings } from "../../ContextAPI/settingsContext"

import { useStyles } from "./style"

import Topo from "../../Componentes/TopCategorias"

export default function Home() {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const { lojinhaInfo } = useSettings()
  const history = useHistory()
  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <Topo name="Estatísticas" />
          </div>
          <div className="infinite-scroll">
            <div
              className={classes.boxContainer}
              aria-hidden="true"
              onClick={() => {
                history.push("/acompanhamento-das-vendas")
              }}
            >
              <div className={classes.Box}>
                <div className={classesGlobal.fonteTitulo}>
                  Acompanhamento das vendas
                </div>
                <div className={classesGlobal.fonteSubtitulo}>
                  Acompanhamento das vendas por período, produtos mais vendidos,
                  status de pedidos, etc.
                </div>
              </div>
              <ArrowForwardIosIcon
                fontSize="small"
                color="disabled"
                role="presentation"
              />
            </div>

            <div
              className={classes.boxContainer}
              aria-hidden="true"
              onClick={() => {
                history.push("/relatorio-de-visitas")
              }}
            >
              <div className={classes.Box}>
                <div className={classesGlobal.fonteTitulo}>Relatório de visitas</div>
                <div className={classesGlobal.fonteSubtitulo}>
                  Saiba quais são os produtos que mais geram acessos e clicks em sua
                  loja
                </div>
              </div>
              <ArrowForwardIosIcon
                fontSize="small"
                color="disabled"
                role="presentation"
              />
            </div>
            {hasPro ? "" : <BannerSejaPro bannerId="banner-menu-estatisticas" />}
          </div>
        </div>
      </div>
    </>
  )
}
