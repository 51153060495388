import { Button, CircularProgress } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { SimpleImg } from "react-simple-img"
import Topo from "../../../../Componentes/TopCategorias"
import api from "../../../../Services/api"

export default function NotificacoesExternas(props) {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [notifications, setNotifications] = useState()

  async function getInformationsData() {
    const notification = await api.get(`/notification/show/${props.location.id}`)
    setNotifications(notification.data.data)

    setLoading(false)
  }

  async function hanldeUpdateRead() {
    history.goBack()
  }

  useEffect(() => {
    getInformationsData()
  }, [])

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <Topo name="Notificações" />
          </div>
          <div className="infinite-scroll">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  minHeight: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    style={{
                      fontSize: "1.8rem",
                      fontWeight: "bold",
                      textAlign: "center"
                    }}
                  >
                    {notifications?.title}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "30px"
                  }}
                >
                  {notifications?.image ? (
                    <SimpleImg
                      width={400}
                      height="auto"
                      placeholder={false}
                      src={notifications?.image}
                    ></SimpleImg>
                  ) : (
                    ""
                  )}
                </div>
                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "400",
                    textAlign: "center"
                  }}
                >
                  {notifications?.content}
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                    marginBottom: "30px"
                  }}
                >
                  {notifications.url ? (
                    <>
                      <a
                        aria-hidden="true"
                        href={notifications?.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          onClick={() => {
                            hanldeUpdateRead(props.location.id)
                          }}
                          variant="contained"
                          color="secondary"
                          size="large"
                        >
                          <>{notifications?.cta_button}</>
                        </Button>
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
