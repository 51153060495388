import { CircularProgress } from "@material-ui/core"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { StateGlobal } from "../../../../ContextAPI/index"
import { useProduct } from "../../../../ContextAPI/productContext"
import conexao from "../../../../Services/api"
import { useStyles } from "./style"
import noVisitors from "../../../../Assets/Home/noProducts.svg"

function InfiniteScroll() {
  const classes = useStyles()
  const { queryOrder } = useProduct()
  const [orderList, setOrderList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const listInnerRef = useRef()
  const [hasMore, setHasMore] = useState(true)

  const { setIdByOrder, setOrderPagesIndex } = StateGlobal()

  const loadNextPage = async (refresh = false) => {
    setIsLoading(true)
    if (refresh === true) {
      const { data } = await conexao.post("order/list", {
        ...queryOrder,
        lojinha_id: localStorage.getItem("id")
      })
      setOrderList([...data.data.data])
      if (data.data.next_page_url !== null) {
        setHasMore(true)
        setPage(oldState => oldState + 1)
      } else setHasMore(false)
    } else {
      const { data } = await conexao.post(`order/list?page=${page}`, {
        ...queryOrder,
        lojinha_id: localStorage.getItem("id")
      })
      setOrderList(oldState => [...oldState, ...data.data.data])
      if (data.data.next_page_url !== null) {
        setHasMore(true)
        setPage(oldState => oldState + 1)
      } else setHasMore(false)
    }
    setIsLoading(false)
    return true
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current

      if (scrollTop + clientHeight === scrollHeight && hasMore === true) {
        loadNextPage().then(() => {
          listInnerRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end"
          })
        })
      }
    }
  }

  useEffect(() => {
    loadNextPage(true)
  }, [queryOrder])

  return (
    <>
      {isLoading ? (
        <div className={classes.Loading} style={{ height: "600px" }}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div
          className="infinite-scroll"
          onScroll={() => onScroll()}
          ref={listInnerRef}
        >
          {orderList.length > 0 ? (
            orderList.map(item => {
              return (
                <>
                  <div
                    key={item.id}
                    className={classes.pedido}
                    aria-hidden="true"
                    onClick={() => {
                      setIdByOrder(item.id)
                      setOrderPagesIndex(2)
                    }}
                  >
                    <div className={classes.headerPedido}>
                      <span className={classes.idPedido}>{item.code}</span>
                      <span className={classes.dataPedido}>
                        {moment(item.created_at).format("DD/MM/YYYY HH:mm")}
                      </span>
                    </div>

                    <div>
                      <p className={classes.namePedido}>
                        {item.client !== null && item.client.name}
                      </p>
                    </div>

                    <div className={classes.footerPedido}>
                      <div style={{ display: "flex" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span className={classes.money}>
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL"
                            }).format(item.total_price)}
                          </span>{" "}
                        </div>
                        <div style={{ display: "flex", width: "min-content" }}>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "500",
                              fontSize:
                                item.status === "AGUARDANDO PAGAMENTO"
                                  ? "10px "
                                  : "13px",
                              marginLeft: "15px",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              color:
                                item.status === "PENDENTE"
                                  ? "#A4A4A4"
                                  : "" || item.status === "CANCELADO"
                                  ? "#A22727"
                                  : "" || item.status === "ENTREGUE"
                                  ? "#22543D "
                                  : "" || item.status === "EM TRÂNSITO"
                                  ? "#A28E27 "
                                  : "" || item.status === "AGUARDANDO PAGAMENTO"
                                  ? "#e4e4e4 "
                                  : "",
                              backgroundColor:
                                item.status === "PENDENTE"
                                  ? "#D8D8D8"
                                  : "" || item.status === "CANCELADO"
                                  ? "#FED7D7"
                                  : "" || item.status === "ENTREGUE"
                                  ? "#C6F6D5 "
                                  : "" || item.status === "EM TRÂNSITO"
                                  ? "#FEF8D7 "
                                  : "" || item.status === "AGUARDANDO PAGAMENTO"
                                  ? "#808080 "
                                  : "",
                              borderRadius: "5px"
                            }}
                          >
                            {item.status}
                          </span>
                        </div>
                      </div>
                      <div>
                        <ArrowForwardIosIcon fontSize="small" color="disabled" />
                      </div>
                    </div>
                  </div>
                </>
              )
            })
          ) : (
            <div className={classes.noVisitors}>
              <img
                src={noVisitors}
                alt="sem produtos"
                style={{
                  marginTop: "10px",
                  marginBottom: "12px",
                  maxWidth: "165px",
                  maxHeight: "115px"
                }}
              />
              <span className={classes.infoText}>
                Você ainda não teve nenhum pedido.
              </span>
            </div>
          )}
        </div>
      )}
    </>
  )
}
export default InfiniteScroll
