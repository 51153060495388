import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  btnFooterWhite: {
    height: "50px",
    backgroundColor: "#fff"
  },
  btnFooterPink: {
    height: "50px",
    marginBottom: "20px"
  },

  header: {
    width: "100vw",
    maxWidth: "600px",
    marginLeft: " -20px",
    marginBottom: "20px",
    "& .MuiTabs-flexContainer > *": {
      fontSize: "0.8rem"
    }
  },
  modalLeaving: {
    textAlign: "center",
    "& .MuiDialog-paper": {
      bottom: "0",
      position: "fixed!important",
      margin: "0",
      padding: "10px 20px",
      width: "100%",
      boxSizing: "border-box",
      borderRadius: "15px 15px 0px 0px"
    }
  },
  FormCep: {
    "& div": {
      display: "flex",
      flexDirection: "column",
      margin: "5px 0"
    },
    "& > div": {
      margin: "5px 0"
    },
    "& label": {
      fontSize: "0.8rem",
      padding: "5px 0",
      fontWeight: "400",
      color: "#555555"
    },
    "& input": {
      border: "1px solid #D0D0D0",
      borderRadius: "5px",
      height: "40px"
    },
    "input:focus": {
      outline: "none",
      border: "1px solid #EA3458cc",
      borderRadius: "5px"
    }
  }
}))
