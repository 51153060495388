import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  formBody: {
    "& div": {
      "& p": {
        margin: "0",
        marginBottom: "6px"
      },

      "& input": {
        height: "3px"
      },

      "& small": {
        fontSize: "11px",
        color: "#D62B2B",
        fontWeight: "400"
      }
    }
  }
}))
