import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined"
import React, { useRef } from "react"
import { useDrag, useDrop } from "react-dnd"
import { SimpleImg } from "react-simple-img"
import { useStyles } from "./style"

const CardDestaques = ({
  key,
  id,
  text,
  index,
  image,
  handleDelete,
  priority,
  moveCard
}) => {
  const ref = useRef(null)
  const classes = useStyles()

  const [{ handlerId }, drop] = useDrop({
    accept: "Card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })
  const [{ isDragging }, drag] = useDrag({
    type: "Card",
    item: () => {
      return { id, index }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const opacity = isDragging ? 0.5 : 1

  drag(drop(ref))

  return (
    <div
      ref={ref}
      className={classes.CardWrapper}
      style={{
        opacity,
        transform: isDragging ? "rotate(2deg)" : "rotate(0deg)",
        margin: isDragging ? "15px 0px" : "5px 0px",
        boxShadow: isDragging
          ? "1px 1px 10px rgba(0, 0, 0, 0.5)"
          : "0px 3px 10px rgba(0, 0, 0, 0.1)"
      }}
      data-handler-id={handlerId}
    >
      <svg
        width="13"
        height="20"
        viewBox="0 0 13 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 13.0001L6.5 18.7144L12 13.0001"
          stroke="#EA3458"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 7.28558L6.5 1.57129L1 7.28557"
          stroke="#EA3458"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <SimpleImg
        width="auto"
        height="auto"
        placeholder={false}
        src={image}
        alt="produto-imagem"
        style={{
          marginLeft: "1rem",
          maxWidth: "2.5rem",
          maxHeight: "2.5rem",
          borderRadius: "5px"
        }}
      />

      <p style={{ flex: 1, textAlign: "center" }}>{text}</p>

      <button
        onClick={() => handleDelete(id)}
        style={{ background: "transparent", border: "none" }}
      >
        <DeleteOutlineOutlinedIcon
          style={{
            fontSize: "1.8em",
            color: "#051626",
            cursor: "pointer",
            minWidth: "30px",
            border: "none",
            background: "transparent"
          }}
        />
      </button>
    </div>
  )
}

export default CardDestaques
