import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  PlanosAlertaModal: {
    "& .MuiDialogTitle-root": {
      padding: "0px 10px"
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "480px"
    }
  },
  PlanosAlertaModalHeader: {
    display: "flex",
    "& h2": {
      fontSize: "2rem",
      color: "#EA3458",
      fontWeight: "700",
      flex: 1,
      textAlign: "center",
      padding: "0.6rem"

    },

  },
  PlanosAlertaModalBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      fontSize: "1rem",
      fontWeight: "300",
      textAlign: "center",
    },
    "& span": {
      fontWeight: "600"
    },
    "& img": {
      width: "100%",
      maxWidth: "170px",
      margin: "10px 0"
    }
  }
})
export default useStyles
