import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
    LabelItem: {
        maxWidth: "100%",
        border: "2px solid #D0D0D0",
        borderRadius: "6px",
        padding: "16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        boxSizing: "border-box",
        "& p": {
            color: "#555555",
            fontSize: "0.8rem", 
            fontWeight: "bold",
            margin: 0,
            textTransform: "lowercase",

        },
        "& p::first-letter": {
            textTransform: "uppercase"
        },
        "& img": {
            color: "#555555",
            fontSize: "1.2rem", 
            fontWeight: "bold",
            cursor: "pointer"
        },
    },
    MoreOptions: {
        background: "white",
        padding: "8px",
        borderRadius: "6px",
        border: "1px solid #555",
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        gap:"8px",
        right: "-2px",
        bottom: "-43px",
        zIndex: 50,

        "& p": {
            fontSize: "0.8rem",
            cursor: "pointer"
        }
    }
}))