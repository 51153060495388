import Topo from "../../../Componentes/TopCategorias"
import payhow_title from '../../../Assets/svg/payhow_title.svg'
import payhow_banner from '../../../Assets/img/payhow_banner.png'
import all_payments from '../../../Assets/img/all_payments.png'
import CheckoutButton from "../../../Componentes/CheckoutButton"
import chill_working from '../../../Assets/img/chill_working.png'
import { useStyles } from "./style"
import { useHistory } from "react-router-dom"

export default function ActiveSuperCheckout() {
    const classes = useStyles()
    const history = useHistory()

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <Topo name="Ativar super checkout" />
          </div>

          <div className="infinite-scroll">
            <div className={classes.partner}>
                <p>Em parceria com o </p>
                <img src={payhow_title} alt=""/>
            </div>

            <img src={payhow_banner} style={{  width: '100%' }} alt=""/>

            <div className={classes.paymentsMethods}>
                <p>Comece a oferecer em sua loja virtual mais de 6 formas de pagamento diferentes:</p>
                <img src={all_payments} alt=""/>
                <span>parcelamento de até 12x sem juros ao seus clientes</span>
            </div>

            <div className={classes.activeCheckout}>
              <CheckoutButton outlined hasIcon onClick={() => {window.location.href = 'https://uppy.deepdata.com.br/'}}>
                Ativar meu cadastro agora!
              </CheckoutButton>
            </div>

            <img src={chill_working} style={{ float: 'right', margin: '50px 0 10px 0' }} alt=""/>
          </div>
        </div>
      </div>
    </>
  )
}
