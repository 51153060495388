import { Button, CircularProgress, Switch, TextField } from "@material-ui/core";
import Topo from "../../../../Componentes/TopCategorias";
import { useStyles } from "./style";
import mercadoPagoLogo from "../../../../Assets/img/mercado-pago-logo.png";
import CartoesdeCredito from "../Assets/CartaoCredito";
import Pix from "../Assets/pix";
import { useEffect, useState } from "react";
import api from "../../../../Services/api";
import CustomAlert from "../../../../Componentes/Alerts/CustomAlert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function CheckoutMercadoPago() {
  const [enableMercadoPagoIntegration, setEnableMercadoPagoIntegration] = useState(false);
  const [enableCreditCardPayment, setEnableCreditCardPayment] = useState(false);
  const [enablePixPayment, setEnablePixPayment] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [installments, setInstallments] = useState("12");
  const shopId = localStorage.getItem("id")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("success")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [currentPayment, setCurrentPayment] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [enablePagseguroIntegration, setEnablePagseguroIntegration] = useState(false);
  const [ paymentsActive, setPaymentsActive] = useState()


  const classes = useStyles();

  const handleChangePublicKey = (event) => {
    setPublicKey(event.target.value);
  };

  const handleChangeAccessToken = (event) => {
    setAccessToken(event.target.value);
  };


  async function updatePayment(active) {
    const body = {
      lojinha_id: shopId,
      payment_active: active ? "mercadopago" : paymentsActive.pagseguro === 1 ? "pagseguro" : "manual",
      payment_data: {
        ...currentPayment.payment_data,
        payhow: {
          mercadopago: active ? 1 : 0,
          pagseguro: 0,
          gateway: 0
        }
      }
    }

    localStorage.setItem("active_checkout", active ? "mercadopago" : "manual")

    await api.put(`/lojinha/payment/update/${currentPayment.id}`, body)
  }


  async function saveMercadoPagoData() {
    setLoading(true);

    const body = {
      payment_id: localStorage.getItem("payment_id"),
      enable: enableMercadoPagoIntegration ? 1 : 0,
      credentials: {
        access_token: accessToken,
        public_key: publicKey,
        installments: installments
      }
    };

    try {
      await api.post(`/payhow/mercadopago/${shopId}`, body);

      const IntegrationAuth = await integrationVerify();

      if (IntegrationAuth.authenticated === 1) {
        if (IntegrationAuth.enable === 1) {
          setLoading(false);
          setShowModalAlert(true);
          setAlertType("success");
          setAlertMessage(
            isEdit
              ? "Integração com Mercado Pago alterada com sucesso!"
              : "Integração com Mercado Pago criada com sucesso!"
          );
          updatePayment(true, "mercadopago");
          setTimeout(() => {
            history.goBack();
          }, 2000);
        } else if (IntegrationAuth.enable === 0 && !enableMercadoPagoIntegration) {
          setLoading(false);
          setShowModalAlert(true);
          setAlertType("success");
          setAlertMessage("Alterado para checkout manual!");
          updatePayment(false);
          setTimeout(() => {
            history.goBack();
          }, 2000);
        } else {
          setAlertMessage(
            isEdit
              ? "Erro ao editar integração com Mercado Pago!"
              : "Erro ao criar integração com Mercado Pago!"
          );
          setAlertType("error");
          setShowModalAlert(true);
          setLoading(false);
          updatePayment(false);
        }
      } else {
        setAlertMessage(
          isEdit
            ? "Erro ao editar integração com Mercado Pago!"
            : "Erro ao criar integração com Mercado Pago!"
        );
        setAlertType("error");
        setShowModalAlert(true);
        setLoading(false);
        updatePayment(false);
      }
    } catch (error) {
      console.error("Erro ao salvar integração com Mercado Pago:", error);
      setAlertMessage("Erro ao salvar integração com Mercado Pago!");
      setAlertType("error");
      setShowModalAlert(true);
      setLoading(false);
      updatePayment(false);
    }
  }

  async function integrationVerify() {
    const { data } = await api
      .get(`payhow/mercadopago/verify/${shopId}`)
      .then(response => response)
      .catch(error => console.error(error))
    return data.data
  }

  const getInformations = async () => {
    setLoading(true);

    try {
      const { data } = await api.get(`/lojinha/payment/get/${shopId}`);
      setPaymentsActive(data.data.payment_data.payhow);
      setCurrentPayment(data.data);

      const integration = await api.get(`/payhow/info/${shopId}`);
      const payhowData = integration?.data?.data;

      if (payhowData) {
        const mercadoPago = payhowData.find((obj) => obj.name === "Mercado Pago");
        if (mercadoPago) {
          setIsEdit(true);
          setEnableMercadoPagoIntegration(mercadoPago.enable === 1);
          setAccessToken(mercadoPago.credentials.access_token || "");
          setPublicKey(mercadoPago.credentials.public_key || "");
          setInstallments(mercadoPago.credentials.installments || "12");
        }
        const creditCardPayment = payhowData.find(
          (obj) => obj.type === "credit_card" && obj.name === "Mercado Pago"
        );
        if (creditCardPayment) {
          setEnableCreditCardPayment(creditCardPayment.enable === 1);
        }
      }
    } catch (error) {
      console.error("Erro ao obter informações:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInformations()
  }, [])

  return (
    <div className="page-container">
      <div className="page-align">
        <div>
          <Topo name="Integração com Mercado Pago" />
        </div>
        <div className={`${classes.pagseguroOrders} infinite-scroll`}>
          {loading ? (
            <div
              style={{
                display: "flex",
                height: "100%",
                minHeight: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <div className="infinite-scroll">
              <div className={classes.enableMercadoPagoIntegrationContainer}>
                <h2>Habilitar integração com Mercado Pago</h2>
                <Switch
                  checked={enableMercadoPagoIntegration}
                  onChange={() => setEnableMercadoPagoIntegration(!enableMercadoPagoIntegration)}
                />
              </div>
              <p className={classes.partnerContainer}>
                Em parceria com
                <img
                  src={mercadoPagoLogo}
                  alt="Mercado pago logo"
                  style={{ width: "80px", marginLeft: "10px" }}
                />
              </p>
              {enableMercadoPagoIntegration === true || enableMercadoPagoIntegration === 1 ? (
                <div className={classes.paymentOptionsContainer}>
                  <div className={classes.creditCardPaymentOptionContainer}>
                    <div className={classes.paymentOptionSwitchContainer}>
                      <div className={classes.switchLabelContainer}>
                        <CartoesdeCredito />
                        <h3>Permitir pagamento com cartão de crédito</h3>
                      </div>
                      <Switch
                        checked={enableCreditCardPayment}
                        onChange={() => setEnableCreditCardPayment(!enableCreditCardPayment)}
                      />
                    </div>
                    {/* <div>
                      <p>Total máximo de parcelas sem juros</p>
                      <TextField
                        fullWidth
                        disabled={!enableCreditCardPayment}
                        variant="outlined"
                        color="secondary"
                        value={installments}
                        select
                        onChange={(e) => setInstallments(e.target.value)}
                        SelectProps={{
                          native: true,
                        }}
                      >
                        {installmentOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </TextField>
                    </div> */}
                  </div>
                  <div>
                    <p>Access token</p>
                    <TextField
                      disabled={!enableCreditCardPayment && !enablePixPayment}
                      fullWidth
                      color="secondary"
                      variant="outlined"
                      multiline
                      rows={2}
                      value={accessToken}
                      onChange={handleChangeAccessToken}
                      placeholder="Token do Mercado Pago"
                    />
                  </div>
                  <div>
                    <p>Public key</p>
                    <TextField
                      disabled={!enableCreditCardPayment && !enablePixPayment}
                      fullWidth
                      color="secondary"
                      variant="outlined"
                      multiline
                      rows={2}
                      value={publicKey}
                      onChange={handleChangePublicKey}
                      placeholder="Chave pública do Mercado Pago"
                    />
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="footer-column">
          <hr style={{ width: "100%" }} />
          <Button
            onClick={saveMercadoPagoData}
            className={classes.buttonSave}
            type="submit"
            variant="contained"
          >
            Salvar informações
          </Button>
        </div>
      </div>
      <CustomAlert
        type={alertType}
        alertText={alertMessage}
        show={showModalAlert}
        close={() => setShowModalAlert(false)}
      />
    </div>
  );

}

export default CheckoutMercadoPago;
