import React from 'react'
import { useHistory } from 'react-router-dom'
import { SimpleImg } from 'react-simple-img'
import logoRosa from '../../../Assets/img/logorosa.png'
import primeiroAcessoImg from '../../../Assets/img/primeiro-acesso.png'
import { globalStyles } from '../../../Componentes/CssGlobal'
import { button } from '../../../Componentes/Inputs/TextFild'
import useStyles from './styles'

export default function PrimeiroAcesso() {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <div className={classes.PrimeiroAcessoTop}>
            <div className={classes.LogoRosa}>
              <SimpleImg
                width={135}
                height="auto"
                placeholder={false}
                src={logoRosa}
                alt="Lojinha Icon"
              />
            </div>
          </div>

          <div className="infinite-scroll">
            {/* <div className={classes.PrimeiroAcessoContainer}> */}
            <div className={classes.PrimeiroAcessoHeader}>
              <h2>Bem-vindo(a)!</h2>
              <h3>bora começar a vender?</h3>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <SimpleImg
                width={20}
                height="auto"
                placeholder={false}
                src={primeiroAcessoImg}
                alt="primeiro-acesso"
                className={classes.PrimeiroAcessoImg}
              />
            </div>
          </div>
          <div className="footer-column">
            {button({
              name: `Começar`,
              funcao: () => history.push('/sessao-guiada')
            })}
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  )
}
