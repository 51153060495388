import banner1Desktop from "../../Assets/banners_png/Banner-1---Produtos-ilimitados---DESKTOP.png"
import banner1Mobile from "../../Assets/banners_png/Banner-1---Produtos-ilimitados---MOBILE.png"
import banner2Desktop from "../../Assets/banners_png/Banner-2---Variações-de-produtos---DESKTOP.png"
import banner2Mobile from "../../Assets/banners_png/Banner-2---Variações-de-produtos---MOBILE.png"
import banner3Desktop from "../../Assets/banners_png/Banner-3---5-fotos-por-produtos-e-variações---DESKTOP.png"
import banner3Mobile from "../../Assets/banners_png/Banner-3---5-fotos-por-produtos-e-variações---MOBILE.png"
import banner4Desktop from "../../Assets/banners_png/Banner-4---Integração-com-instagram-shopping---DESKTOP.png"
import banner4Mobile from "../../Assets/banners_png/Banner-4---Integração-com-instagram-shopping---MOBILE.png"
import banner5Desktop from "../../Assets/banners_png/Banner-5---Relatórios-de-visitas-completo---DESKTOP.png"
import banner5Mobile from "../../Assets/banners_png/Banner-5---Relatórios-de-visitas-completo---MOBILE.png"
import banner6Desktop from "../../Assets/banners_png/Banner-6---Domínio-personalizado---DESKTOP.png"
import banner6Mobile from "../../Assets/banners_png/Banner-6---Domínio-personalizado---MOBILE.png"
import banner7Desktop from "../../Assets/banners_png/Banner-7---Integração-com-Google-Analytics---DESKTOP.png"
import banner7Mobile from "../../Assets/banners_png/Banner-7---Integração-com-Google-Analytics---MOBILE.png"
import banner8Desktop from "../../Assets/banners_png/Banner-8---Integração-com-Facebook-Pixel---DESKTOP.png"
import banner8Mobile from "../../Assets/banners_png/Banner-8---Integração-com-Facebook-Pixel---MOBILE.png"

export const bannersMobile = [
  banner1Mobile,
  banner2Mobile,
  banner3Mobile,
  banner4Mobile,
  banner5Mobile,
  banner6Mobile,
  banner7Mobile,
  banner8Mobile
]

export const bannersDesktop = [
  banner1Desktop,
  banner2Desktop,
  banner3Desktop,
  banner4Desktop,
  banner5Desktop,
  banner6Desktop,
  banner7Desktop,
  banner8Desktop
]
