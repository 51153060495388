import React from "react"
import { IoIosArrowBack } from "react-icons/io"
import { useStyles } from "./style"

export default function TopPagseguro({ isEdit, handleBack, name, noPadding }) {
  const classes = useStyles()

  return (
    <div className={classes.top}>
      <IoIosArrowBack className={classes.arrowBack} onClick={() => handleBack()} />
      <div className={classes.dots}>
      <h2 style={{ paddingLeft: !noPadding && "15px" }}>{name}</h2>
      </div>
      <div className={classes.space} />
    </div>

  )
}
