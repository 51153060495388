import { Button, CircularProgress } from "@material-ui/core"
import Topo from "../../../Componentes/TopCategorias"
import { useStyles } from "./style"
import { useEffect, useState, useRef } from "react"
import AdvancedCropper from "../../../Componentes/AdvancedCropper"
import { useLocation, useHistory } from "react-router-dom"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import requestBanners from "../../../Services/api/banners"
import AlertModal from "./components/AlertModal"
import ImageUploaderCrop from "../../../Componentes/ImageUploaderCrop"
import { useProduct } from "../../../ContextAPI/productContext"

export default function AddBanner() {
  const location = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const [src, setSrc] = useState("")
  const [imageDesktop, setImageDesktop] = useState("")
  const [imageMobile, setImageMobile] = useState("")
  const [isMobile, setIsMobile] = useState(false)
  const [openCrop, setOpenCrop] = useState(false)
  const [bannerName, setBannerName] = useState("")
  const [alertType, setAlertType] = useState("")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [onEdit, setOnEdit] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const receivedBanner = location.state?.banner
  const bannerType = location.state?.type
  const sessaoGuiada = location.state?.sessao_guiada
  const fileInputRefDesktop = useRef(null)
  const fileInputRefMobile = useRef(null)
  const { setTabSession } = useProduct()
  const bannerUrl =
    bannerType == "primary" ? "/banners-principais" : "/banners-secundarios"
  const url = sessaoGuiada ? "/sessao-guiada" : bannerUrl

  useEffect(() => {
    if (!receivedBanner.banner_id) {
      setOnEdit(false)
      return
    }
    setOnEdit(true)
    setBannerName(receivedBanner.banner_name)
    setImageDesktop(receivedBanner.banner_desktop)
    setImageMobile(receivedBanner.banner_mobile)
  }, [])

  useEffect(() => {
    if (src) {
      setOpenCrop(true)
    }
  }, [src])

  const handleChangeImage = async (e, isMobile) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      setIsMobile(isMobile)
      reader.onload = () => {
        setSrc(reader.result)
      }
    }
  }

  const handleButtonClick = isMobile => {
    if (isMobile) {
      fileInputRefMobile.current.click()
      return
    }
    fileInputRefDesktop.current.click()
  }

  const handleImageSet = image => {
    if (isMobile) {
      setImageMobile(image)
      return
    }
    setImageDesktop(image)
  }

  const handleSaveBanner = async () => {
    if (!bannerName.trim() || (!imageDesktop && !imageMobile)) {
      setAlertType("error")
      setAlertMessage("Adicione um nome e no minimo um banner")
      setShowModalAlert(true)
      return
    }
    setIsLoading(true)
    try {
      if (!onEdit) {
        await requestBanners.sendBanners(
          bannerName,
          bannerType,
          receivedBanner.banner_position,
          imageDesktop,
          imageMobile
        )
      } else {
        await requestBanners.updateBanners(
          receivedBanner.banner_id,
          bannerName,
          imageDesktop,
          imageMobile
        )
      }
      setAlertType("success")
      setAlertMessage("Banner atualizado com sucesso!")
      setShowModalAlert(true)
      if (sessaoGuiada) {
        setTabSession(4)
      }
      setTimeout(() => {
        history.push(url)
      }, 1000)
    } catch (e) {
      setAlertType("error")
      setAlertMessage("Erro ao atualizar banner!")
      setShowModalAlert(true)
      setIsLoading(false)
    }
  }

  return (
    <>
      {!openModal ? (
        !openCrop ? (
          <div className="page-container">
            <div className="page-align" style={{ justifyContent: "flex-start" }}>
              <div>
                <Topo
                  rota={url}
                  stepSessaoGuiada={`${sessaoGuiada ? 4 : null}`}
                  name={`Banner ${receivedBanner.banner_position}`}
                />
              </div>
              {isLoading ? (
                <div
                  style={{
                    backgroundColor: " #fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <CircularProgress color="secondary" size="40px" />
                </div>
              ) : (
                <>
                  <div className="infinite-scroll">
                    <div className={classes.main}>
                      <div className={classes.inputContainer}>
                        <label htmlFor="">Nome</label>
                        <input
                          type="text"
                          maxLength={50}
                          value={bannerName}
                          className={classes.input}
                          onChange={e => setBannerName(e.target.value)}
                        />
                      </div>
                      <ImageUploaderCrop
                        image={imageDesktop}
                        width="2340"
                        height="876"
                        setImage={setImageDesktop}
                        handleChangeImage={e => handleChangeImage(e, false)}
                        fileInputRef={fileInputRefDesktop}
                        handleButtonClick={() => handleButtonClick(false)}
                        buttonText="Adicionar banner desktop"
                        description={"Tamanho ideal para o banner desktop"}
                      />
                      <ImageUploaderCrop
                        image={imageMobile}
                        width="1440"
                        height="960"
                        setImage={setImageMobile}
                        handleChangeImage={e => handleChangeImage(e, true)}
                        fileInputRef={fileInputRefMobile}
                        handleButtonClick={() => handleButtonClick(true)}
                        buttonText="Adicionar banner mobile"
                        description={"Tamanho ideal para o banner mobile"}
                      />
                      {receivedBanner.banner_id && (
                        <span
                          className={classes.deleteBannerSpan}
                          onClick={() => setOpenModal(true)}
                        >
                          Deletar Banner
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="footer-column">
                    <hr style={{ width: "100%" }} />
                    <Button
                      onClick={handleSaveBanner}
                      className={classes.buttonSave}
                      type="submit"
                      variant="contained"
                    >
                      Salvar informações
                    </Button>
                  </div>
                </>
              )}
            </div>
            <CustomAlert
              type={alertType}
              alertText={alertMessage}
              show={showModalAlert}
              close={() => setShowModalAlert(false)}
            />
          </div>
        ) : (
          <AdvancedCropper
            setSrc={setSrc}
            src={src}
            close={() => setOpenCrop(false)}
            open={openCrop}
            setImage={handleImageSet}
            width={isMobile ? 1440 : 2340}
            height={isMobile ? 960 : 876}
          />
        )
      ) : (
        <AlertModal
          title={"Deletar banner"}
          description={
            "Todas as informações do banner serão perdidas. Deseja continuar?"
          }
          setOpenModal={setOpenModal}
          close={() => setOpenModal(false)}
          bannerId={receivedBanner.banner_id}
          modalOpen={openModal}
          url={url}
          sessaoGuiada={sessaoGuiada}
        />
      )}
    </>
  )
}
