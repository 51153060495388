import React from 'react'
import { useStyles } from './style'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { Link } from 'react-router-dom'

export default function ProgressCard({ checked, text, link }) {
  const classes = useStyles()
  return (
    <Link to={link}>
      <div className={classes.CardContainer}>
        <div className={classes.CardIcon}>
          <CheckCircleIcon
            style={{ color: checked ? '#2ECC71' : '#E4E4E4', fontSize: '1.8rem' }}
          />
        </div>
        <div className={classes.CardText}>
          <p>{text}</p>
        </div>
        <div className={classes.CardIcon}>
          <ArrowForwardIosIcon style={{ color: '#0D273D', fontSize: '1.8rem' }} />
        </div>
      </div>
    </Link>
  )
}
