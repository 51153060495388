import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles({
  ProdutosContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    flexWrap: "wrap",
    width: "100%",
    paddingTop: "2rem",
    backgroundColor: "transparent"
  },
  AdicionarProduto: {
    border: "1px dashed #A4A4A4",
    backgroundColor: "#F3F3F3",
    borderRadius: "5px",
    width: "130px",
    height: "130px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
    marginBottom: "50px",
    "& svg": {
      color: "#A4A4A4",
      fontSize: "3rem!important"
    },
    "& h3": {
      color: "#A4A4A4",
      fontWeight: "300",
      padding: "0 20px",
      textAlign: "center",
      fontSize: "0.8rem"
    }
  },
  Produto: {
    textAlign: "center",
    width: "130px",
    height: "130px",
    "& svg": {
      cursor: "pointer",
      paddingTop: "10px"
    },
    marginBottom: "50px"
  },
  ProdutoImg: {
    borderRadius: "5px",
    width: "100%",
    height: "130px",
    border: "1px solid #CDCDCD",
    backgroundSize: "cover"
  }
})
export default useStyles
