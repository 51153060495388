import Topo from "../../../Componentes/TopCategorias"
import pana_img from "../../../Assets/img/pana.png"
import { useStyles } from "./style"
import CheckoutButton from "../../../Componentes/CheckoutButton"
import { useHistory } from "react-router-dom"

export default function ActivePayhowRegistration() {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <Topo name="Ativar meu cadastro Payhow" />
          </div>

          <div className="infinite-scroll">
            <div className={classes.image}>
              <img src={pana_img} alt="pana img"/>
            </div>

            <p className={classes.text}>
              Gostaríamos de informar que nós já recebemos seus dados cadastrais e
              muito em breve nossa equipe enviará em seu e-mail de cadastro todas as
              informações e vantagens de ser um lojista que utiliza Payhow como forma
              de pagamento.
            </p>

            <div className={classes.footer}>
              <CheckoutButton onClick={() => history.push('/checkout-menu')}>Ok, vou aguardar</CheckoutButton>
              <span>Solicitar atendimento</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
