import { CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import noVisitors from "../../../../../Assets/Home/noProducts.svg"
import { globalStyles } from "../../../../../Componentes/CssGlobal"
import Topo from "../../../../../Componentes/TopCategorias"
import { useSettings } from "../../../../../ContextAPI/settingsContext"
import api from "../../../../../Services/api"
import CardInfo from "./components/CardInfo"
import CardProduto from "./components/CardProduto"
import CardVisitas from "./components/CardVisitas"
import { useStyles } from "./style"

export default function CardAno(props) {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const { lojinhaInfo } = useSettings()
  const [visits, setVisits] = useState()
  const [products, setProducts] = useState()
  const [loadPage, setLoadPage] = useState(true)
  const [charts, setCharts] = useState()
  const history = useHistory()

  const shopId = localStorage.getItem("id")

  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2

  async function getData() {
    const data = await api.get(
      `tracking/getbyyear/${shopId}`
    )
    const newData = data.data.data

    let trackingYearTotal = 0

    for (let index = 0; index < newData.length; index++) {
      trackingYearTotal = newData[index].tracking_total + trackingYearTotal
    }

    setVisits(trackingYearTotal)

    const data2 = await api.get(
      `tracking/getproducts/${shopId}/10`
    )
    const newDataProduct = data2.data.data
    setProducts(newDataProduct)

    const data3 = await api.get(
      `/tracking/getbyyear/${shopId}`
    )
    const newDataCharts = data3.data.data
    setCharts(newDataCharts)

    setLoadPage(false)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {loadPage ? (
        <div
          style={{
            backgroundColor: " #fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <CircularProgress color="secondary" size="40px" />
        </div>
      ) : (
        <>
          <div className="page-container">
            <div style={{ justifyContent: "flex-start" }} className="page-align">
              <Topo name="Relatório de visitas" />
              {hasPro ? (
                <div className={classes.topOption}>
                  <div className={classes.topSelector2}>
                    <div className={classes.topSelected}>Ano</div>
                    <div
                      className={classes.topSelector}
                      onClick={() => {
                        props.setPosition(1)
                      }}
                      aria-hidden="true"
                    >
                      <div>Mensal</div>
                      {/* <div style={{ color: '#EA3458', fontSize: '1em' }}>PRO</div> */}
                    </div>
                    <div
                      className={classes.topSelector}
                      onClick={() => {
                        props.setPosition(2)
                      }}
                      aria-hidden="true"
                    >
                      <div>7 dias</div>
                    </div>
                    <div
                      className={classes.topSelector}
                      onClick={() => {
                        props.setPosition(3)
                      }}
                      aria-hidden="true"
                    >
                      <div>Hoje</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={classes.topOption}>
                  <div className={classes.topSelector2}>
                    <div className={classes.topSelected}>Ano</div>
                    <div
                      className={classes.topSelector}
                      onClick={() => {
                        history.push("/planos")
                      }}
                      aria-hidden="true"
                    >
                      <div>Mensal</div>
                      <div style={{ color: "#EA3458", fontSize: "1em" }}>PRO</div>
                    </div>
                    <div
                      className={classes.topSelector}
                      onClick={() => {
                        history.push("/planos")
                      }}
                      aria-hidden="true"
                    >
                      <div>7 dias</div>
                      <div style={{ color: "#EA3458", fontSize: "1em" }}>PRO</div>
                    </div>
                    <div
                      className={classes.topSelector}
                      onClick={() => {
                        history.push("/planos")
                      }}
                      aria-hidden="true"
                    >
                      <div>Hoje</div>
                      <div style={{ color: "#EA3458", fontSize: "1em" }}>PRO</div>
                    </div>
                  </div>
                </div>
              )}
              <div style={{ maxHeight: "100%" }} className="infinite-scroll">
                <div className={classes.cardInfo}>
                  {visits === 0 ? (
                    <div className={classes.noVisitors}>
                      <img
                        src={noVisitors}
                        alt="sem produtos"
                        style={{
                          marginTop: "10px",
                          marginBottom: "12px",
                          maxWidth: "165px",
                          maxHeight: "115px"
                        }}
                      />
                      <span className={classes.infoText}>
                        Você ainda não teve visitas.
                      </span>
                    </div>
                  ) : (
                    <CardInfo data={visits}></CardInfo>
                  )}
                </div>
                {products.length <= 0 ? (
                  ""
                ) : (
                  <div style={{ display: "flex" }}>
                    <div style={{ color: "#EA3458", fontWeight: "bold" }}>
                      TOP 10
                    </div>
                    <div style={{ color: "#353535", fontWeight: "500" }}>
                      - Produtos mais procurados na sua loja
                    </div>
                  </div>
                )}
                <div className={classes.cardProduto}>
                  <CardProduto products={products} />
                </div>
                {charts.length <= 0 ? (
                  ""
                ) : (
                  <div>
                    <CardVisitas charts={charts} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
