import React, { useState } from "react"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { useHistory } from "react-router-dom"
import { useStyles } from "./style"
import Topo from "../../Componentes/TopCategorias"
import { globalStyles } from "../../Componentes/CssGlobal"
import { useSettings } from "../../ContextAPI/settingsContext"
import PlanosAlertaModal from "../Planos/Alertas/AlertaModal"
import api from "../../Services/api"


export default function Marketing() {
  const classesGlobal = globalStyles()
  const classes = useStyles()
  const history = useHistory()
  const { lojinhaInfo } = useSettings()
  const hasPro = lojinhaInfo && lojinhaInfo.plan_id === 2
  const [openModal, setOpenModal] = useState(false)

  function openPlanos() {
    const data = api.post(`/plan/intend`, {
      email: localStorage.getItem("email"),
      pro_intend_type: "pro_feature"
    })
    setOpenModal(true)
  }
  function handleCloseModal() {
    setOpenModal(false)
  }
  function planPro() {
    history.push("/planos")
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align" style={{ justifyContent: "flex-start" }}>
          <div>
            <Topo name="Marketing" />
          </div>
          <div className="infinite-scroll">
            <div className={classes.boxConteudo}>
              <div
                className={classes.boxConteudo}
                onClick={() => {
                  hasPro === true
                    ? history.push("/marketing/facebook-instagram")
                    : openPlanos()
                }}
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              >
                <div className={classesGlobal.fonteTitulo}>Facebook e Instagram</div>
                <div className={classes.btnalinhamento}>
                  <div>
                    <div className={classesGlobal.fonteSubtitulo}>
                      Instagram Shopping, Facebook Pixel entre outras funcionalidades
                      utilizando essas grandes redes sociais
                    </div>
                  </div>
                  <div>
                    <ArrowForwardIosIcon fontSize="small" color="disabled" />
                  </div>
                </div>
                <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
              </div>

              <div
                className={classes.boxConteudo}
                onClick={() => {
                  hasPro === true
                    ? history.push("/marketing/google-analytics")
                    : openPlanos()
                }}
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              >
                <div className={classesGlobal.fonteTitulo}>Google Analytics</div>
                <div className={classes.btnalinhamento}>
                  <div className={classesGlobal.fonteSubtitulo}>
                    Configure o Google Analytics em sua loja e ative as opções de
                    conversão e estatísticas
                  </div>
                  <div>
                    <ArrowForwardIosIcon fontSize="small" color="disabled" />
                  </div>
                </div>
                <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
              </div>

              <div
                onClick={() => {
                  hasPro === true
                    ? history.push("/marketing/configSEO")
                    : openPlanos()
                }}
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              >
                <div className={classesGlobal.fonteTitulo}>Configurações SEO</div>
                <div className={classes.btnalinhamento}>
                  <div className={classesGlobal.fonteSubtitulo}>
                    Personalize as informações que os buscadores da internet utilizam
                    para encontrar a sua loja
                  </div>
                  <div>
                    <ArrowForwardIosIcon fontSize="small" color="disabled" />
                  </div>
                </div>

                <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
              </div>

              <div
                onClick={() => {
                  history.push("/marketing/falar-com-especialista")
                }}
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              >
                <div className={classesGlobal.fonteTitulo}>Dicas de marketing</div>
                <div className={classes.btnalinhamento}>
                  <div className={classesGlobal.fonteSubtitulo}>
                    Acesse dicas únicas para alavancar suas vendas.
                  </div>
                  <div>
                    <ArrowForwardIosIcon fontSize="small" color="disabled" />
                  </div>
                </div>
                <hr style={{ width: "100%", borderColor: "#d4d4d445" }} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {openModal === true ? (
        <PlanosAlertaModal
          isOpen={openModal}
          handleClose={handleCloseModal}
          isPush={planPro}
          bannerId="modal-oops-marketing"
        />
      ) : (
        ""
      )}
    </>
  )
}
