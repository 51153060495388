import { Button } from "@material-ui/core"
import React, { useState } from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import CustomAlert from "../../Componentes/Alerts/CustomAlert"
import Topo from "../../Componentes/TopCategorias"
import { useProduct } from "../../ContextAPI/productContext"
import api from "../../Services/api"
import VendidosContainer from "./DragAndDropVendidos/VendidosContainer"

export default function ProdutosVendidos() {
  const { productsTopSaleList } = useProduct()

  const [alertType, setAlertType] = useState("")
  const [showAlertOk, setShowAlertOk] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)

  const closeAlerts = () => {
    setShowAlertOk(false)
    setShowAlertError(false)
  }

  async function handleSaveTopSaleList() {
    let resClear = await api.get(
      `/product/cleartopsale/${localStorage.getItem("id")}`)
    if (resClear) {
      productsTopSaleList.map(async function (card, index) {
        card.priority_top_sale = index + 1
        let { priority_top_sale } = card
        await api.put(`/product/update/${card.id}`, {
          top_sale: 1,
          priority_top_sale
        })
      })
      setAlertType("success")
      setShowAlertOk(true)
    } else {
      setAlertType("error")
      setShowAlertError(true)
    }
  }

  return (
    <>
      <div className="page-container">
        <div className="page-align">
          <Topo name="Produtos mais vendidos" />
          <div className="infinite-scroll">
            <div style={{ marginBottom: "1rem" }}>
              <h2>Organize abaixo a ordem de exibição dos produtos mais vendidos</h2>
              <h3>
                Adicione produtos como mais vendidos:{" "}
                <Link
                  to="/meus-produtos"
                  style={{ color: "#EA3458", textDecoration: "underline" }}
                >
                  Meus Produtos
                </Link>
              </h3>
            </div>
            <div className="infinite-scroll">
              <VendidosContainer />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <CustomAlert
                type={alertType}
                alertText={
                  alertType === "success"
                    ? "Dados salvos com sucesso!"
                    : "Algo deu errado!"
                }
                show={showAlertOk || showAlertError}
                close={() => closeAlerts()}
              />
            </div>
          </div>

          <div className="footer-column">
            <hr style={{ width: "100%" }} />
            <Button
              onClick={() => handleSaveTopSaleList()}
              type="submit"
              style={{
                color: "#fff",
                minWidth: "100%",
                height: "50px",
                boxShadow:
                  "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                marginTop: "5px",
                marginBottom: "15px",
                textTransform: "none",
                backgroundColor: "#EA3458"
              }}
              // className={classes.btnAddLogo}
              variant="contained"
            >
              Salvar alterações
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
