import { useStyles } from "./style"
import ImgPreview from "../ImgPreview"
export default function ImageUploaderCrop({ image, height, width, setImage, fileInputRef, handleChangeImage, handleButtonClick, buttonText, description}) {
    const classes = useStyles()
    return (
        <div className={classes.uploadContainer}>
            <ImgPreview image={image} width={width} height={height} setImage={setImage} description={description}/>
            <div style={{ width: "100%" }}>
                <input
                    hidden="true"
                    id="new-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleChangeImage}
                    ref={fileInputRef}
                />
                <button onClick={handleButtonClick} className={classes.buttonAddImage}>{buttonText}</button>
            </div>
        </div>
    )
}