import ApiRequest from "../index"

const categoriasRequest = {
  create: async name =>
    ApiRequest.post(`category/create`, {
      name,
      lojinha_id: localStorage.getItem("id")
    }),
  get: async () => ApiRequest.get(`category/get/${localStorage.getItem("id")}`),
  delete: async id => ApiRequest.delete(`category/${id}`),
  update: async (id, data) => ApiRequest.put(`category/update/${id}`, { ...data })
}

export default categoriasRequest
