import { CircularProgress, InputAdornment, TextField } from "@material-ui/core"
import Switch from "@material-ui/core/Switch"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import CustomAlert from "../../../Componentes/Alerts/CustomAlert"
import { CepInput } from "../../../Componentes/CustomInputs/CEP"
import { RequiredInput } from "../../../Componentes/CustomInputs/RequiredInput"
import { button } from "../../../Componentes/Inputs/TextFild"
import Topo from "../../../Componentes/TopCategorias"
import { StateGlobal } from "../../../ContextAPI/index"
import retiradaRequest from "../../../Services/api/retirada"
import { useStyles } from "./style"

export default function RetirarLoja() {
  const classes = useStyles()
  const {
    pickupShippingCitiesData,
    pickupShippingStatesData,
    setPickupShippingCitiesData,
    setPickupShippingStatesData,
    enableWithdrawDelivery,
    setEnableWithdrawDelivery,
    hasContext,
    setAddress,
    address
  } = StateGlobal()

  const [isEdit, setIsEdit] = useState(true)

  const [enableWithdrawByState, setEnableWithdrawByState] = useState(false)
  const [enableWithdrawByCity, setEnableWithdrawByCity] = useState(false)
  const shopId = localStorage.getItem("id")
  const [alertMessage, setAlertMessage] = useState("")
  const [loading, setLoading] = useState(true)
  const [alertType, setAlertType] = useState("success")
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [alertMsg, setAlertMsg] = useState("Salvo com sucesso")
  const history = useHistory()
  const [withdrawalEditId, setWithdrawalEditId] = useState()

  const handleLeaving = () => {
    setTimeout(() => {
      history.goBack()
    }, 2500)
  }

  useEffect(() => {
    handleEdit()
  }, [])

  const searchPostalCode = async postalCode => {
    const replacedCEP = postalCode.replace("-", "").replace("_", "")
    if (replacedCEP.length === 8) {
      await axios
        .get(`https://viacep.com.br/ws/${postalCode.replace(/\D/g, "")}/json/`)
        .then(res => {
          if (res.data.erro !== true)
            setAddress({
              zip_code: res.data.cep.replace(/\D/g, ""),
              street: res.data.logradouro,
              neighborhood: res.data.bairro,
              city: res.data.localidade,
              state: res.data.uf,
              number: "",
              complement: ""
            })
        })
    }
  }

  const handleAddressChange = e => {
    const { name, value } = e.target
    if (name === "zip_code") {
      searchPostalCode(value)
    }
    setAddress(oldState => {
      return { ...oldState, [name]: value }
    })
  }

  const handleEdit = async () => {
    const withdrawal = await retiradaRequest.get(shopId)

    if (withdrawal.data.data === null) {
      setIsEdit(false)
      setLoading(false)

      return
    }

    setWithdrawalEditId(withdrawal?.data?.data?.id)
    const withdrawalData = withdrawal.data.data.shipping_data.address
    const withdrawalDataStateFilter =
      withdrawal.data.data.shipping_data.state_filter || []
    const withdrawalDataCitiesFilter =
      withdrawal.data.data.shipping_data.state_city_filter || []

    setAddress({
      city: withdrawalData.city,
      complement: withdrawalData.complement,
      neighborhood: withdrawalData.neighborhood,
      number: withdrawalData.number,
      state: withdrawalData.state,
      street: withdrawalData.street,
      zip_code: withdrawalData.zip_code
    })

    if (!enableWithdrawDelivery) {
      setEnableWithdrawDelivery(!!withdrawal.data.data.shipping_active)
    }

    if (!pickupShippingCitiesData.length && !hasContext) {
      setPickupShippingCitiesData(withdrawalDataCitiesFilter)
    }

    if (!pickupShippingStatesData.length && !hasContext) {
      setPickupShippingStatesData(withdrawalDataStateFilter)
    }

    setLoading(false)
  }

  async function saveWithdrawal() {
    const body = {
      lojinha_id: shopId,
      shipping_type: "pickup",
      shipping_active: enableWithdrawDelivery ? 1 : 0,
      shipping_data: {
        address,
        state_filter: pickupShippingStatesData,
        state_city_filter: pickupShippingCitiesData
      }
    }

    try {
      isEdit
        ? await retiradaRequest.put(withdrawalEditId, body)
        : await retiradaRequest.create(body)

      setAlertMsg(
        isEdit
          ? "Opção de Entrega alterada com sucesso!"
          : "Opção de Entrega criado com sucesso!"
      )
      setShowModalAlert(true)
      setAlertType("success")
      setPickupShippingCitiesData(false)
      setPickupShippingStatesData(false)
      handleLeaving()
    } catch (e) {
      let { error } = e.response.data

      if (error) {
        setAlertMsg(
          isEdit
            ? "Erro ao editar o Opção de Entrega !"
            : "Erro ao criar o Opção de Entrega !"
        )
        setAlertType("error")
        setShowModalAlert(true)
      }
    }
  }

  function selectCitiesPlaceholder(cities) {
    let newArr = []

    cities.forEach(city => {
      newArr.push(...city.cities)
    })

    return newArr.join(", ")
  }

  return (
    <div className="page-container">
      <div className="page-align">
        <div>
          <Topo name="Possibilidade de retirar na loja" />
          <CustomAlert
            type={alertType}
            alertText={alertMsg}
            show={showModalAlert}
            close={() => setShowModalAlert(false)}
          />
        </div>

        <div className="infinite-scroll">
          {loading ? (
            <div
              style={{
                display: "flex",
                height: "100%",
                minHeight: "100%",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <div>
              <div className={classes.toggledTitle}>
                <h2>Habilitar retirada na loja</h2>
                <Switch
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onClick={() => {
                    setEnableWithdrawDelivery(!enableWithdrawDelivery)
                  }}
                  checked={enableWithdrawDelivery}
                />
              </div>

              {enableWithdrawDelivery ? (
                <div>
                  <div className={classes.EtapaCadastroContainer}>
                    <div className={classes.FormEndereco}>
                      <div className={classes.formStyleInput}>
                        <h4 htmlFor="zip_code" className={classes.title}>
                          CEP do local
                        </h4>
                        <CepInput
                          value={address.zip_code}
                          callback={handleAddressChange}
                          type="tel"
                          submit={submit}
                          name="zip_code"
                        />
                      </div>

                      <div className={classes.formStyleInput}>
                        <h4 htmlFor="street" className={classes.title}>
                          Rua
                        </h4>
                        <RequiredInput
                          type="text"
                          name="street"
                          value={address.street}
                          callback={e => handleAddressChange(e)}
                          submit={submit}
                        />
                      </div>

                      <div className={classes.FormGroup}>
                        <div className="numeroInput">
                          <h4 htmlFor="number" className={classes.title}>
                            Número
                          </h4>
                          <RequiredInput
                            type="number"
                            name="number"
                            value={address.number}
                            callback={e => handleAddressChange(e)}
                            submit={submit}
                          />
                        </div>

                        <div className="bairroInput">
                          <h4 htmlFor="complement" className={classes.title}>
                            Complemento
                          </h4>
                          <RequiredInput
                            type="text"
                            name="complement"
                            value={address.complement}
                            callback={e => handleAddressChange(e)}
                            isRequired={false}
                          />
                        </div>
                      </div>

                      <div className="bairroInput">
                        <h4 htmlFor="neighborhood" className={classes.title}>
                          Bairro
                        </h4>
                        <RequiredInput
                          type="text"
                          name="neighborhood"
                          value={address.neighborhood}
                          callback={e => handleAddressChange(e)}
                          submit={submit}
                        />
                      </div>

                      <div className={classes.FormGroup}>
                        <div className="cidadeInput">
                          <h4 htmlFor="city" className={classes.title}>
                            Cidade
                          </h4>
                          <RequiredInput
                            type="text"
                            id="city"
                            name="city"
                            value={address.city}
                            callback={e => handleAddressChange(e)}
                            submit={submit}
                          />
                        </div>
                        <div className="estadoInput">
                          <h4 htmlFor="state" className={classes.title}>
                            Estado
                          </h4>
                          <RequiredInput
                            type="text"
                            name="state"
                            value={address.state}
                            callback={e => handleAddressChange(e)}
                            submit={submit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.divbox}>
                    <div className={classes.switch}>
                      <h2>Opção ativa por região/estado?</h2>
                      <Switch
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onClick={() => {
                          setEnableWithdrawByState(!enableWithdrawByState)
                        }}
                        checked={
                          enableWithdrawByState ||
                          pickupShippingStatesData.length > 0
                        }
                      />
                    </div>

                    {enableWithdrawByState || pickupShippingStatesData.length > 0 ? (
                      <>
                        <h4
                          style={{
                            margin: "10px 0",
                            fontWeight: "500",
                            color: "#555555"
                          }}
                        >
                          Estados
                        </h4>
                        <Link to="/selecionar-estado">
                          <TextField
                            name="states"
                            type="text"
                            value={
                              pickupShippingStatesData.join(", ") ||
                              "Selecionar estados"
                            }
                            size="medium"
                            disabled
                            variant="outlined"
                            color="secondary"
                            style={{
                              display: "flex",
                              width: "100%",
                              backgroundColor: "#fff",
                              cursor: "pointer"
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  <ArrowForwardIosIcon
                                    style={{ color: "#A4A4A4" }}
                                  />
                                </InputAdornment>
                              )
                            }}
                          ></TextField>
                        </Link>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div>
                    <div className={classes.divbox}>
                      <div className={classes.switch}>
                        <h2>Opção ativa somente por cidade?</h2>
                        <Switch
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          onClick={() => {
                            setEnableWithdrawByCity(!enableWithdrawByCity)
                          }}
                          checked={
                            enableWithdrawByCity ||
                            pickupShippingCitiesData.length > 0
                          }
                        />
                      </div>

                      {enableWithdrawByCity ||
                      pickupShippingCitiesData.length > 0 ? (
                        <>
                          <h4
                            style={{
                              margin: "10px 0",
                              fontWeight: "500",
                              color: "#555555"
                            }}
                          >
                            Cidade
                          </h4>
                          <Link to="/selecionar-cidade">
                            <TextField
                              name="states"
                              type="text"
                              value={
                                pickupShippingCitiesData.length > 0
                                  ? selectCitiesPlaceholder(pickupShippingCitiesData)
                                  : ""
                              }
                              size="medium"
                              disabled
                              variant="outlined"
                              placeholder="Selecionar cidades"
                              color="secondary"
                              style={{
                                display: "flex",
                                width: "100%",
                                backgroundColor: "#fff",
                                cursor: "pointer"
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <ArrowForwardIosIcon
                                      style={{ color: "#A4A4A4" }}
                                    />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <div className="footer-column">
          <hr style={{ width: "100%", borderColor: "#E4E4E4", opacity: 0.3 }} />

          {button({
            name: "Salvar Alterações",
            funcao: saveWithdrawal
          })}
        </div>
      </div>
    </div>
  )
}
