import ApiRequest from "../index"

const variationsRequest = {
  create: async data =>
    ApiRequest.post(`variation/product/create`, {
      lojinha_id: Number(localStorage.getItem("id")),
      ...data
    }),
  put: async (id, data) =>
    ApiRequest.put(`variation/product/update/${id}`, {
      lojinha_id: Number(localStorage.getItem("id")),
      ...data
    }),
  get: async id => ApiRequest.get(`variation/product/show/${id}`),
  delete: async id => ApiRequest.delete(`variation/product/delete/${id}`),
  deleteAll: async id => ApiRequest.delete(`variation/product/deleteall/${id}`)
}

export default variationsRequest
