import { Button, Checkbox, Dialog, DialogActions, FormControlLabel, FormGroup, Modal } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import CustomAlert from "../../../../../Componentes/Alerts/CustomAlert"
import TopModal from "../../../../../Componentes/TopModal"
import { useProduct } from "../../../../../ContextAPI/productContext"
import { useStyles } from "./style"

export default function SelectCategoriesFirstAccess({ open, handleCloseCategoriesModal, }) {
  const classes = useStyles()
  const history = useHistory()
  const { currentProduct, setCurrentProduct, categoriesList } = useProduct()
  const [selectedCategories, setSelectedCategories] = useState([])
  const [showModalAlert, setShowModalAlert] = useState(false)
  const show = true

  const handleChange = (e, item) => {
    const hasCategory = selectedCategories.find(el => el.id === item.id)
    if (hasCategory === undefined) {
      setSelectedCategories(oldState => [
        ...oldState,
        { id: item.id, name: item.name }
      ])
    } else {
      setSelectedCategories(oldState => oldState.filter(el => el.id !== item.id))
    }
  }

  function handleSave() {
    setCurrentProduct(oldState => {
      return { ...oldState, categories: selectedCategories }
    })
    setShowModalAlert(true)
    handleCloseCategoriesModal()

  }

  useEffect(() => {
    if (
      currentProduct?.categories !== undefined &&
      currentProduct?.categories.length
    ) {
      setSelectedCategories(currentProduct.categories)
    }
  }, [])

  return (
    <>
      {open && (
        <>
          <div className={classes.container}>
            <Dialog
              className={classes.container}
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <TopModal
                name="Selecionar categoria"
                handleClose={handleCloseCategoriesModal}
              />
              <FormGroup style={{ height: '50vh', width: '300px', padding: '10px 10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: '10px 10px' }}>
                <div style={{display: 'flex', flexDirection: 'column'}}>

                  {categoriesList.length === 0 ? (
                    <p style={{ paddingTop: "20px" }}>
                      Você ainda não possui categorias cadastradas para os seus produtos.
                    </p>
                  ) : (
                    categoriesList.map(category => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedCategories.find(el => el.id === category.id) !==
                              undefined
                            }
                            onChange={e => handleChange(e, category)}
                            name={category.id}
                          />
                        }
                        label={category.name}
                        style={{
                          borderBottom: "1px solid #d9d9d9",
                          marginBottom: "10px"
                        }}
                      />
                    ))
                  )}
                </div>

                <Button
                  className={classes.btnFooterPink}
                  onClick={() => handleSave()}
                  fullWidth
                  variant="contained"
                  color="secondary"
                >
                  Salvar e sair
                </Button>
              </FormGroup>
            </Dialog>
          </div>
          {showModalAlert ? (
            <CustomAlert
              type="success"
              alertText="Categorias salvas com sucesso!"
              show={showModalAlert}
              close={() => setShowModalAlert(false)}
            />
          ) : (
            ""
          )}

        </>)}
    </>
  )
}
