import { useHistory } from "react-router-dom"
import { SimpleImg } from "react-simple-img"
import ativar from "../../../../Assets/svg/ativar.svg"
import api from "../../../../Services/api"
import { useStyles } from "./style"

export function BannerHeader({ totalProducts }) {
  const classes = useStyles()
  const history = useHistory()

  function planProHeader() {
    const data = api.post(`/plan/intend`, {
      email: localStorage.getItem("email"),
      pro_intend_type: "Banner_Header_Pro_Home"
    })
    sessionStorage.setItem("closeHeaderPro", true)
    history.push("/planos/v2/checkout")
  }

  return (
    <>
      <div className={classes.bannerPro}>
        <div className={classes.bannerWrapper}>
          <h3 className={classes.subtitleBanner}>Limite de produtos ativos:</h3>
          <h2 className={classes.titleBanner}>{totalProducts} de 5</h2>
        </div>

        <div className={classes.imgBanner}>
          <SimpleImg
            width="auto"
            height="auto"
            placeholder={false}
            src={ativar}
            alt="Ativar"
          />
        </div>
        {/* <Button
          onClick={() => planProHeader()}
          style={{
            backgroundColor: "#52EB58",
            margin: "10px",
            textTransform: "lowercase",
            fontSize: "14px",
            maxWidth: "180px",
            lineHeight: 1.1
          }}
        >
          ativar produtos ilimitados agora
          <ArrowForwardIosIcon />
        </Button> */}
      </div>
    </>
  )
}
